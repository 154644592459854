import React, { FC, useEffect, useState } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { useDispatch } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import { PrivateChatCreateApiArg, usePrivateChatCreateMutation } from 'store/apiMain/mainApi'
import { useTranslation } from 'react-i18next'

const AdminCreatePrivateChat: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const [createAdminPrivateChat, { isSuccess }] = usePrivateChatCreateMutation()
  const { t } = useTranslation()
  useEffect(() => {
    if (isSuccess) {
      closeModal()
    }
  }, [isSuccess])
  const [clientId, setClientId] = useState<string>('')
  const [modelId, setModelId] = useState<string>('')
  const [messageText, setMessageText] = useState<string>('')

  const handleCreateChat = () => {
    const payload: PrivateChatCreateApiArg = {
      privateChatCreateRequest: {
        initialMessage: {
          body: messageText,
        },
        type: 'SUPPORT',
        clientId: clientId,
        modelId: modelId,
      },
    }
    createAdminPrivateChat(payload)
  }

  return (
    <>
      <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
        <div className='modal'>
          <div className='popup'>
            <div className='AdminCreatePrivateChat'>
              <h2>{t('ui.popUp.adminCreatePrivateChat.admin_create_chat')}</h2>
              <input type='text' placeholder='Client ID' value={clientId} onChange={e => setClientId(e.target.value)} />
              <input type='text' placeholder='Model ID' value={modelId} onChange={e => setModelId(e.target.value)} />
              <input type='text' placeholder='Message Text' value={messageText} onChange={e => setMessageText(e.target.value)} />
              <button
                onClick={() => {
                  closeModal()
                }}
                type='submit'
              >
                {t('ui.popUp.adminCreatePrivateChat.close_chat')}
              </button>
              <button onClick={handleCreateChat}>{t('ui.popUp.adminCreatePrivateChat.create_chat')}</button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default AdminCreatePrivateChat
