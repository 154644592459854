import { setupDisconnectButton } from '@livekit/components-core'
import { ConnectionState } from 'livekit-client'
import * as React from 'react'
import { DisconnectButtonProps, useConnectionState, useRoomContext } from '@livekit/components-react'
export function mergeProps<U extends HTMLElement>(...propsList: Array<React.HTMLAttributes<U> | undefined>): React.HTMLAttributes<U> {
  const result: React.HTMLAttributes<U> = {}

  for (const props of propsList) {
    if (!props) continue

    // Merge or append the className.
    if (props.className) {
      result.className = [result.className, props.className].filter(Boolean).join(' ')
    }

    // Merge style objects.
    if (props.style) {
      result.style = { ...(result.style || {}), ...props.style }
    }

    // Merge all other keys (including event handlers).
    for (const key of Object.keys(props)) {
      // Skip the keys we've already handled manually.
      if (key === 'className' || key === 'style') {
        continue
      }

      // If it's an event handler like onClick, chain them.
      if (key.startsWith('on') && typeof (props as any)[key] === 'function') {
        const existingHandler = (result as any)[key]
        const newHandler = (props as any)[key]
        if (existingHandler) {
          ;(result as any)[key] = function chainedHandler(...args: any[]) {
            existingHandler(...args)
            newHandler(...args)
          }
        } else {
          ;(result as any)[key] = newHandler
        }
      } else {
        // For everything else (e.g. id, disabled, etc.), just overwrite.
        ;(result as any)[key] = (props as any)[key]
      }
    }
  }

  return result
}
/**
 * The `useDisconnectButton` hook is used to implement the `DisconnectButton` or your
 * custom implementation of it. It adds onClick handler to the button to disconnect
 * from the room.
 *
 * @example
 * ```tsx
 * const { buttonProps } = useDisconnectButton(buttonProps);
 * return <button {...buttonProps}>Disconnect</button>;
 * ```
 * @public
 */
export function useDisconnectButton(props: DisconnectButtonProps) {
  const room = useRoomContext()
  const connectionState = useConnectionState(room)

  const buttonProps = React.useMemo(() => {
    const { className, disconnect } = setupDisconnectButton(room)
    const mergedProps = mergeProps(props, {
      className,
      onClick: () => disconnect(props.stopTracks ?? true),
    })
    return mergedProps
  }, [room, props, connectionState])

  return { buttonProps }
}
