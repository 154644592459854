import React, { useState, useEffect, useRef } from 'react'
import { Editor, EditorState, RichUtils, Modifier, convertToRaw, convertFromRaw, DraftInlineStyle, ContentBlock } from 'draft-js'
import 'draft-js/dist/Draft.css'
import Picker from '@emoji-mart/react' // Emoji Picker
import { SketchPicker, ColorResult } from 'react-color' // Color Picker
import './scss/index.scss'
import useOutsideClick from 'hooks/useOutsideClick'
import { useTranslation } from 'react-i18next'

interface CustomTextAreaProps {
  onChangeRaw?: (content: any) => void // Prop to pass formatted data
  initialRawContent?: any // Prop for initial content
  view?: boolean
  formik?: any
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  onChangeRaw,
  initialRawContent,
  formik,
  view = false, // Значение по умолчанию для view
}) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
  const [selectedColor, setSelectedColor] = useState<string>('#000000')
  const [isInitialContentLoaded, setIsInitialContentLoaded] = useState<boolean>(false)
  const [fontSize, setFontSize] = useState<number>(16) // Font size state
  const [fontFamily, setFontFamily] = useState<string>('Arial') // Font family state
  const { t } = useTranslation()

  useEffect(() => {
    if (initialRawContent && !isInitialContentLoaded) {
      let parsedContent
      if (typeof initialRawContent === 'string') {
        try {
          parsedContent = JSON.parse(initialRawContent)
        } catch (error) {
          console.error('Failed to parse initialRawContent:', error)
        }
      } else {
        parsedContent = initialRawContent
      }

      if (parsedContent && parsedContent.blocks) {
        const contentState = convertFromRaw(parsedContent)
        setEditorState(EditorState.createWithContent(contentState))
        setIsInitialContentLoaded(true)
      }
    }
  }, [initialRawContent, isInitialContentLoaded])

  const handleClickOutsideEmojiPicker = () => {
    setShowEmojiPicker(false)
  }
  const handleClickOutsideColorPicker = () => {
    setShowColorPicker(false)
  }

  const emojiPickerRef = useOutsideClick<HTMLDivElement>(handleClickOutsideEmojiPicker, showEmojiPicker)
  const colorPickerRef = useOutsideClick<HTMLDivElement>(handleClickOutsideColorPicker, showColorPicker)

  const updateRawContent = (newEditorState: EditorState) => {
    const contentState = newEditorState.getCurrentContent()
    const rawContent = convertToRaw(contentState)
    if (onChangeRaw) {
      onChangeRaw(rawContent)
    }
  }

  const onChange = (newState: EditorState) => {
    setEditorState(newState)
    updateRawContent(newState)
  }

  const insertEmoji = (emoji: { native: string }) => {
    const contentState = editorState.getCurrentContent()
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      const collapsedSelection = selection.merge({
        anchorOffset: selection.getEndOffset(),
        focusOffset: selection.getEndOffset(),
      })
      const collapsedEditorState = EditorState.acceptSelection(editorState, collapsedSelection)
      setEditorState(collapsedEditorState)
    }

    const newContentState = Modifier.insertText(contentState, selection, emoji.native)
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
    setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()))
    updateRawContent(newEditorState)
  }

  const handleEmojiSelect = (emoji: { native: string }) => {
    insertEmoji(emoji)
    setShowEmojiPicker(false)
  }

  const toggleBold = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, 'BOLD')
    setEditorState(newState)
    updateRawContent(newState)
  }

  const applyColor = (color: string) => {
    if (!color) return
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const colorStyle = `COLOR-${color.replace('#', '')}`

    const stylesToRemove = editorState
      .getCurrentInlineStyle()
      // @ts-ignore
      .filter(style => style && style.startsWith('COLOR-'))

    let newContentState = contentState

    stylesToRemove.forEach(style => {
      if (style) {
        newContentState = Modifier.removeInlineStyle(newContentState, selection, style)
      }
    })

    newContentState = Modifier.applyInlineStyle(newContentState, selection, colorStyle)

    const newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style')

    setEditorState(newEditorState)
    updateRawContent(newEditorState)
  }

  const handleColorChangeComplete = (color: ColorResult) => {
    if (color.hex) {
      setSelectedColor(color.hex)
      applyColor(color.hex)
    }
  }

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const size = parseInt(e.target.value, 10)
    if (isNaN(size) || size <= 0) return

    setFontSize(size)
    applyFontSize(size)
  }

  const applyFontSize = (size: number) => {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const fontSizeStyle = `FONTSIZE-${size}`

    const stylesToRemove = editorState
      .getCurrentInlineStyle()
      // @ts-ignore
      .filter(style => style && style.startsWith('FONTSIZE-'))

    let newContentState = contentState

    stylesToRemove.forEach(style => {
      if (style) {
        newContentState = Modifier.removeInlineStyle(newContentState, selection, style)
      }
    })

    newContentState = Modifier.applyInlineStyle(newContentState, selection, fontSizeStyle)

    const newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style')

    setEditorState(newEditorState)
    updateRawContent(newEditorState)
  }

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const family = e.target.value
    setFontFamily(family)
    applyFontFamily(family)
  }

  const applyFontFamily = (family: string) => {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const fontFamilyStyle = `FONTFAMILY-${family.replace(/\s+/g, '_')}` // Replace spaces with underscores

    const stylesToRemove = editorState
      .getCurrentInlineStyle()
      // @ts-ignore
      .filter(style => style && style.startsWith('FONTFAMILY-'))

    let newContentState = contentState

    stylesToRemove.forEach(style => {
      if (style) {
        newContentState = Modifier.removeInlineStyle(newContentState, selection, style)
      }
    })

    newContentState = Modifier.applyInlineStyle(newContentState, selection, fontFamilyStyle)

    const newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style')

    setEditorState(newEditorState)
    updateRawContent(newEditorState)
  }

  const customStyleFn = (styleSet: DraftInlineStyle, block: ContentBlock): React.CSSProperties => {
    const styles: React.CSSProperties = {}
    styleSet.forEach(style => {
      if (style && style.startsWith('COLOR-')) {
        const colorCode = '#' + style.substring(6)
        styles.color = colorCode
      }
      if (style && style.startsWith('FONTSIZE-')) {
        const size = style.substring(9)
        styles.fontSize = `${size}px`
      }
      if (style && style.startsWith('FONTFAMILY-')) {
        const family = style.substring(11).replace(/_/g, ' ')
        styles.fontFamily = family
      }
    })
    return styles
  }

  const customStyleMap = {
    BOLD: { fontWeight: 'bold' },
  }

  const fontFamilies = [
    'Arial',
    'Courier New',
    'Georgia',
    'Times New Roman',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Comic Sans MS',
    'Lucida Console',
    'Roboto',
    'Open Sans',
    // Add other fonts as needed
  ]

  return (
    <>
      <div className='custom-editor'>
        <div className={`custom-editor_block ${!view ? 'edit' : ''}`}>
          <Editor
            editorState={editorState}
            onChange={onChange}
            customStyleMap={customStyleMap}
            customStyleFn={customStyleFn}
            readOnly={view} // Устанавливаем режим только для чтения
          />
        </div>
        {formik ? (
          <>
            {' '}
            {formik.errors.selfInformation && formik.touched.selfInformation ? <div className='error'>{formik.errors.selfInformation}</div> : null}
          </>
        ) : null}
      </div>
      {/* Toolbar (отображается только если view = false) */}
      {!view && (
        <div
          style={{
            width: '100%',
            margin: '10px 0',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
            <label htmlFor='font-family-select'>{t('ui.customTextArea.font')}:</label>
            <select
              id='font-family-select'
              value={fontFamily}
              onChange={handleFontFamilyChange}
              style={{ padding: '2px 5px', borderRadius: '4px', border: '1px solid #999999' }}
            >
              {fontFamilies.map(family => (
                <option key={family} value={family}>
                  {family}
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <label htmlFor='font-size-input'>{t('ui.customTextArea.font_size')}:</label>
            <input
              id='font-size-input'
              type='number'
              value={fontSize}
              onChange={handleFontSizeChange}
              min='1'
              style={{ width: '60px', borderRadius: '4px', border: '1px solid #999999' }}
            />
            <span>px</span>
          </div>
          <button type='button' onClick={toggleBold}>
            {t('ui.customTextArea.bold')}
          </button>
          <button type='button' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            {showEmojiPicker ? t('ui.customTextArea.close_emozi') : t('ui.customTextArea.add_emozi')}
          </button>
          <button type='button' onClick={() => setShowColorPicker(!showColorPicker)}>
            {showColorPicker ? t('ui.customTextArea.close_color') : t('ui.customTextArea.chose_color')}
          </button>
        </div>
      )}
      {/* Emoji Picker (отображается только если view = false и открыт) */}
      {!view && showEmojiPicker && (
        <div ref={emojiPickerRef} className='emoji-picker'>
          <Picker onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
      {/* Color Picker (отображается только если view = false и открыт) */}
      {!view && showColorPicker && (
        <div ref={colorPickerRef} style={{ position: 'relative', display: 'inline-block' }}>
          <button
            type='button'
            onClick={() => setShowColorPicker(false)}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              background: 'transparent',
              border: 'none',
              fontSize: '16px',
              cursor: 'pointer',
            }}
          >
            ×
          </button>
          <SketchPicker color={selectedColor} onChangeComplete={handleColorChangeComplete} />
        </div>
      )}
    </>
  )
}

export default CustomTextArea
