import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectVideoChatObg, selectVideoChatParticipants } from 'store/reducer/videoChat/selector'
import { startTimer } from 'store/reducer/videoChatWithdrawalMoney/reducer'
import useWithdrawFunds from '../../../../../../hooks/videoChat/useWithdrawFunds'
import { selectTime } from 'store/reducer/videoChatWithdrawalMoney/selector'

const HandlerWithdrawalMoney = () => {
  const dispatch = useDispatch()
  const selectParticipants = useSelector(selectVideoChatParticipants)
  const selectVideoChat = useSelector(selectVideoChatObg)
  const time = useSelector(selectTime)
  const { withdrawFunds } = useWithdrawFunds(selectVideoChat?.room.id)
  const videoChat = useSelector(selectVideoChatObg)?.room
  const disabled = videoChat?.participantRole === undefined || videoChat?.ownerUserRole === undefined

  // Logic for handling money withdrawal
  useEffect(() => {
    if (selectParticipants?.remoteParticipant && selectParticipants.localParticipant) {
      dispatch(startTimer())
    }
  }, [selectParticipants, dispatch])

  // Check if a minute has passed based on time
  useEffect(() => {
    const minutesPassed = Math.floor(time / 10)
    const lastMinute = Math.floor((time - 1) / 10)

    if (minutesPassed > lastMinute && !disabled) {
      withdrawFunds(1)
    }
  }, [time, withdrawFunds])

  return null
}

export default HandlerWithdrawalMoney
