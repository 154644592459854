import React, { useState } from 'react'
import './scss/index.scss'
import { SvgChatGroup, SvgChatHeart, SvgChatPlus, SvgChatProfile, SvgCloseSearch, SvgOnline, SvgSearchChat } from 'images/svg'
import MessagesItems from './MessagesItems'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectorMessagesSelectedUser, getSelectorSetMessagePrivetChats } from '../../../../store/reducer/messages/selectors'
import { setMessagesSelectedId } from '../../../../store/reducer/messages/reducer'
import { getSubscribeUser } from '../../../../store/reducer/socket/selector'
import { selectRole, selectUserMe } from '../../../../store/reducer/token/selector'

import { useNavigate } from 'react-router'
import Tippy from '@tippyjs/react'
import { usePrivateChats } from '../../../../hooks/chat/usePrivateChats'
import TextTooltip from '../../../../UI/Tooltip/TextTooltip/TextTooltip'
import Loading from '../../../../elements/Loading/Loading'
import { useTranslation } from 'react-i18next'

const SidebarLeft = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const role = useSelector(selectRole)
  const selectedUser = useSelector(getSelectorMessagesSelectedUser)
  const subscribeUser = useSelector(getSubscribeUser)

  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const dataChatSelect = useSelector(getSelectorSetMessagePrivetChats)
  const { isLoading } = usePrivateChats()
  const [filter, setFilter] = useState({
    all: true,
    online: false,
    favorites: false,
  })
  const [search, setSearch] = useState('')
  const { t } = useTranslation()

  const selectAll = () => {
    setFilter({ all: true, online: false, favorites: false })
  }

  const selectOnline = () => {
    const onlineStatus = subscribeUser?.body?.find(user => user.userId === selectedUser?.user?.id)?.activityStatus ?? 'noStatus'
    const isOnline = onlineStatus === 'ONLINE' || onlineStatus === 'AWAY'
    if (!isOnline) {
      dispatch(setMessagesSelectedId(null))
    }
    setFilter({ all: false, online: true, favorites: false })
  }

  const selectFavorites = () => {
    setFilter({ all: false, online: false, favorites: true })
  }

  return (
    <>
      <div className='sidebar-left'>
        <ul className='sidebar-left__icons'>
          <Tippy
            interactive
            trigger='mouseenter'
            content={<TextTooltip text={t('components.user.messages.sidebarLeft.messageSitem.sidebarLeft.All')} />}
            placement='top'
          >
            <li className={`sidebar-left__icon ${filter.all ? 'active' : ''}`} onClick={selectAll}>
              <div className='primary-icon'>
                <SvgChatGroup />
              </div>
            </li>
          </Tippy>

          <Tippy
            interactive
            trigger='mouseenter'
            content={<TextTooltip text={t('components.user.messages.sidebarLeft.messageSitem.sidebarLeft.Online')} />}
            placement='auto'
          >
            <li className={`sidebar-left__icon ${filter.online ? 'active' : ''}`} onClick={selectOnline}>
              <div className='friends-online'>
                <div className='online-icon'>
                  <SvgOnline />
                </div>
                <div className='primary-icon'>
                  <SvgChatProfile />
                </div>
              </div>
            </li>
          </Tippy>

          <Tippy
            interactive
            trigger='mouseenter'
            content={<TextTooltip text={t('components.user.messages.sidebarLeft.messageSitem.sidebarLeft.Favorites')} />}
            placement='bottom'
          >
            <li className={`sidebar-left__icon ${filter.favorites ? 'active' : ''}`} onClick={selectFavorites}>
              <div className='favorites'>
                <div className='favorites-icon'>
                  <SvgChatHeart />
                </div>
                <div className='primary-icon'>
                  <SvgChatProfile />
                </div>
              </div>
            </li>
          </Tippy>
        </ul>
      </div>
      <div className='sidebar__content'>
        <div className='sidebar__content_top'>
          <div className='sidebar_flex'>
            <h3 className='sidebar__title'>{t('components.user.messages.sidebarLeft.messageSitem.sidebarLeft.chat')}</h3>
            {dataPrivateChats && dataPrivateChats.size > 0 ? (
              <div className='chats-count'>
                <span>{dataPrivateChats.size}</span>
              </div>
            ) : null}
          </div>
          <button
            onClick={() => {
              if (role === 'CLIENT') {
                navigate(`/girls`)
              } else if (role === 'MODEL') {
                navigate(`/model/people`)
              }
            }}
            className='messages__button'
          >
            <SvgChatPlus />
            <span>{t('components.user.messages.sidebarLeft.messageSitem.sidebarLeft.new_chat')}</span>
          </button>
        </div>
        <search className='sidebar__search'>
          <input
            maxLength={10}
            onChange={e => setSearch(e.target.value)}
            type='text'
            value={search}
            placeholder={t('components.user.messages.sidebarLeft.messageSitem.sidebarLeft.search')}
          />
          {search.length > 0 ? (
            <button className='sidebar__close-btn' onClick={() => setSearch('')}>
              <SvgCloseSearch />
            </button>
          ) : (
            <div>
              <SvgSearchChat />
            </div>
          )}
          {search && search.length >= 10 && (
            <div className='error'>
              <p>{t('components.user.messages.sidebarLeft.messageSitem.sidebarLeft.input_limit')} </p>
            </div>
          )}
        </search>
        <div className='messages-view'>
          {isLoading ? (
            <Loading />
          ) : dataChatSelect !== null ? (
            <MessagesItems searchName={search} filterOnline={filter.online} filterFavorites={filter.favorites} />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SidebarLeft
