import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNotificationGetUnreadCountQuery, useNotificationReadMutation } from 'store/apiMain/mainApi'
import './scss/index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { decrementUnreadCount, markNotificationsAsRead, setUnreadCount } from 'store/reducer/notifications/reducer'
import { selectUnreadCount } from 'store/reducer/notifications/selectors'
import NotificationsIcon from './NotificationsIcon'
import useNotifications from '../../hooks/useNotifications'
import NotificationsBlockItems from './NotificationsBlockItems'
import { fetchUnreadCount } from './func/fetchUnreadCount'

const Notifications = () => {
  const dispatch = useDispatch()
  const { active, closing, notificationsBlockRef, wrapperRef, openNotificationWindow, closeNotificationWindow } = useNotifications()
  const { data: unreadCountData, refetch: refetchUnreadCount } = useNotificationGetUnreadCountQuery()
  const [unreadNotificationIds, setUnreadNotificationIds] = useState<Set<number>>(new Set())
  const debounceTimer = useRef<NodeJS.Timeout | null>(null)
  const [readNotifications] = useNotificationReadMutation()
  const unreadCountSelect = useSelector(selectUnreadCount)

  useEffect(() => {
    fetchUnreadCount(dispatch, unreadCountData)
  }, [unreadCountData, dispatch])

  useEffect(() => {
    if (unreadNotificationIds.size > 0) {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current)
      }
      debounceTimer.current = setTimeout(() => {
        const idsToMarkRead = Array.from(unreadNotificationIds).map(Number)
        const payload = {
          notificationReadRequest: {
            notificationIds: idsToMarkRead,
          },
        }

        readNotifications(payload)
        setUnreadNotificationIds(new Set())
      }, 1000)
    }

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current)
      }
    }
  }, [unreadNotificationIds, readNotifications])

  const handleNotificationRead = useCallback(
    (notificationId: number) => {
      setUnreadNotificationIds(prev => {
        const newSet = new Set(prev)
        newSet.add(notificationId)
        return newSet
      })

      // Dispatch markNotificationsAsRead immediately
      dispatch(decrementUnreadCount())
      dispatch(markNotificationsAsRead([notificationId]))
    },
    [setUnreadNotificationIds, dispatch],
  )

  return (
    <div ref={wrapperRef} onClick={active ? closeNotificationWindow : openNotificationWindow} className='notifications'>
      {unreadCountSelect > 0 ? (
        <>
          <div className={`notifications-count ${unreadCountSelect < 100 ? 'short-length' : 'long-length'}`}>
            {unreadCountSelect < 100 ? unreadCountSelect : '99+'}
          </div>
          <div className='notifications-icon notifications-icon_pink'>
            <NotificationsIcon />
          </div>
        </>
      ) : (
        <div className='notifications-icon notifications-icon_black'>
          <NotificationsIcon />
        </div>
      )}
      {active && (
        <div ref={notificationsBlockRef} onClick={e => e.stopPropagation()} className={closing ? 'open' : 'close'}>
          <NotificationsBlockItems
            unreadNotificationIds={unreadNotificationIds}
            setUnreadNotificationIds={setUnreadNotificationIds}
            handleNotificationRead={handleNotificationRead}
            closeNotificationWindow={closeNotificationWindow}
          />
        </div>
      )}
    </div>
  )
}

export default Notifications
