import React from 'react'
import { useNavigate } from 'react-router-dom'

interface DataRoom {
  id: string
  ownerUserId: string
  ownerUserRole: 'CLIENT' | 'MODEL' | 'STAFF'
  isActive: boolean
  type: 'GENERAL_ROOM' | 'CALL_FROM_CLIENT' | 'CALL_FROM_MODEL' | 'CALL_FROM_STAFF'
  privateChatId?: string
  createdAt: string
  participantId?: string
  participantRole?: 'CLIENT' | 'MODEL' | 'STAFF'
}

interface IProps {
  dataRooms: DataRoom[]
}

const Item: React.FC<IProps> = ({ dataRooms }) => {
  const navigate = useNavigate()

  return (
    <div className='data-rooms'>
      {dataRooms.map(room => (
        <div className='data-room' key={room.id}>
          <h2>Room ID: {room.id}</h2>
          <p>Owner User ID: {room.ownerUserId}</p>
          <p>Owner User Role: {room.ownerUserRole}</p>
          <p>Is Active: {room.isActive ? 'Yes' : 'No'}</p>
          <p>Type: {room.type}</p>
          {room.privateChatId && <p>Private Chat ID: {room.privateChatId}</p>}
          <p>Created At: {new Date(room.createdAt).toLocaleString()}</p>
          {room.participantId && <p>Participant ID: {room.participantId}</p>}
          {room.participantRole && <p>Participant Role: {room.participantRole}</p>}
          {room.isActive ? (
            <button
              onClick={() => {
                navigate(`/room/${room.id}`)
              }}
            >
              Подключиться
            </button>
          ) : null}
        </div>
      ))}
    </div>
  )
}

export default Item
