import React, { FC, useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { IPopUp } from '../../types'
import './scss/index.scss'
import LogicStars from './LogicStars'
import { useSelector } from 'react-redux'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import { CreateVoteRoomApiArg, useCreateVoteRoomMutation } from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import { useTranslation } from 'react-i18next'

const RateVideoCall: FC<IPopUp> = ({ open, closeModal }) => {
  const [comment, setComment] = useState<string>('') // Состояние для комментария
  const [stars, setStars] = useState(0)
  const selectedDataPopUp = useSelector(getPopUpData)
  const [postRoomVote, { isLoading: isLoadingRoomVote, isSuccess: isSuccessRoomVote }] = useCreateVoteRoomMutation()
  const { t } = useTranslation()

  useEffect(() => {
    if (isSuccessRoomVote) {
      toastSuccess(t('ui.popUp.videoPopUp.rateVideoCall.rateVideoCall.feedback_success'))
      closeModal()
    }
  }, [isSuccessRoomVote])

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value)
  }

  const handleSubmit = () => {
    const payload: CreateVoteRoomApiArg = {
      roomId: selectedDataPopUp?.data?.room?.id,
      roomVoteRequest: {
        activeTime: 90,
        rating: stars,
        comment: comment,
      },
    }
    postRoomVote(payload)
  }

  const isLimitReached = comment.length >= 100

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          <div className='RateVideoCall'>
            <div className='LogicStars'>
              <div className='feedback'>
                <LogicStars setStars={setStars} />
                <textarea
                  className='comment-textarea'
                  placeholder='Сделай проект лучше, оставь отзыв...'
                  value={comment}
                  maxLength={100}
                  onChange={handleCommentChange}
                />
                <div
                  style={{
                    fontSize: '12px',
                    marginTop: '4px',
                    color: isLimitReached ? 'red' : '#888',
                  }}
                >
                  {isLimitReached ? 'Вы достигли максимального количества символов!' : `Символов: ${comment.length}/100`}
                </div>
                <div className='actions'>
                  <button className='cancel-button' onClick={closeModal}>
                    {t('ui.popUp.videoPopUp.rateVideoCall.rateVideoCall.cancel_action')}
                  </button>
                  <button
                    className='submit-button'
                    onClick={handleSubmit}
                    disabled={stars === 0 || isLimitReached}
                    style={{
                      filter: stars === 0 || isLimitReached ? 'grayscale(100%)' : 'none',
                      cursor: stars === 0 || isLimitReached ? 'not-allowed' : 'pointer',
                    }}
                  >
                    {t('ui.popUp.videoPopUp.rateVideoCall.rateVideoCall.send_action')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default RateVideoCall
