import React from 'react'
import './scss/index.scss'
import { Link } from 'react-router-dom'

const Dashboard = () => {
  return (
    <>
      <div className='dashboard'>
        <div className='dashboard-block'>
          <nav>
            <ul>
              <li>
                <Link to='/admin/users'>Users</Link>
              </li>
              <li>
                <Link to='/admin/clients'>Clients</Link>
              </li>
              <li>
                <Link to='/admin/models-request'>Model request</Link>
              </li>
              <li>
                <Link to='/admin/models'>Models</Link>
              </li>
              <li>
                <Link to='/admin/vote'>Vote</Link>
              </li>
              <li>
                <Link to='/admin/model-price'>Models Price</Link>
              </li>
              <li>
                <Link to='/admin/complaint'>Complaint</Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="/admin/metrics">Metrics</Link>*/}
              {/*</li>*/}
              <li>
                <Link to='/admin/referral-controller'>Referral controller</Link>
              </li>
              <li>
                <Link to='/admin/message-replenishment'>Message Replenishment</Link>
              </li>
              <li>
                <Link to='/admin/transactional-controller'>Transactional-controller</Link>
              </li>
              <li>
                <Link to='/admin/messages'>Messages</Link>
              </li>
              <li>
                <Link to='/admin/rooms'>Rooms</Link>
              </li>
              <li>
                <Link to='/admin/staff'>Staff</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Dashboard
