import { useTranslation } from 'react-i18next'

const errorMessages: Record<string, string> = {
  ACCESS_DENIED: 'ACCESS DENIED - error',
  CONSTRAINT_VIOLATION_TYPE: 'CONSTRAINT VIOLATION TYPE',
  USER_EMAIL_NOT_FOUND: 'USER EMAIL NOT FOUND',
}

// const errorMessages=  ()=>{
//   const { t } = useTranslation();
//   return {ACCESS_DENIED: t('utils.notificationContainerError.errorText.access_denied') ,
//   CONSTRAINT_VIOLATION_TYPE:  t('utils.notificationContainerError.errorText.constraint_violation_type'),
//   USER_EMAIL_NOT_FOUND:  t('utils.notificationContainerError.errorText.user_email_not_found'),

// }
// }

export default errorMessages
