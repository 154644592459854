import type { MessageResponse } from 'store/apiMain/mainApi'
import { SvgMessageReceivedGradient, SvgMessageReadGradient, SvgMessageReadWhite, SvgMessageReceivedWhite } from './../images/svg'

type ReadStatusProps = {
  role: 'CLIENT' | 'MODEL' | 'STAFF' | undefined
  message: MessageResponse
  userMe: any
  color?: string
}

const ReadStatus = ({ role, message, userMe, color }: ReadStatusProps) => {
  const userId = userMe?.userData?.user?.id
  if (!userId || message?.createdBy !== userId) {
    return null // Don't show SVG for GENERAL type messages or messages not created by the user
  }

  const { recipientRole, senderRole } = message
  let isDoubleCheck = false

  if (role === 'MODEL' && senderRole === 'MODEL' && recipientRole === 'CLIENT') {
    isDoubleCheck = message.isReadByClient
  } else if (role === 'CLIENT' && senderRole === 'CLIENT' && recipientRole === 'MODEL') {
    isDoubleCheck = message.isReadByModel
  } else if (role === 'STAFF' && senderRole === 'STAFF') {
    if (recipientRole === 'MODEL') {
      isDoubleCheck = message.isReadByModel
    } else if (recipientRole === 'CLIENT') {
      isDoubleCheck = message.isReadByClient
    } else if (recipientRole === 'STAFF') {
      isDoubleCheck = message.isReadByStaff
    }
  } else if (role === 'STAFF' && recipientRole === 'STAFF' && senderRole !== 'STAFF') {
    isDoubleCheck = message.isReadByStaff
  } else if (role === 'CLIENT' && senderRole === 'CLIENT' && recipientRole === 'STAFF') {
    isDoubleCheck = message.isReadByStaff
  } else if (role === 'MODEL' && senderRole === 'MODEL' && recipientRole === 'STAFF') {
    isDoubleCheck = message.isReadByStaff
  }

  if (color === 'white') {
    return <div className='read-status'>{isDoubleCheck ? <SvgMessageReadWhite /> : <SvgMessageReceivedWhite />}</div>
  }

  return <div className='read-status'>{isDoubleCheck ? <SvgMessageReadGradient /> : <SvgMessageReceivedGradient />}</div>
}

export default ReadStatus
