/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPopUpData, IPopUpStore } from './types'

// ==========================================:
export const initialState: IPopUpStore = {
  popUpText: '',
  popUpTextTwo: '',
  data: {},
}

// ==========================================:
const popUpSlice = createSlice({
  name: '@@popUp',
  initialState,
  reducers: {
    popUpOpen: (state, action: PayloadAction<string>) => {
      const { payload } = action
      const stateData = state

      stateData.popUpText = payload
    },
    popUpOpenTwo: (state, action: PayloadAction<string>) => {
      const { payload } = action
      const stateData = state

      stateData.popUpTextTwo = payload
    },
    setPopUpData: (state, action: PayloadAction<IPopUpData>) => {
      const { payload } = action
      const stateData = state
      stateData.data = payload
    },
    getPopUpClose: state => {
      const stateData = state
      stateData.popUpText = ''
      stateData.data = {}
    },
    getPopUpTwoClose: state => {
      const stateData = state
      stateData.popUpTextTwo = ''
    },
    popUpInitState: () => initialState,
  },
})
export default popUpSlice.reducer
export const { popUpInitState, popUpOpen, popUpOpenTwo, getPopUpClose, setPopUpData, getPopUpTwoClose } = popUpSlice.actions
