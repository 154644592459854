import React, { useEffect, useState } from 'react'
import './scss/index.scss'
import { useGetAverageVoteRoomsQuery } from 'store/apiMain/mainApi'

type ModelVoteProps = {
  userId: string
  update: boolean
}

const ModelVote: React.FC<ModelVoteProps> = ({ userId, update }) => {
  // Локальное состояние для фильтров
  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: '',
    withComment: false,
  })

  // Запрос через RTK Query
  const { data, isLoading, isError, refetch } = useGetAverageVoteRoomsQuery({
    ownerUserId: userId,
    fromDate: filters.fromDate || undefined,
    toDate: filters.toDate || undefined,
    withComment: filters.withComment,
  })
  useEffect(() => {
    refetch()
  }, [update])
  // Обработчики изменения фильтров
  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>, field: 'fromDate' | 'toDate') => {
    setFilters(prev => ({ ...prev, [field]: e.target.value }))
  }

  const handleChangeWithComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ ...prev, withComment: e.target.checked }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    refetch() // Перезапрашиваем данные
  }

  return (
    <div className='modelVote'>
      <div className='model-vote'>
        <h2 className='model-vote__title'>Средняя оценка по комнатам</h2>

        <form className='model-vote__filters' onSubmit={handleSubmit}>
          <div className='model-vote__input'>
            <label htmlFor='fromDate'>Дата от</label>
            <input type='date' id='fromDate' value={filters.fromDate} onChange={e => handleChangeDate(e, 'fromDate')} />
          </div>

          <div className='model-vote__input'>
            <label htmlFor='toDate'>Дата до</label>
            <input type='date' id='toDate' value={filters.toDate} onChange={e => handleChangeDate(e, 'toDate')} />
          </div>

          <div className='model-vote__input model-vote__checkbox'>
            <label htmlFor='withComment'>С комментариями</label>
            <input type='checkbox' id='withComment' checked={filters.withComment} onChange={handleChangeWithComment} />
          </div>

          <button type='submit' className='model-vote__button'>
            Применить
          </button>
        </form>

        {/* Блок результата */}
        <div className='model-vote__results'>
          {isLoading && <p>Загрузка...</p>}
          {isError && <p>Ошибка при загрузке данных.</p>}
          {data && (
            <>
              <div className='model-vote__stat'>
                <span>OwnerUserId:</span> {data.ownerUserId ?? '—'}
              </div>
              <div className='model-vote__stat'>
                <span>Общее активное время (мин):</span> {data.totalActiveTime}
              </div>
              <div className='model-vote__stat'>
                <span>Средний рейтинг:</span> {data.averageRating}
              </div>
              <div className='model-vote__stat'>
                <span>Всего голосов:</span> {data.countVotes}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModelVote
