import { emptySplitApi as api } from './emptyApi'
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    messageRulesGet: build.query<MessageRulesGetApiResponse, MessageRulesGetApiArg>({
      query: () => ({ url: `/api/v1/replenish-message-rules` }),
    }),
    messageRulesSet: build.mutation<MessageRulesSetApiResponse, MessageRulesSetApiArg>({
      query: queryArg => ({ url: `/api/v1/replenish-message-rules`, method: 'PUT', body: queryArg.messageRulesSetRequest }),
    }),
    messageUpdateById: build.mutation<MessageUpdateByIdApiResponse, MessageUpdateByIdApiArg>({
      query: queryArg => ({
        url: `/api/v1/private-chats/${queryArg.privateChatId}/messages/${queryArg.messageId}`,
        method: 'PUT',
        body: queryArg.messageUpdateRequest,
      }),
    }),
    referralUpdateById: build.mutation<ReferralUpdateByIdApiResponse, ReferralUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/referrals/${queryArg.referralId}`, method: 'PUT', body: queryArg.referralUpdateRequest }),
    }),
    referralDeleteById: build.mutation<ReferralDeleteByIdApiResponse, ReferralDeleteByIdApiArg>({
      query: queryArg => ({ url: `/api/referrals/${queryArg.referralId}`, method: 'DELETE' }),
    }),
    modelServiceGetAll: build.query<ModelServiceGetAllApiResponse, ModelServiceGetAllApiArg>({
      query: queryArg => ({ url: `/api/models/${queryArg.modelId}/services` }),
    }),
    modelServiceSet: build.mutation<ModelServiceSetApiResponse, ModelServiceSetApiArg>({
      query: queryArg => ({ url: `/api/models/${queryArg.modelId}/services`, method: 'PUT', body: queryArg.body }),
    }),
    globalConfigGet: build.query<GlobalConfigGetApiResponse, GlobalConfigGetApiArg>({
      query: () => ({ url: `/api/global-config` }),
    }),
    globalConfigSet: build.mutation<GlobalConfigSetApiResponse, GlobalConfigSetApiArg>({
      query: queryArg => ({ url: `/api/global-config`, method: 'PUT', body: queryArg.globalConfigSaveDto }),
    }),
    tokenGet: build.mutation<TokenGetApiResponse, TokenGetApiArg>({
      query: queryArg => ({ url: `/oauth2/token`, method: 'POST', body: queryArg.body }),
    }),
    tokenRevoke: build.mutation<TokenRevokeApiResponse, TokenRevokeApiArg>({
      query: queryArg => ({ url: `/oauth2/revoke`, method: 'POST', body: queryArg.body }),
    }),
    wordBlackListGetAll: build.query<WordBlackListGetAllApiResponse, WordBlackListGetAllApiArg>({
      query: queryArg => ({
        url: `/api/words-black-list`,
        params: { contains: queryArg.contains, page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    wordBlackListCreate: build.mutation<WordBlackListCreateApiResponse, WordBlackListCreateApiArg>({
      query: queryArg => ({ url: `/api/words-black-list`, method: 'POST', body: queryArg.wordBlackListCreateDto }),
    }),
    translate: build.mutation<TranslateApiResponse, TranslateApiArg>({
      query: queryArg => ({ url: `/api/v1/translate`, method: 'POST', body: queryArg.translateRequest }),
    }),
    ticketCreate: build.mutation<TicketCreateApiResponse, TicketCreateApiArg>({
      query: () => ({ url: `/api/v1/tickets`, method: 'POST' }),
    }),
    search: build.query<SearchApiResponse, SearchApiArg>({
      query: queryArg => ({
        url: `/api/v1/rooms`,
        params: {
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
          userIdToConnect: queryArg.userIdToConnect,
          withCurrentUserIdToConnect: queryArg.withCurrentUserIdToConnect,
          withActive: queryArg.withActive,
          withParticipant: queryArg.withParticipant,
          blockedUserIdsToConnect: queryArg.blockedUserIdsToConnect,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    createRoom: build.mutation<CreateRoomApiResponse, CreateRoomApiArg>({
      query: queryArg => ({ url: `/api/v1/rooms`, method: 'POST', params: { callerUserId: queryArg.callerUserId } }),
    }),
    createVoteRoom: build.mutation<CreateVoteRoomApiResponse, CreateVoteRoomApiArg>({
      query: queryArg => ({ url: `/api/v1/rooms/${queryArg.roomId}/vote`, method: 'POST', body: queryArg.roomVoteRequest }),
    }),
    registerLiveTime: build.mutation<RegisterLiveTimeApiResponse, RegisterLiveTimeApiArg>({
      query: queryArg => ({ url: `/api/v1/rooms/${queryArg.roomId}/time`, method: 'POST', body: queryArg.registerLiveTimeRequest }),
    }),
    createRoom1: build.mutation<CreateRoom1ApiResponse, CreateRoom1ApiArg>({
      query: queryArg => ({ url: `/api/v1/rooms/${queryArg.roomId}/join`, method: 'POST', params: { secured: queryArg.secured } }),
    }),
    receiveWebhook: build.mutation<ReceiveWebhookApiResponse, ReceiveWebhookApiArg>({
      query: queryArg => ({
        url: `/api/v1/rooms/livekit/webhook`,
        method: 'POST',
        body: queryArg.body,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    privateChatGetAll: build.query<PrivateChatGetAllApiResponse, PrivateChatGetAllApiArg>({
      query: queryArg => ({
        url: `/api/v1/private-chats`,
        params: {
          unreadMessageCountBy: queryArg.unreadMessageCountBy,
          clientId: queryArg.clientId,
          modelId: queryArg.modelId,
          staffId: queryArg.staffId,
          approvedByClient: queryArg.approvedByClient,
          approvedByModel: queryArg.approvedByModel,
          approvedByModelOrAttentionAttracted: queryArg.approvedByModelOrAttentionAttracted,
          approvedByStaff: queryArg.approvedByStaff,
          type: queryArg['type'],
          disabled: queryArg.disabled,
          byRole: queryArg.byRole,
          token: queryArg.token,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    privateChatCreate: build.mutation<PrivateChatCreateApiResponse, PrivateChatCreateApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats`, method: 'POST', body: queryArg.privateChatCreateRequest }),
    }),
    messageWithVideoCreate: build.mutation<MessageWithVideoCreateApiResponse, MessageWithVideoCreateApiArg>({
      query: queryArg => ({
        url: `/api/v1/private-chats/${queryArg.privateChatId}/video-messages`,
        method: 'POST',
        body: queryArg.messageCreateRequest,
      }),
    }),
    messageGetAll: build.query<MessageGetAllApiResponse, MessageGetAllApiArg>({
      query: queryArg => ({
        url: `/api/v1/private-chats/${queryArg.privateChatId}/messages`,
        params: { token: queryArg.token, pageSize: queryArg.pageSize },
      }),
    }),
    messageCreate: build.mutation<MessageCreateApiResponse, MessageCreateApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats/${queryArg.privateChatId}/messages`, method: 'POST', body: queryArg.messageCreateRequest }),
    }),
    messageReadByIds: build.mutation<MessageReadByIdsApiResponse, MessageReadByIdsApiArg>({
      query: queryArg => ({
        url: `/api/v1/private-chats/${queryArg.privateChatId}/messages/read`,
        method: 'POST',
        params: { messageIds: queryArg.messageIds },
      }),
    }),
    messageReadAll: build.mutation<MessageReadAllApiResponse, MessageReadAllApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats/${queryArg.privateChatId}/messages/read-all`, method: 'POST' }),
    }),
    messageClearHistory: build.mutation<MessageClearHistoryApiResponse, MessageClearHistoryApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats/${queryArg.privateChatId}/messages/clear`, method: 'POST' }),
    }),
    privateChatDisableById: build.mutation<PrivateChatDisableByIdApiResponse, PrivateChatDisableByIdApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats/${queryArg.privateChatId}/disable`, method: 'POST' }),
    }),
    privateChatAssignById: build.mutation<PrivateChatAssignByIdApiResponse, PrivateChatAssignByIdApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats/${queryArg.privateChatId}/assign`, method: 'POST' }),
    }),
    notificationRead: build.mutation<NotificationReadApiResponse, NotificationReadApiArg>({
      query: queryArg => ({ url: `/api/v1/notifications/read`, method: 'POST', body: queryArg.notificationReadRequest }),
    }),
    giftRecordsGetAll: build.query<GiftRecordsGetAllApiResponse, GiftRecordsGetAllApiArg>({
      query: queryArg => ({
        url: `/api/v1/gifts/${queryArg.modelId}/records`,
        params: { fromClientId: queryArg.fromClientId, page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    giftSend: build.mutation<GiftSendApiResponse, GiftSendApiArg>({
      query: queryArg => ({ url: `/api/v1/gifts/${queryArg.modelId}/records`, method: 'POST', body: queryArg.giftSendRequest }),
    }),
    userEnableById: build.mutation<UserEnableByIdApiResponse, UserEnableByIdApiArg>({
      query: queryArg => ({ url: `/api/users/${queryArg.userId}/enable`, method: 'POST' }),
    }),
    userDisableById: build.mutation<UserDisableByIdApiResponse, UserDisableByIdApiArg>({
      query: queryArg => ({ url: `/api/users/${queryArg.userId}/disable`, method: 'POST' }),
    }),
    userVerifyCurrentEmail: build.mutation<UserVerifyCurrentEmailApiResponse, UserVerifyCurrentEmailApiArg>({
      query: queryArg => ({ url: `/api/users/verify-email`, method: 'POST', body: queryArg.emailVerificationRequest }),
    }),
    userSendCurrentEmailConfirmationCode: build.mutation<UserSendCurrentEmailConfirmationCodeApiResponse, UserSendCurrentEmailConfirmationCodeApiArg>(
      {
        query: queryArg => ({ url: `/api/users/send-email-confirmation`, method: 'POST', body: queryArg.emailUpdateConfirmationRequest }),
      },
    ),
    userResetPassword: build.mutation<UserResetPasswordApiResponse, UserResetPasswordApiArg>({
      query: queryArg => ({ url: `/api/users/reset-password`, method: 'POST', body: queryArg.resetPasswordRequest }),
    }),
    userSendForgotPasswordCode: build.mutation<UserSendForgotPasswordCodeApiResponse, UserSendForgotPasswordCodeApiArg>({
      query: queryArg => ({ url: `/api/users/forgot-password`, method: 'POST', body: queryArg.forgotPasswordRequest }),
    }),
    staffGetAll: build.query<StaffGetAllApiResponse, StaffGetAllApiArg>({
      query: queryArg => ({
        url: `/api/staff`,
        params: {
          isOnline: queryArg.isOnline,
          showDeleted: queryArg.showDeleted,
          showDisabled: queryArg.showDisabled,
          ids: queryArg.ids,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    staffCreate: build.mutation<StaffCreateApiResponse, StaffCreateApiArg>({
      query: queryArg => ({ url: `/api/staff`, method: 'POST', body: queryArg.staffCreateDto }),
    }),
    serviceGetAll: build.query<ServiceGetAllApiResponse, ServiceGetAllApiArg>({
      query: () => ({ url: `/api/services` }),
    }),
    serviceCreate: build.mutation<ServiceCreateApiResponse, ServiceCreateApiArg>({
      query: queryArg => ({ url: `/api/services`, method: 'POST', body: queryArg.serviceCreateDto }),
    }),
    relationshipCreate: build.mutation<RelationshipCreateApiResponse, RelationshipCreateApiArg>({
      query: queryArg => ({ url: `/api/relationships`, method: 'POST', body: queryArg.relationshipCreateDto }),
    }),
    referralGetAll: build.query<ReferralGetAllApiResponse, ReferralGetAllApiArg>({
      query: queryArg => ({
        url: `/api/referrals`,
        params: {
          nicknameContains: queryArg.nicknameContains,
          isDeleted: queryArg.isDeleted,
          createdBy: queryArg.createdBy,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    referralCreate: build.mutation<ReferralCreateApiResponse, ReferralCreateApiArg>({
      query: queryArg => ({ url: `/api/referrals`, method: 'POST', body: queryArg.referralCreateRequest }),
    }),
    modelGetAll: build.query<ModelGetAllApiResponse, ModelGetAllApiArg>({
      query: queryArg => ({
        url: `/api/models`,
        params: {
          languages: queryArg.languages,
          services: queryArg.services,
          name: queryArg.name,
          isHaveRelationshipWithCurrentClient: queryArg.isHaveRelationshipWithCurrentClient,
          relationshipStatuses: queryArg.relationshipStatuses,
          isOnline: queryArg.isOnline,
          showDeleted: queryArg.showDeleted,
          showDisabled: queryArg.showDisabled,
          ids: queryArg.ids,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    modelCreate: build.mutation<ModelCreateApiResponse, ModelCreateApiArg>({
      query: queryArg => ({ url: `/api/models`, method: 'POST', body: queryArg.userCreateDto }),
    }),
    modelRequestGetAll: build.query<ModelRequestGetAllApiResponse, ModelRequestGetAllApiArg>({
      query: queryArg => ({ url: `/api/models/requests`, params: { state: queryArg.state, page: queryArg.page, pageSize: queryArg.pageSize } }),
    }),
    modelRequestCreate: build.mutation<ModelRequestCreateApiResponse, ModelRequestCreateApiArg>({
      query: queryArg => ({ url: `/api/models/requests`, method: 'POST', body: queryArg.body, params: { modelUserId: queryArg.modelUserId } }),
    }),
    modelRequestApproveById: build.mutation<ModelRequestApproveByIdApiResponse, ModelRequestApproveByIdApiArg>({
      query: queryArg => ({ url: `/api/models/requests/${queryArg.modelRequestId}/approve`, method: 'POST', params: { type: queryArg['type'] } }),
    }),
    modelGetIds: build.mutation<ModelGetIdsApiResponse, ModelGetIdsApiArg>({
      query: queryArg => ({
        url: `/api/models/ids`,
        method: 'POST',
        body: queryArg.modelFilterDto,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    fileCreate: build.mutation<FileCreateApiResponse, FileCreateApiArg>({
      query: queryArg => ({ url: `/api/files`, method: 'POST', body: queryArg.body, params: { ownerId: queryArg.ownerId } }),
    }),
    complaintGetAll: build.query<ComplaintGetAllApiResponse, ComplaintGetAllApiArg>({
      query: queryArg => ({ url: `/api/complaints`, params: { page: queryArg.page, pageSize: queryArg.pageSize } }),
    }),
    complaintCreate: build.mutation<ComplaintCreateApiResponse, ComplaintCreateApiArg>({
      query: queryArg => ({ url: `/api/complaints`, method: 'POST', body: queryArg.complaintCreateDto }),
    }),
    clientGetAll: build.query<ClientGetAllApiResponse, ClientGetAllApiArg>({
      query: queryArg => ({
        url: `/api/clients`,
        params: {
          isHaveRelationshipWithCurrentModel: queryArg.isHaveRelationshipWithCurrentModel,
          relationshipStatuses: queryArg.relationshipStatuses,
          isOnline: queryArg.isOnline,
          showDeleted: queryArg.showDeleted,
          showDisabled: queryArg.showDisabled,
          ids: queryArg.ids,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    clientCreate: build.mutation<ClientCreateApiResponse, ClientCreateApiArg>({
      query: queryArg => ({ url: `/api/clients`, method: 'POST', body: queryArg.clientCreateDto }),
    }),
    clientGetIds: build.mutation<ClientGetIdsApiResponse, ClientGetIdsApiArg>({
      query: queryArg => ({
        url: `/api/clients/ids`,
        method: 'POST',
        body: queryArg.clientFilterDto,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    verifyCaptcha: build.mutation<VerifyCaptchaApiResponse, VerifyCaptchaApiArg>({
      query: queryArg => ({ url: `/api/captcha-verify`, method: 'POST', body: queryArg.captchaDto }),
    }),
    accountGetById: build.query<AccountGetByIdApiResponse, AccountGetByIdApiArg>({
      query: queryArg => ({ url: `/api/v1/accounts/${queryArg.userId}` }),
    }),
    accountPartialUpdateById: build.mutation<AccountPartialUpdateByIdApiResponse, AccountPartialUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/v1/accounts/${queryArg.userId}`, method: 'PATCH', body: queryArg.accountPartialUpdateRequest }),
    }),
    userGetFullById: build.query<UserGetFullByIdApiResponse, UserGetFullByIdApiArg>({
      query: queryArg => ({ url: `/api/users/${queryArg.userId}` }),
    }),
    userDeleteById: build.mutation<UserDeleteByIdApiResponse, UserDeleteByIdApiArg>({
      query: queryArg => ({ url: `/api/users/${queryArg.userId}`, method: 'DELETE' }),
    }),
    userPartialUpdateById: build.mutation<UserPartialUpdateByIdApiResponse, UserPartialUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/users/${queryArg.userId}`, method: 'PATCH', body: queryArg.userPartialUpdateDto }),
    }),
    staffGetFullById: build.query<StaffGetFullByIdApiResponse, StaffGetFullByIdApiArg>({
      query: queryArg => ({ url: `/api/staff/${queryArg.staffId}` }),
    }),
    staffPartialUpdateById: build.mutation<StaffPartialUpdateByIdApiResponse, StaffPartialUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/staff/${queryArg.staffId}`, method: 'PATCH', body: queryArg.staffPartialUpdateDto }),
    }),
    serviceGetById: build.query<ServiceGetByIdApiResponse, ServiceGetByIdApiArg>({
      query: queryArg => ({ url: `/api/services/${queryArg.serviceId}` }),
    }),
    serviceDeleteById: build.mutation<ServiceDeleteByIdApiResponse, ServiceDeleteByIdApiArg>({
      query: queryArg => ({ url: `/api/services/${queryArg.serviceId}`, method: 'DELETE' }),
    }),
    servicePartialUpdateById: build.mutation<ServicePartialUpdateByIdApiResponse, ServicePartialUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/services/${queryArg.serviceId}`, method: 'PATCH', body: queryArg.servicePartialUpdateDto }),
    }),
    relationshipDeleteById: build.mutation<RelationshipDeleteByIdApiResponse, RelationshipDeleteByIdApiArg>({
      query: queryArg => ({ url: `/api/relationships/${queryArg.relationshipId}`, method: 'DELETE' }),
    }),
    relationshipPartialUpdateById: build.mutation<RelationshipPartialUpdateByIdApiResponse, RelationshipPartialUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/relationships/${queryArg.relationshipId}`, method: 'PATCH', body: queryArg.relationshipPartialUpdateDto }),
    }),
    modelGetFullById: build.query<ModelGetFullByIdApiResponse, ModelGetFullByIdApiArg>({
      query: queryArg => ({ url: `/api/models/${queryArg.modelId}`, params: { idType: queryArg.idType } }),
    }),
    modelPartialUpdateById: build.mutation<ModelPartialUpdateByIdApiResponse, ModelPartialUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/models/${queryArg.modelId}`, method: 'PATCH', body: queryArg.modelPartialUpdateDto }),
    }),
    clientGetFullById: build.query<ClientGetFullByIdApiResponse, ClientGetFullByIdApiArg>({
      query: queryArg => ({ url: `/api/clients/${queryArg.clientId}`, params: { idType: queryArg.idType } }),
    }),
    clientPartialUpdateById: build.mutation<ClientPartialUpdateByIdApiResponse, ClientPartialUpdateByIdApiArg>({
      query: queryArg => ({ url: `/api/clients/${queryArg.clientId}`, method: 'PATCH', body: queryArg.clientPartialUpdateDto }),
    }),
    transactionGetAll: build.query<TransactionGetAllApiResponse, TransactionGetAllApiArg>({
      query: queryArg => ({
        url: `/api/v1/transactions`,
        params: {
          userRole: queryArg.userRole,
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    getRoom: build.query<GetRoomApiResponse, GetRoomApiArg>({
      query: queryArg => ({ url: `/api/v1/rooms/${queryArg.roomId}` }),
    }),
    searchVoteRooms: build.query<SearchVoteRoomsApiResponse, SearchVoteRoomsApiArg>({
      query: queryArg => ({
        url: `/api/v1/rooms/votes`,
        params: {
          roomId: queryArg.roomId,
          ownerUserId: queryArg.ownerUserId,
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
          lessRating: queryArg.lessRating,
          greaterRating: queryArg.greaterRating,
          withComment: queryArg.withComment,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    roomGetRandomWaiting: build.query<RoomGetRandomWaitingApiResponse, RoomGetRandomWaitingApiArg>({
      query: () => ({ url: `/api/v1/rooms/random-waiting-room` }),
    }),
    getAverageVoteRooms: build.query<GetAverageVoteRoomsApiResponse, GetAverageVoteRoomsApiArg>({
      query: queryArg => ({
        url: `/api/v1/rooms/average-vote`,
        params: { ownerUserId: queryArg.ownerUserId, fromDate: queryArg.fromDate, toDate: queryArg.toDate, withComment: queryArg.withComment },
      }),
    }),
    recipientMessageGetByPrivateChatId: build.query<RecipientMessageGetByPrivateChatIdApiResponse, RecipientMessageGetByPrivateChatIdApiArg>({
      query: queryArg => ({
        url: `/api/v1/private-chats/${queryArg.privateChatId}/recipient-messages`,
        params: { type: queryArg['type'], page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    getCurrentPrivateChatToModelOrUser: build.query<GetCurrentPrivateChatToModelOrUserApiResponse, GetCurrentPrivateChatToModelOrUserApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats/by-user/${queryArg.userId}` }),
    }),
    getAnyPrivateChatToStaff: build.query<GetAnyPrivateChatToStaffApiResponse, GetAnyPrivateChatToStaffApiArg>({
      query: queryArg => ({ url: `/api/v1/private-chats/by-model/${queryArg.modelId}/by-client/${queryArg.clientId}` }),
    }),
    notificationGetAll: build.query<NotificationGetAllApiResponse, NotificationGetAllApiArg>({
      query: queryArg => ({ url: `/api/v1/notifications`, params: { token: queryArg.token, pageSize: queryArg.pageSize } }),
    }),
    notificationGetUnreadCount: build.query<NotificationGetUnreadCountApiResponse, NotificationGetUnreadCountApiArg>({
      query: () => ({ url: `/api/v1/notifications/count` }),
    }),
    giftGetAll: build.query<GiftGetAllApiResponse, GiftGetAllApiArg>({
      query: () => ({ url: `/api/v1/gifts` }),
    }),
    userEventLogGet: build.query<UserEventLogGetApiResponse, UserEventLogGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/event-log`,
        params: { fetchFrom: queryArg.fetchFrom, token: queryArg.token, pageSize: queryArg.pageSize },
      }),
    }),
    transactionGetAllByUserId: build.query<TransactionGetAllByUserIdApiResponse, TransactionGetAllByUserIdApiArg>({
      query: queryArg => ({ url: `/api/v1/accounts/${queryArg.userId}/transactions`, params: { page: queryArg.page, pageSize: queryArg.pageSize } }),
    }),
    userGetAll: build.query<UserGetAllApiResponse, UserGetAllApiArg>({
      query: queryArg => ({
        url: `/api/users`,
        params: {
          isOnline: queryArg.isOnline,
          showDeleted: queryArg.showDeleted,
          showDisabled: queryArg.showDisabled,
          ids: queryArg.ids,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    permissionGetAll: build.query<PermissionGetAllApiResponse, PermissionGetAllApiArg>({
      query: () => ({ url: `/api/users/permissions` }),
    }),
    userGetFullMe: build.query<UserGetFullMeApiResponse, UserGetFullMeApiArg>({
      query: () => ({ url: `/api/users/me` }),
    }),
    relationshipGetFullAll: build.query<RelationshipGetFullAllApiResponse, RelationshipGetFullAllApiArg>({
      query: queryArg => ({
        url: `/api/relationships/full`,
        params: {
          isFavorite: queryArg.isFavorite,
          name: queryArg.name,
          initiatedBy: queryArg.initiatedBy,
          pointOfViewRole: queryArg.pointOfViewRole,
          statuses: queryArg.statuses,
          isOnline: queryArg.isOnline,
          showDeleted: queryArg.showDeleted,
          showDisabled: queryArg.showDisabled,
          ids: queryArg.ids,
          userId: queryArg.userId,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    relationshipGetCount: build.query<RelationshipGetCountApiResponse, RelationshipGetCountApiArg>({
      query: queryArg => ({
        url: `/api/relationships/count`,
        params: {
          isFavorite: queryArg.isFavorite,
          name: queryArg.name,
          initiatedBy: queryArg.initiatedBy,
          pointOfViewRole: queryArg.pointOfViewRole,
          statuses: queryArg.statuses,
          isOnline: queryArg.isOnline,
          showDeleted: queryArg.showDeleted,
          showDisabled: queryArg.showDisabled,
          ids: queryArg.ids,
          userId: queryArg.userId,
        },
      }),
    }),
    transactionGetByAccountReplenish: build.query<TransactionGetByAccountReplenishApiResponse, TransactionGetByAccountReplenishApiArg>({
      query: queryArg => ({
        url: `/api/referrals/${queryArg.referralId}/transactions`,
        params: {
          userRole: queryArg.userRole,
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    modelRequestGetById: build.query<ModelRequestGetByIdApiResponse, ModelRequestGetByIdApiArg>({
      query: queryArg => ({ url: `/api/models/requests/${queryArg.modelRequestId}` }),
    }),
    modelRequestDeleteById: build.mutation<ModelRequestDeleteByIdApiResponse, ModelRequestDeleteByIdApiArg>({
      query: queryArg => ({ url: `/api/models/requests/${queryArg.modelRequestId}`, method: 'DELETE' }),
    }),
    modelRequestGetMe: build.query<ModelRequestGetMeApiResponse, ModelRequestGetMeApiArg>({
      query: () => ({ url: `/api/models/requests/me` }),
    }),
    fileGetDataById: build.query<FileGetDataByIdApiResponse, FileGetDataByIdApiArg>({
      query: queryArg => ({ url: `/api/files/${queryArg.fileId}`, headers: { Range: queryArg.range } }),
    }),
    generateCaptcha: build.query<GenerateCaptchaApiResponse, GenerateCaptchaApiArg>({
      query: () => ({ url: `/api/captcha` }),
    }),
    wordBlackListDelete: build.mutation<WordBlackListDeleteApiResponse, WordBlackListDeleteApiArg>({
      query: queryArg => ({ url: `/api/words-black-list/${queryArg.wordBlackListId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as mainApi }
export type MessageRulesGetApiResponse = /** status 200 OK */ MessageRulesResponse
export type MessageRulesGetApiArg = void
export type MessageRulesSetApiResponse = /** status 200 OK */ MessageRulesResponse
export type MessageRulesSetApiArg = {
  messageRulesSetRequest: MessageRulesSetRequest
}
export type MessageUpdateByIdApiResponse = /** status 200 OK */ MessageResponse
export type MessageUpdateByIdApiArg = {
  privateChatId: string
  messageId: string
  messageUpdateRequest: MessageUpdateRequest
}
export type ReferralUpdateByIdApiResponse = /** status 200 OK */ ReferralViewResponse
export type ReferralUpdateByIdApiArg = {
  referralId: string
  referralUpdateRequest: ReferralUpdateRequest
}
export type ReferralDeleteByIdApiResponse = /** status 204 No Content */ undefined
export type ReferralDeleteByIdApiArg = {
  referralId: string
}
export type ModelServiceGetAllApiResponse = /** status 200 OK */ ModelServiceDto[]
export type ModelServiceGetAllApiArg = {
  modelId: string
}
export type ModelServiceSetApiResponse = /** status 200 OK */ ModelServiceDto[]
export type ModelServiceSetApiArg = {
  modelId: string
  body: ModelServiceSaveDto[]
}
export type GlobalConfigGetApiResponse = /** status 200 OK */ GlobalConfigDto
export type GlobalConfigGetApiArg = void
export type GlobalConfigSetApiResponse = /** status 200 OK */ GlobalConfigDto
export type GlobalConfigSetApiArg = {
  globalConfigSaveDto: GlobalConfigSaveDto
}
export type TokenGetApiResponse = /** status 200 Successful operation */ TokenResponseForUsernamePassword | TokenResponseForRefresh
export type TokenGetApiArg = {
  body: {
    client_id: string
    client_secret: string
    grant_type: string
    username?: string
    password?: string
    refresh_token?: string
  }
}
export type TokenRevokeApiResponse = /** status 200 OK */ undefined
export type TokenRevokeApiArg = {
  body: {
    client_id: string
    client_secret: string
    token: string
  }
}
export type WordBlackListGetAllApiResponse = /** status 200 OK */ PageWordBlackListDto
export type WordBlackListGetAllApiArg = {
  contains?: string
  page?: number
  pageSize?: number
}
export type WordBlackListCreateApiResponse = /** status 201 Created */ WordBlackListDto
export type WordBlackListCreateApiArg = {
  wordBlackListCreateDto: WordBlackListCreateDto
}
export type TranslateApiResponse = /** status 200 OK */ TranslateResponse
export type TranslateApiArg = {
  translateRequest: TranslateRequest
}
export type TicketCreateApiResponse = /** status 201 Created */ TicketResponse
export type TicketCreateApiArg = void
export type SearchApiResponse = /** status 200 OK */ PageRoomResponse
export type SearchApiArg = {
  fromDate?: string
  toDate?: string
  userIdToConnect?: string
  withCurrentUserIdToConnect?: boolean
  withActive?: boolean
  withParticipant?: boolean
  blockedUserIdsToConnect?: string[]
  page?: number
  pageSize?: number
}
export type CreateRoomApiResponse = /** status 200 OK */ RoomWithAccessResponse
export type CreateRoomApiArg = {
  callerUserId?: string
}
export type CreateVoteRoomApiResponse = /** status 200 OK */ undefined
export type CreateVoteRoomApiArg = {
  roomId: string
  roomVoteRequest: RoomVoteRequest
}
export type RegisterLiveTimeApiResponse = /** status 200 OK */ undefined
export type RegisterLiveTimeApiArg = {
  roomId: string
  registerLiveTimeRequest: RegisterLiveTimeRequest
}
export type CreateRoom1ApiResponse = /** status 200 OK */ RoomWithAccessResponse
export type CreateRoom1ApiArg = {
  roomId: string
  secured?: boolean
}
export type ReceiveWebhookApiResponse = /** status 200 OK */ undefined
export type ReceiveWebhookApiArg = {
  authorization: string
  body: string
}
export type PrivateChatGetAllApiResponse = /** status 200 OK */ CursorPagePrivateChatViewResponse
export type PrivateChatGetAllApiArg = {
  unreadMessageCountBy?: 'CLIENT' | 'MODEL' | 'STAFF'
  clientId?: string
  modelId?: string
  staffId?: string
  approvedByClient?: boolean
  approvedByModel?: boolean
  approvedByModelOrAttentionAttracted?: boolean
  approvedByStaff?: boolean
  type?: 'GENERAL' | 'SUPPORT'
  disabled?: boolean
  byRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  token?: string
  pageSize: number
}
export type PrivateChatCreateApiResponse = /** status 200 OK */ PrivateChatResponse
export type PrivateChatCreateApiArg = {
  privateChatCreateRequest: PrivateChatCreateRequest
}
export type MessageWithVideoCreateApiResponse = /** status 201 Created */ MessageResponse
export type MessageWithVideoCreateApiArg = {
  privateChatId: string
  messageCreateRequest: MessageCreateRequest
}
export type MessageGetAllApiResponse = /** status 200 OK */ CursorPageMessageResponse
export type MessageGetAllApiArg = {
  privateChatId: string
  token?: string
  pageSize: number
}
export type MessageCreateApiResponse = /** status 201 Created */ MessageResponse
export type MessageCreateApiArg = {
  privateChatId: string
  messageCreateRequest: MessageCreateRequest
}
export type MessageReadByIdsApiResponse = /** status 200 OK */ undefined
export type MessageReadByIdsApiArg = {
  privateChatId: string
  messageIds: string[]
}
export type MessageReadAllApiResponse = /** status 200 OK */ undefined
export type MessageReadAllApiArg = {
  privateChatId: string
}
export type MessageClearHistoryApiResponse = /** status 204 No Content */ undefined
export type MessageClearHistoryApiArg = {
  privateChatId: string
}
export type PrivateChatDisableByIdApiResponse = /** status 200 OK */ PrivateChatResponse
export type PrivateChatDisableByIdApiArg = {
  privateChatId: string
}
export type PrivateChatAssignByIdApiResponse = /** status 200 OK */ PrivateChatResponse
export type PrivateChatAssignByIdApiArg = {
  privateChatId: string
}
export type NotificationReadApiResponse = /** status 204 No Content */ undefined
export type NotificationReadApiArg = {
  notificationReadRequest: NotificationReadRequest
}
export type GiftRecordsGetAllApiResponse = /** status 200 OK */ PageGiftRecordResponse
export type GiftRecordsGetAllApiArg = {
  modelId: string
  fromClientId?: string
  page?: number
  pageSize?: number
}
export type GiftSendApiResponse = /** status 201 Created */ GiftRecordResponse
export type GiftSendApiArg = {
  modelId: string
  giftSendRequest: GiftSendRequest
}
export type UserEnableByIdApiResponse = /** status 204 No Content */ undefined
export type UserEnableByIdApiArg = {
  userId: string
}
export type UserDisableByIdApiResponse = /** status 204 No Content */ undefined
export type UserDisableByIdApiArg = {
  userId: string
}
export type UserVerifyCurrentEmailApiResponse = /** status 204 No Content */ undefined
export type UserVerifyCurrentEmailApiArg = {
  emailVerificationRequest: EmailVerificationRequest
}
export type UserSendCurrentEmailConfirmationCodeApiResponse = /** status 204 No Content */ undefined
export type UserSendCurrentEmailConfirmationCodeApiArg = {
  emailUpdateConfirmationRequest: EmailUpdateConfirmationRequest
}
export type UserResetPasswordApiResponse = /** status 204 No Content */ undefined
export type UserResetPasswordApiArg = {
  resetPasswordRequest: ResetPasswordRequest
}
export type UserSendForgotPasswordCodeApiResponse = /** status 204 No Content */ undefined
export type UserSendForgotPasswordCodeApiArg = {
  forgotPasswordRequest: ForgotPasswordRequest
}
export type StaffGetAllApiResponse = /** status 200 OK */ PageStaffViewDto
export type StaffGetAllApiArg = {
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  page?: number
  pageSize?: number
}
export type StaffCreateApiResponse = /** status 201 Created */ StaffFullDto
export type StaffCreateApiArg = {
  staffCreateDto: StaffCreateDto
}
export type ServiceGetAllApiResponse = /** status 200 OK */ ServiceDto[]
export type ServiceGetAllApiArg = void
export type ServiceCreateApiResponse = /** status 201 Created */ ServiceDto
export type ServiceCreateApiArg = {
  serviceCreateDto: ServiceCreateDto
}
export type RelationshipCreateApiResponse = /** status 201 Created */ RelationshipDto
export type RelationshipCreateApiArg = {
  relationshipCreateDto: RelationshipCreateDto
}
export type ReferralGetAllApiResponse = /** status 200 OK */ ReferralPageReferralViewResponse
export type ReferralGetAllApiArg = {
  nicknameContains?: string
  isDeleted?: boolean
  createdBy?: string
  page?: number
  pageSize?: number
}
export type ReferralCreateApiResponse = /** status 201 Created */ ReferralViewResponse
export type ReferralCreateApiArg = {
  referralCreateRequest: ReferralCreateRequest
}
export type ModelGetAllApiResponse = /** status 200 OK */ PageModelViewDto
export type ModelGetAllApiArg = {
  languages?: (
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  )[]
  services?: number[]
  name?: string
  isHaveRelationshipWithCurrentClient?: boolean
  relationshipStatuses?: ('FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED')[]
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  page?: number
  pageSize?: number
}
export type ModelCreateApiResponse = /** status 201 Created */ string
export type ModelCreateApiArg = {
  userCreateDto: UserCreateDto
}
export type ModelRequestGetAllApiResponse = /** status 200 OK */ PageModelRequestViewDto
export type ModelRequestGetAllApiArg = {
  state?: 'CREATE_NEW' | 'UPDATE_EXISTED'
  page?: number
  pageSize?: number
}
export type ModelRequestCreateApiResponse = /** status 201 Created */ ModelRequestFullDto
export type ModelRequestCreateApiArg = {
  modelUserId?: string
  body: ModelRequestMainInfoSaveDto | ModelSecretDataRequestSaveDto
}
export type ModelRequestApproveByIdApiResponse = /** status 204 No Content */ undefined
export type ModelRequestApproveByIdApiArg = {
  modelRequestId: string
  type: 'MAIN_INFO' | 'SECRET_DATA'
}
export type ModelGetIdsApiResponse = /** status 200 OK */ PageModelViewDto
export type ModelGetIdsApiArg = {
  page?: number
  pageSize?: number
  modelFilterDto: ModelFilterDto
}
export type FileCreateApiResponse = /** status 201 Created */ FileDto
export type FileCreateApiArg = {
  ownerId?: string
  body: {
    file: Blob
  }
}
export type ComplaintGetAllApiResponse = /** status 200 OK */ PageComplaintDto
export type ComplaintGetAllApiArg = {
  page?: number
  pageSize?: number
}
export type ComplaintCreateApiResponse = /** status 204 No Content */ undefined
export type ComplaintCreateApiArg = {
  complaintCreateDto: ComplaintCreateDto
}
export type ClientGetAllApiResponse = /** status 200 OK */ PageClientViewDto
export type ClientGetAllApiArg = {
  isHaveRelationshipWithCurrentModel?: boolean
  relationshipStatuses?: ('FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED')[]
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  page?: number
  pageSize?: number
}
export type ClientCreateApiResponse = /** status 201 Created */ string
export type ClientCreateApiArg = {
  clientCreateDto: ClientCreateDto
}
export type ClientGetIdsApiResponse = /** status 200 OK */ PageClientViewDto
export type ClientGetIdsApiArg = {
  page?: number
  pageSize?: number
  clientFilterDto: ClientFilterDto
}
export type VerifyCaptchaApiResponse = /** status 200 OK */ undefined
export type VerifyCaptchaApiArg = {
  captchaDto: CaptchaDto
}
export type AccountGetByIdApiResponse = /** status 200 OK */ AccountResponse
export type AccountGetByIdApiArg = {
  userId: string
}
export type AccountPartialUpdateByIdApiResponse = /** status 200 OK */ AccountResponse
export type AccountPartialUpdateByIdApiArg = {
  userId: string
  accountPartialUpdateRequest: AccountPartialUpdateRequest
}
export type UserGetFullByIdApiResponse = any
export type UserGetFullByIdApiArg = {
  userId: string
}
export type UserDeleteByIdApiResponse = /** status 204 No Content */ undefined
export type UserDeleteByIdApiArg = {
  userId: string
}
export type UserPartialUpdateByIdApiResponse = any
export type UserPartialUpdateByIdApiArg = {
  userId: string
  userPartialUpdateDto: UserPartialUpdateDto
}
export type StaffGetFullByIdApiResponse = /** status 200 OK */ StaffFullDto
export type StaffGetFullByIdApiArg = {
  staffId: string
}
export type StaffPartialUpdateByIdApiResponse = /** status 200 OK */ StaffFullDto
export type StaffPartialUpdateByIdApiArg = {
  staffId: string
  staffPartialUpdateDto: StaffPartialUpdateDto
}
export type ServiceGetByIdApiResponse = /** status 200 OK */ ServiceDto
export type ServiceGetByIdApiArg = {
  serviceId: number
}
export type ServiceDeleteByIdApiResponse = /** status 204 No Content */ undefined
export type ServiceDeleteByIdApiArg = {
  serviceId: number
}
export type ServicePartialUpdateByIdApiResponse = /** status 200 OK */ ServiceDto
export type ServicePartialUpdateByIdApiArg = {
  serviceId: number
  servicePartialUpdateDto: ServicePartialUpdateDto
}
export type RelationshipDeleteByIdApiResponse = /** status 204 No Content */ undefined
export type RelationshipDeleteByIdApiArg = {
  relationshipId: string
}
export type RelationshipPartialUpdateByIdApiResponse = /** status 200 OK */ RelationshipDto
export type RelationshipPartialUpdateByIdApiArg = {
  relationshipId: string
  relationshipPartialUpdateDto: RelationshipPartialUpdateDto
}
export type ModelGetFullByIdApiResponse = /** status 200 OK */ ModelFullDto
export type ModelGetFullByIdApiArg = {
  modelId: string
  idType: 'ID' | 'IDENTIFIER'
}
export type ModelPartialUpdateByIdApiResponse = /** status 200 OK */ ModelFullDto
export type ModelPartialUpdateByIdApiArg = {
  modelId: string
  modelPartialUpdateDto: ModelPartialUpdateDto
}
export type ClientGetFullByIdApiResponse = /** status 200 OK */ ClientFullDto
export type ClientGetFullByIdApiArg = {
  clientId: string
  idType: 'ID' | 'IDENTIFIER'
}
export type ClientPartialUpdateByIdApiResponse = /** status 200 OK */ ClientFullDto
export type ClientPartialUpdateByIdApiArg = {
  clientId: string
  clientPartialUpdateDto: ClientPartialUpdateDto
}
export type TransactionGetAllApiResponse = /** status 200 OK */ PageTransactionResponse
export type TransactionGetAllApiArg = {
  userRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  fromDate?: string
  toDate?: string
  page?: number
  pageSize?: number
}
export type GetRoomApiResponse = /** status 200 OK */ RoomResponse
export type GetRoomApiArg = {
  roomId: string
}
export type SearchVoteRoomsApiResponse = /** status 200 OK */ PageRoomVoteResponse
export type SearchVoteRoomsApiArg = {
  roomId?: string
  ownerUserId?: string
  fromDate?: string
  toDate?: string
  lessRating?: number
  greaterRating?: number
  withComment?: boolean
  page?: number
  pageSize?: number
}
export type RoomGetRandomWaitingApiResponse = /** status 200 OK */ WaitingRoomDto
export type RoomGetRandomWaitingApiArg = void
export type GetAverageVoteRoomsApiResponse = /** status 200 OK */ AverageRoomVoteResponse
export type GetAverageVoteRoomsApiArg = {
  ownerUserId?: string
  fromDate?: string
  toDate?: string
  withComment?: boolean
}
export type RecipientMessageGetByPrivateChatIdApiResponse = /** status 200 OK */ MessageResponse[]
export type RecipientMessageGetByPrivateChatIdApiArg = {
  privateChatId: string
  type?:
    | 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_MODEL'
    | 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_MODEL'
    | 'SYSTEM_FRIEND_REMOVED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REMOVED_BY_MODEL'
    | 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_MODEL'
    | 'SYSTEM_USER_WAS_DELETED'
    | 'SYSTEM_BLOCKED_BY_MODEL'
    | 'SYSTEM_UNBLOCKED_BY_MODEL'
    | 'SYSTEM_BLOCKED_BY_CLIENT'
    | 'SYSTEM_UNBLOCKED_BY_CLIENT'
    | 'SYSTEM_PRIVATE_CHAT_STAFF_ASSIGNED'
    | 'SYSTEM_PRIVATE_CHAT_CLIENT_ASSIGNED'
    | 'SYSTEM_PRIVATE_CHAT_MODEL_ASSIGNED'
    | 'SYSTEM_PRIVATE_CHAT_DISABLED'
    | 'SYSTEM_CALL_STARTED'
    | 'SYSTEM_CALL_INITIATED'
    | 'SYSTEM_CALL_CANCELLED'
    | 'SYSTEM_CALL_FINISHED'
    | 'SYSTEM_GIFT_RECEIVED'
    | 'GENERAL'
  page?: number
  pageSize?: number
}
export type GetCurrentPrivateChatToModelOrUserApiResponse = /** status 200 OK */ PrivateChatModel
export type GetCurrentPrivateChatToModelOrUserApiArg = {
  userId: string
}
export type GetAnyPrivateChatToStaffApiResponse = /** status 200 OK */ PrivateChatModel
export type GetAnyPrivateChatToStaffApiArg = {
  modelId: string
  clientId: string
}
export type NotificationGetAllApiResponse = /** status 200 OK */ CursorPageNotificationResponse
export type NotificationGetAllApiArg = {
  token?: string
  pageSize: number
}
export type NotificationGetUnreadCountApiResponse = /** status 200 OK */ NotificationCountResponse
export type NotificationGetUnreadCountApiArg = void
export type GiftGetAllApiResponse = /** status 200 OK */ GiftResponse[]
export type GiftGetAllApiArg = void
export type UserEventLogGetApiResponse = /** status 200 OK */ CursorPageUelResponse
export type UserEventLogGetApiArg = {
  fetchFrom?: string
  token?: string
  pageSize: number
}
export type TransactionGetAllByUserIdApiResponse = /** status 200 OK */ PageTransactionResponse
export type TransactionGetAllByUserIdApiArg = {
  userId: string
  page?: number
  pageSize?: number
}
export type UserGetAllApiResponse = /** status 200 OK */ PageUserDto
export type UserGetAllApiArg = {
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  page?: number
  pageSize?: number
}
export type PermissionGetAllApiResponse = /** status 200 OK */ PermissionDto[]
export type PermissionGetAllApiArg = void
export type UserGetFullMeApiResponse = any
export type UserGetFullMeApiArg = void
export type RelationshipGetFullAllApiResponse = /** status 200 OK */ PageRelationshipFullDto
export type RelationshipGetFullAllApiArg = {
  isFavorite?: boolean
  name?: string
  initiatedBy?: 'CLIENT' | 'MODEL' | 'STAFF'
  pointOfViewRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  statuses?: ('FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED')[]
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  userId?: string
  page?: number
  pageSize?: number
}
export type RelationshipGetCountApiResponse = /** status 200 OK */ RelationshipCountDto
export type RelationshipGetCountApiArg = {
  isFavorite?: boolean
  name?: string
  initiatedBy?: 'CLIENT' | 'MODEL' | 'STAFF'
  pointOfViewRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  statuses?: ('FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED')[]
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  userId?: string
}
export type TransactionGetByAccountReplenishApiResponse = /** status 200 OK */ PageTransactionResponse
export type TransactionGetByAccountReplenishApiArg = {
  referralId: string
  userRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  fromDate?: string
  toDate?: string
  page?: number
  pageSize?: number
}
export type ModelRequestGetByIdApiResponse = /** status 200 OK */ ModelRequestFullDto
export type ModelRequestGetByIdApiArg = {
  modelRequestId: string
}
export type ModelRequestDeleteByIdApiResponse = /** status 204 No Content */ undefined
export type ModelRequestDeleteByIdApiArg = {
  modelRequestId: string
}
export type ModelRequestGetMeApiResponse = /** status 200 OK */ ModelRequestFullDto
export type ModelRequestGetMeApiArg = void
export type FileGetDataByIdApiResponse = /** status 200 OK */ string
export type FileGetDataByIdApiArg = {
  fileId: string
  /** Byte range for partial download */
  range?: any
}
export type GenerateCaptchaApiResponse = /** status 200 OK */ undefined
export type GenerateCaptchaApiArg = void
export type WordBlackListDeleteApiResponse = /** status 204 No Content */ undefined
export type WordBlackListDeleteApiArg = {
  wordBlackListId: string
}
export type MessageRuleResponse = {
  coinsDeltaFrom: number
  coinsDeltaTo?: number
  messages: number
}
export type MessageRulesResponse = {
  rules: MessageRuleResponse[]
}
export type ValidationError = {
  message?: string
  value?: object
  field?: string
}
export type ProblemDetail = {
  type:
    | 'MESSAGE_NOT_FOUND'
    | 'AUTH_USER_NOT_FOUND'
    | 'AUTH_USER_BLOCKED'
    | 'AUTH_USER_NOT_TRUSTED'
    | 'AUTH_USER_DISABLED'
    | 'AUTH_USER_BAD_CREDENTIALS'
    | 'AUTH_USER_INTERNAL_SERVER_ERROR'
    | 'INTERNAL_SERVER_ERROR'
    | 'BAD_REQUEST'
    | 'CONSTRAINT_VIOLATION_TYPE'
    | 'ACCESS_DENIED'
    | 'UNAUTHORIZED'
    | 'TOKEN_INVALID'
    | 'FIELD_IS_MISSING'
    | 'INVALID_TYPE_ID'
    | 'INVALID_ENUM_VALUE'
    | 'INVALID_FORMAT'
    | 'REQUEST_SIZE_EXCEEDED'
    | 'ACTIVITY_STATUS_NOT_ALLOWED_FOR_ROLE'
    | 'ACTIVITY_STATUS_MANUAL_SET_NOT_ALLOWED'
    | 'PRIVATE_CHAT_NOT_FOUND'
    | 'PRIVATE_CHAT_CREATION_NOT_ALLOWED'
    | 'PRIVATE_CHAT_DISABLED'
    | 'PRIVATE_CHAT_INCORRECT_PARTICIPANTS'
    | 'PRIVATE_CHAT_DISABLE_NOT_ALLOWED'
    | 'PRIVATE_CHAT_SHOULD_BE_UNIQUE'
    | 'PRIVATE_CHAT_SHOWING_NOT_ALLOWED'
    | 'MESSAGE_RULE_NOT_VALID'
    | 'TRANSACTION_NOT_FOUND'
    | 'GIFT_NOT_FOUND'
    | 'GIFT_RECORD_NOT_FOUND'
    | 'GIFT_SEND_PERMISSION'
    | 'REFERRAL_NOT_FOUND'
    | 'REFERRAL_ALREADY_USED'
    | 'ACCOUNT_NOT_FOUND'
    | 'ACCOUNT_COINS_BALANCE_NOT_VALID'
    | 'ACCOUNT_COINS_BALANCE_UPDATE_TYPE_NOT_ALLOWED_FOR_ROLE'
    | 'ACCOUNT_MESSAGES_BALANCE_UPDATE_NOT_ALLOWED_FOR_ROLE'
    | 'ACCOUNT_MESSAGES_BALANCE_NOT_VALID'
    | 'ACCOUNT_COINS_BALANCE_INSUFFICIENT'
    | 'ACCOUNT_MESSAGES_BALANCE_INSUFFICIENT'
    | 'ACCOUNT_DELETED'
    | 'TICKET_NOT_FOUND'
    | 'CALL_PARTICIPANTS_NOT_ALLOWED'
    | 'CALL_NOT_FOUND'
    | 'MULTIPLE_RANGES_NOT_ALLOWED'
    | 'RAW_CONTAINS_BLOCKED_WORD'
    | 'WS_SERVICE_UNAVAILABLE'
    | 'USER_NOT_FOUND'
    | 'USER_DELETED'
    | 'USER_EMAIL_ALREADY_USED'
    | 'USER_EMAIL_NOT_VERIFIED'
    | 'USER_EMAIL_NOT_FOUND'
    | 'PERMISSION_ROLE_INCONSISTENCY'
    | 'USER_ROLE_INCORRECT'
    | 'USER_EMAIL_CONFIRMATION_CODE_NOT_FOUND'
    | 'USER_EMAIL_CONFIRMATION_CODE_REQUIRED'
    | 'USER_RESET_PASSWORD_CODE_NOT_FOUND'
    | 'FILE_NOT_FOUND'
    | 'UNSUPPORTED_MEDIA_TYPE'
    | 'FILE_NOT_TEMP'
    | 'FILE_OWNER_INCORRECT'
    | 'FILE_MIGRATION_ERROR'
    | 'FILE_TYPE_NOT_ALLOWED'
    | 'STAFF_NOT_FOUND'
    | 'ROOT_STAFF_ALREADY_EXISTS'
    | 'WORD_ALREADY_EXISTS_IN_BLACK_LIST'
    | 'WORD_BLACK_LIST_NOT_FOUND'
    | 'NOT_FOUND'
    | 'CLIENT_NOT_FOUND'
    | 'MODEL_NOT_FOUND'
    | 'MODEL_PART_NOT_FOUND'
    | 'SERVICE_NOT_FOUND'
    | 'PRICE_FOR_PRICELESS_SERVICE_CANNOT_BE_SET'
    | 'DEFAULT_SERVICE_CANNOT_BE_LINKED'
    | 'RECOMMENDED_PRICE_FOR_PRICELESS_SERVICE'
    | 'DEFAULT_SERVICE_NOT_PRICELESS'
    | 'MODEL_REQUEST_NOT_FOUND'
    | 'MODEL_REQUEST_NOT_ALLOWED'
    | 'MODEL_REQUEST_PART_NOT_FOUND'
    | 'MODEL_REQUEST_FILES_DUPLICATES'
    | 'MODEL_REQUEST_MERGE_NOT_ALLOWED'
    | 'MODEL_REQUEST_DOCUMENTS_REQUIRED'
    | 'MODEL_DOCUMENTS_REQUIRED'
    | 'NOTIFICATION_NOT_FOUND'
    | 'PERMANENT_NOTIFICATION_SUBSCRIPTION_MANAGED'
    | 'RELATIONSHIP_NOT_FOUND'
    | 'RELATIONSHIP_ALREADY_EXISTS'
    | 'RELATIONSHIP_POINT_OF_VIEW_NOT_POSSIBLE'
    | 'RELATIONSHIP_FAVORITE_STATUS_CHANGE_FOR_NON_FRIEND'
    | 'RELATIONSHIP_IS_NOT_IN_FRIEND_REQUEST_STATUS'
    | 'RELATIONSHIP_INITIATOR_NOT_ALLOWED'
    | 'NO_INTENTIONS_FOR_NOTIFICATION'
    | 'DEFAULT_MODEL_MINUTE_PRICE_NOT_SET'
    | 'COMPLAINT_NOT_FOUND'
    | 'SELF_COMPLAINT_NOT_ALLOWED'
    | 'ROOM_WAITING_NOT_FOUND'
    | 'CAPTCHA_NOT_VERIFIED'
    | 'ROOM_NOT_FOUND'
    | 'ROOM_NOT_ACTIVE'
    | 'ROOM_CALL_NOT_RELATED_TO_USER'
    | 'ROOM_ALREADY_BUSY'
    | 'ROOM_PRIVATE_CHAT_NOT_ASSIGNED'
    | 'ROOM_ACCESS_DENIED'
    | 'USER_OWNER_ROOM_NOT_FOUND'
    | 'HEARTBEAT_NOT_ALLOWED_FOR_ROLE'
    | 'HEARTBEAT_MANUAL_SET_NOT_ALLOWED'
    | 'AUTH_USER_EMAIL_ALREADY_EXISTS'
    | 'AUTH_USER_NOT_FOUND'
    | 'AUTH_USER_DELETED'
    | 'AUTH_USER_DISABLED'
    | 'AUTH_USER_STATUS_NOT_FOUND'
  title: string
  resourceId?: string | null
  status: number
  instance?: string | null
  violations?: ValidationError[] | null
  args?: object | null
}
export type MessageRuleRequest = {
  coinsDeltaFrom: number
  coinsDeltaTo?: number
  messages: number
}
export type MessageRulesSetRequest = {
  rules: MessageRuleRequest[]
}
export type MessageResponse = {
  id: string
  createdAt: string
  createdBy?: string
  updatedAt?: string
  privateChatId: string
  type:
    | 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_MODEL'
    | 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_MODEL'
    | 'SYSTEM_FRIEND_REMOVED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REMOVED_BY_MODEL'
    | 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_CLIENT'
    | 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_MODEL'
    | 'SYSTEM_USER_WAS_DELETED'
    | 'SYSTEM_BLOCKED_BY_MODEL'
    | 'SYSTEM_UNBLOCKED_BY_MODEL'
    | 'SYSTEM_BLOCKED_BY_CLIENT'
    | 'SYSTEM_UNBLOCKED_BY_CLIENT'
    | 'SYSTEM_PRIVATE_CHAT_STAFF_ASSIGNED'
    | 'SYSTEM_PRIVATE_CHAT_CLIENT_ASSIGNED'
    | 'SYSTEM_PRIVATE_CHAT_MODEL_ASSIGNED'
    | 'SYSTEM_PRIVATE_CHAT_DISABLED'
    | 'SYSTEM_CALL_STARTED'
    | 'SYSTEM_CALL_INITIATED'
    | 'SYSTEM_CALL_CANCELLED'
    | 'SYSTEM_CALL_FINISHED'
    | 'SYSTEM_GIFT_RECEIVED'
    | 'GENERAL'
  body?: string
  translatedBody?: string
  translatedBodyLanguage?:
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  senderRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  recipientRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  isReadByClient: boolean
  isReadByModel: boolean
  isReadByStaff: boolean
  isDeleted?: boolean
}
export type MessageUpdateRequest = {
  body: string
}
export type ReferralResponse = {
  id?: string
  createdAt?: string
  createdBy?: string
  updatedAt?: string
  updatedBy?: string
  nickname?: string
  userSource?: string
  description?: string
  isDeleted?: boolean
}
export type ReferralViewResponse = {
  referral?: ReferralResponse
  clientIds?: string[]
  modelIds?: string[]
}
export type ReferralUpdateRequest = {
  nickname: string
  userSource: string
  description: string
}
export type ModelServiceDto = {
  modelId?: string
  serviceId?: number
  price?: number
}
export type ModelServiceSaveDto = {
  serviceId: number
  price?: number
}
export type GlobalConfigDto = {
  defaultModelMinutePrice?: number
  clientEmailVerificationCoinsBonus?: number
  obligatoryMinutesForRoomWithClientAndModel?: number
  coefficientForObligatoryMinutesForRoomWithClientAndModel?: number
  obligatoryMinutesForCallFromClientToModel?: number
  coefficientForObligatoryMinutesForCallFromClientToModel?: number
  obligatoryMinutesForCallFromModelToClient?: number
  coefficientForObligatoryMinutesForCallFromModelToClient?: number
}
export type GlobalConfigSaveDto = {
  defaultModelMinutePrice: number
  clientEmailVerificationCoinsBonus: number
  obligatoryMinutesForRoomWithClientAndModel: number
  coefficientForObligatoryMinutesForRoomWithClientAndModel: number
  obligatoryMinutesForCallFromClientToModel: number
  coefficientForObligatoryMinutesForCallFromClientToModel: number
  obligatoryMinutesForCallFromModelToClient: number
  coefficientForObligatoryMinutesForCallFromModelToClient: number
}
export type TokenResponseForUsernamePassword = {
  access_token: string
  token_type: string
  refresh_token: string
  refresh_token_expires_in: number
  expires_in: number
}
export type TokenResponseForRefresh = {
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: number
}
export type WordBlackListDto = {
  id?: string
  word?: string
}
export type PageWordBlackListDto = {
  content: WordBlackListDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type WordBlackListCreateDto = {
  word: string
}
export type TranslateResponse = {
  initialBody?: string
  translatedBody?: string
  translatedLanguage?:
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
}
export type TranslateRequest = {
  body: string
  language:
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
}
export type TicketResponse = {
  ticket: string
}
export type RoomResponse = {
  id: string
  ownerUserId: string
  ownerUserRole: 'CLIENT' | 'MODEL' | 'STAFF'
  isActive: boolean
  type: 'GENERAL_ROOM' | 'CALL_FROM_CLIENT' | 'CALL_FROM_MODEL' | 'CALL_FROM_STAFF'
  privateChatId?: string
  createdAt: string
  participantId?: string
  participantRole?: 'CLIENT' | 'MODEL' | 'STAFF'
}
export type PageRoomResponse = {
  content: RoomResponse[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type LiveKitTokenResponse = {
  token: string
}
export type RoomWithAccessResponse = {
  room: RoomResponse
  token: LiveKitTokenResponse
}
export type RoomVoteRequest = {
  activeTime: number
  rating: number
  comment?: string
}
export type RegisterLiveTimeRequest = {
  minutes: number
}
export type PrivateChatResponse = {
  id: string
  modelId?: string
  staffId?: string
  clientId?: string
  approvedByClient: boolean
  approvedByStaff: boolean
  approvedByModel: boolean
  clientAttractAttentionCount: number
  isDisabled: boolean
  createdAt: string
  type: 'GENERAL' | 'SUPPORT'
}
export type PrivateChatViewResponse = {
  privateChat: PrivateChatResponse
  lastMessage?: MessageResponse
  unreadMessageCount: number
}
export type CursorPagePrivateChatViewResponse = {
  content: PrivateChatViewResponse[]
  token?: string
  size: number
  pageSize: number
  isEnd: boolean
}
export type MessageCreateRequest = {
  body: string
}
export type PrivateChatCreateRequest = {
  initialMessage: MessageCreateRequest
  type: 'GENERAL' | 'SUPPORT'
  clientId?: string
  modelId?: string
}
export type CursorPageMessageResponse = {
  content: MessageResponse[]
  token?: string
  size: number
  pageSize: number
  isEnd: boolean
}
export type NotificationReadRequest = {
  notificationIds: number[]
}
export type GiftRecordResponse = {
  id?: string
  fromClientId?: string
  modelId?: string
  giftId?: number
  createdAt?: string
}
export type PageGiftRecordResponse = {
  content: GiftRecordResponse[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type GiftSendRequest = {
  giftId: number
}
export type EmailVerificationRequest = {
  confirmationCode: string
}
export type EmailUpdateConfirmationRequest = {
  email: string
  currentEmailConfirmationCode?: string
}
export type ResetPasswordRequest = {
  newPassword: string
  confirmationCode: string
}
export type ForgotPasswordRequest = {
  email: string
}
export type UserDto = {
  id?: string
  createdAt?: string
  createdBy?: string
  updatedAt?: string
  updatedBy?: string
  role?: 'CLIENT' | 'MODEL' | 'STAFF'
  email?: string
  isEmailVerified?: boolean
  isDeleted?: boolean
  deletedAt?: string
  deletedBy?: string
  language?:
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  isDisabled?: boolean
  disabledAt?: string
  disabledBy?: string
  activityStatus?: string
  activityStatusAt?: string
}
export type StaffDto = {
  id?: string
  isRoot?: boolean
  updatedAt?: string
  updatedBy?: string
}
export type StaffViewDto = {
  user?: UserDto
  staff?: StaffDto
}
export type PageStaffViewDto = {
  content: StaffViewDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type UserFullDto = {
  user?: UserDto
  permissions?: (
    | 'CLIENT_VIEW'
    | 'CLIENT_UPDATE'
    | 'CLIENT_UPDATE_NAME'
    | 'CLIENT_UPDATE_NAME_SELF'
    | 'MODEL_REQUEST_VIEW'
    | 'MODEL_REQUEST_CREATE'
    | 'MODEL_REQUEST_DELETE'
    | 'MODEL_REQUEST_APPROVE_MAIN_INFO'
    | 'MODEL_REQUEST_APPROVE_SECRET_DATA'
    | 'MODEL_VIEW'
    | 'MODEL_VIEW_SECRET_DATA'
    | 'MODEL_UPDATE_MINUTE_PRICE'
    | 'ROOM_VIEW'
    | 'ROOM_REGISTER_LIVE_TIME'
    | 'ROOM_JOIN'
    | 'ROOM_JOIN_2'
    | 'ROOM_CREATE'
    | 'SERVICE_VIEW_RECOMMENDED_PRICE'
    | 'SERVICE_CREATE'
    | 'SERVICE_UPDATE'
    | 'SERVICE_DELETE'
    | 'MODEL_SERVICE_SET'
    | 'FILE_CREATE_TEMP'
    | 'FILE_SET_OWNER_ID'
    | 'FILE_VIEW_TEMP'
    | 'FILE_VIEW_DELETED'
    | 'NOTIFICATION_VIEW'
    | 'NOTIFICATION_CREATE'
    | 'COMPLAINT_VIEW'
    | 'COMPLAINT_CREATE'
    | 'RELATIONSHIP_VIEW'
    | 'RELATIONSHIP_CREATE'
    | 'RELATIONSHIP_CREATE_SELF'
    | 'RELATIONSHIP_DELETE'
    | 'RELATIONSHIP_DELETE_SELF'
    | 'RELATIONSHIP_UPDATE'
    | 'RELATIONSHIP_UPDATE_SELF'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_SET'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_SET'
    | 'STAFF_VIEW'
    | 'STAFF_CREATE'
    | 'STAFF_UPDATE'
    | 'USER_VIEW'
    | 'USER_UPDATE'
    | 'USER_UPDATE_EMAIL'
    | 'USER_UPDATE_EMAIL_SELF'
    | 'USER_UPDATE_PASSWORD'
    | 'USER_UPDATE_PASSWORD_SELF'
    | 'USER_DELETE'
    | 'USER_DELETED_VIEW'
    | 'USER_MANAGE_DISABLE_STATE'
    | 'USER_DISABLED_VIEW'
    | 'USER_MANAGE_PERMISSION'
    | 'FIELD_FILE_OWNER_ID_VIEW'
    | 'FIELD_EMAIL_VIEW'
    | 'FIELD_AGE_RANGE_VIEW'
    | 'FIELD_PERMISSION_VIEW'
    | 'FIELD_ROOT_VIEW'
    | 'FIELD_AUDIT_VIEW'
    | 'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW'
    | 'WORD_BLACK_LIST_VIEW'
    | 'WORD_BLACK_LIST_CREATE'
    | 'WORD_BLACK_LIST_DELETE'
    | 'GLOBAL_CONFIG_VIEW'
    | 'GLOBAL_CONFIG_SET'
    | 'PERMISSION_VIEW'
    | 'PRIVATE_CHAT_VIEW'
    | 'PRIVATE_CHAT_DISABLE'
    | 'PRIVATE_CHAT_ASSIGN'
    | 'MESSAGE_CREATE'
    | 'MESSAGE_CREATE_SELF'
    | 'MESSAGE_UPDATE'
    | 'MESSAGE_UPDATE_SELF'
    | 'MESSAGE_VIEW'
    | 'MESSAGE_VIEW_DELETED'
    | 'MESSAGE_CLEAR'
    | 'MESSAGE_CLEAR_SELF'
    | 'REFERRAL_CREATE'
    | 'REFERRAL_UPDATE'
    | 'REFERRAL_DELETE'
    | 'REFERRAL_VIEW'
    | 'ACCOUNT_VIEW'
    | 'ACCOUNT_UPDATE'
    | 'TRANSACTION_VIEW'
    | 'MESSAGE_RULE_SET'
    | 'GIFT_SEND'
    | 'GIFT_RECORDS_VIEW'
  )[]
}
export type StaffFullDto = {
  userData?: UserFullDto
  staff?: StaffDto
}
export type UserCreateDto = {
  email: string
  password: string
  language:
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  referralId?: string
}
export type StaffCreateDto = {
  user: UserCreateDto
  permissions?: (
    | 'CLIENT_VIEW'
    | 'CLIENT_UPDATE'
    | 'CLIENT_UPDATE_NAME'
    | 'CLIENT_UPDATE_NAME_SELF'
    | 'MODEL_REQUEST_VIEW'
    | 'MODEL_REQUEST_CREATE'
    | 'MODEL_REQUEST_DELETE'
    | 'MODEL_REQUEST_APPROVE_MAIN_INFO'
    | 'MODEL_REQUEST_APPROVE_SECRET_DATA'
    | 'MODEL_VIEW'
    | 'MODEL_VIEW_SECRET_DATA'
    | 'MODEL_UPDATE_MINUTE_PRICE'
    | 'ROOM_VIEW'
    | 'ROOM_REGISTER_LIVE_TIME'
    | 'ROOM_JOIN'
    | 'ROOM_JOIN_2'
    | 'ROOM_CREATE'
    | 'SERVICE_VIEW_RECOMMENDED_PRICE'
    | 'SERVICE_CREATE'
    | 'SERVICE_UPDATE'
    | 'SERVICE_DELETE'
    | 'MODEL_SERVICE_SET'
    | 'FILE_CREATE_TEMP'
    | 'FILE_SET_OWNER_ID'
    | 'FILE_VIEW_TEMP'
    | 'FILE_VIEW_DELETED'
    | 'NOTIFICATION_VIEW'
    | 'NOTIFICATION_CREATE'
    | 'COMPLAINT_VIEW'
    | 'COMPLAINT_CREATE'
    | 'RELATIONSHIP_VIEW'
    | 'RELATIONSHIP_CREATE'
    | 'RELATIONSHIP_CREATE_SELF'
    | 'RELATIONSHIP_DELETE'
    | 'RELATIONSHIP_DELETE_SELF'
    | 'RELATIONSHIP_UPDATE'
    | 'RELATIONSHIP_UPDATE_SELF'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_SET'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_SET'
    | 'STAFF_VIEW'
    | 'STAFF_CREATE'
    | 'STAFF_UPDATE'
    | 'USER_VIEW'
    | 'USER_UPDATE'
    | 'USER_UPDATE_EMAIL'
    | 'USER_UPDATE_EMAIL_SELF'
    | 'USER_UPDATE_PASSWORD'
    | 'USER_UPDATE_PASSWORD_SELF'
    | 'USER_DELETE'
    | 'USER_DELETED_VIEW'
    | 'USER_MANAGE_DISABLE_STATE'
    | 'USER_DISABLED_VIEW'
    | 'USER_MANAGE_PERMISSION'
    | 'FIELD_FILE_OWNER_ID_VIEW'
    | 'FIELD_EMAIL_VIEW'
    | 'FIELD_AGE_RANGE_VIEW'
    | 'FIELD_PERMISSION_VIEW'
    | 'FIELD_ROOT_VIEW'
    | 'FIELD_AUDIT_VIEW'
    | 'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW'
    | 'WORD_BLACK_LIST_VIEW'
    | 'WORD_BLACK_LIST_CREATE'
    | 'WORD_BLACK_LIST_DELETE'
    | 'GLOBAL_CONFIG_VIEW'
    | 'GLOBAL_CONFIG_SET'
    | 'PERMISSION_VIEW'
    | 'PRIVATE_CHAT_VIEW'
    | 'PRIVATE_CHAT_DISABLE'
    | 'PRIVATE_CHAT_ASSIGN'
    | 'MESSAGE_CREATE'
    | 'MESSAGE_CREATE_SELF'
    | 'MESSAGE_UPDATE'
    | 'MESSAGE_UPDATE_SELF'
    | 'MESSAGE_VIEW'
    | 'MESSAGE_VIEW_DELETED'
    | 'MESSAGE_CLEAR'
    | 'MESSAGE_CLEAR_SELF'
    | 'REFERRAL_CREATE'
    | 'REFERRAL_UPDATE'
    | 'REFERRAL_DELETE'
    | 'REFERRAL_VIEW'
    | 'ACCOUNT_VIEW'
    | 'ACCOUNT_UPDATE'
    | 'TRANSACTION_VIEW'
    | 'MESSAGE_RULE_SET'
    | 'GIFT_SEND'
    | 'GIFT_RECORDS_VIEW'
  )[]
}
export type ServiceDto = {
  id?: number
  createdAt?: string
  createdBy?: string
  updatedAt?: string
  updatedBy?: string
  name?: string
  description?: string
  isDefault?: boolean
  recommendedPrice?: number
  isPriceless?: boolean
  order?: number
}
export type ServiceCreateDto = {
  name: {
    [key: string]: string
  }
  description: {
    [key: string]: string
  }
  isDefault: boolean
  recommendedPrice?: number
  isPriceless: boolean
  order: number
}
export type RelationshipDto = {
  id?: string
  clientId?: string
  modelId?: string
  initiatedBy?: 'CLIENT' | 'MODEL' | 'STAFF'
  initiatedAt?: string
  status?: 'FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED'
  isClientFavorite?: boolean
  isModelFavorite?: boolean
}
export type RelationshipCreateDto = {
  clientId: string
  modelId: string
  type: 'FRIEND_REQUEST' | 'BLOCK'
  initiatedBy?: 'CLIENT' | 'MODEL' | 'STAFF'
}
export type ReferralPageReferralViewResponse = {
  content?: ReferralViewResponse[]
  totalElements?: number
  totalAccountReplenishment?: number
  pageSize?: number
  totalPages?: number
  currentPage?: number
  contentSize?: number
}
export type ReferralCreateRequest = {
  nickname: string
  userSource: string
  description: string
}
export type ModelDto = {
  id?: string
  identifier?: number
  createdAt?: string
  updatedAt?: string
  name?: string
  selfInformation?: string
  birthYear?: number
  weight?: number
  height?: number
  chest?: number
  waist?: number
  hips?: number
  hairColor?:
    | 'BLACK'
    | 'DARK_BROWN'
    | 'LIGHT_BROWN'
    | 'CHESTNUT'
    | 'BLOND'
    | 'ASH_BLONDE'
    | 'RED'
    | 'LIGHT_BLOND'
    | 'DARK_BLOND'
    | 'PINK'
    | 'BLUE'
    | 'GREEN'
    | 'VIOLET'
    | 'BRIGHT_RED'
    | 'SILVER'
    | 'RAINBOW'
    | 'OMBRE'
    | 'BALAYAGE'
    | 'LIGHT_TIPS'
    | 'GREY'
    | 'PARTIAL_GREY'
  breastSize?: number
  sexualOrientation?: 'BISEXUAL' | 'HETERO' | 'LESBIAN'
  bodyType?: 'SLIM' | 'AVERAGE' | 'BIG'
  hairLength?: 'SHORT' | 'MEDIUM' | 'LONG' | 'VERY_LONG'
  activeUpdateRequestId?: string
  minutePrice?: number
}
export type FileDto = {
  id?: string
  url?: string
  ownerId?: string
  createdAt?: string
  createdBy?: string
  name?: string
  size?: number
  type?: 'PDF' | 'JPEG' | 'MP3' | 'MP4' | 'PNG'
  isSecured?: boolean
  isTemp?: boolean
  expireDateToDelete?: string
}
export type ModelFileDto = {
  type?: 'PHOTO' | 'PROFILE_PHOTO' | 'AUDIO' | 'VIDEO' | 'DOCUMENT'
  file?: FileDto
  order?: number
}
export type ModelViewDto = {
  user?: UserDto
  model?: ModelDto
  languages?: (
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  )[]
  services?: ModelServiceDto[]
  profilePhoto?: ModelFileDto
  photos?: ModelFileDto[]
  videos?: ModelFileDto[]
  audios?: ModelFileDto[]
}
export type PageModelViewDto = {
  content: ModelViewDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type ModelRequestDto = {
  id?: string
  createdAt?: string
  createdBy?: string
  modelUserId?: string
  state?: 'CREATE_NEW' | 'UPDATE_EXISTED'
}
export type ModelRequestViewDto = {
  request?: ModelRequestDto
  user?: UserDto
}
export type PageModelRequestViewDto = {
  content: ModelRequestViewDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type ModelRequestFileDto = {
  type?: 'PHOTO' | 'PROFILE_PHOTO' | 'AUDIO' | 'VIDEO' | 'DOCUMENT'
  file?: FileDto
  order?: number
}
export type ModelMainInfoRequestDto = {
  ownerId?: string
  createdAt?: string
  createdBy?: string
  languages?: (
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  )[]
  name?: string
  selfInformation?: string
  birthYear?: number
  weight?: number
  height?: number
  chest?: number
  waist?: number
  hips?: number
  hairColor?:
    | 'BLACK'
    | 'DARK_BROWN'
    | 'LIGHT_BROWN'
    | 'CHESTNUT'
    | 'BLOND'
    | 'ASH_BLONDE'
    | 'RED'
    | 'LIGHT_BLOND'
    | 'DARK_BLOND'
    | 'PINK'
    | 'BLUE'
    | 'GREEN'
    | 'VIOLET'
    | 'BRIGHT_RED'
    | 'SILVER'
    | 'RAINBOW'
    | 'OMBRE'
    | 'BALAYAGE'
    | 'LIGHT_TIPS'
    | 'GREY'
    | 'PARTIAL_GREY'
  breastSize?: number
  sexualOrientation?: 'BISEXUAL' | 'HETERO' | 'LESBIAN'
  bodyType?: 'SLIM' | 'AVERAGE' | 'BIG'
  hairLength?: 'SHORT' | 'MEDIUM' | 'LONG' | 'VERY_LONG'
  profilePhoto?: ModelRequestFileDto
  photos?: ModelRequestFileDto[]
  videos?: ModelRequestFileDto[]
  audios?: ModelRequestFileDto[]
  isApproved?: boolean
  dataType?: 'MAIN_INFO' | 'SECRET_DATA'
}
export type ModelSecretDataRequestDto = {
  id?: string
  ownerId?: string
  createdAt?: string
  createdBy?: string
  birthDate?: string
  realName?: string
  telegram?: string
  viber?: string
  whatsApp?: string
  phone?: string
  documents?: ModelRequestFileDto[]
  isApproved?: boolean
  dataType?: 'MAIN_INFO' | 'SECRET_DATA'
}
export type ModelRequestFullDto = {
  request?: ModelRequestDto
  mainInfo?: ModelMainInfoRequestDto
  secretData?: ModelSecretDataRequestDto
}
export type AbstractModelRequest = {
  dataType: 'MAIN_INFO' | 'SECRET_DATA'
}
export type ModelRequestMainInfoSaveDto = AbstractModelRequest & {
  languages?: (
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  )[]
  name?: string
  selfInformation?: string
  birthYear?: number
  weight?: number
  height?: number
  chest?: number
  waist?: number
  hips?: number
  hairColor?:
    | 'BLACK'
    | 'DARK_BROWN'
    | 'LIGHT_BROWN'
    | 'CHESTNUT'
    | 'BLOND'
    | 'ASH_BLONDE'
    | 'RED'
    | 'LIGHT_BLOND'
    | 'DARK_BLOND'
    | 'PINK'
    | 'BLUE'
    | 'GREEN'
    | 'VIOLET'
    | 'BRIGHT_RED'
    | 'SILVER'
    | 'RAINBOW'
    | 'OMBRE'
    | 'BALAYAGE'
    | 'LIGHT_TIPS'
    | 'GREY'
    | 'PARTIAL_GREY'
  breastSize?: number
  sexualOrientation?: 'BISEXUAL' | 'HETERO' | 'LESBIAN'
  bodyType?: 'SLIM' | 'AVERAGE' | 'BIG'
  hairLength?: 'SHORT' | 'MEDIUM' | 'LONG' | 'VERY_LONG'
  profilePhoto?: string
  photos?: string[]
  videos?: string[]
  audios?: string[]
}
export type ModelSecretDataRequestSaveDto = AbstractModelRequest & {
  birthDate?: string
  realName?: string
  telegram?: string
  viber?: string
  whatsApp?: string
  phone?: string
  documents?: string[]
}
export type ModelFilterDto = {
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  languages?: (
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  )[]
  services?: number[]
  name?: string
  isHaveRelationshipWithCurrentClient?: boolean
  relationshipStatuses?: ('FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED')[]
}
export type ComplaintDto = {
  id?: string
  accuserUserId?: string
  accusedUserId?: string
  createdAt?: string
  initiatedWithBlock?: boolean
  message?: string
}
export type PageComplaintDto = {
  content: ComplaintDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type ComplaintCreateDto = {
  accused: string
  message: string
  initiatedWithBlock: boolean
}
export type ClientDto = {
  id?: string
  identifier?: number
  photoId?: number
  name?: string
  ageRange?: 'EIGHTEEN__TWENTY_THREE' | 'TWENTY_FOUR__THIRTY_THREE' | 'THIRTY_FOUR__FORTY_FOUR' | 'FORTY_FIVE__MORE'
  updatedAt?: string
  updatedBy?: string
}
export type ClientViewDto = {
  user?: UserDto
  client?: ClientDto
  relationshipWithCurrentUser?: RelationshipDto
}
export type PageClientViewDto = {
  content: ClientViewDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type ClientCreateDto = {
  photoId: number
  user: UserCreateDto
  name: string
  ageRange: 'EIGHTEEN__TWENTY_THREE' | 'TWENTY_FOUR__THIRTY_THREE' | 'THIRTY_FOUR__FORTY_FOUR' | 'FORTY_FIVE__MORE'
}
export type ClientFilterDto = {
  isOnline?: boolean
  showDeleted?: boolean
  showDisabled?: boolean
  ids?: string[]
  isHaveRelationshipWithCurrentModel?: boolean
  relationshipStatuses?: ('FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED')[]
}
export type CaptchaDto = {
  captcha: string
}
export type AccountResponse = {
  userId: string
  coins: number
  messages: number
  createdAt: string
  updatedAt?: string
  isDeleted: boolean
  deletedAt?: string
  deletedBy?: string
}
export type AccountPartialUpdateRequest = {
  coinsDelta: number
  messagesDelta: number
  details?: string
}
export type ClientFullDto = {
  userData?: UserFullDto
  client?: ClientDto
}
export type ModelSecretDataDto = {
  updatedAt?: string
  birthDate?: string
  realName?: string
  telegram?: string
  viber?: string
  whatsApp?: string
  phone?: string
  documents?: ModelFileDto[]
}
export type ModelFullDto = {
  userData?: UserFullDto
  model?: ModelDto
  languages?: (
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  )[]
  services?: ModelServiceDto[]
  profilePhoto?: ModelFileDto
  photos?: ModelFileDto[]
  videos?: ModelFileDto[]
  audios?: ModelFileDto[]
  relationshipWithCurrentUser?: RelationshipDto
  secretData?: ModelSecretDataDto
}
export type UserOutlineDto = {
  userData?: UserFullDto
  clientIdentifier?: number
  modelIdentifier?: number
  name?: string
}
export type UserPartialUpdateDto = {
  email?: string
  language?:
    | 'ARABIC'
    | 'BULGARIAN'
    | 'CZECH'
    | 'DANISH'
    | 'GERMAN'
    | 'GREEK'
    | 'ENGLISH_BRITISH'
    | 'ENGLISH_AMERICAN'
    | 'SPANISH'
    | 'ESTONIAN'
    | 'FINNISH'
    | 'FRENCH'
    | 'HUNGARIAN'
    | 'INDONESIAN'
    | 'ITALIAN'
    | 'JAPANESE'
    | 'KOREAN'
    | 'LITHUANIAN'
    | 'LATVIAN'
    | 'NORWEGIAN_BOKMAL'
    | 'DUTCH'
    | 'POLISH'
    | 'PORTUGUESE_BRAZILIAN'
    | 'PORTUGUESE_PORTUGAL'
    | 'ROMANIAN'
    | 'RUSSIAN'
    | 'SLOVAK'
    | 'SLOVENIAN'
    | 'SWEDISH'
    | 'TURKISH'
    | 'UKRAINIAN'
    | 'CHINESE_SIMPLIFIED'
    | 'CHINESE_TRADITIONAL'
  permissions?: (
    | 'CLIENT_VIEW'
    | 'CLIENT_UPDATE'
    | 'CLIENT_UPDATE_NAME'
    | 'CLIENT_UPDATE_NAME_SELF'
    | 'MODEL_REQUEST_VIEW'
    | 'MODEL_REQUEST_CREATE'
    | 'MODEL_REQUEST_DELETE'
    | 'MODEL_REQUEST_APPROVE_MAIN_INFO'
    | 'MODEL_REQUEST_APPROVE_SECRET_DATA'
    | 'MODEL_VIEW'
    | 'MODEL_VIEW_SECRET_DATA'
    | 'MODEL_UPDATE_MINUTE_PRICE'
    | 'ROOM_VIEW'
    | 'ROOM_REGISTER_LIVE_TIME'
    | 'ROOM_JOIN'
    | 'ROOM_JOIN_2'
    | 'ROOM_CREATE'
    | 'SERVICE_VIEW_RECOMMENDED_PRICE'
    | 'SERVICE_CREATE'
    | 'SERVICE_UPDATE'
    | 'SERVICE_DELETE'
    | 'MODEL_SERVICE_SET'
    | 'FILE_CREATE_TEMP'
    | 'FILE_SET_OWNER_ID'
    | 'FILE_VIEW_TEMP'
    | 'FILE_VIEW_DELETED'
    | 'NOTIFICATION_VIEW'
    | 'NOTIFICATION_CREATE'
    | 'COMPLAINT_VIEW'
    | 'COMPLAINT_CREATE'
    | 'RELATIONSHIP_VIEW'
    | 'RELATIONSHIP_CREATE'
    | 'RELATIONSHIP_CREATE_SELF'
    | 'RELATIONSHIP_DELETE'
    | 'RELATIONSHIP_DELETE_SELF'
    | 'RELATIONSHIP_UPDATE'
    | 'RELATIONSHIP_UPDATE_SELF'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_SET'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_SET'
    | 'STAFF_VIEW'
    | 'STAFF_CREATE'
    | 'STAFF_UPDATE'
    | 'USER_VIEW'
    | 'USER_UPDATE'
    | 'USER_UPDATE_EMAIL'
    | 'USER_UPDATE_EMAIL_SELF'
    | 'USER_UPDATE_PASSWORD'
    | 'USER_UPDATE_PASSWORD_SELF'
    | 'USER_DELETE'
    | 'USER_DELETED_VIEW'
    | 'USER_MANAGE_DISABLE_STATE'
    | 'USER_DISABLED_VIEW'
    | 'USER_MANAGE_PERMISSION'
    | 'FIELD_FILE_OWNER_ID_VIEW'
    | 'FIELD_EMAIL_VIEW'
    | 'FIELD_AGE_RANGE_VIEW'
    | 'FIELD_PERMISSION_VIEW'
    | 'FIELD_ROOT_VIEW'
    | 'FIELD_AUDIT_VIEW'
    | 'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW'
    | 'WORD_BLACK_LIST_VIEW'
    | 'WORD_BLACK_LIST_CREATE'
    | 'WORD_BLACK_LIST_DELETE'
    | 'GLOBAL_CONFIG_VIEW'
    | 'GLOBAL_CONFIG_SET'
    | 'PERMISSION_VIEW'
    | 'PRIVATE_CHAT_VIEW'
    | 'PRIVATE_CHAT_DISABLE'
    | 'PRIVATE_CHAT_ASSIGN'
    | 'MESSAGE_CREATE'
    | 'MESSAGE_CREATE_SELF'
    | 'MESSAGE_UPDATE'
    | 'MESSAGE_UPDATE_SELF'
    | 'MESSAGE_VIEW'
    | 'MESSAGE_VIEW_DELETED'
    | 'MESSAGE_CLEAR'
    | 'MESSAGE_CLEAR_SELF'
    | 'REFERRAL_CREATE'
    | 'REFERRAL_UPDATE'
    | 'REFERRAL_DELETE'
    | 'REFERRAL_VIEW'
    | 'ACCOUNT_VIEW'
    | 'ACCOUNT_UPDATE'
    | 'TRANSACTION_VIEW'
    | 'MESSAGE_RULE_SET'
    | 'GIFT_SEND'
    | 'GIFT_RECORDS_VIEW'
  )[]
  password?: string
  confirmationCode?: string
}
export type StaffPartialUpdateDto = {
  permissions?: (
    | 'CLIENT_VIEW'
    | 'CLIENT_UPDATE'
    | 'CLIENT_UPDATE_NAME'
    | 'CLIENT_UPDATE_NAME_SELF'
    | 'MODEL_REQUEST_VIEW'
    | 'MODEL_REQUEST_CREATE'
    | 'MODEL_REQUEST_DELETE'
    | 'MODEL_REQUEST_APPROVE_MAIN_INFO'
    | 'MODEL_REQUEST_APPROVE_SECRET_DATA'
    | 'MODEL_VIEW'
    | 'MODEL_VIEW_SECRET_DATA'
    | 'MODEL_UPDATE_MINUTE_PRICE'
    | 'ROOM_VIEW'
    | 'ROOM_REGISTER_LIVE_TIME'
    | 'ROOM_JOIN'
    | 'ROOM_JOIN_2'
    | 'ROOM_CREATE'
    | 'SERVICE_VIEW_RECOMMENDED_PRICE'
    | 'SERVICE_CREATE'
    | 'SERVICE_UPDATE'
    | 'SERVICE_DELETE'
    | 'MODEL_SERVICE_SET'
    | 'FILE_CREATE_TEMP'
    | 'FILE_SET_OWNER_ID'
    | 'FILE_VIEW_TEMP'
    | 'FILE_VIEW_DELETED'
    | 'NOTIFICATION_VIEW'
    | 'NOTIFICATION_CREATE'
    | 'COMPLAINT_VIEW'
    | 'COMPLAINT_CREATE'
    | 'RELATIONSHIP_VIEW'
    | 'RELATIONSHIP_CREATE'
    | 'RELATIONSHIP_CREATE_SELF'
    | 'RELATIONSHIP_DELETE'
    | 'RELATIONSHIP_DELETE_SELF'
    | 'RELATIONSHIP_UPDATE'
    | 'RELATIONSHIP_UPDATE_SELF'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW'
    | 'RELATIONSHIP_IS_CLIENT_FAVORITE_SET'
    | 'RELATIONSHIP_IS_MODEL_FAVORITE_SET'
    | 'STAFF_VIEW'
    | 'STAFF_CREATE'
    | 'STAFF_UPDATE'
    | 'USER_VIEW'
    | 'USER_UPDATE'
    | 'USER_UPDATE_EMAIL'
    | 'USER_UPDATE_EMAIL_SELF'
    | 'USER_UPDATE_PASSWORD'
    | 'USER_UPDATE_PASSWORD_SELF'
    | 'USER_DELETE'
    | 'USER_DELETED_VIEW'
    | 'USER_MANAGE_DISABLE_STATE'
    | 'USER_DISABLED_VIEW'
    | 'USER_MANAGE_PERMISSION'
    | 'FIELD_FILE_OWNER_ID_VIEW'
    | 'FIELD_EMAIL_VIEW'
    | 'FIELD_AGE_RANGE_VIEW'
    | 'FIELD_PERMISSION_VIEW'
    | 'FIELD_ROOT_VIEW'
    | 'FIELD_AUDIT_VIEW'
    | 'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW'
    | 'WORD_BLACK_LIST_VIEW'
    | 'WORD_BLACK_LIST_CREATE'
    | 'WORD_BLACK_LIST_DELETE'
    | 'GLOBAL_CONFIG_VIEW'
    | 'GLOBAL_CONFIG_SET'
    | 'PERMISSION_VIEW'
    | 'PRIVATE_CHAT_VIEW'
    | 'PRIVATE_CHAT_DISABLE'
    | 'PRIVATE_CHAT_ASSIGN'
    | 'MESSAGE_CREATE'
    | 'MESSAGE_CREATE_SELF'
    | 'MESSAGE_UPDATE'
    | 'MESSAGE_UPDATE_SELF'
    | 'MESSAGE_VIEW'
    | 'MESSAGE_VIEW_DELETED'
    | 'MESSAGE_CLEAR'
    | 'MESSAGE_CLEAR_SELF'
    | 'REFERRAL_CREATE'
    | 'REFERRAL_UPDATE'
    | 'REFERRAL_DELETE'
    | 'REFERRAL_VIEW'
    | 'ACCOUNT_VIEW'
    | 'ACCOUNT_UPDATE'
    | 'TRANSACTION_VIEW'
    | 'MESSAGE_RULE_SET'
    | 'GIFT_SEND'
    | 'GIFT_RECORDS_VIEW'
  )[]
}
export type ServicePartialUpdateDto = {
  name?: {
    [key: string]: string
  }
  description?: {
    [key: string]: string
  }
  recommendedPrice?: number
  isPriceless?: boolean
  order?: number
}
export type RelationshipPartialUpdateDto = {
  type?: 'APPROVE_FRIEND_REQUEST' | 'BLOCK'
  isFavorite?: boolean
  initiatedBy?: 'CLIENT' | 'MODEL' | 'STAFF'
}
export type ModelPartialUpdateDto = {
  minutePrice?: number
}
export type ClientPartialUpdateDto = {
  name?: string
  ageRange?: 'EIGHTEEN__TWENTY_THREE' | 'TWENTY_FOUR__THIRTY_THREE' | 'THIRTY_FOUR__FORTY_FOUR' | 'FORTY_FIVE__MORE'
  photoId?: number
}
export type TransactionResponse = {
  id: string
  userId: string
  createdAt: string
  createdBy?: string
  coinsDelta: number
  type: 'ACCOUNT_REPLENISH_BY_USER' | 'ACCOUNT_UPDATE_BY_STAFF' | 'EMAIL_VERIFICATION_BONUS' | 'GIFT' | 'VIDEO_CALL'
  details?: string
}
export type PageTransactionResponse = {
  content: TransactionResponse[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type RoomVoteResponse = {
  roomId: string
  ownerUserId: string
  roomTimeStart: string
  roomTimeStop: string
  activeTime: number
  rating: number
  comment?: string
}
export type PageRoomVoteResponse = {
  content: RoomVoteResponse[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type WaitingRoomDto = {
  roomId?: string
  ownerUserId?: string
  ownerUserRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  isActive?: boolean
  type?: 'GENERAL_ROOM' | 'CALL_FROM_CLIENT' | 'CALL_FROM_MODEL' | 'CALL_FROM_STAFF'
  privateChatId?: string
  createdAt?: string
  participantId?: string
  participantRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  countWaitingRooms?: number
  relationship?: RelationshipDto
}
export type AverageRoomVoteResponse = {
  ownerUserId?: string
  totalActiveTime: number
  averageRating: number
  countVotes: number
}
export type PrivateChatModel = {
  id: string
  modelId?: string
  staffId?: string
  clientId?: string
  approvedByClient: boolean
  approvedByStaff: boolean
  approvedByModel: boolean
  createdAt: string
  updatedAt?: string
  clientAttractAttentionCount: number
  type: 'GENERAL' | 'SUPPORT'
  isDisabled: boolean
}
export type NotificationResponse = {
  id: number
  createdAt: string
  relatedUserId?: string
  isRead: boolean
  type:
    | 'USER_DISABLED'
    | 'SERVICE_ADDED'
    | 'SERVICE_UPDATED'
    | 'SERVICE_DELETED'
    | 'MODEL_FULLY_APPROVED'
    | 'PERMISSIONS_UPDATED'
    | 'FRIEND_REQUEST'
    | 'FRIEND_REQUEST_APPROVED'
    | 'MODEL_REQUEST_MAIN_INFO_APPROVED'
    | 'MODEL_REQUEST_SECRET_DATA_APPROVED'
    | 'NEW_MODEL_REQUEST_MAIN_INFO'
    | 'NEW_MODEL_REQUEST_SECRET_DATA'
    | 'MODEL_DELETED'
    | 'STAFF_DELETED'
    | 'MODEL_FULLY_APPROVED_AND_CREATED'
    | 'MODEL_REGISTERED'
    | 'COMPLAINT_CREATED'
    | 'ACCOUNT_COINS_BALANCE_CHANGED'
    | 'ACCOUNT_MESSAGES_BALANCE_CHANGED'
    | 'GIFT_RECEIVED'
}
export type CursorPageNotificationResponse = {
  content: NotificationResponse[]
  token?: string
  size: number
  pageSize: number
  isEnd: boolean
}
export type NotificationCountResponse = {
  unreadNotificationsCount: number
}
export type GiftResponse = {
  id?: number
  price?: number
}
export type UelResponse = {
  id: string
  privateChatId: string
  privateChat?: PrivateChatResponse
  createdAt: string
  type:
    | 'MESSAGE_CREATED'
    | 'MESSAGE_UPDATED'
    | 'MESSAGE_HISTORY_CLEARED'
    | 'MESSAGE_READ'
    | 'PRIVATE_CHAT_STAFF_ASSIGNED'
    | 'PRIVATE_CHAT_CLIENT_ASSIGNED'
    | 'PRIVATE_CHAT_MODEL_ASSIGNED'
    | 'PRIVATE_CHAT_APPROVED_BY_CLIENT'
    | 'PRIVATE_CHAT_APPROVED_BY_MODEL'
    | 'PRIVATE_CHAT_APPROVED_BY_STAFF'
    | 'PRIVATE_CHAT_DISAPPROVED_BY_CLIENT'
    | 'PRIVATE_CHAT_DISAPPROVED_BY_MODEL'
    | 'PRIVATE_CHAT_DISAPPROVED_BY_STAFF'
    | 'PRIVATE_CHAT_DISABLED'
  messageId?: string
  message?: MessageResponse
  initiatorUserId?: string
}
export type CursorPageUelResponse = {
  content: UelResponse[]
  token?: string
  size: number
  pageSize: number
  isEnd: boolean
}
export type PageUserDto = {
  content: UserDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type PermissionDto = {
  name?: string
  allowedFor?: ('CLIENT' | 'MODEL' | 'STAFF')[]
  defaultFor?: ('CLIENT' | 'MODEL' | 'STAFF')[]
}
export type RelationshipFullDto = {
  relationship?: RelationshipDto
  client?: ClientViewDto
  model?: ModelViewDto
}
export type PageRelationshipFullDto = {
  content: RelationshipFullDto[]
  totalElements: number
  pageSize: number
  totalPages: number
  currentPage: number
  contentSize: number
}
export type RelationshipCountDto = {
  amount?: number
}
export const {
  useMessageRulesGetQuery,
  useMessageRulesSetMutation,
  useMessageUpdateByIdMutation,
  useReferralUpdateByIdMutation,
  useReferralDeleteByIdMutation,
  useModelServiceGetAllQuery,
  useModelServiceSetMutation,
  useGlobalConfigGetQuery,
  useGlobalConfigSetMutation,
  useTokenGetMutation,
  useTokenRevokeMutation,
  useWordBlackListGetAllQuery,
  useWordBlackListCreateMutation,
  useTranslateMutation,
  useTicketCreateMutation,
  useSearchQuery,
  useCreateRoomMutation,
  useCreateVoteRoomMutation,
  useRegisterLiveTimeMutation,
  useCreateRoom1Mutation,
  useReceiveWebhookMutation,
  usePrivateChatGetAllQuery,
  usePrivateChatCreateMutation,
  useMessageWithVideoCreateMutation,
  useMessageGetAllQuery,
  useMessageCreateMutation,
  useMessageReadByIdsMutation,
  useMessageReadAllMutation,
  useMessageClearHistoryMutation,
  usePrivateChatDisableByIdMutation,
  usePrivateChatAssignByIdMutation,
  useNotificationReadMutation,
  useGiftRecordsGetAllQuery,
  useGiftSendMutation,
  useUserEnableByIdMutation,
  useUserDisableByIdMutation,
  useUserVerifyCurrentEmailMutation,
  useUserSendCurrentEmailConfirmationCodeMutation,
  useUserResetPasswordMutation,
  useUserSendForgotPasswordCodeMutation,
  useStaffGetAllQuery,
  useStaffCreateMutation,
  useServiceGetAllQuery,
  useServiceCreateMutation,
  useRelationshipCreateMutation,
  useReferralGetAllQuery,
  useReferralCreateMutation,
  useModelGetAllQuery,
  useModelCreateMutation,
  useModelRequestGetAllQuery,
  useModelRequestCreateMutation,
  useModelRequestApproveByIdMutation,
  useModelGetIdsMutation,
  useFileCreateMutation,
  useComplaintGetAllQuery,
  useComplaintCreateMutation,
  useClientGetAllQuery,
  useClientCreateMutation,
  useClientGetIdsMutation,
  useVerifyCaptchaMutation,
  useAccountGetByIdQuery,
  useAccountPartialUpdateByIdMutation,
  useUserGetFullByIdQuery,
  useUserDeleteByIdMutation,
  useUserPartialUpdateByIdMutation,
  useStaffGetFullByIdQuery,
  useStaffPartialUpdateByIdMutation,
  useServiceGetByIdQuery,
  useServiceDeleteByIdMutation,
  useServicePartialUpdateByIdMutation,
  useRelationshipDeleteByIdMutation,
  useRelationshipPartialUpdateByIdMutation,
  useModelGetFullByIdQuery,
  useModelPartialUpdateByIdMutation,
  useClientGetFullByIdQuery,
  useClientPartialUpdateByIdMutation,
  useTransactionGetAllQuery,
  useGetRoomQuery,
  useSearchVoteRoomsQuery,
  useRoomGetRandomWaitingQuery,
  useGetAverageVoteRoomsQuery,
  useRecipientMessageGetByPrivateChatIdQuery,
  useGetCurrentPrivateChatToModelOrUserQuery,
  useGetAnyPrivateChatToStaffQuery,
  useNotificationGetAllQuery,
  useNotificationGetUnreadCountQuery,
  useGiftGetAllQuery,
  useUserEventLogGetQuery,
  useTransactionGetAllByUserIdQuery,
  useUserGetAllQuery,
  usePermissionGetAllQuery,
  useUserGetFullMeQuery,
  useRelationshipGetFullAllQuery,
  useRelationshipGetCountQuery,
  useTransactionGetByAccountReplenishQuery,
  useModelRequestGetByIdQuery,
  useModelRequestDeleteByIdMutation,
  useModelRequestGetMeQuery,
  useFileGetDataByIdQuery,
  useGenerateCaptchaQuery,
  useWordBlackListDeleteMutation,
} = injectedRtkApi
