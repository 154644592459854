import { FC } from 'react'
import { Popup } from 'reactjs-popup'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { SvgClosePopUp, SvgCoinVh, SvgPrice1, SvgPrice2, SvgPrice3, SvgPrice4 } from '../../../../images/svg'
import { useTranslation } from 'react-i18next'

const TopUpBalance: FC<IPopUp> = ({ open, closeModal }) => {
  const balanceOptions = [
    { id: 1, coins: 50, bonus: 0, price: 5.44, icon: <SvgPrice1 /> },
    { id: 2, coins: 100, bonus: 10, price: 10.99, icon: <SvgPrice2 /> },
    { id: 3, coins: 200, bonus: 30, price: 19.99, icon: <SvgPrice3 /> },
    { id: 4, coins: 500, bonus: 100, price: 49.99, icon: <SvgPrice4 /> },
  ]
  const { t } = useTranslation()

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='top-up'>
        <h1>{t('ui.popUp.videoPopUp.topUpBalance.balance_topup')}</h1>
        <div className='top-up__cards'>
          {balanceOptions.map(option => (
            <div className='top-up__card' key={option.id}>
              <div className='top-up__card-image'>{option.icon}</div>
              <div className='top-up__card-content'>
                <p className='top-up__coins'>
                  <span>{option.coins}</span> <SvgCoinVh />
                </p>
                <p className='top-up__bonus'>
                  +{option.bonus} {t('ui.popUp.videoPopUp.topUpBalance.free_label')}
                </p>
                <p className='top-up__price'>${option.price}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          onClick={() => {
            closeModal()
          }}
          className='top-up-close'
        >
          <SvgClosePopUp />
        </div>
      </div>
    </Popup>
  )
}

export default TopUpBalance
