import React from 'react'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'
const Legal = () => {
  const { t } = useTranslation()
  return (
    <div className='legal'>
      <h2>{t('components.model.profile.legal.legal_info_title')}</h2>
      <p>{t('components.model.profile.legal.legal_info_description')} </p>
      <div className='legal-block'>
        <div className='legal-block__item'>
          <p>{t('components.model.profile.legal.terms_description')}</p>
          <a href='/info/model/terms-and-conditions' target='_blank' rel='noopener noreferrer' className='btn'>
            {t('components.model.profile.legal.terms_title')}
          </a>
        </div>
        <div className='legal-block__item'>
          <p>{t('components.model.profile.legal.privacy_description')}</p>
          <a href='/info/model/privacy-policy' target='_blank' rel='noopener noreferrer' className='btn'>
            {t('components.model.profile.legal.privacy_title')}
          </a>
        </div>
        <div className='legal-block__item'>
          <p>{t('components.model.profile.legal.statement_2257_description')}</p>
          <a
            href='/info/model/privacy-policy' // Обновите эту ссылку, если это необходимо для другого документа
            target='_blank'
            rel='noopener noreferrer'
            className='btn'
          >
            {t('components.model.profile.legal.statement_2257_title')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Legal
