import React from 'react'
import './scss/index.scss'
import { FormikHandlers, FormikValues } from 'formik'
import { SvgEyeClose, SvgEyeOpen } from 'images/svg'

interface FormControlProps {
  name: string
  type: string
  autoComplete?: string
  disabled?: boolean
  placeholder?: string
  formik: {
    values: FormikValues
    handleChange: FormikHandlers['handleChange']
    handleBlur: FormikHandlers['handleBlur']
    touched: FormikValues
    errors: FormikValues
  }
}

export const FormControl = ({ name, type, formik, autoComplete, placeholder, disabled }: FormControlProps) => {
  const isError = formik.touched[name] && formik.errors[name]
  const [isOpen, setOpen] = React.useState(false)
  // Render different elements based on the type
  let element
  switch (type) {
    case 'textarea':
      element = (
        <textarea
          id={name}
          name={name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          className={isError ? 'input-error' : ''}
          placeholder={placeholder}
        />
      )
      break

    case 'checkbox':
      element = (
        <>
          <input
            id={name}
            name={name}
            type={type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values[name]}
            className={isError ? 'input-error' : ''}
          />
        </>
      )
      break

    default:
      element = (
        <input
          id={name}
          name={name}
          type={isOpen ? 'text' : type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
        />
      )
      break
  }

  return (
    <div className='custom-input'>
      <div className={`wrapper-input ${isError && 'input-error'}`}>
        {element}
        {type === 'password' && (
          <div className='eye' onClick={() => setOpen(prev => !prev)}>
            {isOpen ? <SvgEyeOpen /> : <SvgEyeClose />}
          </div>
        )}
      </div>

      <div className={`error-input${isError ? ' visible' : ''}`}>
        <p className='error-message'>{isError ? formik.errors[name] : ''}</p>
      </div>
    </div>
  )
}
