import React from 'react'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'

const Withdrawal = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className='withdrawal'>
        <h2>{t('components.model.withdrawal.withdrawal')}</h2>
      </div>
    </>
  )
}

export default Withdrawal
