import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Photo from 'UI/Photo/Photo'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import {
  useModelRequestCreateMutation,
  useFileCreateMutation,
  useUserGetFullMeQuery,
  useModelRequestGetMeQuery,
  useModelGetFullByIdQuery,
  ModelRequestCreateApiArg,
  useClientGetFullByIdQuery,
} from 'store/apiMain/mainApi'
import Audio from 'UI/Audio/Audio'
import {
  optionsBirthYear,
  optionsWeight,
  optionsHeight,
  optionsChest,
  optionsWaist,
  optionsBreastSize,
  optionsHairColor,
  optionsSexualOrientation,
  optionsBodyType,
  optionsHairLength,
  optionsHips,
  optionsLanguage,
} from 'UI/SelectCustom/constValue'
import { toastSuccess } from 'elements/Notification/Notification'
import SelectedCustom from 'UI/SelectCustom/SelectCustom'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { useNavigate, useParams } from 'react-router-dom'
import Video from 'UI/Video/Video'
import { useDispatch, useSelector } from 'react-redux'
import { selectSteps1 } from 'store/reducer/steps/selector'
import { setSteps1 } from 'store/reducer/steps/reducer'
import SelectedLanguage from 'UI/SelectedLanguage/SelectedLanguage'
import Delete from '../../Global/Delete/Delete'
import PriceModel from './PriceModel/PriceModel'
import Documents from '../ModelRequest/Documents/Documents'
import CropeMain from '../../Global/Crope/CropeMain'
import { useTranslation } from 'react-i18next'
import AccountController from '../Client/AccountController/AccountController'
import TransactionalController from '../Client/TransactionalController/TransactionalController'
import { countChars, RawContent } from '../../Model/Steps/Step1/Step1'
import CustomTextArea from '../../../UI/CustomTextArea/CustomTextArea'
import ModelVote from '../ModelVote/ModelVote'

const Model: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { modelId } = useParams()
  const queryArg = {
    modelId: String(modelId),
    idType: 'ID',
  }
  // @ts-ignore
  // const {data} = useModelGetFullByIdQuery({...queryArg})
  const {
    data: modelData,
    isLoading,
    isError,
    refetch: refetchModel,
    // @ts-ignore
  } = useModelGetFullByIdQuery({ ...queryArg })
  const { modelRequestId } = useParams()
  const { data: modelRequest, isLoading: byIdLoading, isError: byIdError, refetch } = useModelRequestGetMeQuery(undefined, { skip: !modelRequestId })
  const [update, setUpdate] = useState(false)
  useEffect(() => {
    if (modelRequest !== undefined) {
      navigate('/model/profile/main-info/edit/request')
    }
  }, [modelRequest])
  const [checkedProverka, setCheckedProverka] = useState(false)
  const steps1 = useSelector(selectSteps1)

  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()
  const params = {
    modelUserId: modelId,
  }
  // @ts-ignore
  const [addRequest, { isSuccess }] = useModelRequestCreateMutation()

  const [profilePhoto, setProfilePhoto] = useState<string | null>(null)
  const [profilePhotoIds, setProfilePhotoIds] = useState<string | null>(null)

  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])

  const [videos, setVideos] = useState<any[]>([])
  const [videosIds, setVideosIds] = useState<string[]>([])

  const [audios, setAudios] = useState<any[]>([])
  const [audiosIds, setAudiosIds] = useState<string[]>([])
  useEffect(() => {
    if (modelData !== null && modelData !== undefined) {
      setCheckedProverka(true)

      const profilePhoto = modelData?.profilePhoto
      const profilePhotoID = profilePhoto?.file?.id
      // @ts-ignore
      setProfilePhotoIds(profilePhotoID)

      const arrayPhoto = modelData?.photos
      // @ts-ignore
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      //

      const arrayVideos = modelData?.videos
      // @ts-ignore
      const idVideo = arrayVideos?.map(item => item?.file?.id)
      // @ts-ignore
      setVideosIds(idVideo)
      //
      const arrayAudios = modelData?.audios
      // @ts-ignore
      const idAudio = arrayAudios?.map(item => item?.file?.id)
      // @ts-ignore
      setAudiosIds(idAudio)

      formik.resetForm({
        values: {
          // @ts-ignore
          languages: modelData?.languages || [],
          dataType: 'MAIN_INFO',
          // @ts-ignore
          name: modelData?.model?.name,
          // @ts-ignore
          selfInformation: JSON.parse(modelData?.model?.selfInformation) || '',
          // @ts-ignore
          birthYear: modelData?.model?.birthYear,
          // @ts-ignore
          weight: modelData?.model?.weight,
          // @ts-ignore
          height: modelData?.model?.height,
          // @ts-ignore
          chest: modelData?.model?.chest,
          // @ts-ignore
          waist: modelData?.model?.waist,
          // @ts-ignore
          hips: modelData?.model?.hips,
          // @ts-ignore
          hairColor: modelData?.model?.hairColor,
          // @ts-ignore
          breastSize: modelData?.model?.breastSize,
          // @ts-ignore
          sexualOrientation: modelData?.model?.sexualOrientation,
          // @ts-ignore
          bodyType: modelData?.model?.bodyType,
          // @ts-ignore
          hairLength: modelData?.model?.hairLength,
          // @ts-ignore
          profilePhoto: modelData?.profilePhoto,
        },
      })
    }
  }, [modelData])

  const initialValues = {
    languages: [],
    dataType: 'MAIN_INFO',
    name: null,
    selfInformation: null,
    birthYear: null,
    weight: null,
    height: null,
    chest: null,
    waist: null,
    hips: null,
    hairColor: null,
    breastSize: null,
    sexualOrientation: null,
    bodyType: null,
    hairLength: null,

    profilePhoto: null,
  }

  const validationSchema = yup.object().shape({
    dataType: yup.string().required('Data type is required'),
    name: yup
      .string()
      .required(t('stepOne.nameRequired'))
      .min(2, t('stepOne.nameMin'))
      .max(50, t('stepOne.nameMax'))
      .matches(/^[\p{L}-]+$/u, t('stepOne.nameInvalid')),
    selfInformation: yup
      .mixed<RawContent>()
      .test(
        'selfInformationRequired',
        t('stepOne.selfInformationRequired'),
        value => !!value && countChars(value as RawContent) > 0, // Проверка на наличие текста
      )
      .test(
        'selfInformationMin',
        t('stepOne.selfInformationMin'),
        value => !!value && countChars(value as RawContent) >= 10, // Минимум 10 символов
      )
      .test(
        'selfInformationMax',
        t('stepOne.selfInformationMax'),
        value => !!value && countChars(value as RawContent) <= 10000, // Максимум 10000 символов
      ),
    birthYear: yup.number().required('Birth year is required').min(1900).max(new Date().getFullYear()),
    weight: yup.number().required('Weight is required'),
    height: yup.number().required('Height is required'),
    chest: yup.number().required('Chest is required'),
    waist: yup.number().required('Waist is required'),
    hips: yup.number().required('Hips is required'),
    hairColor: yup.string().required('Hair color is required'),
    breastSize: yup.number().required('Breast size is required'),
    sexualOrientation: yup.string().required('Sexual orientation is required'),
    bodyType: yup.string().required('Body type is required'),
    hairLength: yup.string().required('Hair length is required'),
    profilePhoto: yup.mixed().required(t('stepOne.mainPhotoRequired')).notOneOf([null], t('stepOne.mainPhotoRequired')),
  })

  const onSubmit = (values: any) => {
    const bodyParams = {
      modelUserId: modelId,
      body: {
        ...values,
        profilePhoto: profilePhotoIds,
        selfInformation: JSON.stringify(values.selfInformation),
        photos: [...photoIds],
        videos: [...videosIds],
        audios: [...audiosIds],
      },
    }
    addRequest({ ...bodyParams })
  }
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Данные отправлены')
      refetchModel()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  const [clickSubmit, setClickSubmit] = useState(false)

  return (
    <div className='editProfileModel'>
      <div className='step1-information'>
        EMAIL:{modelData?.userData?.user?.email}
        <form onSubmit={formik.handleSubmit}>
          <div className='step1-information-block'>
            <div className='step1-information-block_box custom-selected name'>
              <FormControl name='name' type='text' placeholder='Enter Name' formik={formik} />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='birthYear' options={optionsBirthYear} formik={formik} placeholder='Выберите год рождения' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='weight' options={optionsWeight} formik={formik} placeholder='Выберите приблизительный вес' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='height' options={optionsHeight} formik={formik} placeholder='Выберите приблизительный рост' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='chest' options={optionsChest} formik={formik} placeholder='Выберите приблизительный грудь' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='waist' options={optionsWaist} formik={formik} placeholder='Выберите приблизительный талия' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='hips' options={optionsHips} formik={formik} placeholder='Выберите приблизительный Бедра' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='breastSize' options={optionsBreastSize} formik={formik} placeholder='Выберите приблизительный грудь' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='hairColor' options={optionsHairColor} formik={formik} placeholder='Выберите приблизительный цвет волос' />
            </div>

            <div className='step1-information-block_box'>
              <SelectedCustom name='sexualOrientation' options={optionsSexualOrientation} formik={formik} placeholder='Выберите орентацию' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='bodyType' options={optionsBodyType} formik={formik} placeholder='Выберите телосложение' />
            </div>
            <div className='step1-information-block_box'>
              <SelectedCustom name='hairLength' options={optionsHairLength} formik={formik} placeholder='Выберите длину волос' />
            </div>
            <div className='step1-information-block_box language'>
              <SelectedLanguage name='languages' formik={formik} placeholder='Разговорный язык' />
            </div>
            <div className='step1-information-block_box Photo'>
              <CropeMain
                ownerId={modelData?.model?.id}
                formik={formik}
                name='profilePhoto'
                nameText={formik.values.name}
                profilePhoto={profilePhoto}
                setProfilePhoto={setProfilePhoto}
                addFile={addFile}
                getFile={getFile}
                setProfilePhotoIds={setProfilePhotoIds}
                profilePhotoIds={profilePhotoIds}
              />
            </div>
            <div className='step1-information-block_box Photo'>
              <Photo
                ownerId={modelData?.model?.id}
                photoIds={photoIds}
                photos={photos}
                setPhotos={setPhotos}
                addFile={addFile}
                getFile={getFile}
                setPhotoIds={setPhotoIds}
              />
            </div>
            <div className='errorPhoto'>
              <div className='errorPhoto-block'>{clickSubmit && photos.length <= 1 ? <p>Must have at least two photographs.</p> : null}</div>
            </div>
            <div className='step1-information-block_box Video'>
              <Video videoIds={videosIds} videos={videos} setVideos={setVideos} addFile={addFile} getFile={getFile} setVideoIds={setVideosIds} />
            </div>
            <div className='step1-information-block_box Audio'>
              <Audio audioIds={audiosIds} audios={audios} setAudios={setAudios} addFile={addFile} getFile={getFile} setAudioIds={setAudiosIds} />
            </div>
            <div className='step1-information-block_box selfInformation'>
              <CustomTextArea
                formik={formik}
                initialRawContent={formik.values.selfInformation}
                onChangeRaw={
                  rawContent => formik.setFieldValue('selfInformation', rawContent) // Установка значения в formik
                }
              />
            </div>
            <div className='step1-information-block__button'>
              <button
                onClick={() => {
                  setClickSubmit(true)
                }}
                type='submit'
              >
                Edit
              </button>
            </div>
          </div>
        </form>
        <Documents />
        <PriceModel refetch={refetchModel} modelId={modelId} minutePriceProps={modelData?.model?.minutePrice} />
        {modelData?.model?.id !== undefined ? <AccountController userId={modelData.model.id} update={update} setUpdate={setUpdate} /> : null}
        {modelData?.model?.id !== undefined ? <TransactionalController userId={modelData.model.id} update={update} /> : null}
        {modelData?.model?.id !== undefined ? <ModelVote userId={modelData.model.id} update={update} /> : null}
        <Delete idDelete={String(modelId)} refetch={refetchModel} role='admin' />
      </div>
    </div>
  )
}

export default Model
