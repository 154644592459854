// matchingContentUtils.ts
import { PrivateChatViewResponse } from 'store/apiMain/mainApi'
import { IChatFilter } from 'store/reducer/messages/types'

export const findMatchingContent = (
  e: PrivateChatViewResponse,
  dataClient: any,
  dataModel: any,
  dataStaff: any,
  selectedChatType: IChatFilter,
  role: any,
) => {
  let matchingClientContent
  let matchingModelContent
  let matchingStaffContent
  if (selectedChatType?.active === 'view_client') {
    matchingStaffContent = dataStaff?.content?.find(staff => e?.privateChat?.staffId === staff?.staff?.id)
    matchingModelContent = dataModel?.find(model => e.privateChat.modelId === model?.model?.id)
  } else if (selectedChatType?.active === 'view_model') {
    matchingClientContent = dataClient?.find(client => e.privateChat.clientId === client?.client?.id)
    matchingStaffContent = dataStaff?.content?.find(staff => e?.privateChat?.staffId === staff?.staff?.id)
  } else {
    matchingClientContent = dataClient?.find(client => e.privateChat.clientId === client?.client?.id)
    matchingModelContent = dataModel?.find(model => e.privateChat.modelId === model?.model?.id)
  }

  const foundModel = Array.isArray(dataModel) ? dataModel.find(model => model.user.id === e?.privateChat?.modelId) : null
  if (foundModel === undefined) {
    if (
      e?.privateChat?.staffId &&
      e?.privateChat?.modelId &&
      role === 'STAFF' &&
      ((foundModel && foundModel.languages === undefined) || selectedChatType?.byRole === 'STAFF' || selectedChatType?.byRole === 'MODEL')
    ) {
      if (selectedChatType?.active === 'view_model') {
        matchingClientContent = dataModel?.find(client => e.privateChat.modelId === client?.model?.id)
        matchingStaffContent = dataStaff?.content?.find(staff => e?.privateChat?.staffId === staff?.staff?.id)
      } else {
        const newModel = { id: e.privateChat.modelId, name: 'Model' }
        matchingModelContent = {
          user: {
            id: e.privateChat.modelId,
            role: 'MODEL',
            isDeleted: false,
            language: undefined,
            isDisabled: false,
          },
          model: newModel,
        }
      }
    }
  } else {
    if (
      e?.privateChat?.staffId &&
      e?.privateChat?.modelId &&
      role === 'STAFF' &&
      foundModel &&
      !Array.isArray(foundModel.languages) && // Условие: languages не является массивом
      (selectedChatType?.byRole === 'STAFF' || // или byRole равно 'STAFF'
        selectedChatType?.byRole === 'MODEL') // или byRole равно 'MODEL'
    ) {
      if (selectedChatType?.active === 'view_model') {
        // Логика для просмотра модели
        matchingClientContent = dataModel?.find(client => e.privateChat.modelId === client?.model?.id)
        matchingStaffContent = dataStaff?.content?.find(staff => e?.privateChat?.staffId === staff?.staff?.id)
      } else {
        // Логика для создания нового объекта модели
        const newModel = { id: e.privateChat.modelId, name: 'Model' }
        matchingModelContent = {
          user: {
            id: e.privateChat.modelId,
            role: 'MODEL',
            isDeleted: false,
            language: undefined,
            isDisabled: false,
          },
          model: newModel,
        }
      }
    }
  }

  matchingStaffContent = dataStaff?.content?.find(staff => e?.privateChat?.staffId === staff?.staff?.id)
  if (matchingStaffContent) {
    const newStaff = { ...matchingStaffContent.staff, name: 'Admin' }
    matchingStaffContent = { ...matchingStaffContent, staff: newStaff }
  }
  return { matchingClientContent, matchingModelContent, matchingStaffContent }
}
