import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import './scss/index.scss'
import Photo from 'UI/Photo/Photo'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { useModelRequestCreateMutation, useFileCreateMutation, useUserGetFullMeQuery, useModelRequestGetMeQuery } from 'store/apiMain/mainApi'
import Audio from 'UI/Audio/Audio'
import {
  optionsBirthYear,
  optionsWeight,
  optionsHeight,
  optionsChest,
  optionsWaist,
  optionsBreastSize,
  optionsHairColor,
  optionsSexualOrientation,
  optionsBodyType,
  optionsHairLength,
  optionsHips,
  optionsLanguage,
} from 'UI/SelectCustom/constValue'
import { toastSuccess } from 'elements/Notification/Notification'
import SelectedCustom from 'UI/SelectCustom/SelectCustom'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { useNavigate } from 'react-router-dom'
import Video from 'UI/Video/Video'
import { useDispatch, useSelector } from 'react-redux'
import { selectSteps1 } from 'store/reducer/steps/selector'
import { setSteps1 } from 'store/reducer/steps/reducer'
import SelectedLanguage from '../../../../../UI/SelectedLanguage/SelectedLanguage'
import { getRequestModelErrorSelector } from 'store/reducer/global/selectors'
import { setRequestModelError } from '../../../../../store/reducer/global/reducer'
import { useTranslation } from 'react-i18next'
import CropeMain from '../../../../Global/Crope/CropeMain'
import { countChars, RawContent } from '../../../Steps/Step1/Step1'
import CustomTextArea from '../../../../../UI/CustomTextArea/CustomTextArea'
import SelectItem from 'Components/Model/Steps/Step1/SelectItem'
import LanguagesSelect from 'Components/Model/Steps/LanguagesSelect'

const Edit: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { data: modelData, isLoading, isError, refetch: modelDataRefetch } = useUserGetFullMeQuery()

  const { data: modelRequest, isLoading: byIdLoading, isError: byIdError, refetch } = useModelRequestGetMeQuery()
  const modelError = useSelector(getRequestModelErrorSelector)
  const { t } = useTranslation()
  useEffect(() => {
    if (modelRequest !== undefined && !modelError) {
      if (modelRequest?.request === undefined) {
        dispatch(setRequestModelError(false))
        navigate('/model/profile/main-info/edit')
      }
      if (modelRequest?.request?.state === 'UPDATE_EXISTED') {
        dispatch(setRequestModelError(false))
        navigate('/model/profile/main-info/edit/request')
      }
    }
    if (modelError) {
      modelDataRefetch()
      dispatch(setRequestModelError(false))
      navigate('/model/profile/main-info/edit')
    }
  }, [modelRequest])
  const [checkedProverka, setCheckedProverka] = useState(false)
  const steps1 = useSelector(selectSteps1)

  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()
  const [addRequest, { isSuccess }] = useModelRequestCreateMutation()

  const [profilePhoto, setProfilePhoto] = useState<string | null>(null)
  const [profilePhotoIds, setProfilePhotoIds] = useState<string | null>(null)

  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])

  const [videos, setVideos] = useState<any[]>([])
  const [videosIds, setVideosIds] = useState<string[]>([])

  const [audios, setAudios] = useState<any[]>([])
  const [audiosIds, setAudiosIds] = useState<string[]>([])
  useEffect(() => {
    if (modelData !== null && modelData !== undefined) {
      const profilePhoto = modelData?.profilePhoto
      const profilePhotoID = profilePhoto?.file?.id
      // @ts-ignore
      setProfilePhotoIds(profilePhotoID)
      setCheckedProverka(true)
      const arrayPhoto = modelData?.photos
      // @ts-ignore
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      //

      const arrayVideos = modelData?.videos
      // @ts-ignore
      const idVideo = arrayVideos?.map(item => item?.file?.id)
      // @ts-ignore
      setVideosIds(idVideo)
      //
      const arrayAudios = modelData?.audios
      // @ts-ignore
      const idAudio = arrayAudios?.map(item => item?.file?.id)
      // @ts-ignore
      setAudiosIds(idAudio)
      formik.resetForm({
        values: {
          languages: modelData?.languages || [],
          dataType: 'MAIN_INFO',
          // @ts-ignore
          name: modelData?.model?.name,
          // @ts-ignore
          selfInformation: JSON.parse(modelData?.model?.selfInformation) || '',
          // @ts-ignore
          birthYear: modelData?.model?.birthYear,
          // @ts-ignore
          weight: modelData?.model?.weight,
          // @ts-ignore
          height: modelData?.model?.height,
          // @ts-ignore
          chest: modelData?.model?.chest,
          // @ts-ignore
          waist: modelData?.model?.waist,
          // @ts-ignore
          hips: modelData?.model?.hips,
          // @ts-ignore
          hairColor: modelData?.model?.hairColor,
          // @ts-ignore
          breastSize: modelData?.model?.breastSize,
          // @ts-ignore
          sexualOrientation: modelData?.model?.sexualOrientation,
          // @ts-ignore
          bodyType: modelData?.model?.bodyType,
          // @ts-ignore
          hairLength: modelData?.model?.hairLength,
          // @ts-ignore
          profilePhoto: modelData?.profilePhoto,
        },
      })
    }
  }, [modelData])

  const initialValues = {
    languages: [],
    dataType: 'MAIN_INFO',
    name: null,
    selfInformation: null,
    birthYear: null,
    weight: null,
    height: null,
    chest: null,
    waist: null,
    hips: null,
    hairColor: null,
    breastSize: null,
    sexualOrientation: null,
    bodyType: null,
    hairLength: null,
    profilePhoto: null,
  }

  const validationSchema = yup.object().shape({
    dataType: yup.string().required(t('components.model.profile.mainInfo.DataType')),
    name: yup
      .string()
      .required(t('components.model.profile.mainInfo.nameRequired'))
      .min(2, t('components.model.profile.mainInfo.nameMinLength'))
      .max(50, t('components.model.profile.mainInfo.nameMaxLength'))
      // @ts-ignore
      .matches(/^[\p{L}-]+$/u, t('components.model.profile.mainInfo.nameInvalid')),
    selfInformation: yup
      .mixed<RawContent>()
      .test(
        'selfInformationRequired',
        t('components.model.profile.mainInfo.selfInformationRequired'),
        value => !!value && countChars(value as RawContent) > 0, // Проверка на наличие текста
      )
      .test(
        'selfInformationMin',
        t('components.model.profile.mainInfo.selfInformationMin'),
        value => !!value && countChars(value as RawContent) >= 10, // Минимум 10 символов
      )
      .test(
        'selfInformationMax',
        t('components.model.profile.mainInfo.selfInformationMax'),
        value => !!value && countChars(value as RawContent) <= 10000, // Максимум 10000 символов
      ),
    birthYear: yup.number().required(t('components.model.profile.mainInfo.birth_year_required')).min(1900).max(new Date().getFullYear()),

    weight: yup.number().required(t('components.model.profile.mainInfo.weight_required')),

    height: yup.number().required(t('components.model.profile.mainInfo.height_required')),

    chest: yup.number().required(t('components.model.profile.mainInfo.chest_required')),

    waist: yup.number().required(t('components.model.profile.mainInfo.waist_required')),

    hips: yup.number().required(t('components.model.profile.mainInfo.hips_required')),

    hairColor: yup.string().required(t('components.model.profile.mainInfo.hair_color_required')),

    breastSize: yup.number().required(t('components.model.profile.mainInfo.breast_size_required')),

    sexualOrientation: yup.string().required(t('components.model.profile.mainInfo.sexual_orientation_required')),

    bodyType: yup.string().required(t('components.model.profile.mainInfo.body_type_required')),

    hairLength: yup.string().required(t('components.model.profile.mainInfo.hair_length_required')),
    profilePhoto: yup
      .mixed()
      .required(t('components.model.profile.mainInfo.mainPhotoRequired'))
      .notOneOf([null], t('components.model.profile.mainInfo.mainPhotoRequired')),
  })

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      selfInformation: JSON.stringify(values.selfInformation),
      profilePhoto: profilePhotoIds,
      photos: [...photoIds],
      videos: [...videosIds],
      audios: [...audiosIds],
    }
    addRequest({ body })
  }
  useEffect(() => {
    if (isSuccess) {
      toastSuccess(t('components.model.profile.mainInfo.data_sent_success'))
      refetch()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  const [clickSubmit, setClickSubmit] = useState(false)

  return (
    <div className='editProfileModel'>
      <div className='step1-information edit'>
        <div className='edit__title'>
          <h2>{t('components.model.profile.mainInfo.editData')}</h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <label className='name'>
            <span>{t('components.model.profile.mainInfo.Name')}</span>
            <FormControl name='name' type='text' formik={formik} placeholder='' />
          </label>
          <div className='step1-information-block'>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.birth_year')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='birthYear'
                  options={optionsBirthYear}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.weight_label')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='weight'
                  options={optionsWeight}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.height_label')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='height'
                  options={optionsHeight}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.chest_label')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem name='chest' options={optionsChest} formik={formik} placeholder={t('components.model.profile.mainInfo.select_option')} />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.waist_label')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem name='waist' options={optionsWaist} formik={formik} placeholder={t('components.model.profile.mainInfo.select_option')} />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.hips_label')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem name='hips' options={optionsHips} formik={formik} placeholder={t('components.model.profile.mainInfo.select_option')} />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.breast_size')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='breastSize'
                  options={optionsBreastSize}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.hair_color')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='hairColor'
                  options={optionsHairColor}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.sexual_orientation')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='sexualOrientation'
                  options={optionsSexualOrientation}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.body_type')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='bodyType'
                  options={optionsBodyType}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.hair_length')}</label>
              <div className='step1-information-block__item_box'>
                <SelectItem
                  name='hairLength'
                  options={optionsHairLength}
                  formik={formik}
                  placeholder={t('components.model.profile.mainInfo.select_option')}
                />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.spoken_languages')}</label>
              <div className='step1-information-block__item_box'>
                <LanguagesSelect name='languages' formik={formik} placeholder={t('components.model.profile.mainInfo.select_option')} />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.main_photo')}</label>
              <div className='step1-photo profile-photo'>
                <CropeMain
                  formik={formik}
                  name='profilePhoto'
                  nameText={formik.values.name}
                  profilePhoto={profilePhoto}
                  setProfilePhoto={setProfilePhoto}
                  addFile={addFile}
                  getFile={getFile}
                  setProfilePhotoIds={setProfilePhotoIds}
                  profilePhotoIds={profilePhotoIds}
                />
              </div>
            </div>
            <div className='step1-information-block__item photos'>
              <label>{t('components.model.profile.mainInfo.additional_photos')}</label>
              <div className='step1-information-block__item_box step1-photo'>
                <Photo photoIds={photoIds} photos={photos} setPhotos={setPhotos} addFile={addFile} getFile={getFile} setPhotoIds={setPhotoIds} />
              </div>
              <div className='errorPhoto'>
                <div className='errorPhoto-block'>{clickSubmit && photos.length <= 1 ? <p>{t('profile.minPhoto')}</p> : null}</div>
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.video_files')}</label>
              <div className='step1-information-block__item_box step1-video'>
                <Video videoIds={videosIds} videos={videos} setVideos={setVideos} addFile={addFile} getFile={getFile} setVideoIds={setVideosIds} />
              </div>
            </div>
            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.voice_label')}</label>
              <div className='step1-information-block__item_box step1-audio'>
                <Audio audioIds={audiosIds} audios={audios} setAudios={setAudios} addFile={addFile} getFile={getFile} setAudioIds={setAudiosIds} />
              </div>
            </div>

            <div className='step1-information-block__item'>
              <label>{t('components.model.profile.mainInfo.personal_info')}</label>
              <div className='step1-information-block__item_box step1-textarea'>
                <CustomTextArea
                  formik={formik}
                  initialRawContent={formik.values.selfInformation}
                  onChangeRaw={rawContent => formik.setFieldValue('selfInformation', rawContent)}
                />
              </div>
            </div>
            {!formik.dirty && (
              <div className='notification-panel'>
                <p>{t('components.model.profile.mainInfo.changes_notice')}</p>
              </div>
            )}
            <div className='step1-information-block__button'>
              <button
                onClick={() => {
                  setClickSubmit(true)
                }}
                type='submit'
                disabled={!formik.dirty || formik.isSubmitting}
              >
                {t('components.model.profile.mainInfo.save_action')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Edit
