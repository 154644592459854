import React, { useEffect, useRef } from 'react'
import './scss/index.scss'
import { useDispatch } from 'react-redux'
import { setChatTyping } from 'store/reducer/messages/reducer'
import { IChatTyping } from '../../../../socket/types'
import { useTranslation } from 'react-i18next'

interface TypingProps {
  typing: boolean
  privateChatId: string
  targetUserId: string
  children: React.ReactNode
}

const Typing: React.FC<TypingProps> = ({ typing, children, privateChatId, targetUserId }) => {
  const dispatch = useDispatch()
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (typing) {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }

      typingTimeoutRef.current = setTimeout(() => {
        const payload: IChatTyping = {
          body: {
            privateChatId: privateChatId,
            targetUserId: targetUserId,
            isActive: false,
          },
          type: 'CHAT_TYPING_NOTIFICATION',
          timestamp: new Date().toISOString(),
        }
        dispatch(setChatTyping(payload))
      }, 3000) // 3 секунды
    } else if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
      typingTimeoutRef.current = null
    }
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
    }
  }, [typing, privateChatId, targetUserId, dispatch])

  return (
    <div className='typing'>
      {typing ? (
        <div className='typing_messages'>
          <p>{t('components.user.messages.sidebarLeft.typing.write')}</p>
          <span className='dot'></span>
          <span className='dot'></span>
          <span className='dot'></span>
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export default Typing
