import React, { useMemo } from 'react'
import Heart from './Heart/Heart'
import './scss/index.scss'
import PhotoRoulette from './PhotoRoulette/PhotoRoulette'
import ButtonRoulette from './ButtonRoulette/ButtonRoulette'

const Roulette: React.FC = () => {
  return (
    <div className='roulette'>
      <div className='roulette-block'>
        <ButtonRoulette />
        <Heart />
        <PhotoRoulette />
      </div>
    </div>
  )
}

export default Roulette
