import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import Dashboard from 'Components/Admin/Dashboard/Dashboard'
import Header from 'Components/Admin/Header/Header'
import Login from 'Components/Admin/Login/Login'
import ModelsRequest from '../Components/Admin/ModelsRequest/ModelsRequest'
import ModelRequest from '../Components/Admin/ModelRequest/ModelRequest'
import Models from '../Components/Admin/Models/Models'
import Clients from '../Components/Admin/Clients/Clients'
import Client from '../Components/Admin/Client/Client'
import Model from 'Components/Admin/Model/Model'
import ModelPrice from '../Components/Admin/ModelPrice/ModelPrice'
import Complaint from 'Components/Admin/Complaint/Complaint'
import Metrics from 'Components/Admin/Metrics/Metrics'
import Messages from '../Components/User/Messages/Messages'
import MessageReplenishment from '../Components/Admin/MessageReplenishment/MessageReplenishment'
import ReferralController from '../Components/Admin/ReferralController/ReferralController'
import AccountController from '../Components/Admin/AccountController/AccountController'
import TransactionalController from '../Components/Admin/TransactionalController/TransactionalController'
import Staff from '../Components/Admin/Staff/Staff'
import Rooms from '../Components/Admin/Rooms/Rooms'
import StaffVerifications from '../Components/Admin/StaffVerifications/StaffVerifications'
import ModelSettings from '../Components/Admin/ModelSettings/ModelSettings'
import ReferralControllerTransactions from '../Components/Admin/ReferralControllerTransactions/ReferralControllerTransactions'
import ProtectedRouteStaff from './ProtectedRouteStaff/ProtectedRouteStaff'
import Users from '../Components/Admin/Users/Users'
import Vote from '../Components/Admin/Vote/Vote'

const AdminRoutes = () => {
  const { data: userMe, isLoading } = useUserGetFullMeQuery()
  const location = useLocation()

  return (
    <div className='admin'>
      <Header />
      <div className='admin-block'>
        <Dashboard />
        <Routes>
          {/* Public Route: Login */}
          <Route path='/login' element={<Login />} />

          <Route
            path='/users'
            element={
              <ProtectedRouteStaff>
                <Users />
              </ProtectedRouteStaff>
            }
          />
          {/* Protected Routes */}
          <Route
            path='/models-request'
            element={
              <ProtectedRouteStaff>
                <ModelsRequest />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/model-request/:modelRequestId'
            element={
              <ProtectedRouteStaff>
                <ModelRequest />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/clients'
            element={
              <ProtectedRouteStaff>
                <Clients />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/client/:clientId'
            element={
              <ProtectedRouteStaff>
                <Client />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/models'
            element={
              <ProtectedRouteStaff>
                <Models />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/vote'
            element={
              <ProtectedRouteStaff>
                <Vote />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/models/model-settings/:modelId'
            element={
              <ProtectedRouteStaff>
                <ModelSettings />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/model/:modelId'
            element={
              <ProtectedRouteStaff>
                <Model />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/model-price'
            element={
              <ProtectedRouteStaff>
                <ModelPrice />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/complaint'
            element={
              <ProtectedRouteStaff>
                <Complaint />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/metrics'
            element={
              <ProtectedRouteStaff>
                <Metrics />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/metrics/:metricsId'
            element={
              <ProtectedRouteStaff>
                <Metrics />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/referral-controller'
            element={
              <ProtectedRouteStaff>
                <ReferralController />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/referral-controller/:referralId'
            element={
              <ProtectedRouteStaff>
                <ReferralControllerTransactions />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/message-replenishment'
            element={
              <ProtectedRouteStaff>
                <MessageReplenishment />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/account-controller'
            element={
              <ProtectedRouteStaff>
                <AccountController />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/transactional-controller'
            element={
              <ProtectedRouteStaff>
                <TransactionalController />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/messages'
            element={
              <ProtectedRouteStaff>
                <Messages />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/rooms'
            element={
              <ProtectedRouteStaff>
                <Rooms />
              </ProtectedRouteStaff>
            }
          />
          <Route
            path='/staff/*'
            element={
              <ProtectedRouteStaff>
                <Staff />
              </ProtectedRouteStaff>
            }
          />

          {/* Catch-all route */}
          <Route path='*' element={<Navigate to='/models-request' replace />} />
        </Routes>
      </div>
    </div>
  )
}

export default AdminRoutes
