import React, { useState } from 'react'
import StarRating from './StarRating/StarRating'

const LogicStars = ({ setStars }: any) => {
  const [rating, setRating] = useState(0)

  const handleRatingClick = value => {
    setRating(value)
  }
  const handleRatingSelect = ratingId => {
    setStars(ratingId)
    handleRatingClick(ratingId)
  }
  return (
    <div className='rating'>
      <StarRating onRatingSelect={handleRatingSelect} />
      <div className='emoji-wrapper'>
        <div className='emoji' style={{ transform: `translateY(-${rating * 100}px)` }}>
          <div style={{ filter: 'grayscale(100%)' }}>🤔</div>
          <div className='rating-1'>🤬</div>
          <div className='rating-2'>😒</div>
          <div className='rating-3'>🙂</div>
          <div className='rating-4'>😊</div>
          <div className='rating-5'>🥰</div>
        </div>
      </div>
    </div>
  )
}

export default LogicStars
