// hooks/useMediaPermissions.ts
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Селекторы, которые возвращают текущие состояния разрешений (например, 'prompt', 'granted', 'denied')
import { selectCameraState, selectMicrophoneState } from 'store/reducer/permissionMonitor/selectors'
// Экшены для изменения состояний разрешений
import { setCameraState, setMicrophoneState } from 'store/reducer/permissionMonitor/reducer'
import { popUpOpenTwo } from 'store/reducer/popUp/reducer'

type PermissionState = 'prompt' | 'granted' | 'denied'

export function useMediaPermissions() {
  const dispatch = useDispatch()

  const cameraState = useSelector(selectCameraState) as PermissionState
  const microphoneState = useSelector(selectMicrophoneState) as PermissionState

  /**
   * Запрашивает разрешение на использование камеры.
   */
  const requestCameraPermission = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true })
      dispatch(setCameraState('granted'))
      return true
    } catch (error) {
      console.error('Ошибка при запросе разрешения на камеру:', error)
      dispatch(setCameraState('denied'))
      return false
    }
  }, [dispatch])

  /**
   * Запрашивает разрешение на использование микрофона.
   */
  const requestMicrophonePermission = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true })
      dispatch(setMicrophoneState('granted'))
      return true
    } catch (error) {
      console.error('Ошибка при запросе разрешения на микрофон:', error)
      dispatch(setMicrophoneState('denied'))
      return false
    }
  }, [dispatch])

  /**
   * Основная функция, которую вы вызываете внутри onClick на кнопке (или где угодно).
   * Принимает колбэк, который нужно выполнить после того, как будет гарантировано,
   * что разрешения на камеру и микрофон предоставлены.
   */
  const ensurePermissionsAndExecute = useCallback(
    async (callback: () => void) => {
      let cameraGranted = cameraState === 'granted'
      let microphoneGranted = microphoneState === 'granted'

      // Запрашиваем разрешение на камеру, если еще не предоставлено
      if (!cameraGranted) {
        cameraGranted = await requestCameraPermission()
      }

      // Запрашиваем разрешение на микрофон, если еще не предоставлено
      if (!microphoneGranted) {
        microphoneGranted = await requestMicrophonePermission()
      }

      if (cameraGranted && microphoneGranted) {
        // Если оба разрешения предоставлены, выполняем колбэк
        callback()
      } else {
        // Если одно или оба разрешения отклонены, открываем попап
        dispatch(popUpOpenTwo('mediaPermissionMonitorPopUp'))
      }
    },
    [cameraState, microphoneState, dispatch, requestCameraPermission, requestMicrophonePermission],
  )

  return { ensurePermissionsAndExecute }
}
