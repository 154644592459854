// ActionButtons.tsx
import React from 'react'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SvgBlockUser } from '../../../../../images/svg'
import { sendMessages } from 'store/reducer/messages/reducer'
import { RelationshipDeleteByIdApiArg, RelationshipPartialUpdateByIdApiArg } from 'store/apiMain/mainApi'

interface ActionButtonsProps {
  filter: string
  item: any // При наличии можно указать тип RelationshipFullDto
  role: string
  friendsDelete: (arg: RelationshipDeleteByIdApiArg) => void
  friendsUpdate: (arg: RelationshipPartialUpdateByIdApiArg) => void
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ filter, item, role, friendsDelete, friendsUpdate }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  if (filter === 'FRIENDS') {
    return (
      <>
        <button
          onClick={event => {
            event.stopPropagation()
            const payloadButton: RelationshipPartialUpdateByIdApiArg = {
              // @ts-ignore
              relationshipId: item.relationship.id,
              relationshipPartialUpdateDto: {
                type: 'BLOCK',
              },
            }
            friendsUpdate(payloadButton)
          }}
          className='item__btn-block-user'
        >
          <SvgBlockUser />
        </button>
        <div className='item__buttons'>
          <button
            onClick={event => {
              event.stopPropagation()
              if (item.relationship?.clientId && item.relationship?.modelId) {
                const obg = {
                  recipientId: item.relationship?.clientId,
                  senderId: item.relationship?.modelId,
                }
                dispatch(sendMessages(obg))
                navigate('/messages')
              }
            }}
            className='item__button_blue'
          >
            {t('components.global.friends.content.item.actionButtons.message_action')}
          </button>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipDeleteByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
              }
              friendsDelete(payloadButton)
            }}
            className='item__button_grey'
          >
            {t('components.global.friends.content.item.actionButtons.delete_action')}
          </button>
        </div>
      </>
    )
  }

  if (filter === 'FRIEND_REQUEST') {
    if (item.relationship?.initiatedBy === role) {
      return (
        <div className='item__buttons'>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipDeleteByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
              }
              friendsDelete(payloadButton)
            }}
            className='item__button_grey'
          >
            {t('components.global.friends.content.item.actionButtons.cancel_action')}
          </button>
        </div>
      )
    } else {
      return (
        <div className='item__buttons'>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
                relationshipPartialUpdateDto: {
                  type: 'APPROVE_FRIEND_REQUEST',
                },
              }
              friendsUpdate(payloadButton)
            }}
            className='item__button_blue'
          >
            {t('components.global.friends.content.item.actionButtons.accept_action')}
          </button>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipDeleteByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
              }
              friendsDelete(payloadButton)
            }}
            className='item__button_grey'
          >
            {t('components.global.friends.content.item.actionButtons.reject_action')}
          </button>
        </div>
      )
    }
  }

  if (filter === 'ONLINE') {
    return (
      <>
        <button
          onClick={event => {
            event.stopPropagation()
            const payloadButton: RelationshipPartialUpdateByIdApiArg = {
              // @ts-ignore
              relationshipId: item.relationship.id,
              relationshipPartialUpdateDto: {
                type: 'BLOCK',
              },
            }
            friendsUpdate(payloadButton)
          }}
          className='item__btn-block-user'
        >
          <SvgBlockUser />
        </button>
        <div className='item__buttons'>
          <button
            onClick={() => {
              navigate('/messages')
            }}
            className='item__button_blue'
          >
            {t('components.global.friends.content.item.actionButtons.message_action')}
          </button>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipDeleteByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
              }
              friendsDelete(payloadButton)
            }}
            className='item__button_grey'
          >
            {t('components.global.friends.content.item.actionButtons.delete_action')}
          </button>
        </div>
      </>
    )
  }

  if (filter === 'FAVORITES') {
    return (
      <>
        <button
          onClick={event => {
            event.stopPropagation()
            const payloadButton: RelationshipPartialUpdateByIdApiArg = {
              // @ts-ignore
              relationshipId: item.relationship.id,
              relationshipPartialUpdateDto: {
                type: 'BLOCK',
              },
            }
            friendsUpdate(payloadButton)
          }}
          className='item__btn-block-user'
        >
          <SvgBlockUser />
        </button>
        <div className='item__buttons'>
          <button
            onClick={event => {
              event.stopPropagation()
              if (item.relationship?.clientId && item.relationship?.modelId) {
                const obg = {
                  recipientId: item.relationship?.clientId,
                  senderId: item.relationship?.modelId,
                }
                dispatch(sendMessages(obg))
                navigate('/messages')
              }
            }}
            className='item__button_blue'
          >
            {t('components.global.friends.content.item.actionButtons.message_action')}
          </button>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipDeleteByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
              }
              friendsDelete(payloadButton)
            }}
            className='item__button_grey'
          >
            {t('components.global.friends.content.item.actionButtons.delete_action')}
          </button>
        </div>
      </>
    )
  }

  if (filter === 'BLOCKED' && role === item?.relationship?.initiatedBy) {
    return (
      <div className='item__buttons'>
        <button
          onClick={event => {
            event.stopPropagation()
            const payloadButton: RelationshipDeleteByIdApiArg = {
              // @ts-ignore
              relationshipId: item.relationship.id,
            }
            friendsDelete(payloadButton)
          }}
          className='item__button_grey'
        >
          {t('components.global.friends.content.item.actionButtons.unblock')}
        </button>
      </div>
    )
  }

  return null
}

export default ActionButtons
