import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store/store'
import { ToastContainer } from 'react-toastify'

import './variables.css'
import './reset.css'
import './global.css'

import 'react-toastify/dist/ReactToastify.css'
import 'react-medium-image-zoom/dist/styles.css'
import 'tippy.js/dist/tippy.css'
import PopUp from 'UI/PopUp/PopUp'
import App from './App'
import ScrollToTop from './utils/ScrollToTop/ScrollToTop'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
        <PopUp />
        <ToastContainer autoClose={2000} />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)
