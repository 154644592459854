import React, { FC, useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { IPopUp } from '../types'
import './scss/index.scss'
import Loading from '../../../elements/Loading/Loading'
import { useTranslation } from 'react-i18next'

const LoadingPopUp: FC<IPopUp> = ({ open, closeModal }) => {
  const [timeoutExceeded, setTimeoutExceeded] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (open) {
      setTimeoutExceeded(false)
      timer = setTimeout(() => {
        setTimeoutExceeded(true)
      }, 6000)
    }
    return () => clearTimeout(timer)
  }, [open])

  return (
    <Popup lockScroll={true} open={open} closeOnDocumentClick={false}>
      <div className='modal'>
        <div className='popup'>{timeoutExceeded ? <p>{t('ui.popUp.loading.server_error')}</p> : <Loading />}</div>
      </div>
    </Popup>
  )
}

export default LoadingPopUp
