import { Track } from 'livekit-client'
import * as React from 'react'
import { supportsScreenSharing } from '@livekit/components-core'
import { LeaveIcon, TrackToggle, useLocalParticipantPermissions, useMaybeLayoutContext, usePersistentUserChoices } from '@livekit/components-react'
// import { mergeProps } from '@livekit/components-react/dist/utils'
import { useMediaQuery } from 'usehooks-ts'
import { StartMediaButton } from './StartMediaButton/StartMediaButton'
import CustomButton from '../Component/CustomsButton'
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket from '../../../../../../socket/useWebSocket'
import Logo from '../../../../Logo/Logo'
import './scss/index.scss'
import { MediaDeviceMenu } from '../MediaDeviceMenu/MediaDeviceMenu'
import { setVideoRoomCreatedByModel } from 'store/reducer/videoChat/reducer'
import { selectRole } from 'store/reducer/token/selector'
import { DisconnectButton } from './DisconnectButton/DisconnectButton'
import { selectTime } from 'store/reducer/videoChatWithdrawalMoney/selector'
import { toastInfo } from '../../../../../../elements/Notification/Notification'
import { deleteRoom } from 'store/reducer/rooms/reducer'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/** @public */
export type ControlBarControls = {
  microphone?: boolean
  camera?: boolean
  chat?: boolean
  screenShare?: boolean
  leave?: boolean
  settings?: boolean
}

/** @public */
export interface ControlBarProps extends React.HTMLAttributes<HTMLDivElement> {
  onDeviceError?: (error: { source: Track.Source; error: Error }) => void
  variation?: 'minimal' | 'verbose' | 'textOnly'
  controls?: ControlBarControls
  /**
   * If `true`, the user's device choices will be persisted.
   * This will enables the user to have the same device choices when they rejoin the room.
   * @defaultValue true
   * @alpha
   */
  saveUserChoices?: boolean
}

/**
 * The `ControlBar` prefab gives the user the basic user interface to control their
 * media devices (camera, microphone and screen share), open the `Chat` and leave the room.
 *
 * @remarks
 * This component is build with other LiveKit components like `TrackToggle`,
 * `DeviceSelectorButton`, `DisconnectButton` and `StartAudio`.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <ControlBar />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function ControlBar({ variation, controls, saveUserChoices = true, onDeviceError, ...props }: ControlBarProps) {
  const dispatch = useDispatch()
  const role = useSelector(selectRole)
  const [isChatOpen, setIsChatOpen] = React.useState(false)
  const layoutContext = useMaybeLayoutContext()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (layoutContext?.widget.state?.showChat !== undefined) {
      setIsChatOpen(layoutContext?.widget.state?.showChat)
    }
  }, [layoutContext?.widget.state?.showChat])
  const isTooLittleSpace = useMediaQuery(`(max-width: ${isChatOpen ? 1000 : 760}px)`)

  const defaultVariation = isTooLittleSpace ? 'minimal' : 'verbose'
  variation ??= defaultVariation

  const visibleControls = { leave: true, ...controls }

  const localPermissions = useLocalParticipantPermissions()

  if (!localPermissions) {
    visibleControls.camera = false
    visibleControls.chat = false
    visibleControls.microphone = false
    visibleControls.screenShare = false
  } else {
    visibleControls.camera ??= localPermissions.canPublish
    visibleControls.microphone ??= localPermissions.canPublish
    visibleControls.screenShare ??= localPermissions.canPublish
    visibleControls.chat ??= localPermissions.canPublishData && controls?.chat
  }

  const showIcon = React.useMemo(() => variation === 'minimal' || variation === 'verbose', [variation])
  const showText = React.useMemo(() => variation === 'textOnly' || variation === 'verbose', [variation])

  const browserSupportsScreenSharing = supportsScreenSharing()

  const [isScreenShareEnabled, setIsScreenShareEnabled] = React.useState(false)

  const onScreenShareChange = React.useCallback(
    (enabled: boolean) => {
      setIsScreenShareEnabled(enabled)
    },
    [setIsScreenShareEnabled],
  )

  // const htmlProps = mergeProps({ className: 'lk-control-bar' }, props)

  const { saveAudioInputEnabled, saveVideoInputEnabled, saveAudioInputDeviceId, saveVideoInputDeviceId } = usePersistentUserChoices({
    preventSave: !saveUserChoices,
  })

  const microphoneOnChange = React.useCallback(
    (enabled: boolean, isUserInitiated: boolean) => (isUserInitiated ? saveAudioInputEnabled(enabled) : null),
    [saveAudioInputEnabled],
  )

  const cameraOnChange = React.useCallback(
    (enabled: boolean, isUserInitiated: boolean) => (isUserInitiated ? saveVideoInputEnabled(enabled) : null),
    [saveVideoInputEnabled],
  )
  ////////////////////// custom
  const { sendActivityUpdate } = useWebSocket()
  const time = useSelector(selectTime)
  console.log(time)
  const disableLeave = () => {
    console.log('УБрать на 60!!!!!!!')
    if (time < 5 && time !== 0) {
      return true
    } else {
      return false
    }
  }
  const { roomId } = useParams()
  return (
    <div className='lk-control-bar'>
      <div className='lk-control-bar_logo'>
        <Logo />
      </div>
      {visibleControls.microphone && (
        <div className='lk-button-group-custom'>
          {/*// @ts-ignore*/}
          <TrackToggle
            source={Track.Source.Microphone}
            showIcon={showIcon}
            onChange={microphoneOnChange}
            onDeviceError={error => onDeviceError?.({ source: Track.Source.Microphone, error })}
          >
            {showText && ''}
          </TrackToggle>
          <div className='lk-button-group-custom-menu'>
            <MediaDeviceMenu kind='audioinput' onActiveDeviceChange={(_kind, deviceId) => saveAudioInputDeviceId(deviceId ?? '')} />
          </div>
        </div>
      )}
      {visibleControls.camera && (
        <div className='lk-button-group-custom'>
          {/*// @ts-ignore*/}
          <TrackToggle
            source={Track.Source.Camera}
            showIcon={showIcon}
            onChange={cameraOnChange}
            onDeviceError={error => onDeviceError?.({ source: Track.Source.Camera, error })}
          >
            {showText && ''}
          </TrackToggle>
          <div className='lk-button-group-custom-menu'>
            <MediaDeviceMenu kind='videoinput' onActiveDeviceChange={(_kind, deviceId) => saveVideoInputDeviceId(deviceId ?? '')} />
          </div>
        </div>
      )}
      <CustomButton />
      {visibleControls.leave && (
        <div className='leave'>
          {disableLeave() && (
            // Полупрозрачный слой поверх кнопки для ловли кликов при заблокированном состоянии
            <div
              onClick={e => {
                // Показываем тост при клике на заблокированную кнопку
                toastInfo(`${t('liveKitCustom.controlBar.controlBar.room_access_notice')} ${60 - time}`)
                // Предотвращаем всплытие события к родительскому div
                e.stopPropagation()
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'not-allowed',
                // Убедитесь, что слой находится поверх кнопки
                zIndex: 1,
              }}
            />
          )}
          {/*// @ts-ignore*/}
          <DisconnectButton
            onClick={() => {
              if (role === 'MODEL') {
                dispatch(setVideoRoomCreatedByModel(false))
              }
              if (roomId) {
                dispatch(deleteRoom(roomId))
              }
              sendActivityUpdate('ONLINE')
            }}
            disabled={disableLeave()}
          >
            {showIcon && <LeaveIcon />}
            {showText && t('liveKitCustom.controlBar.controlBar.leave_action')}
          </DisconnectButton>
        </div>
      )}
      {/*// @ts-ignore*/}
      <StartMediaButton />
    </div>
  )
}
