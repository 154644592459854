import React, { ChangeEvent } from 'react'
import { SearchApiArg } from 'store/apiMain/mainApi'

interface FiltersProps {
  filters: SearchApiArg
  onChangeFilters: (newFilters: SearchApiArg) => void
}

const Filters: React.FC<FiltersProps> = ({ filters, onChangeFilters }) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target
    if (name === 'page' || name === 'pageSize') {
      onChangeFilters({
        ...filters,
        [name]: value ? parseInt(value, 10) : 0,
      })
    } else {
      onChangeFilters({
        ...filters,
        [name]: value || undefined,
      })
    }
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    onChangeFilters({
      ...filters,
      [name]: checked,
    })
  }

  return (
    <div className='filters'>
      <h2>Фильтры</h2>

      <div className='filterRow'>
        <label htmlFor='fromDate'>From Date:</label>
        <input type='date' id='fromDate' name='fromDate' value={filters.fromDate || ''} onChange={handleInputChange} />
      </div>

      <div className='filterRow'>
        <label htmlFor='toDate'>To Date:</label>
        <input type='date' id='toDate' name='toDate' value={filters.toDate || ''} onChange={handleInputChange} />
      </div>

      <div className='filterRow'>
        <label htmlFor='userIdToConnect'>User ID to Connect:</label>
        <input type='text' id='userIdToConnect' name='userIdToConnect' value={filters.userIdToConnect || ''} onChange={handleInputChange} />
      </div>

      <div className='filterRow checkboxRow'>
        <label htmlFor='withCurrentUserIdToConnect'>withCurrentUserIdToConnect:</label>
        <input
          type='checkbox'
          id='withCurrentUserIdToConnect'
          name='withCurrentUserIdToConnect'
          checked={filters.withCurrentUserIdToConnect || false}
          onChange={handleCheckboxChange}
        />
      </div>

      <div className='filterRow checkboxRow'>
        <label htmlFor='withActive'>withActive:</label>
        <input type='checkbox' id='withActive' name='withActive' checked={filters.withActive || false} onChange={handleCheckboxChange} />
      </div>

      <div className='filterRow checkboxRow'>
        <label htmlFor='withParticipant'>withParticipant:</label>
        <input
          type='checkbox'
          id='withParticipant'
          name='withParticipant'
          checked={filters.withParticipant || false}
          onChange={handleCheckboxChange}
        />
      </div>
    </div>
  )
}

export default Filters
