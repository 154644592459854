import React, { useState, useEffect } from 'react'
import { GlobalConfigSetApiArg, useGlobalConfigGetQuery, useGlobalConfigSetMutation } from 'store/apiMain/mainApi'
import './scss/index.scss'

const ModelPrice = () => {
  const [isEditing, setIsEditing] = useState(false)

  const [price, setPrice] = useState('')
  const [bonus, setBonus] = useState('')
  const [obligatoryMinutesForRoom, setObligatoryMinutesForRoom] = useState('')
  const [coefficientForRoom, setCoefficientForRoom] = useState('')
  const [obligatoryMinutesForCallFromClient, setObligatoryMinutesForCallFromClient] = useState('')
  const [coefficientForCallFromClient, setCoefficientForCallFromClient] = useState('')
  const [obligatoryMinutesForCallFromModel, setObligatoryMinutesForCallFromModel] = useState('')
  const [coefficientForCallFromModel, setCoefficientForCallFromModel] = useState('')

  const { data, refetch } = useGlobalConfigGetQuery()
  const [editFunc, { isSuccess }] = useGlobalConfigSetMutation()

  useEffect(() => {
    if (data) {
      setPrice(data.defaultModelMinutePrice?.toString() || '')
      setBonus(data.clientEmailVerificationCoinsBonus?.toString() || '')
      setObligatoryMinutesForRoom(data.obligatoryMinutesForRoomWithClientAndModel?.toString() || '')
      setCoefficientForRoom(data.coefficientForObligatoryMinutesForRoomWithClientAndModel?.toString() || '')
      setObligatoryMinutesForCallFromClient(data.obligatoryMinutesForCallFromClientToModel?.toString() || '')
      setCoefficientForCallFromClient(data.coefficientForObligatoryMinutesForCallFromClientToModel?.toString() || '')
      setObligatoryMinutesForCallFromModel(data.obligatoryMinutesForCallFromModelToClient?.toString() || '')
      setCoefficientForCallFromModel(data.coefficientForObligatoryMinutesForCallFromModelToClient?.toString() || '')
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false)
      refetch()
    }
  }, [isSuccess, refetch])

  const params: GlobalConfigSetApiArg = {
    globalConfigSaveDto: {
      defaultModelMinutePrice: Number(price),
      clientEmailVerificationCoinsBonus: Number(bonus),
      obligatoryMinutesForRoomWithClientAndModel: Number(obligatoryMinutesForRoom),
      coefficientForObligatoryMinutesForRoomWithClientAndModel: Number(coefficientForRoom),
      obligatoryMinutesForCallFromClientToModel: Number(obligatoryMinutesForCallFromClient),
      coefficientForObligatoryMinutesForCallFromClientToModel: Number(coefficientForCallFromClient),
      obligatoryMinutesForCallFromModelToClient: Number(obligatoryMinutesForCallFromModel),
      coefficientForObligatoryMinutesForCallFromModelToClient: Number(coefficientForCallFromModel),
    },
  }

  const handleSave = () => {
    editFunc(params)
  }

  if (!data) {
    return <div>Загрузка...</div>
  }

  return (
    <div className='ModelPrice'>
      {!isEditing ? (
        <>
          <div>Model Price: {data.defaultModelMinutePrice}</div>
          <div>Client Email Verification Coins Bonus: {data.clientEmailVerificationCoinsBonus}</div>
          <div>Obligatory Minutes for Room with Client and Model: {data.obligatoryMinutesForRoomWithClientAndModel}</div>
          <div>
            Coefficient for Obligatory Minutes for Room with Client and Model: {data.coefficientForObligatoryMinutesForRoomWithClientAndModel}
          </div>
          <div>Obligatory Minutes for Call from Client to Model: {data.obligatoryMinutesForCallFromClientToModel}</div>
          <div>Coefficient for Obligatory Minutes for Call from Client to Model: {data.coefficientForObligatoryMinutesForCallFromClientToModel}</div>
          <div>Obligatory Minutes for Call from Model to Client: {data.obligatoryMinutesForCallFromModelToClient}</div>
          <div>Coefficient for Obligatory Minutes for Call from Model to Client: {data.coefficientForObligatoryMinutesForCallFromModelToClient}</div>
          <button onClick={() => setIsEditing(true)}>Редактировать</button>
        </>
      ) : (
        <div>
          <div>
            <span>Model Price: </span>
            <input value={price} type='text' onChange={e => setPrice(e.target.value)} />
          </div>
          <div>
            <span>Client Email Verification Coins Bonus: </span>
            <input value={bonus} type='text' onChange={e => setBonus(e.target.value)} />
          </div>
          <div>
            <span>Obligatory Minutes for Room with Client and Model: </span>
            <input value={obligatoryMinutesForRoom} type='text' onChange={e => setObligatoryMinutesForRoom(e.target.value)} />
          </div>
          <div>
            <span>Coefficient for Obligatory Minutes for Room: </span>
            <input value={coefficientForRoom} type='text' onChange={e => setCoefficientForRoom(e.target.value)} />
          </div>
          <div>
            <span>Obligatory Minutes for Call from Client: </span>
            <input value={obligatoryMinutesForCallFromClient} type='text' onChange={e => setObligatoryMinutesForCallFromClient(e.target.value)} />
          </div>
          <div>
            <span>Coefficient for Call from Client: </span>
            <input value={coefficientForCallFromClient} type='text' onChange={e => setCoefficientForCallFromClient(e.target.value)} />
          </div>
          <div>
            <span>Obligatory Minutes for Call from Model: </span>
            <input value={obligatoryMinutesForCallFromModel} type='text' onChange={e => setObligatoryMinutesForCallFromModel(e.target.value)} />
          </div>
          <div>
            <span>Coefficient for Call from Model: </span>
            <input value={coefficientForCallFromModel} type='text' onChange={e => setCoefficientForCallFromModel(e.target.value)} />
          </div>
          <button onClick={handleSave}>Сохранить</button>
          <button onClick={() => setIsEditing(false)}>Отмена</button>
        </div>
      )}
    </div>
  )
}

export default ModelPrice
