import { SvgDown, SvgTranslate } from 'images/svg'
import { FC, useEffect, useState, useCallback, useRef, useLayoutEffect } from 'react'
import { TranslateRequest, useTranslateMutation } from 'store/apiMain/mainApi'
import CustomTextArea from '../../../UI/CustomTextArea/CustomTextArea'
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js'
import { IILanguagesBackend } from 'store/reducer/language/types'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { useTranslation } from 'react-i18next'

interface Props {
  info: string // JSON-строка Draft.js ContentState
  language: IILanguagesBackend
}

// Настройки для экспорта и импорта с сохранением стилей
const exportOptions = {
  inlineStyles: {
    COLOR: {
      element: 'span',
      style: (style: any) => ({
        color: style.color,
      }),
    },
    FONTFAMILY: {
      element: 'span',
      style: (style: any) => ({
        fontFamily: style.fontFamily,
      }),
    },
    // Добавьте другие стили при необходимости
  },
}

const importOptions = {
  customInlineFn: (element: any, { style }: any) => {
    if (element.style.color) {
      style.COLOR = { color: element.style.color }
    }
    if (element.style.fontFamily) {
      style.FONTFAMILY = { fontFamily: element.style.fontFamily }
    }
    return style
  },
}

// Определение кастомных стилей для Draft.js
const customStyleMap = {
  COLOR: (style: any) => ({
    color: style.color,
  }),
  FONTFAMILY: (style: any) => ({
    fontFamily: style.fontFamily,
  }),
  // Добавьте другие стили при необходимости
}

export const GirlInfo: FC<Props> = ({ info, language }) => {
  const [isShow, setIsShow] = useState<boolean>(false)
  const [isTranslate, setIsTranslate] = useState<boolean>(false)
  const [currentLanguage, setCurrentLanguage] = useState<IILanguagesBackend>(language)
  const [editorState, setEditorState] = useState<EditorState>(() => {
    try {
      const contentState = convertFromRaw(JSON.parse(info))
      return EditorState.createWithContent(contentState)
    } catch (error) {
      console.error('Ошибка при парсинге info:', error)
      return EditorState.createEmpty()
    }
  })
  const [translatedEditorState, setTranslatedEditorState] = useState<EditorState | null>(null)
  const [translateMutation, { isLoading }] = useTranslateMutation()
  const { t } = useTranslation()
  useEffect(() => {
    if (contentRef.current) {
      // Создаём MutationObserver для отслеживания изменений в содержимом
      const observer = new MutationObserver(() => {
        // Обновляем maxHeight после изменения содержимого
        if (isShow) {
          setMaxHeight(`${contentRef.current?.scrollHeight}px`)
        }
      })

      // Наблюдаем за изменениями внутри contentRef
      observer.observe(contentRef.current, {
        childList: true, // Отслеживаем изменения в дочерних элементах
        subtree: true, // Отслеживаем изменения во вложенных узлах
        characterData: true, // Отслеживаем изменения в тексте
      })

      return () => observer.disconnect() // Отключаем observer при размонтировании компонента
    }
  }, [isShow, editorState, translatedEditorState, isTranslate])

  const translateClick = useCallback(async () => {
    if (!isTranslate) {
      if (translatedEditorState) {
        setIsTranslate(true)
      } else {
        // Преобразуем ContentState в HTML с сохранением стилей
        const contentState = editorState.getCurrentContent()
        const html = stateToHTML(contentState, exportOptions)

        try {
          const response = await translateMutation({
            translateRequest: {
              body: html, // Отправляем HTML на перевод
              language: currentLanguage,
            },
          }).unwrap()

          if (response?.translatedBody) {
            // Преобразуем переведённый HTML обратно в ContentState с сохранением стилей
            const translatedContentState = stateFromHTML(response.translatedBody, importOptions)
            const newEditorState = EditorState.createWithContent(translatedContentState)

            setTranslatedEditorState(newEditorState)
            setIsTranslate(true)
            setCurrentLanguage(response.translatedLanguage as IILanguagesBackend)

            // Принудительно обновляем высоту после рендеринга переведенного текста
            setTimeout(() => {
              if (contentRef.current) {
                const newHeight = contentRef.current.scrollHeight
                setMaxHeight(`${newHeight}px`)
                contentRef.current.scrollIntoView({
                  behavior: 'smooth', // Плавная прокрутка
                  block: 'start', // Прокрутить к началу элемента
                })
              }
            }, 100) // Добавляем небольшую задержку для корректного пересчета
          } else {
            console.error('Переведённый текст отсутствует в ответе API.')
          }
        } catch (error) {
          console.error('Ошибка при переводе:', error)
        }
      }
    } else {
      setIsTranslate(false)
      setCurrentLanguage(language)
    }
  }, [isTranslate, translatedEditorState, translateMutation, currentLanguage, editorState, language])

  useEffect(() => {
    setIsTranslate(false)
    setTranslatedEditorState(null)
    setCurrentLanguage(language) // Обновляем текущий язык при изменении пропса
    try {
      const contentState = convertFromRaw(JSON.parse(info))
      setEditorState(EditorState.createWithContent(contentState))
    } catch (error) {
      console.error('Ошибка при парсинге info при изменении props:', error)
      setEditorState(EditorState.createEmpty())
    }
  }, [language, info])

  const handlerClick = () => {
    setIsShow(prev => {
      const newState = !prev
      if (!newState && contentRef.current) {
        // Если блок сворачивается, скроллим к началу блока
        contentRef.current.scrollIntoView({
          behavior: 'smooth', // Плавная прокрутка
          block: 'start', // Прокрутить к началу элемента
        })
      }
      return newState
    })
  }
  // Ref and state for dynamic max-height
  const contentRef = useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = useState<string>('100px')

  useLayoutEffect(() => {
    if (contentRef.current) {
      // Если блок раскрыт, устанавливаем его высоту в зависимости от содержимого
      if (isShow) {
        // Используем requestAnimationFrame для того, чтобы дождаться рендера контента
        requestAnimationFrame(() => {
          const newHeight = contentRef.current?.scrollHeight
          setMaxHeight(`${newHeight}px`)
        })
      } else {
        // Если блок скрыт, устанавливаем ограниченную высоту
        setMaxHeight('100px')
      }
    }
  }, [isShow, editorState, translatedEditorState, isTranslate])
  return (
    <div className='right-price'>
      <div className='right-priceBox'>
        <div
          className={`right-price__info ${isShow ? 'expanded' : ''}`}
          ref={contentRef}
          style={{ maxHeight: maxHeight, transition: 'max-height 1s ease' }}
        >
          {isLoading ? (
            t('components.user.girl.girlInfo.translate_placeholder')
          ) : (
            <>
              {/* Оригинальный текст */}
              <div
                style={{
                  display: isTranslate ? 'none' : 'block',
                }}
              >
                <CustomTextArea view={true} initialRawContent={info} />
              </div>

              {/* Переведённый текст */}
              <div
                style={{
                  display: isTranslate ? 'block' : 'none',
                }}
              >
                {translatedEditorState && (
                  <CustomTextArea view={true} initialRawContent={JSON.stringify(convertToRaw(translatedEditorState.getCurrentContent()))} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className='right-price-btn'>
        <button onClick={translateClick}>
          <div className='text'>
            <p>{isTranslate ? t('components.user.girl.girlInfo.original_label') : t('components.user.girl.girlInfo.translate_action')}</p>
          </div>
          <div className='icon'>
            <SvgTranslate />
          </div>
        </button>
        <button className={`btn__read`} onClick={handlerClick}>
          <p className='text'>{!isShow ? t('components.user.girl.girlInfo.read_more') : t('components.user.girl.girlInfo.hide_action')}</p>
          <div className={`icon ${isShow ? 'hide' : ''}`}>
            <SvgDown />
          </div>
        </button>
      </div>
    </div>
  )
}
