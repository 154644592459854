import React, { FC, useEffect } from 'react'
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import { selectLanguageData } from 'store/reducer/language/selector'
import { setLanguageData } from 'store/reducer/language/reducer'
import languageIcons from '../../languageData/icons'
import languages from '../../languageData/languages'
import { IILanguagesBackend } from 'store/reducer/language/types'
import { UserPartialUpdateByIdApiArg, useUserGetFullMeQuery, useUserPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../elements/Notification/Notification'
import useLockBodyScroll from '../../../hooks/useLockBodyScroll'
import Loading from '../../../elements/Loading/Loading'
import { useTranslation } from 'react-i18next'

const Language: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(selectLanguageData)
  const { data: user, refetch } = useUserGetFullMeQuery()
  const { t } = useTranslation()

  const handleLanguageSelect = (code: string, name: string, backendName: string) => {
    if (user) {
      const payload: UserPartialUpdateByIdApiArg = {
        userId: user?.userData?.user?.id,
        userPartialUpdateDto: {
          language: backendName as IILanguagesBackend,
        },
      }
      updateLanguage(payload)
    } else {
      toastSuccess(t('ui.popUp.language.success'))
      dispatch(
        setLanguageData({
          code,
          name,
          backendName: backendName as IILanguagesBackend,
        }),
      )
      closeModal()
    }
  }

  const [updateLanguage, { isSuccess: isSuccessUpdateLanguage, isLoading: isLoadingUpdateLanguage }] = useUserPartialUpdateByIdMutation()

  useEffect(() => {
    if (isSuccessUpdateLanguage) {
      refetch()
      closeModal()
      toastSuccess(t('ui.popUp.language.success'))
    }
  }, [isSuccessUpdateLanguage])

  useLockBodyScroll(open)
  return (
    <Popup lockScroll={true} closeOnDocumentClick={true} closeOnEscape={true} open={open}>
      <div className='modal'>
        <div className='popup'>
          {isLoadingUpdateLanguage ? (
            <div>
              <Loading />
            </div>
          ) : (
            <div className='PopupLanguage'>
              <div className='languagePopUp'>
                <div className='languagePopUp-header'>
                  <h3>{t('ui.popUp.language.language_title')}</h3>
                  <div className='languagePopUp-header-selected'>
                    <div className='languagePopUp-block__box___content'>
                      <div className='languagePopUp-block__box___content___icon'>{selectedLanguage && languageIcons[selectedLanguage.code]}</div>
                      <span className='active'>{selectedLanguage.name}</span>
                    </div>
                  </div>
                  <div className='languagePopUp-header_close'>
                    <button onClick={closeModal}>{t('ui.popUp.language.close_button')}</button>
                  </div>
                </div>
                {languages.map((region, index) => (
                  <div key={index} className='languagePopUp-block'>
                    <div className='languagePopUp-block__box'>
                      <div className='languagePopUp-block__box___title'>{region.region}</div>
                      <div className='languagePopUp-block__content-container'>
                        {region.languages.map((language, langIndex) => (
                          <div
                            key={langIndex}
                            className='languagePopUp-block__box___content'
                            onClick={() => handleLanguageSelect(language.code, language.name, language.backendName)}
                          >
                            <div className='languagePopUp-block__box___content___icon'>{languageIcons[language.code]}</div>
                            <span className={`${selectedLanguage.code === language.code ? 'active' : ''}`}>{language.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Popup>
  )
}

export default Language
