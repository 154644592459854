import imgSupport from 'images/img/imgSupport.png'
import { setMessagesSelectedId, setMessagesSelectedStaffTrue } from '../../../../../store/reducer/messages/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectorMessagesSelectedStaff } from '../../../../../store/reducer/messages/selectors'
import Status from '../../../../../UI/Status/Status'
import { useTranslation } from 'react-i18next'

const MessagesItemAdmin = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const messagesSelectedStaff = useSelector(getSelectorMessagesSelectedStaff)
  return (
    <div
      onClick={() => {
        // @ts-ignore
        dispatch(setMessagesSelectedId(`CREATE_NEW_CHAT_STAFF`))
        dispatch(setMessagesSelectedStaffTrue())
      }}
      className={`messages__item messages__admin-item ${messagesSelectedStaff ? 'active' : ''}`}
    >
      <div className={'item__img'}>
        <div className='item__profile-photo'>
          <img src={imgSupport} alt='Support' />
        </div>
        <div className='item__online-status'>
          {/*// @ts-ignore*/}
          <Status status={'ONLINE'} />
        </div>
      </div>
      <div className='item__content'>
        <div className='item__content-block'>
          <h6 className='item__content-name'>{t('components.user.messages.sidebarLeft.messageSitem.messageSitemAdmin.vhSupport')}</h6>
        </div>
        {/* <div className='item__content-block'>
          <p className='item__content-text'>Задавай вопросы</p>
        </div> */}
      </div>
    </div>
  )
}

export default MessagesItemAdmin
