// store/reducer/permissionMonitor/permissionMonitorSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPermissionMonitorStore, PermissionStateType } from './types'

const initialState: IPermissionMonitorStore = {
  cameraState: undefined,
  microphoneState: undefined,
}

// Slice
const permissionMonitorSlice = createSlice({
  name: 'permissionMonitor',
  initialState,
  reducers: {
    setCameraState(state, action: PayloadAction<PermissionStateType>) {
      state.cameraState = action.payload
    },
    setMicrophoneState(state, action: PayloadAction<PermissionStateType>) {
      state.microphoneState = action.payload
    },
  },
})

export const { setCameraState, setMicrophoneState } = permissionMonitorSlice.actions

export default permissionMonitorSlice.reducer
