import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormControl } from '../../../../layouts-elements/FormControl/FormControl'
import { IRegistrationInitialValues } from '../types'
import '../scss/index.scss'
import { useModelCreateMutation, useUserSendCurrentEmailConfirmationCodeMutation } from 'store/apiMain/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import { setRole, setTokenData } from 'store/reducer/token/reducer'
import imgLoginDecor from '../../../../images/img/login-decor.svg'

import { useNavigate, Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../UI/Checkbox/Checkbox'
import { getUuidUrl } from 'store/reducer/age/selectors'
import { setModelVerifications } from 'store/reducer/global/reducer'
import { selectLanguageData } from 'store/reducer/language/selector'

interface IProps {
  setEmail: (email: string) => void
}

const Registration: React.FC<IProps> = ({ setEmail }) => {
  const { t } = useTranslation()
  const uuidUrl = useSelector(getUuidUrl)

  const navigate = useNavigate()
  const [createClient, { data, isLoading, error }] = useModelCreateMutation()
  const dispatch = useDispatch()

  const [sendEmail] = useUserSendCurrentEmailConfirmationCodeMutation()
  const initialValues: IRegistrationInitialValues = {
    email: 'model@gmail.com',
    password: 'Qwerty',
    confirmPassword: 'Qwerty',
    age: false,
    agreement: false,
    privacyPolicy: false,
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t('components.model.registration.registration.email_required'))
      .email(t('components.model.registration.registration.enter_valid_email')),
    age: yup
      .boolean()
      .required(t('components.model.registration.registration.agreement_required'))
      .oneOf([true], t('components.model.registration.registration.agreementMustBeAccepted')),
    agreement: yup
      .boolean()
      .required(t('components.model.registration.registration.agreement_required'))
      .oneOf([true], t('components.model.registration.registration.agreementMustBeAccepted')),
    privacyPolicy: yup
      .boolean()
      .required(t('components.model.registration.registration.agreement_required'))
      .oneOf([true], t('components.model.registration.registration.agreementMustBeAccepted')),
    password: yup
      .string()
      .required(t('components.model.registration.registration.confirm_password_required'))
      .min(6, t('components.model.registration.registration.password_min_length'))
      .matches(/^[A-Za-z\d$!%*?&"@#^=+)(_-]+$/, t('components.model.registration.registration.password_english_only'))
      .matches(/^(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d$!%*?&"@#^=+)(_-]{6,}$/, t('components.model.registration.registration.password_criteria')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('components.model.registration.registration.confirm_password_match'))
      .required(t('components.model.registration.registration.confirmPasswordRequired')),
  })
  const selectLanguage = useSelector(selectLanguageData)
  const onSubmit = async (values: IRegistrationInitialValues) => {
    const body = {
      userCreateDto: {
        email: values.email,
        password: values.password,
        referralId: uuidUrl,
        language: selectLanguage.backendName,
      },
    }

    try {
      const result = await createClient(body).unwrap()

      setEmail(values.email)
      dispatch(setModelVerifications(true))
      dispatch(setRole('MODEL'))
      const payload = {
        emailUpdateConfirmationRequest: {
          email: values.email,
        },
      }
      // navigate('/model/registration/step/1');
      dispatch(setTokenData(result))
      setTimeout(() => {
        sendEmail(payload).unwrap()
      }, 200)
    } catch (error) {
      console.error('Failed toа create client', error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div className='login__main-left'>
      <div className='login__main-left_box'>
        <h1 className='login__title'>{t('components.model.registration.registration.registration_label')}</h1>
        <form onSubmit={formik.handleSubmit} className='login__form'>
          <label className='email'>
            <span>{t('components.model.registration.registration.email_label')}</span>
            <FormControl name='email' type='email' formik={formik} placeholder='' autoComplete='off' />
          </label>
          <label className='password'>
            <span>{t('components.model.registration.registration.password_label')}</span>
            <FormControl name='password' type='password' formik={formik} placeholder='' autoComplete='off' />
          </label>
          <label className='confirm-password'>
            <span>{t('components.model.registration.registration.confirm_password_label')}</span>
            <FormControl name='confirmPassword' type='password' formik={formik} placeholder='' autoComplete='off' />
          </label>
          <div className='rules'>
            <div className='rules-block'>
              <div className='rules-block_checkbox'>
                <Checkbox name='age' formik={formik} />
              </div>
              <div className='rules-block_text'>
                <p>{t('components.model.registration.registration.age_agreement')}</p>
              </div>
            </div>
            <div className='custom-input'>
              <div className={`error-input${formik.touched['age'] && formik.errors['age'] ? ' visible' : ''}`}>
                <p>{formik.touched['age'] && formik.errors['age'] ? formik.errors['age'] : ''}</p>
              </div>
            </div>
          </div>
          <div className='rules'>
            <div className='rules-block'>
              <div className='rules-block_checkbox'>
                <Checkbox name='agreement' formik={formik} />
              </div>
              <div className='rules-block_text'>
                <p>
                  {t('components.model.registration.registration.familiar_with_terms')}
                  <span onClick={() => window.open(`/info/model/terms-and-conditions`, '_blank')}>
                    {' '}
                    {t('components.model.registration.registration.agreement')}
                  </span>
                  {t('components.model.registration.registration.agree_terms')}
                </p>
              </div>
            </div>
            <div className='custom-input'>
              <div className={`error-input${formik.touched['agreement'] && formik.errors['agreement'] ? ' visible' : ''}`}>
                <p>{formik.touched['agreement'] && formik.errors['agreement'] ? formik.errors['agreement'] : ''}</p>
              </div>
            </div>
          </div>
          <div className='rules'>
            <div className='rules-block'>
              <div className='rules-block_checkbox'>
                <Checkbox name='privacyPolicy' formik={formik} />
              </div>
              <div className='rules-block_text'>
                <p>
                  {t('components.model.registration.registration.agree_terms')}
                  <span onClick={() => window.open(`/info/model/privacy-policy`, '_blank')}>
                    {t('components.model.registration.registration.privacy_policy')}
                  </span>
                  .
                </p>
              </div>
            </div>
            <div className='custom-input'>
              <div className={`error-input${formik.touched['privacyPolicy'] && formik.errors['privacyPolicy'] ? ' visible' : ''}`}>
                <p>{formik.touched['privacyPolicy'] && formik.errors['privacyPolicy'] ? formik.errors['privacyPolicy'] : ''}</p>
              </div>
            </div>
          </div>

          <button type='submit' className='login__form-button'>
            {t('components.model.registration.registration.sign_up_action')}
          </button>
          <div className='login__footer'>
            <span className='login__footer-link'>&nbsp;</span>
            <Link to='/login' className='login__footer-link'>
              {t('components.model.registration.registration.log_in_action')}
            </Link>
            {/*<Link to='/forgot-password' className='login__footer-link'>*/}
            {/*  {t('Forgot password')}*/}
            {/*</Link>*/}
          </div>
        </form>
        <div className='login__decor'>
          <img src={imgLoginDecor} alt='Decor auth' />
        </div>
      </div>
    </div>
  )
}

export default Registration
