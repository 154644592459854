import React, { useState, ChangeEvent, useEffect } from 'react'
import {
  GiftRecordsGetAllApiArg,
  useClientGetAllQuery,
  useGiftRecordsGetAllQuery,
  useRelationshipGetFullAllQuery,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import './scss/index.scss'
import RenderGiftIconAndName from '../../../utils/RenderGiftIconAndName/RenderGiftIconAndName'
import { photoViewUser } from '../../../UI/PhotoViewUser/PhotoViewUser'
import { useTranslation } from 'react-i18next'

const Gifts: React.FC = () => {
  const { data: dataUserMe } = useUserGetFullMeQuery()
  const { data: dataClient } = useClientGetAllQuery({ pageSize: 999 })
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(2)

  const payloadGifts: GiftRecordsGetAllApiArg = {
    modelId: dataUserMe?.userData?.user?.id,
    page,
    pageSize,
  }

  const {
    data: dataGifts,
    error,
    isLoading,
  } = useGiftRecordsGetAllQuery(payloadGifts, {
    skip: dataUserMe?.userData?.user?.id === undefined,
  })
  // const { data: dataFriends, refetch: dataFriendsRefetch } = useRelationshipGetFullAllQuery({ statuses: ['FRIENDS']});
  // const [clientsNotFriends, setClientsNotFriends] = useState<string[]>([]);
  // const [filteredClients, setFilteredClients] = useState<any[]>([]);

  // useEffect(() => {
  //     if (dataGifts && dataFriends) {
  //         const gifts = dataGifts.content;
  //         const friends = dataFriends.content;
  //
  //         const giftGivers = gifts.map((gift) => gift.fromClientId);
  //
  //         // @ts-ignore
  //         const friendsOrRequests = friends.map((friend) => friend.relationship.clientId);
  //
  //         const uniqueClientsNotFriends = Array.from(
  //             new Set(giftGivers.filter(clientId => !friendsOrRequests.includes(clientId)))
  //         );
  //         // @ts-ignore
  //         setClientsNotFriends(uniqueClientsNotFriends);
  //
  //         // @ts-ignore
  //         const clients = dataClient?.content.filter(client => uniqueClientsNotFriends.includes(client.client.id));
  //         setFilteredClients(clients || []);
  //     }
  // }, [dataGifts, dataFriends, dataClient]);
  const { t } = useTranslation()
  useEffect(() => {
    setPage(0)
  }, [pageSize])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handlePageSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(e.target.value))
  }

  if (isLoading) {
    return <div>{t('components.model.gifts.loading_message')}</div>
  }

  // Создаем объект для быстрого доступа к фото по ID клиента
  const clientPhotos: { [key: string]: number | null } = {}
  dataClient?.content?.forEach(e => {
    if (e.user?.id && e.client?.photoId) {
      clientPhotos[e.user.id.toString()] = e.client.photoId
    }
  })
  return (
    <div className='model-gifts'>
      <h2>{t('components.model.gifts.gifts_label')}</h2>
      <div>
        <label>
          {t('components.model.gifts.page_size')}
          <select value={pageSize} onChange={handlePageSizeChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={9999}>9999</option>
          </select>
        </label>
      </div>
      {dataGifts && dataGifts.content.length > 0 ? (
        <div>
          <p>
            {t('components.model.gifts.total_gifts')}: {dataGifts.totalElements}
          </p>
          <p>
            {t('components.model.gifts.page_label')}: {dataGifts.currentPage + 1} of {dataGifts.totalPages}
          </p>
          <ul>
            {dataGifts.content.map(gift => (
              <li key={gift.id}>
                {gift?.giftId ? <RenderGiftIconAndName index={gift.giftId} /> : null}
                <p>
                  {t('components.model.gifts.created_at')}: {new Date(gift.createdAt!).toLocaleString()}
                </p>
                {gift.fromClientId !== undefined && clientPhotos[gift.fromClientId.toString()]
                  ? photoViewUser(Number(clientPhotos[gift.fromClientId]))
                  : null}
              </li>
            ))}
          </ul>
          <div>
            <button onClick={() => handlePageChange(page - 1)} disabled={page <= 0}>
              {t('components.model.gifts.previous_action')}
            </button>
            <button onClick={() => handlePageChange(page + 1)} disabled={page + 1 >= dataGifts.totalPages}>
              {t('components.model.gifts.next_action')}
            </button>
          </div>
        </div>
      ) : (
        <div>{t('components.model.gifts.no_gifts_found')}</div>
      )}

      {/*<div>*/}
      {/*    <h2>Clients Not Friends</h2>*/}
      {/*    {filteredClients.length > 0 ? (*/}
      {/*        <ul>*/}
      {/*            {filteredClients.map(client => (*/}
      {/*                <li key={client.client.id}>*/}
      {/*                    <p>Client ID: {client.client.id}</p>*/}
      {/*                    <p>Client Name: {client.user.name}</p>*/}
      {/*                    {client.client.photoId ? photoViewUser(client.client.photoId) : null}*/}
      {/*                </li>*/}
      {/*            ))}*/}
      {/*        </ul>*/}
      {/*    ) : (*/}
      {/*        <p>No clients found who are not friends.</p>*/}
      {/*    )}*/}
      {/*</div>*/}
    </div>
  )
}

export default Gifts
