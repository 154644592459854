import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useUserGetFullMeQuery, useUserPartialUpdateByIdMutation, useUserSendCurrentEmailConfirmationCodeMutation } from 'store/apiMain/mainApi'
import { FormControl } from '../../../../layouts-elements/FormControl/FormControl'
import { useTranslation } from 'react-i18next'
import { toastSuccess, toastError } from '../../../../elements/Notification/Notification'

const EmailSuccess = () => {
  const { data, refetch } = useUserGetFullMeQuery()
  const [confirmEmail, { isSuccess: isSuccessConfirmEmail, isLoading, isError, error }] = useUserSendCurrentEmailConfirmationCodeMutation()
  const [updateEmail, { isSuccess: isSuccessUpdateEmail }] = useUserPartialUpdateByIdMutation()
  const [cooldown, setCooldown] = useState(0)

  const email = data?.userData?.user?.email
  const id = data?.userData?.user.id
  const [editCheck, setEditCheck] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(data?.userData?.user?.isEmailVerified ?? false)
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (isSuccessConfirmEmail) {
      setCooldown(30)
      if (step === 1) {
        setStep(2) // Move to step 2 if it was the first step
      }
    }
  }, [isSuccessConfirmEmail])

  useEffect(() => {
    if (isSuccessUpdateEmail) {
      toastSuccess(t('components.user.profile.emailSuccess.email_updated'))
      refetch()
      setEditCheck(false)
      setStep(1)
    }
  }, [isSuccessUpdateEmail, refetch, t])

  useEffect(() => {
    if (data?.userData?.user?.isEmailVerified !== undefined) {
      setIsEmailVerified(data.userData.user.isEmailVerified)
    }
  }, [data])

  const initialValues = {
    email: email || '',
    confirmationCode: '',
    newEmail: '',
    newEmailConfirmationCode: '',
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('components.user.profile.invalid_email')).required(t('components.user.profile.email_required')),
    confirmationCode: step === 2 ? yup.string().required(t('components.user.profile.code_required')) : yup.string(),
    newEmail:
      step >= 2 ? yup.string().email(t('components.user.profile.invalid_email')).required(t('components.user.profile.email_required')) : yup.string(),
    newEmailConfirmationCode: step === 3 ? yup.string().required(t('components.user.profile.code_required')) : yup.string(),
  })

  const onSubmit = async (values: any) => {
    setLoading(true)
    try {
      if (step === 1) {
        const payload = {
          emailUpdateConfirmationRequest: {
            email: values.email,
          },
        }
        await confirmEmail(payload)
      } else if (step === 2) {
        const payload = {
          emailUpdateConfirmationRequest: {
            email: values.newEmail, // New email
            currentEmailConfirmationCode: values.confirmationCode, // Code from old email
          },
        }
        await confirmEmail(payload)
        if (isSuccessConfirmEmail) {
          setStep(3) // Move to step 3 if the confirmation was successful
        }
      } else if (step === 3) {
        const payload = {
          userId: id,
          userPartialUpdateDto: {
            email: values.newEmail,
            confirmationCode: values.newEmailConfirmationCode, // Code from new email
          },
        }
        await updateEmail(payload)
      }
    } catch (error) {
      console.error(error)
      toastError(t('components.user.profile.emailSuccess.email_update_error'))
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const funcRefreshUpdateEmailStep2 = async () => {
    const payload = {
      emailUpdateConfirmationRequest: {
        email: formik.values.email,
      },
    }
    await confirmEmail(payload)
    if (isSuccessConfirmEmail) {
      setCooldown(30) // Restart cooldown
    }
  }

  const funcRefreshUpdateEmailStep3 = async () => {
    const payload = {
      emailUpdateConfirmationRequest: {
        email: formik.values.newEmail,
        currentEmailConfirmationCode: formik.values.confirmationCode, // Code from old
      },
    }
    await confirmEmail(payload)
    if (isSuccessConfirmEmail) {
      setCooldown(30) // Restart cooldown
    }
  }

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldown])

  return (
    <div className='content__box-item'>
      {editCheck ? (
        <form onSubmit={formik.handleSubmit}>
          <h6>{t('components.user.profile.email_label')}</h6>
          <label className='email'>
            <span>{t('components.user.profile.email_label')} :</span>
            <FormControl disabled={true} name='email' type='text' formik={formik} placeholder={t('components.user.profile.enter_email')} />
          </label>
          {step >= 2 && (
            <>
              <label className='email'>
                <span>{t('components.user.profile.emailSuccess.new_email')} :</span>
                <FormControl name='newEmail' type='text' formik={formik} placeholder={t('components.user.profile.emailSuccess.enter_new_email')} />
              </label>
              <label className='email'>
                <span>{t('components.user.profile.emailSuccess.old_email_code')}:</span>
                <FormControl
                  name='confirmationCode'
                  type='text'
                  formik={formik}
                  placeholder={t('components.user.profile.emailSuccess.enter_old_email_code')}
                />
              </label>
              {(step === 2 || step === 3) && (
                <div className='item-buttons'>
                  {step === 2 && (
                    <button type={'button'} onClick={funcRefreshUpdateEmailStep2} disabled={cooldown > 0 || loading}>
                      {loading
                        ? t('components.user.profile.loading_message')
                        : cooldown > 0
                          ? `${t('components.user.profile.resend_code')} (${cooldown})`
                          : t('components.user.profile.resend_code')}
                    </button>
                  )}
                </div>
              )}
            </>
          )}
          {step === 3 && (
            <>
              <label className='email'>
                <span>{t('components.user.profile.emailSuccess.new_email_code')}:</span>
                <FormControl
                  name='newEmailConfirmationCode'
                  type='text'
                  formik={formik}
                  placeholder={t('components.user.profile.emailSuccess.enter_new_email_code')}
                />
              </label>
              <div className='item-buttons'>
                <button type={'button'} onClick={funcRefreshUpdateEmailStep3} disabled={cooldown > 0 || loading}>
                  {loading
                    ? t('components.user.profile.loading_message')
                    : cooldown > 0
                      ? `${t('components.user.profile.resend_code')} (${cooldown})`
                      : t('components.user.profile.resend_code')}
                </button>
              </div>
            </>
          )}
          <div className='item-buttons' style={{ marginTop: '0.5rem' }}>
            <button onClick={() => setEditCheck(false)} type='button'>
              {t('components.user.profile.emailSuccess.close_action')}
            </button>
            <button type='submit' disabled={loading}>
              {loading ? t('components.user.profile.loading_message') : t('components.user.profile.emailSuccess.change_email')}
            </button>
          </div>
        </form>
      ) : (
        <>
          <h6>{t('components.user.profile.email_label')}</h6>
          <label>
            <span>{t('components.user.profile.email_label')}</span>
            <p>{email}</p>
            {isEmailVerified ? (
              <span className='success'>{t('components.user.profile.email_verified')}</span>
            ) : (
              <span className='error'>{t('components.user.profile.email_verify_promo')}</span>
            )}
          </label>
          <div className='item-buttons'>
            {!isEmailVerified && <button onClick={() => setEditCheck(true)}>{t('components.user.profile.verify_email')}</button>}
            <button onClick={() => setEditCheck(true)}>{t('components.user.profile.edit_action')}</button>
          </div>
        </>
      )}
    </div>
  )
}

export default EmailSuccess
