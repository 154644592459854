import { t } from 'i18next'
import { SvgSelectChat } from 'images/svg'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'

const NoId = () => {
  const { t } = useTranslation()

  return (
    <div className='no-id'>
      <div className='no-id-block'>
        <SvgSelectChat />
        <p>{t('components.user.messages.rightBlock.nold.select')}</p>
      </div>
    </div>
  )
}

export default NoId
