import EmailSuccess from '../../../User/Profile/EmailSuccess/EmailSuccess'
import Password from '../../../User/Profile/Password/Password'
import Delete from '../../../Global/Delete/Delete'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { t } from 'i18next'
import './scss/index.scss'
import useLogout from 'hooks/useLogout'
import { useTranslation } from 'react-i18next'
const EmailPassword = () => {
  const { data, isLoading, isError } = useUserGetFullMeQuery()
  const logOutHook = useLogout()
  const { t } = useTranslation()
  return (
    <div className='model-profile'>
      <h2>Account</h2>
      <div className='profile'>
        <main className='profile__main'>
          <div className='profile__main-inner content'>
            <div className='content__box'>
              <EmailSuccess />
              <Password />
              <div className='content__box-item'>
                <h6>{t('components.model.profile.emailPassword.account_section')}</h6>
                <div className='item-buttons'>
                  <button onClick={logOutHook} type='submit'>
                    {t('components.model.profile.emailPassword.logout_action')}
                  </button>
                  <Delete idDelete={String(data?.model?.id)} role='model' />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default EmailPassword
