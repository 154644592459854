import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'
const Documents = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data, isLoading, isError } = useUserGetFullMeQuery()
  return (
    <div className='main-info-documents'>
      <h2>{t('components.model.profile.documents.documents.documents_title')}</h2>
      <p>{t('components.model.profile.documents.documents.secret_data_warning')}</p>
      <button
        onClick={() => {
          navigate('/model/profile/documents/edit')
        }}
      >
        {t('components.model.profile.documents.documents.edit_action')}
      </button>
    </div>
  )
}

export default Documents
