import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../types'
import notificationContainerError from 'utils/notificationContainerError/notificationContainerError'
import { resetStateToken, setError, setTokenData } from '../reducer/token/reducer'
import { TokenGetApiArg, TokenGetApiResponse, TokenRevokeApiArg, TokenRevokeApiResponse } from 'store/apiMain/mainApi'
import { Mutex } from 'async-mutex'
import { baseUrl } from '../../config'

const mutex = new Mutex()

const baseQueryWithResponseHandler = async (args: FetchArgs, api: BaseQueryApi, extra: any) => {
  await mutex.waitForUnlock()

  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState
      if (state?.token?.tokenData?.access_token) {
        headers.set('Authorization', `Bearer ${state.token.tokenData.access_token}`)
        headers.set('Accept-Language', 'ru')
      }
      return headers
    },
  })(args, api, extra)

  if ('error' in result) {
    if (result.error?.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const state = api.getState() as RootState
          const refreshToken = state.token.tokenData?.refresh_token

          if (refreshToken) {
            const refreshResult = await fetchBaseQuery({
              baseUrl,
            })(
              {
                url: '/oauth2/token',
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                  refresh_token: refreshToken,
                  client_id: 'general-client',
                  grant_type: 'refresh_token',
                  client_secret: 'general',
                }).toString(),
              },
              api,
              extra,
            )

            if (refreshResult.data) {
              api.dispatch(setTokenData(refreshResult.data))
              result = await fetchBaseQuery({
                baseUrl,
                prepareHeaders: (headers, { getState }) => {
                  const state = getState() as RootState
                  if (state?.token?.tokenData?.access_token) {
                    headers.set('Authorization', `Bearer ${state.token.tokenData.access_token}`)
                    headers.set('Accept-Language', 'ru')
                  }
                  return headers
                },
              })(args, api, extra)
            } else {
              api.dispatch(
                setError({
                  text: 'Ошибка обновления токена',
                  url: args,
                }),
              )
              api.dispatch(resetStateToken())
              // window.location.href = '/login';
            }
          } else {
            api.dispatch(
              setError({
                text: 'Ошибка обновления токена',
                url: args,
              }),
            )
            api.dispatch(resetStateToken())
            // window.location.href = '/login';
          }
        } catch (error) {
          api.dispatch(resetStateToken())
          api.dispatch(
            setError({
              text: 'Ошибка обновления токена',
              url: args,
            }),
          )
          // window.location.href = '/login';
        } finally {
          release()
        }
      } else {
        await mutex.waitForUnlock()
        result = await fetchBaseQuery({
          baseUrl,
          prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState
            if (state?.token?.tokenData?.access_token) {
              headers.set('Authorization', `Bearer ${state.token.tokenData.access_token}`)
              headers.set('Accept-Language', 'ru')
            }
            return headers
          },
        })(args, api, extra)
      }
    } else {
      notificationContainerError(result)
      api.dispatch(
        setError({
          text: 'Ошибка обновления токена',
          url: api,
        }),
      )
    }
  } else {
    // notificationContainerSuccess(args);
  }

  return result
}

export const emptySplitApi = createApi({
  reducerPath: 'emptySplitApi',
  baseQuery: baseQueryWithResponseHandler,
  endpoints: builder => ({
    getFile: builder.mutation<any, any>({
      query(args) {
        return {
          url: `/api/files/${args.fileId}`,
          method: 'GET',
          responseHandler: async response => window.URL.createObjectURL(await response.blob()),
        }
      },
    }),
    tokenGet: builder.mutation<TokenGetApiResponse, TokenGetApiArg>({
      query: queryArg => ({
        url: '/oauth2/token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(queryArg.body).toString(),
      }),
    }),
    tokenRevoke: builder.mutation<TokenRevokeApiResponse, TokenRevokeApiArg>({
      query: queryArg => ({
        url: '/oauth2/revoke',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(queryArg.body).toString(),
      }),
    }),
  }),
})

export const { useGetFileMutation, useTokenGetMutation, useTokenRevokeMutation } = emptySplitApi
