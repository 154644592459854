import React, { ChangeEvent, useCallback } from 'react'

interface PaginationProps {
  page: number
  pageSize: number
  totalPages: number
  totalElements: number
  onChangePage: (newPage: number) => void
  onChangePageSize: (newPageSize: number) => void
}

const Pagination: React.FC<PaginationProps> = ({ page, pageSize, totalPages, totalElements, onChangePage, onChangePageSize }) => {
  const handlePrev = useCallback(() => {
    if (page > 0) {
      onChangePage(page - 1)
    }
  }, [page, onChangePage])

  const handleNext = useCallback(() => {
    if (page < totalPages - 1) {
      onChangePage(page + 1)
    }
  }, [page, onChangePage, totalPages])

  const handlePageSizeChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const newSize = parseInt(event.target.value, 10) || 1
      onChangePageSize(newSize)
    },
    [onChangePageSize],
  )

  return (
    <div className='pagination'>
      <div>
        <button onClick={handlePrev} disabled={page <= 0}>
          &laquo; Пред
        </button>
        <span style={{ margin: '0 8px' }}>
          Страница {page + 1} из {totalPages}
        </span>
        <button onClick={handleNext} disabled={page >= totalPages - 1}>
          След &raquo;
        </button>
      </div>

      <div style={{ marginTop: 8 }}>Всего элементов: {totalElements}</div>

      <div style={{ marginTop: 8 }}>
        <select value={pageSize} onChange={handlePageSizeChange} style={{ marginLeft: '16px' }}>
          {[5, 10, 20, 50, 100].map(size => (
            <option key={size} value={size}>
              {size} на страницу
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Pagination
