import React from 'react'
import { ComplaintDto, ComplaintGetAllApiArg, useComplaintGetAllQuery } from 'store/apiMain/mainApi'
import Item from './Item/Item'

const Complaint = () => {
  const payload: ComplaintGetAllApiArg = {
    page: undefined,
    pageSize: undefined,
  }
  // @ts-ignore
  const { data: complaintData }: any = useComplaintGetAllQuery(payload)

  return (
    <div className='complaint'>
      <div className='complaint-data'>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>AccuserUserId</th>
              <th>AccusedUserId</th>
              <th>CreatedAt</th>
              <th>InitiatedWithBlock</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {complaintData?.content?.map((e: ComplaintDto) => {
              return <Item key={e?.id} item={e} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Complaint

// import React, { useState } from 'react'
// import { ComplaintDto, ComplaintGetAllApiArg, useComplaintGetAllQuery } from 'store/apiMain/mainApi'
// import Item from './Item/Item'
//
// const Complaint = () => {
//   const [page, setPage] = useState<number>(1)
//   const [pageSize, setPageSize] = useState<number>(10)
//
//   const payload: ComplaintGetAllApiArg = {
//     page,
//     pageSize,
//   }
//
//   const { data: complaintData, isLoading, isError } = useComplaintGetAllQuery(payload)
//
//   const handlePrevPage = () => {
//     if (page > 1) {
//       setPage(prev => prev - 1)
//     }
//   }
//
//   const handleNextPage = () => {
//     if (complaintData?.content && complaintData?.content.length === pageSize) {
//       setPage(prev => prev + 1)
//     }
//   }
//
//   if (isLoading) return <div>Загрузка...</div>
//   if (isError) return <div>Ошибка при загрузке данных</div>
//
//   return (
//     <div className='complaint'>
//       <div className='complaint-data'>
//         <table>
//           <thead>
//           <tr>
//             <th>ID</th>
//             <th>AccuserUserId</th>
//             <th>AccusedUserId</th>
//             <th>CreatedAt</th>
//             <th>InitiatedWithBlock</th>
//             <th>Message</th>
//           </tr>
//           </thead>
//           <tbody>
//           {complaintData?.content?.map((e: ComplaintDto) => {
//             return <Item key={e?.id} item={e} />
//           })}
//           </tbody>
//         </table>
//       </div>
//       <div className='pagination-controls' style={{ marginTop: '20px' }}>
//         <button onClick={handlePrevPage} disabled={page === 1}>
//           Назад
//         </button>
//         <span style={{ margin: '0 10px' }}>Страница: {page}</span>
//         <button onClick={handleNextPage} disabled={!complaintData?.content || complaintData.content.length < pageSize}>
//           Вперёд
//         </button>
//       </div>
//     </div>
//   )
// }
//
// export default Complaint
