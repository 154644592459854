// themeSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ThemeStore, Theme } from './types'

const initialState: ThemeStore = {
  currentTheme: 'light', // начальное состояние (можно задать 'dark')
}

const themeReducerSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    // Устанавливает конкретную тему
    setTheme(state, action: PayloadAction<Theme>) {
      state.currentTheme = action.payload
    },
    // Переключает тему между 'light' и 'dark'
    toggleTheme(state) {
      state.currentTheme = state.currentTheme === 'light' ? 'dark' : 'light'
    },
  },
})

export const { setTheme, toggleTheme } = themeReducerSlice.actions
export default themeReducerSlice.reducer
