import React from 'react'
import Logo from '../../Logo/Logo'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { photoViewUserMobile1 } from '../../../../UI/PhotoViewUserMobile2/PhotoViewUserMobile1'
import Notifications from '../../../../elements/Notifications/Notifications'
import Language from '../../../../UI/Language/Language'
import { photoViewUserMobile } from '../../../../UI/PhotoViewUserMobile/PhotoViewUserMobile'
import { useTranslation } from 'react-i18next'

import { getAccount } from '../../../../store/reducer/accountController/selectors'
import { useDispatch, useSelector } from 'react-redux'
import UnreadCountMessages from '../../../../utils/UnreadCount/UnreadCountMessages/UnreadCountMessages'
import useLogout from '../../../../hooks/useLogout'
import { resetStateToken } from 'store/reducer/token/reducer'

const ModelStatusRegister = ({ isMenuOpen, setIsMenuOpen, data, dataUser, handleMenuToggle }: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const logOutHook = useLogout()

  return (
    <>
      <header className='header' role='banner'>
        <div className='header-menu_left'>
          <Logo />
        </div>
        <nav id='nav__desc'>
          <ul>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/messages' ? 'active' : ''}
            >
              <Link to='/model/messages'>{t('components.global.header.block.modelStatusRegister.messages')}</Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/registration/step/1' || location.pathname === '/model/registration/step/2' ? 'active' : ''}
            >
              <Link to='/model/registration/step/1'>{t('components.global.header.block.modelStatusRegister.registration_steps')}</Link>
            </li>
          </ul>
        </nav>
        <div className='header-menu_right'>
          <div className='headers'>
            <nav>
              <ul>
                <li>
                  <Notifications />
                </li>
                <li>
                  <Language />
                </li>
                <li style={{ marginLeft: '10px' }}>
                  <Link
                    onClick={() => {
                      dispatch(resetStateToken())
                    }}
                    to='/login'
                  >
                    <button onClick={logOutHook}>{t('components.global.header.block.modelStatusRegister.logout')}</button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <nav style={{ right: 0 }} id='nav' className={`${'nav'}${isMenuOpen ? ` ${['nav--open']}` : ''}`} role='navigation'>
          <ul
            className='nav__menu'
            id='menu'
            // @ts-ignore
            tabIndex='-1'
            aria-label='main navigation'
            hidden={!isMenuOpen}
          >
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/messages' ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/model/messages'>
                {t('components.global.header.block.modelStatusRegister.messages')}
              </Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={
                location.pathname === '/model/registration/step/1' || location.pathname === '/model/registration/step/2'
                  ? 'nav__item active'
                  : 'nav__item'
              }
            >
              <Link className='nav__link' to='/model/registration/step/1'>
                {t('components.global.header.block.modelStatusRegister.registration_steps')}{' '}
              </Link>
            </li>
            <li
              onClick={() => {
                dispatch(resetStateToken())
              }}
              className={
                location.pathname === '/model/registration/step/1' || location.pathname === '/model/registration/step/2' ? 'nav__item' : 'nav__item'
              }
            >
              <Link className='nav__link' to='/model/registration/step/1'>
                {t('components.global.header.block.modelStatusRegister.logout')}
              </Link>
            </li>
          </ul>
          <div className='menuMobile'>
            <a
              href='#nav'
              className={`nav__toggle ${isMenuOpen ? ` ${['nav__toggle--open']}` : ''}`}
              role='button'
              aria-expanded={isMenuOpen}
              aria-controls='menu'
              onClick={handleMenuToggle}
            >
              <svg
                className={`menuicon ${isMenuOpen ? ` ${['menuicon--open']}` : ''}`}
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='50'
                viewBox='0 0 50 50'
              >
                <title>{t('components.global.header.block.modelStatusRegister.toggle_menu')}</title>
                <g>
                  <line className={'menuicon__bar'} x1='13' y1='16.5' x2='37' y2='16.5' />
                  <line className={'menuicon__bar'} x1='13' y1='24.5' x2='37' y2='24.5' />
                  <line className={'menuicon__bar'} x1='13' y1='24.5' x2='37' y2='24.5' />
                  <line className={'menuicon__bar'} x1='13' y1='32.5' x2='37' y2='32.5' />
                  <circle className={'menuicon__circle'} r='23' cx='25' cy='25' />
                </g>
              </svg>
            </a>
          </div>

          <div
            style={{
              opacity: isMenuOpen ? 1 : 0,
              pointerEvents: isMenuOpen ? 'auto' : 'none',
            }}
            className={'splash'}
          ></div>
        </nav>
      </header>
    </>
  )
}

export default ModelStatusRegister
