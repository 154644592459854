import React from 'react'
import './scss/index.scss'

// Импортируем 12 изображений
import One from '../../../../images/roulette/girls/1.png'
import Two from '../../../../images/roulette/girls/2.png'
import Three from '../../../../images/roulette/girls/3.png'
import Four from '../../../../images/roulette/girls/4.png'
import Five from '../../../../images/roulette/girls/5.png'
import Six from '../../../../images/roulette/girls/6.png'
import Seven from '../../../../images/roulette/girls/7.png'
import Eight from '../../../../images/roulette/girls/8.png'
import Nine from '../../../../images/roulette/girls/9.png'
import Ten from '../../../../images/roulette/girls/10.png'
import Eleven from '../../../../images/roulette/girls/11.png'
import Twelve from '../../../../images/roulette/girls/12.png'

// Определяем интерфейс для карточки
interface Card {
  image: string
  style: React.CSSProperties
}

const PhotoRoulette: React.FC = () => {
  const cards: Card[] = [
    {
      image: One,
      style: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(0deg)',
        opacity: 0.8,
        width: '100%',
        maxWidth: '410px',
        height: 'auto',
      },
    },
    {
      image: Two,
      style: {
        position: 'absolute',
        top: '30%',
        left: '40%',
        transform: 'translate(-50%, -50%) rotate(15deg)',
        opacity: 0.9,
        width: '100px',
        height: '200px',
      },
    },
    {
      image: Three,
      style: {
        position: 'absolute',
        top: '60%',
        left: '20%',
        transform: 'translate(-50%, -50%) rotate(-15deg)',
        opacity: 1,
        width: '90px',
        height: '170px',
      },
    },
    {
      image: Four,
      style: {
        position: 'absolute',
        top: '70%',
        left: '70%',
        transform: 'translate(-50%, -50%) rotate(10deg)',
        opacity: 0.7,
        width: '100px',
        height: '200px',
      },
    },
    {
      image: Five,
      style: {
        position: 'absolute',
        top: '40%',
        left: '60%',
        transform: 'translate(-50%, -50%) rotate(-10deg)',
        opacity: 0.85,
        width: '95px',
        height: '190px',
      },
    },
    {
      image: Six,
      style: {
        position: 'absolute',
        top: '25%',
        left: '75%',
        transform: 'translate(-50%, -50%) rotate(20deg)',
        opacity: 0.9,
        width: '85px',
        height: '180px',
      },
    },
    {
      image: Seven,
      style: {
        position: 'absolute',
        top: '55%',
        left: '15%',
        transform: 'translate(-50%, -50%) rotate(-20deg)',
        opacity: 0.75,
        width: '110px',
        height: '210px',
      },
    },
    {
      image: Eight,
      style: {
        position: 'absolute',
        top: '65%',
        left: '80%',
        transform: 'translate(-50%, -50%) rotate(25deg)',
        opacity: 0.8,
        width: '100px',
        height: '200px',
      },
    },
    {
      image: Nine,
      style: {
        position: 'absolute',
        top: '35%',
        left: '25%',
        transform: 'translate(-50%, -50%) rotate(-25deg)',
        opacity: 0.85,
        width: '95px',
        height: '190px',
      },
    },
    {
      image: Ten,
      style: {
        position: 'absolute',
        top: '45%',
        left: '55%',
        transform: 'translate(-50%, -50%) rotate(30deg)',
        opacity: 0.9,
        width: '105px',
        height: '205px',
      },
    },
    {
      image: Eleven,
      style: {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-30deg)',
        opacity: 0.7,
        width: '90px',
        height: '180px',
      },
    },
    {
      image: Twelve,
      style: {
        position: 'absolute',
        top: '75%',
        left: '35%',
        transform: 'translate(-50%, -50%) rotate(35deg)',
        opacity: 0.8,
        width: '100px',
        height: '200px',
      },
    },
  ]

  return (
    <div className='photo-roulette'>
      {cards.map((card, index) => (
        <img key={index} src={card.image} alt={`card-${index + 1}`} style={card.style} className='photo-roulette-card' />
      ))}
    </div>
  )
}

export default PhotoRoulette
