import { useTranslation } from 'react-i18next'

export function convertStringRange(argument: string | undefined): string {
  const { t } = useTranslation()

  switch (argument) {
    case 'EIGHTEEN__TWENTY_THREE':
      return t('utils.ageRange.From18_23')
    case 'TWENTY_FOUR__THIRTY_THREE':
      return t('utils.ageRange.From24_33')
    case 'THIRTY_FOUR__FORTY_FOUR':
      return t('utils.ageRange.From34_44')

    case 'FORTY_FIVE__MORE':
      return t('utils.ageRange.over45')
    default:
      return t('utils.ageRange.errors')
  }
}
