import { FC, useEffect, useRef, useState } from 'react'
import { Popup } from 'reactjs-popup'
import { useSelector } from 'react-redux'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import useWebSocket from '../../../../socket/useWebSocket'
import { useUserGetFullByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { selectRole } from 'store/reducer/token/selector'
import { photoViewClient } from '../../../Photo/photoViewClient'
import { ICallEvent } from '../../../../hooks/socketTs/videoChat'
import imgSupport from '../../../../images/img/imgSupport.png'
import { SvgBackground, SvgPhone } from '../../../../images/svg'
import useSoundPlayer from '../../../../hooks/useSoundPlayer'
import { useMediaPermissions } from '../../../../hooks/useMediaPermissions'
import { toastInfo } from '../../../../elements/Notification/Notification'
import { useTranslation } from 'react-i18next'

const Calls: FC<IPopUp> = ({ open, closeModal }) => {
  const { handleCall } = useWebSocket()
  const selectedDataPopUp = useSelector(getPopUpData)?.data as ICallEvent | undefined
  const [selectedDataPop, setSelectedDataPop] = useState<ICallEvent | null>(null)

  const { data: userMe } = useUserGetFullMeQuery()
  const { data: targetUserData } = useUserGetFullByIdQuery({
    // @ts-ignore
    userId:
      userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId
        ? selectedDataPopUp?.body.targetUserId
        : selectedDataPopUp?.body.callerUserId,
  })

  const { playSound, stopAllSounds } = useSoundPlayer(false)
  const { ensurePermissionsAndExecute } = useMediaPermissions()
  const role = useSelector(selectRole)

  // Реф, где будет храниться идентификатор таймера
  const callTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedDataPopUp) {
      setSelectedDataPop(selectedDataPopUp)
    }
  }, [selectedDataPopUp])

  // Логика автоотключения через 20 секунд
  useEffect(() => {
    if (open) {
      callTimeoutRef.current = setTimeout(() => {
        if (role === 'MODEL' && selectedDataPopUp?.body?.callerUserRole === 'MODEL') {
          toastInfo(t('ui.popUp.videoPopUp.calls.subscriber_unavailable'))
        }
        if (role === 'CLIENT' && selectedDataPopUp?.body?.callerUserRole === 'MODEL') {
          toastInfo(t('ui.popUp.videoPopUp.calls.missed_call'))
        }
        if (role === 'CLIENT' && selectedDataPopUp?.body?.callerUserRole === 'CLIENT') {
          toastInfo(t('ui.popUp.videoPopUp.calls.subscriber_unavailable'))
        }
        if (role === 'MODEL' && selectedDataPopUp?.body?.callerUserRole === 'CLIENT') {
          toastInfo(t('ui.popUp.videoPopUp.calls.missed_call'))
        }
        handleDecline()
        closeModal()
      }, 1500000)
    }

    // При размонтировании/закрытии попапа очищаем таймер
    return () => {
      if (callTimeoutRef.current) {
        clearTimeout(callTimeoutRef.current)
        callTimeoutRef.current = null
      }
    }
  }, [open, closeModal])

  const handleAccept = () => {
    // Если пользователь принял звонок — очищаем таймер, чтобы он не сработал
    if (callTimeoutRef.current) {
      clearTimeout(callTimeoutRef.current)
      callTimeoutRef.current = null
    }
    ensurePermissionsAndExecute(() => {
      stopAllSounds()
      if (selectedDataPop?.body?.callerUserId) {
        handleCall(selectedDataPop.body.callerUserId, 'CALL_ACCEPT')
      }
    })
  }

  const handleDecline = () => {
    // Если пользователь отклонил звонок — также очищаем таймер
    if (callTimeoutRef.current) {
      clearTimeout(callTimeoutRef.current)
      callTimeoutRef.current = null
    }
    if (selectedDataPop?.body?.callerUserId) {
      handleCall(selectedDataPop.body.callerUserId, 'CALL_CANCEL')
    }
    stopAllSounds()
    closeModal()
  }

  const getPhotoAndName = () => {
    if (!targetUserData) return { photo: null, name: t('ui.popUp.videoPopUp.calls.unknown_status') }
    const callerUserRole = selectedDataPopUp?.body?.callerUserRole

    if (callerUserRole === 'CLIENT' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || t('ui.popUp.videoPopUp.calls.unknown_status'),
      }
    } else if (callerUserRole === 'CLIENT' && role === 'CLIENT') {
      return {
        photo: <img src={targetUserData?.profilePhoto?.file?.url} alt='' />,
        name: targetUserData?.model?.name || t('ui.popUp.videoPopUp.calls.unknown_status'),
      }
    } else if (callerUserRole === 'MODEL' && role === 'CLIENT') {
      return {
        photo: <img src={targetUserData?.profilePhoto?.file?.url} alt='' />,
        name: targetUserData?.model?.name || t('ui.popUp.videoPopUp.calls.unknown_status'),
      }
    } else if (callerUserRole === 'MODEL' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || t('ui.popUp.videoPopUp.calls.unknown_status'),
      }
    } else if (callerUserRole === 'STAFF') {
      return {
        photo: <img src={imgSupport} alt='' />,
        name: t('ui.popUp.videoPopUp.calls.admin_status'),
      }
    } else if (role === 'STAFF') {
      return {
        photo: <img src={imgSupport} alt='' />,
        name: t('ui.popUp.videoPopUp.calls.admin_status'),
      }
    } else {
      return { photo: null, name: t('ui.popUp.videoPopUp.calls.unknown_status') }
    }
  }

  const { photo, name } = getPhotoAndName()

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='call-modal'>
        <h3 className='caller-info'>
          {name} {userMe?.userData?.user?.id !== selectedDataPopUp?.body?.callerUserId && <span>{t('ui.popUp.videoPopUp.calls.call')}</span>}
        </h3>
        <div className='caller-picture'>
          <div className='photo-background'>
            <SvgBackground />
          </div>
          <div className='caller-photo'>{photo}</div>
        </div>
        <div className='call-buttons'>
          {userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId ? (
            <button className='decline-call' onClick={handleDecline}>
              {t('ui.popUp.videoPopUp.calls.cancel_action')}{' '}
            </button>
          ) : (
            <>
              <button className='decline-call' onClick={handleDecline}>
                {t('ui.popUp.videoPopUp.calls.decline_action')}{' '}
              </button>
              <button className='accept-call' onClick={handleAccept}>
                <span>{t('ui.popUp.videoPopUp.calls.accept_action')}</span>
                <SvgPhone />
              </button>
            </>
          )}
        </div>
      </div>
    </Popup>
  )
}

export default Calls
