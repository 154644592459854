import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tippy from '@tippyjs/react'
import { SvgBack, SvgCall, SvgChatCoin, SvgGift, SvgMessage, SvgVerticalThreeDots, SvgVideoCall } from 'images/svg'
// Если нужны чёрные иконки, оставляем их или переименовываем здесь:
// import { SvgBlackGift1, SvgBlackMoreVertical, SvgBlackPhone } from 'images/svg'

import ActionUserTooltip from '../../../../UI/Tooltip/ActionUserTooltip/ActionUserTooltip'
import TooltipGift from '../../../../UI/Tooltip/GiftTooltip/GiftTooltip'
// Ниже — либо Chat, либо Messages, в зависимости от того, что у вас реально используется
import Chat from './Chat/Chat' // или import Messages from './Messages/Messages'
import NeedsToAddFriend from './NeedsToAddFriend/NeedsToAddFriend'
import Support from './Support/Support'
import NoId from './NoId/NoId'
import TooltipGiftModel from '../../../../UI/Tooltip/GiftTooltipModel/TooltipGiftModel'
import ImgMessages from '../ImgMessages/ImgMessages'
import Status from '../../../../UI/Status/Status'

import {
  getSelectorMessagesSelectedId,
  getSelectorMessagesSelectedStaff,
  getSelectorMessagesSelectedUser,
  getSelectorSetMessagePrivetChats,
} from '../../../../store/reducer/messages/selectors'

import { selectRole, selectUserMe } from '../../../../store/reducer/token/selector'
import { getSubscribeUser } from '../../../../store/reducer/socket/selector'
import { RoomResponse, useAccountGetByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { setAccountClient } from 'store/reducer/accountController/reducer'
import { getAccountClients } from 'store/reducer/accountController/selectors'
import { setMessagesSelectedId } from 'store/reducer/messages/reducer'
import { useNavigate } from 'react-router-dom'
import useWebSocket from '../../../../socket/useWebSocket'
import { popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'
import './scss/index.scss'
import imgSupport from '../../../../images/img/imgSupport.png'
import { selectorRooms } from 'store/reducer/rooms/selector'
import { useMediaPermissions } from '../../../../hooks/useMediaPermissions'
import { useTranslation } from 'react-i18next'

// Или используйте ваш тип, если он называется иначе.

interface IProps {
  windowWidth: number
  setRightBlockActive: (active: boolean) => void
}

const RightBlock = ({ windowWidth, setRightBlockActive }: IProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { ensurePermissionsAndExecute } = useMediaPermissions()

  const role = useSelector(selectRole)
  const userMe = useSelector(selectUserMe) // или useUserGetFullMeQuery() ниже
  // Данные о пользователе
  const { data: dataUserMe } = useUserGetFullMeQuery() // если нужно

  // Селекторы сообщений:
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const messagesSelectedUser = useSelector(getSelectorMessagesSelectedUser)
  const selectorStaff = useSelector(getSelectorMessagesSelectedStaff)
  const selectorSetMessagePrivetChats = useSelector(getSelectorSetMessagePrivetChats)

  // Список залогиненных пользователей (статус онлайн/оффлайн)
  const subscribeUser = useSelector(getSubscribeUser)
  const onlineStatus = subscribeUser?.body?.find(user => user.userId === messagesSelectedUser?.user?.id)?.activityStatus ?? 'noStatus'
  const isStaff = messagesSelectedUser?.user?.role === 'STAFF'
  const isSupportStaff = selectorStaff !== null && selectorStaff

  // Ищем индекс текущего чата в массиве
  const chatIndex = selectorSetMessagePrivetChats?.content.findIndex(chat => chat.privateChat.id === messagesSelectedId)

  // Сам чат
  // @ts-ignore
  const privateChat = selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat
  // Айди клиента
  // @ts-ignore
  const clientId = privateChat?.clientId

  // Если в модели/клиенте нужно подтянуть баланс (coins, messages, и т.п.)
  const payloadAmount = {
    userId: clientId,
  }
  const { data: dataAmountClient } = useAccountGetByIdQuery(payloadAmount, {
    skip: !clientId,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true, // при переключении вкладок
    refetchOnReconnect: true, // при потере/возвращении соединения
  })
  const { t } = useTranslation()

  // Сохраняем данные об аккаунте клиента в redux, чтобы использовать в других местах
  useEffect(() => {
    if (clientId && dataAmountClient) {
      dispatch(
        setAccountClient({
          [clientId]: {
            ...dataAmountClient,
          },
        }),
      )
    }
  }, [clientId, dataAmountClient, dispatch])

  // Достаём сохранённые данные из redux
  const accountClient = useSelector(getAccountClients)[clientId]

  // Список комнат, чтобы показать кнопку "Enter Room"
  const rooms = useSelector(selectorRooms) as RoomResponse[]
  // Находим комнату, если нужно
  const roomsObj = rooms?.find(item => item.ownerUserId === messagesSelectedUser?.user?.id)
  // WebSocket. Например, для вызова аудио/видеозвонка
  const { startCall } = useWebSocket()

  // Тут храним состояние "открыт ли Tooltip"
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  // Тут храним Tippy инстанс, чтобы закрывать вручную
  const tippyInstance = useRef<any | null>(null)
  const handleCreate = instance => {
    tippyInstance.current = instance
  }
  const handleClose = () => {
    if (tippyInstance.current) {
      tippyInstance.current.hide()
    }
  }

  // Модалка (например, для Enter Room)
  const [isModalOpen, setModalOpen] = useState(false)

  // Типинг
  // @ts-ignore
  const chatTyping = privateChat?.chatTyping

  // Рендер верхней панели с аватаркой, именем и т.д.
  const renderUserInfo = () => {
    // Если техподдержка
    if (isSupportStaff) {
      return (
        <div className='chat-header'>
          <div className='chat-header__user'>
            <div style={windowWidth > 768 ? { display: 'none' } : {}} className='chat-header__back'>
              <button
                onClick={() => {
                  setRightBlockActive(true)
                  dispatch(setMessagesSelectedId(null))
                }}
              >
                <SvgBack />
              </button>
            </div>
            <div className='chat-header__user-avatar'>
              <img src={imgSupport} alt='Support' />
              <Status status={'ONLINE'} />
            </div>
            <div className='chat-header__user-info'>
              <h6 className='chat-header__user-name'>{t('components.user.messages.rightBlock.rightBlock.Support')} </h6>
              {/* Можно добавить тут что-то вроде: <p>online</p> */}
            </div>
          </div>
        </div>
      )
    }
    // Если STAFF
    else if (isStaff) {
      return (
        <div className='chat-header'>
          <div className='chat-header__user'>
            <div style={windowWidth > 768 ? { display: 'none' } : {}} className='chat-header__back'>
              <button
                onClick={() => {
                  setRightBlockActive(true)
                  dispatch(setMessagesSelectedId(null))
                }}
              >
                <SvgBack />
              </button>
            </div>
            <div className='chat-header__user-avatar'>
              <ImgMessages
                recipientRole={messagesSelectedUser?.user?.role}
                // @ts-ignore
                idClient={messagesSelectedUser?.client?.photoId}
                // @ts-ignore
                imgUrl={messagesSelectedUser?.profilePhoto?.file?.url}
                data={messagesSelectedUser}
              />
              <Status status={onlineStatus} />
            </div>
            <div className='chat-header__user-info'>
              <h6 className='chat-header__user-name'>
                {/*// @ts-ignore*/}
                {messagesSelectedUser?.user?.name || messagesSelectedUser?.staff?.name}
              </h6>

              {/* Пример, как подключить индикатор "печатает" */}
              {/* <Typing typing={chatTyping} privateChatId={privateChat?.id} targetUserId=''>
                <p className={`statusText ${onlineStatus.toLowerCase()}`}>{onlineStatus.toLowerCase()}</p>
              </Typing> */}
            </div>
          </div>
        </div>
      )
    }
    // Обычный пользователь (CLIENT) или MODEL
    else if (messagesSelectedId !== null) {
      return (
        <div className='chat-header'>
          <div className='chat-header__user'>
            <div style={windowWidth > 768 ? { display: 'none' } : {}} className='chat-header__back'>
              <button
                onClick={() => {
                  dispatch(setMessagesSelectedId(null))
                  setRightBlockActive(true)
                }}
              >
                <SvgBack />
              </button>
            </div>
            <div
              onClick={() => {
                if (role === 'CLIENT' && messagesSelectedUser?.user?.role === 'MODEL') {
                  // @ts-ignore
                  navigate(`/girl/${messagesSelectedUser?.model?.identifier}`)
                }
              }}
              className={
                messagesSelectedUser?.user?.role === 'MODEL' ? 'chat-header__user-avatar chat-header__model-avatar' : 'chat-header__user-avatar'
              }
            >
              <ImgMessages
                recipientRole={messagesSelectedUser?.user?.role}
                // @ts-ignore
                idClient={messagesSelectedUser?.client?.photoId}
                // @ts-ignore
                imgUrl={messagesSelectedUser?.profilePhoto?.file?.url}
                data={messagesSelectedUser}
              />
              <Status status={onlineStatus} />
            </div>
            <div
              onClick={() => {
                if (role === 'CLIENT' && messagesSelectedUser?.user?.role === 'MODEL') {
                  // @ts-ignore
                  navigate(`/girl/${messagesSelectedUser?.model?.identifier}`)
                }
              }}
              className={messagesSelectedUser?.user?.role === 'MODEL' ? 'chat-header__user-info chat-header__model-info' : 'chat-header__user-info'}
            >
              <h6 className='chat-header__user-name'>
                {/*// @ts-ignore*/}
                {messagesSelectedUser?.client?.name || messagesSelectedUser?.model?.name}
              </h6>

              {/* Пример, если модель хочет видеть у клиента баланс */}
              {role === 'MODEL' && (
                <div className='chat-header__user-amount'>
                  <div className='chat-header__user-amount-item'>
                    <SvgMessage /> <span>{accountClient?.messages}</span>
                  </div>
                  <div className='chat-header__user-amount-item'>
                    <SvgChatCoin /> <span>{accountClient?.coins}</span>
                  </div>
                </div>
              )}

              {/* Индикатор печати */}
              {/* <Typing typing={chatTyping} privateChatId={messagesSelectedId} targetUserId=''>
                <p className={`statusText ${onlineStatus.toLowerCase()}`}>{onlineStatus.toLowerCase()}</p>
              </Typing> */}
            </div>
          </div>

          {/* Блок с иконками справа */}
          <div className='chat-header__icons'>
            {/* Если CLIENT и есть своя комната у собеседника — кнопка Enter Room */}
            {role === 'CLIENT' && roomsObj && (
              <button
                onClick={() => {
                  ensurePermissionsAndExecute(() => {
                    setModalOpen(true)
                    dispatch(popUpOpen('enterRoom'))
                    const payloadLocal = {
                      body: {
                        callerUserId: messagesSelectedUser?.user?.id,
                        callerUserRole: messagesSelectedUser?.user?.role,
                        roomId: roomsObj?.id,
                      },
                    }
                    dispatch(setPopUpData({ data: payloadLocal }))
                  })
                }}
                type='button'
                className='messages__button'
              >
                <SvgVideoCall />
                <span>{t('components.user.messages.rightBlock.rightBlock.Enter')} </span>
              </button>
            )}

            {/* Кнопка Call (если нужно) */}
            {(onlineStatus === 'ONLINE' || onlineStatus === 'AWAY') && (
              <div
                onClick={() => {
                  if (messagesSelectedUser?.user?.id) {
                    const userId = messagesSelectedUser?.user?.id
                    ensurePermissionsAndExecute(() => {
                      startCall(userId)
                    })
                  }
                }}
              >
                <SvgCall />
              </div>
            )}

            {/* Подарки (Gift) для клиента и модели — разные тултипы */}
            {role === 'CLIENT' ? (
              <Tippy
                className='gift'
                interactive
                trigger='click'
                content={<TooltipGift id={messagesSelectedUser?.user?.id} onClose={handleClose} />}
                placement='bottom'
                onCreate={handleCreate}
              >
                <div id='dots-vertical' style={{ cursor: 'pointer' }}>
                  <SvgGift />
                </div>
              </Tippy>
            ) : (
              <Tippy
                interactive
                trigger='click'
                placement='bottom'
                visible={isTooltipOpen}
                onClickOutside={() => setIsTooltipOpen(false)}
                render={() =>
                  isTooltipOpen && (
                    <TooltipGiftModel
                      clientId={clientId}
                      // @ts-ignore
                      name={messagesSelectedUser?.client?.name}
                    />
                  )
                }
              >
                <div id='dots-vertical' style={{ cursor: 'pointer' }} onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
                  <SvgGift />
                </div>
              </Tippy>
            )}

            {/* Кнопка с тремя точками (действия) */}
            <Tippy zIndex={1} interactive trigger='click' content={<ActionUserTooltip />} placement='bottom'>
              <div id='dots-vertical' style={{ cursor: 'pointer' }}>
                <SvgVerticalThreeDots />
              </div>
            </Tippy>
          </div>
        </div>
      )
    }
    // Если вообще ничего не выбрано (messagesSelectedId === null)
    return null
  }

  return (
    <div
      className='message-user__content'
      style={{
        // Если хотите добавить фон, как в одном из вариантов кода:
        backgroundImage: `linear-gradient(to right, rgba(255,255,255, 0.6) 0 100%), url('/chat_bg.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      {/* Шапка (аватар, имя, кнопки) */}
      {messagesSelectedUser && <div className='message-user__content-top'>{renderUserInfo()}</div>}

      {/* Основное тело чата */}
      <div className='message-user__chat-container'>
        {/* Если пользователь залогинен, тогда уже показываем переписку */}
        {dataUserMe ? (
          <>
            {/* Если модель, и у приватного чата есть флаг, что клиент привлекает внимание */}
            {privateChat?.clientAttractAttentionCount > 0 && role === 'MODEL' ? (
              <NeedsToAddFriend
                privateChatId={privateChat?.id}
                relationshipWithCurrentUserId={privateChat?.matchingContent?.relationshipWithCurrentUser?.id}
              />
            ) : (
              <>
                {/* Основной компонент чата или сообщений Chat */}
                {/* Если это саппорт, показываем Support, если ничего не выбрано — NoId */}
                {selectorSetMessagePrivetChats !== undefined && messagesSelectedId && !isSupportStaff ? (
                  <Chat />
                ) : isSupportStaff ? (
                  <Support />
                ) : messagesSelectedId === null ? (
                  <NoId />
                ) : null}
              </>
            )}
          </>
        ) : null}
      </div>
    </div>
  )
}

export default RightBlock
