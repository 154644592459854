const getSystemMessageText = (messageType, role, t) => {
  switch (messageType) {
    case 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_CLIENT':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.friend_request_sent')
        : t('components.user.messages.func.getSystemMessageText.friend_request_received')

    case 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_MODEL':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.friend_request_received')
        : t('components.user.messages.func.getSystemMessageText.friend_request_sent')

    case 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_CLIENT':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.deletedFriendRequest')
        : t('components.user.messages.func.getSystemMessageText.clientDeletedFriendRequest')

    case 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_MODEL':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.modelDeletedFriendReq')
        : t('components.user.messages.func.getSystemMessageText.deletedFriendRequest')

    case 'SYSTEM_FRIEND_REMOVED_BY_CLIENT':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.deletedFriend')
        : t('components.user.messages.func.getSystemMessageText.clientRemoveFromFriendsList')

    case 'SYSTEM_FRIEND_REMOVED_BY_MODEL':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.modelDeletedFriendReq')
        : t('components.user.messages.func.getSystemMessageText.deletedFriend')

    case 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_CLIENT':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.youAcceptedFriendReq')
        : t('components.user.messages.func.getSystemMessageText.clientAcceptedFriendReq')

    case 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_MODEL':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.friend_request_accepted')
        : t('components.user.messages.func.getSystemMessageText.youAcceptedFriendReq')

    case 'SYSTEM_BLOCKED_BY_MODEL':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.you_were_blocked')
        : t('components.user.messages.func.getSystemMessageText.you_blocked_client')

    case 'SYSTEM_UNBLOCKED_BY_MODEL':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.youUnblock')
        : t('components.user.messages.func.getSystemMessageText.clientUnblock')

    case 'SYSTEM_BLOCKED_BY_CLIENT':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.you_blocked_model')
        : t('components.user.messages.func.getSystemMessageText.client_blocked_you')
    case 'SYSTEM_UNBLOCKED_BY_CLIENT':
      return role === 'CLIENT'
        ? t('components.user.messages.func.getSystemMessageText.modelUnblock')
        : t('components.user.messages.func.getSystemMessageText.client_blocked_you')

    case 'SYSTEM_PRIVATE_CHAT_STAFF_ASSIGNED':
      return t('components.user.messages.func.getSystemMessageText.chat_for_staff')

    case 'SYSTEM_PRIVATE_CHAT_DISABLED':
      return t('components.user.messages.func.getSystemMessageText.chat_disabled')

    case 'SYSTEM_USER_WAS_DELETED':
      return t('components.user.messages.func.getSystemMessageText.user_deleted')

    case 'SYSTEM_PRIVATE_CHAT_CLIENT_ASSIGNED':
      return t('components.user.messages.func.getSystemMessageText.chat_for_client')

    case 'SYSTEM_PRIVATE_CHAT_MODEL_ASSIGNED':
      return t('components.user.messages.func.getSystemMessageText.chat_for_model')

    case 'SYSTEM_CALL_STARTED':
      return t('components.user.messages.func.getSystemMessageText.call_started')

    case 'SYSTEM_CALL_FINISHED':
      return t('components.user.messages.func.getSystemMessageText.call_ended')

    case 'SYSTEM_CALL_INITIATED':
      return t('components.user.messages.func.getSystemMessageText.call_initiated')

    case 'SYSTEM_CALL_CANCELLED':
      return t('components.user.messages.func.getSystemMessageText.call_cancelled')

    default:
      // Если вдруг какой-то системный тип не попал в switch:
      return t('components.user.messages.func.getSystemMessageText.system_message')
  }
}
export default getSystemMessageText
