import { useEffect, useRef } from 'react'
import { selectRole } from '../../../../../store/reducer/token/selector'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { MessageResponse, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import dataFormatMessages from '../../../../../utils/dataFormatMessages'
import { getSelectorMessagesSelectedId } from '../../../../../store/reducer/messages/selectors'

import { MessageAttributes } from '../../../../../utils/getMessageAttributesByUserId'

import GiftMessages from '../../GiftMessages/GiftMessages'
import imgRequestGirls from '../../../../../images/img/imgRequestGirls.png'
import SystemMessage from './SystemMessage'
import ReadStatusIcon from 'utils/ReadStatus'
import { SvgChatInfo, SvgChatSuccess, SvgFailure, SvgTranslate, SvgWarning } from 'images/svg'
import { useParams } from 'react-router-dom'

interface IProps {
  message: MessageResponse
  lastMessage: boolean
  changeArrayReadIds: (idsRead: string) => void
  messageAttributesByUserId?: Map<string, MessageAttributes>
}

export type TSenderRole = 'CLIENT' | 'MODEL' | 'STAFF' | 'UNKNOWN'

const MessageType = ({ message, changeArrayReadIds, lastMessage, messageAttributesByUserId }: IProps) => {
  const role = useSelector(selectRole)
  const { data: userMe } = useUserGetFullMeQuery()
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const messageRef = useRef<HTMLDivElement | null>(null)
  const { roomId } = useParams()

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && message.id !== 'pending' && message.id !== 'error') {
            if (role === 'CLIENT' && !message.isReadByClient) {
              changeArrayReadIds(message.id)
            } else if (role === 'MODEL' && !message.isReadByModel) {
              changeArrayReadIds(message.id)
            } else if (role === 'STAFF' && !message.isReadByStaff) {
              if (
                message.recipientRole !== 'CLIENT' &&
                message.recipientRole !== 'MODEL' &&
                (message.recipientRole === 'STAFF' || message.senderRole === 'STAFF') &&
                // @ts-ignore
                !(message.recipientRole === 'MODEL' && message.senderRole === 'CLIENT')
              ) {
                changeArrayReadIds(message.id)
              }
            }
          }
        })
      },
      { threshold: 0.5 },
    )

    const currentMessageRef = messageRef.current
    if (currentMessageRef) {
      observer.observe(currentMessageRef)
    }

    return () => {
      if (currentMessageRef) {
        observer.unobserve(currentMessageRef)
      }
    }
  }, [message, role, messagesSelectedId])

  const recipientRole = message?.recipientRole
  const senderRole = message?.senderRole
  let messageAttributes = messageAttributesByUserId?.get(message?.createdBy ?? '')

  const renderMessage = () => {
    if (roomId && message.type !== 'GENERAL') {
      return null
    }

    if (message.type === 'GENERAL') {
      if (roomId) {
        return (
          <div className={`chat__item ${messageAttributes?.position === 'RIGHT' ? 'me' : 'you'}`}>
            <div className={`chat__item-content ${message?.senderRole}`}>
              <p className='text-message'>{message.body}</p>
              <div className='text-message_translate'>
                <div>
                  <SvgTranslate />
                </div>
                <p>{message.translatedBody}</p>
              </div>
              <div className='info-message'>
                {message.id === 'pending' ? <p>{t('message.messageOnTheWay')}...</p> : null}
                {message.id !== 'pending' &&
                (message.id === 'ACCOUNT_MESSAGES_BALANCE_INSUFFICIENT' || message.id === 'CONSTRAINT_VIOLATION_TYPE') ? (
                  <p>{t(`${message.id}`)}</p>
                ) : (
                  <>
                    <p className='time'>{dataFormatMessages(message.createdAt, true)}</p>
                    <ReadStatusIcon role={senderRole} message={message} userMe={userMe} color='white' />
                    <p>{message?.isReadByClient === false && role === 'CLIENT' ? 'New' : null}</p>
                    <p>{message?.isReadByModel === false && role === 'MODEL' ? 'New' : null}</p>
                    <p>{message?.isReadByStaff === false && role === 'STAFF' ? 'New' : null}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className={`chat__item ${messageAttributes?.position === 'RIGHT' ? 'me' : 'you'}`}>
            {senderRole !== userMe?.userData?.user?.role && (
              <div className='item__profile-photo'>
                <div className='img-box'>{messageAttributes?.image || <img src={imgRequestGirls} alt='' />}</div>
              </div>
            )}
            <div className={`chat__item-content ${message?.senderRole}`}>
              <p className='text-message'>{message.body}</p>
              <div className='text-message_translate'>
                <div>
                  <SvgTranslate />
                </div>
                <p>{message.translatedBody}</p>
              </div>
              <div className='info-message'>
                {message.id === 'pending' ? <p>{t('components.user.messages.rightBlock.messagesType.messageType.message_on_the_way')}...</p> : null}
                {message.id !== 'pending' &&
                (message.id === 'ACCOUNT_MESSAGES_BALANCE_INSUFFICIENT' || message.id === 'CONSTRAINT_VIOLATION_TYPE') ? (
                  <p>{t(`${message.id}`)}</p>
                ) : (
                  <>
                    <p className='time'>{dataFormatMessages(message.createdAt, true)}</p>
                    <ReadStatusIcon role={senderRole} message={message} userMe={userMe} color='white' />
                    <p>{message?.isReadByClient === false && role === 'CLIENT' ? 'New' : null}</p>
                    <p>{message?.isReadByModel === false && role === 'MODEL' ? 'New' : null}</p>
                    <p>{message?.isReadByStaff === false && role === 'STAFF' ? 'New' : null}</p>
                  </>
                )}
              </div>
            </div>
            {senderRole === userMe?.userData?.user?.role && (
              <div className='item__profile-photo'>
                <div className='img-box'>{messageAttributes?.image}</div>
              </div>
            )}
          </div>
        )
      }
    } else if (!roomId) {
      if (message.type === 'SYSTEM_GIFT_RECEIVED' && message.body) {
        const giftId = JSON.parse(message.body).giftId
        return (
          <div className='chat__item system-messages'>
            <div className='chat__item-content'>
              <GiftMessages giftId={giftId} message={message} role={role} userMe={userMe} />
            </div>
          </div>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_CLIENT') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.friend_request_sent')
            : t('components.user.messages.rightBlock.messagesType.messageType.friend_request_received')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatInfo />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REQUEST_INITIATED_BY_MODEL') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.friend_request_received')
            : t('components.user.messages.rightBlock.messagesType.messageType.friend_request_sent')
        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatInfo />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_CLIENT') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.deleted_friend_request')
            : t('components.user.messages.rightBlock.messagesType.messageType.client_deleted_friend_request')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgFailure />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REQUEST_REMOVED_BY_MODEL') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.model_deleted_friend_request')
            : t('components.user.messages.rightBlock.messagesType.messageType.deleted_friend_request')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgFailure />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REMOVED_BY_CLIENT') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.deleted_friend')
            : t('components.user.messages.rightBlock.messagesType.messageType.client_removed_from_friends')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgFailure />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REMOVED_BY_MODEL') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.model_deleted_friend_request')
            : t('components.user.messages.rightBlock.messagesType.messageType.deleted_friend')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgFailure />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_CLIENT') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.youAcceptedFriendReq')
            : t('components.user.messages.rightBlock.messagesType.messageType.client_accepted_friend_request')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatSuccess />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_FRIEND_REQUEST_ACCEPTED_BY_MODEL') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.model_accepted_friend_request')
            : t('components.user.messages.rightBlock.messagesType.messageType.youAcceptedFriendReq')
        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatSuccess />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_BLOCKED_BY_MODEL') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.you_were_blocked')
            : t('components.user.messages.rightBlock.messagesType.messageType.you_blocked_client')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgWarning />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_UNBLOCKED_BY_MODEL') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.youUnblock')
            : t('components.user.messages.rightBlock.messagesType.messageType.clientUnblock')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatSuccess />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_BLOCKED_BY_CLIENT') {
        const messageText =
          role === 'CLIENT' ? t('components.user.messages.rightBlock.messagesType.messageType.you_blocked_model') : 'Клиент заблокировал вас.'
        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgWarning />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_UNBLOCKED_BY_CLIENT') {
        const messageText =
          role === 'CLIENT'
            ? t('components.user.messages.rightBlock.messagesType.messageType.you_unblocked_model')
            : t('components.user.messages.rightBlock.messagesType.messageType.client_blocked_you')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatSuccess />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_PRIVATE_CHAT_STAFF_ASSIGNED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.chat_for_staff')
        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatInfo />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_PRIVATE_CHAT_DISABLED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.chat_disabled')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgFailure />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_USER_WAS_DELETED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.user_deleted')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgWarning />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_PRIVATE_CHAT_CLIENT_ASSIGNED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.chat_for_client')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatInfo />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_PRIVATE_CHAT_MODEL_ASSIGNED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.chat_for_model')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatInfo />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_CALL_STARTED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.call_started')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatSuccess />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_CALL_FINISHED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.call_ended')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatSuccess />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_CALL_INITIATED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.call_initiated')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgChatInfo />
          </SystemMessage>
        )
      } else if (message.type === 'SYSTEM_CALL_CANCELLED') {
        const messageText = t('components.user.messages.rightBlock.messagesType.messageType.call_cancelled')

        return (
          <SystemMessage text={messageText} createdAt={message.createdAt} role={senderRole} message={message} userMe={userMe}>
            <SvgFailure />
          </SystemMessage>
        )
      }
    }

    return message.type
  }

  return lastMessage ? (
    <div className='last-message' ref={messageRef}>
      {renderMessage()}
    </div>
  ) : (
    <div ref={messageRef}>{renderMessage()}</div>
  )
}

export default MessageType
