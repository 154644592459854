import React from 'react'
import Tippy from '@tippyjs/react'
import { RelationshipGetCountApiArg, useRelationshipGetCountQuery } from 'store/apiMain/mainApi'
import { SvgBlockUser, SvgChatGroup, SvgChatHeart, SvgChatProfile, SvgOnline, SvgPlusAdd } from 'images/svg'
import TextTooltip from 'UI/Tooltip/TextTooltip/TextTooltip'
import { IPropsSidebar } from './types'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'

const SideBar: React.FC<IPropsSidebar> = ({ sideBarFilterValue, setSideBarFilterValue, dataRequest }) => {
  const { t } = useTranslation()
  return (
    <div className='friends__sidebar '>
      <ul className='sidebar__icons'>
        <Tippy
          interactive
          trigger='mouseenter'
          content={<TextTooltip text={t('components.global.friends.content.sideBar.all_friends')} />}
          placement='top'
        >
          <li
            onClick={() => {
              setSideBarFilterValue('FRIENDS')
            }}
            className={`sidebar__icon ${sideBarFilterValue === 'FRIENDS' ? 'active' : ''}`}
          >
            <div className='primary-icon'>
              <SvgChatGroup />
            </div>
          </li>
        </Tippy>
        <Tippy
          interactive
          trigger='mouseenter'
          content={<TextTooltip text={t('components.global.friends.content.sideBar.online_friends')} />}
          placement='auto'
        >
          <li
            onClick={() => {
              setSideBarFilterValue('ONLINE')
            }}
            className={`sidebar__icon ${sideBarFilterValue === 'ONLINE' ? 'active' : ''}`}
          >
            <div className='friends-online'>
              <div className='online-icon'>
                <SvgOnline />
              </div>
              <div className='primary-icon'>
                <SvgChatProfile />
              </div>
            </div>
          </li>
        </Tippy>
        <Tippy
          interactive
          trigger='mouseenter'
          content={<TextTooltip text={t('components.global.friends.content.sideBar.favorites')} />}
          placement='auto'
        >
          <li
            onClick={() => {
              setSideBarFilterValue('FAVORITES')
            }}
            className={`sidebar__icon ${sideBarFilterValue === 'FAVORITES' ? 'active' : ''}`}
          >
            <div className='favorites'>
              <div className='favorites-icon'>
                <SvgChatHeart />
              </div>
              <div className='primary-icon'>
                <SvgChatProfile />
              </div>
            </div>
          </li>
        </Tippy>
        <Tippy
          interactive
          trigger='mouseenter'
          content={<TextTooltip text={t('components.global.friends.content.sideBar.friend_requests')} />}
          placement='auto'
        >
          <li
            onClick={() => {
              setSideBarFilterValue('FRIEND_REQUEST')
            }}
            className={`sidebar__icon ${sideBarFilterValue === 'FRIEND_REQUEST' ? 'active' : ''}`}
          >
            <div className='friend-requests'>
              <div className='plus-icon'>
                <span>+</span>
              </div>
              <div className='primary-icon'>
                <SvgChatProfile />
              </div>
              {dataRequest?.amount === 0 ? null : (
                <div className='friend-requests-count'>
                  <span>{dataRequest?.amount}</span>
                </div>
              )}
            </div>
          </li>
        </Tippy>
        <Tippy
          interactive
          trigger='mouseenter'
          content={<TextTooltip text={t('components.global.friends.content.sideBar.blocked_users')} />}
          placement='bottom'
        >
          <li
            onClick={() => {
              setSideBarFilterValue('BLOCKED')
            }}
            className={`sidebar__icon ${sideBarFilterValue === 'BLOCKED' ? 'active' : ''}`}
          >
            <div className='blocked'>
              <div className='blocked-icon'>
                <SvgBlockUser />
              </div>
              <div className='primary-icon'>
                <SvgChatProfile />
              </div>
            </div>
          </li>
        </Tippy>
      </ul>
    </div>
  )
}

export default SideBar
