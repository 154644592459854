import languages from '../languageData/languages'
import { useTranslation } from 'react-i18next'

interface IOptionValue {
  value: string
  label: string
}

// Год рождения
const optionsBirthYear: IOptionValue[] = []
const currentYear = new Date().getFullYear()
const minYear = currentYear - 60 // Ограничение для минимального возраста (например, 100 лет)
const maxYear = currentYear - 18 // Только для тех, кому исполнилось 18 лет

for (let year = minYear; year <= maxYear; year++) {
  optionsBirthYear.push({ value: year.toString(), label: year.toString() })
}
// Вес
const optionsWeight: IOptionValue[] = []
for (let weight = 40; weight <= 150; weight++) {
  optionsWeight.push({ value: weight.toString(), label: `${weight} kg` })
}

// Рост
const optionsHeight: IOptionValue[] = []
for (let height = 140; height <= 210; height++) {
  optionsHeight.push({ value: height.toString(), label: `${height} cm` })
}

// Грудь
const optionsChest: IOptionValue[] = []
for (let chest = 70; chest <= 130; chest++) {
  optionsChest.push({ value: chest.toString(), label: `${chest} cm` })
}

// Талия
const optionsWaist: IOptionValue[] = []
for (let waist = 50; waist <= 110; waist++) {
  optionsWaist.push({ value: waist.toString(), label: `${waist} cm` })
}

// Бедра
const optionsHips: IOptionValue[] = []
for (let hips = 60; hips <= 140; hips++) {
  optionsHips.push({ value: hips.toString(), label: `${hips} cm` })
}

// Грудь
const optionsBreastSize: IOptionValue[] = []
for (let breastSizes = 1; breastSizes <= 9; breastSizes++) {
  optionsBreastSize.push({
    value: String(breastSizes),
    label: String(breastSizes),
  })
}

// Цвет волос
const hairColorsValue = [
  'Black',
  'Dark brown',
  'Light brown',
  'Chestnut',
  'Blond',
  'Ash blonde',
  'Red',
  'Light blond',
  'Dark blond',
  'Pink',
  'Blue',
  'Green',
  'Violet',
  'Bright red',
  'Silver',
  'Rainbow',
  'Ombre',
  'Balayage',
  'Light tips',
  'Grey',
  'Partial grey',
]

const hairColorsLabel = [
  'BLACK',
  'DARK_BROWN',
  'LIGHT_BROWN',
  'CHESTNUT',
  'BLOND',
  'ASH_BLONDE',
  'RED',
  'LIGHT_BLOND',
  'DARK_BLOND',
  'PINK',
  'BLUE',
  'GREEN',
  'VIOLET',
  'BRIGHT_RED',
  'SILVER',
  'RAINBOW',
  'OMBRE',
  'BALAYAGE',
  'LIGHT_TIPS',
  'GREY',
  'PARTIAL_GREY',
]

const optionsHairColor = hairColorsValue.map((label, index) => ({
  label: label,
  value: hairColorsLabel[index],
}))

const sexualOrientationsValue = ['Bisexual', 'Hetero', 'Lesbian']
const sexualOrientationsLabel = ['BISEXUAL', 'HETERO', 'LESBIAN']
const optionsSexualOrientation = sexualOrientationsValue.map((label, index) => ({
  label: label,
  value: sexualOrientationsLabel[index],
}))

const bodyTypesValue = ['Slim', 'Average', 'Big']
const bodyTypesLabel = ['SLIM', 'AVERAGE', 'BIG']
const optionsBodyType = bodyTypesValue.map((label, index) => ({
  label: label,
  value: bodyTypesLabel[index],
}))

const hairLengthsValue = ['Short', 'Medium', 'Long', 'Very long']
const hairLengthsLabel = ['SHORT', 'MEDIUM', 'LONG', 'VERY_LONG']
const optionsHairLength = hairLengthsValue.map((label, index) => ({
  label: label,
  value: hairLengthsLabel[index],
}))

// языки
// const languageLengths = ["uk", "ru", "en", "es"];
const optionsLanguage = languages.flatMap(region =>
  region.languages.map(language => ({
    code: language.code,
    name: language.name,
    backendName: language.backendName,
    value: language.backendName, // Add this line to ensure the value is set
    label: language.name, // Add this line to ensure the label is set
  })),
)
export {
  optionsBirthYear,
  optionsWeight,
  optionsHeight,
  optionsChest,
  optionsWaist,
  optionsHips,
  optionsBreastSize,
  optionsHairColor,
  optionsSexualOrientation,
  optionsBodyType,
  optionsHairLength,
  optionsLanguage,
}
