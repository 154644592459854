import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { FixedSizeList as List } from 'react-window'
import {
  PrivateChatViewResponse,
  RelationshipGetFullAllApiArg,
  useRelationshipGetFullAllQuery,
  UserGetAllApiArg,
  useStaffGetAllQuery,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import { addMatchingContent } from 'store/reducer/messages/reducer'
import {
  getSelectorMessagesSelectedId,
  getSelectorSetMessagePrivetChats,
  selectorAdminSelectedChatType,
  selectorChangeFullPrivateChats,
} from 'store/reducer/messages/selectors'
import { selectRole } from 'store/reducer/token/selector'
import { getSubscribeUser } from 'store/reducer/socket/selector'
import MessagesItemAdmin from './MessagesItem/MessagesItemAdmin'
import { selectorUpdateFriends } from 'store/reducer/friends/selectors'
import { setStaffs } from 'store/reducer/user/reducer'
import { findMatchingContent } from '../func/findMatchingContent'
import { selectClients, selectModels } from 'store/reducer/user/selectors'
import useFetchClientData from 'hooks/userGet/useFetchClientData'
import useFetchModelData from 'hooks/userGet/useFetchModelData'
import MessagesItem from './MessagesItem/MessagesItem'
import NoResults from 'UI/NoResults/NoResults'

interface IProps {
  searchName: string
  filterOnline: boolean
  filterFavorites: boolean
}

const searchOppositeRoleObject = ({
  dataPrivateChats,
  dataModel,
  dataClient,
  dataStaff,
  selectedChatType,
  role,
  dispatch,
  processedChatsRef,
}: {
  dataPrivateChats: any
  dataModel: any
  dataClient: any
  dataStaff: any
  selectedChatType: any
  role: any
  dispatch: any
  processedChatsRef: React.MutableRefObject<Set<string>>
}) => {
  if (!dataPrivateChats || (!dataModel && !dataClient && !dataStaff)) return
  for (const e of dataPrivateChats.content) {
    // Обрабатываем только новые чаты
    const { matchingClientContent, matchingModelContent, matchingStaffContent } = findMatchingContent(
      e,
      dataClient,
      dataModel,
      dataStaff,
      selectedChatType,
      role,
    )
    const hasNewContent = matchingClientContent || matchingModelContent || matchingStaffContent
    if (hasNewContent) {
      processedChatsRef.current.add(e.privateChat.id)
      if (matchingStaffContent) {
        dispatch(addMatchingContent({ matchingContent: matchingStaffContent, privateChat: e.privateChat }))
      }
      if (matchingClientContent) {
        dispatch(addMatchingContent({ matchingContent: matchingClientContent, privateChat: e.privateChat }))
      }
      if (matchingModelContent) {
        dispatch(addMatchingContent({ matchingContent: matchingModelContent, privateChat: e.privateChat }))
      }
    }
  }
}

const Row = React.memo(
  ({
    index,
    style,
    data,
  }: {
    index: number
    style: React.CSSProperties
    data: {
      filteredChats: PrivateChatViewResponse[]
      role: string
      userMe: any
      searchName: string
      filterOnline: boolean
      filterFavorites: boolean
      subscribeUser: any
      dataFriends: any
      selectedChatType: any
      dataClient: any
      dataModel: any
      dataStaff: any
    }
  }) => {
    const chat = data.filteredChats[index]

    return (
      <div style={style}>
        <MessagesItem
          chat={chat}
          role={data.role}
          userMe={data.userMe}
          searchName={data.searchName}
          // filterOnline={data.filterOnline}
          // filterFavorites={data.filterFavorites}
          subscribeUser={data.subscribeUser}
          dataFriends={data.dataFriends}
          selectedChatType={data.selectedChatType}
          dataClient={data.dataClient}
          dataModel={data.dataModel}
          dataStaff={data.dataStaff}
        />
      </div>
    )
  },
)

const MessagesItems: React.FC<IProps> = ({ searchName, filterOnline, filterFavorites }) => {
  const dispatch = useDispatch()
  const { data: userMe } = useUserGetFullMeQuery()
  const role = useSelector(selectRole, shallowEqual)
  const subscribeUser = useSelector(getSubscribeUser, shallowEqual)
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId, shallowEqual)
  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const selectedChatType = useSelector(selectorAdminSelectedChatType, shallowEqual)
  const updateFriends = useSelector(selectorUpdateFriends, shallowEqual)
  const changeFullPrivateChats = useSelector(selectorChangeFullPrivateChats, shallowEqual)
  const [payloadFavorite] = useState<RelationshipGetFullAllApiArg>({
    // @ts-ignore
    userId: (userMe as any)?.[role.toLowerCase()]?.id,
    isFavorite: true,
  })

  const { data: dataFriends, refetch: dataFriendsRefetch } = useRelationshipGetFullAllQuery({ ...payloadFavorite }, { skip: role === 'STAFF' })

  useEffect(() => {
    if (role !== 'STAFF') {
      dataFriendsRefetch()
    }
  }, [updateFriends, role, dataFriendsRefetch])

  const payload: UserGetAllApiArg = {
    ids: [],
  }

  useFetchClientData(userMe)
  useFetchModelData()
  const dataClient = useSelector(selectClients)
  const dataModel = useSelector(selectModels)
  const { data: dataStaff } = useStaffGetAllQuery({ ...payload })

  useEffect(() => {
    if (dataStaff) {
      dispatch(setStaffs(dataStaff))
    }
  }, [dataStaff, dispatch])

  const processedChatsRef = useRef<Set<string>>(new Set())
  useEffect(() => {
    searchOppositeRoleObject({
      dataPrivateChats,
      dataModel,
      dataClient,
      dataStaff,
      selectedChatType,
      role,
      dispatch,
      processedChatsRef,
    })
  }, [dataModel, dataClient, dataStaff, dataPrivateChats?.content?.length, dispatch, role, selectedChatType])

  const filteredChats = useMemo(() => {
    if (!dataPrivateChats) return []
    return dataPrivateChats.content.filter((e: PrivateChatViewResponse) => {
      // Basic filtering
      if (e.privateChat.approvedByStaff) {
        // Approved by staff passes the basic check
      } else if (role === 'CLIENT' && e.privateChat.approvedByModel === true && e.privateChat.approvedByClient === false) {
        return false
      } else if (role === 'MODEL' && e.privateChat.approvedByModel === true && e.privateChat.approvedByClient === false) {
        return false
      } else if (
        role === 'MODEL' &&
        e.privateChat.approvedByModel === false &&
        e.privateChat.approvedByClient === true &&
        e.privateChat.clientAttractAttentionCount === 0
      ) {
        return false
      }
      // @ts-ignore
      const clientName = e.privateChat?.matchingContent?.client?.name?.toLowerCase() || ''
      // @ts-ignore
      const modelName = e.privateChat?.matchingContent?.model?.name?.toLowerCase() || ''
      // @ts-ignore
      const staffName = e.privateChat?.matchingContent?.staff?.name?.toLowerCase() || ''

      const nameMatch =
        clientName.includes(searchName.toLowerCase()) || modelName.includes(searchName.toLowerCase()) || staffName.includes(searchName.toLowerCase())

      if (!nameMatch) return false

      // Online filtering
      // @ts-ignore
      const userId = e.privateChat?.matchingContent?.user?.id
      const onlineStatus = subscribeUser?.body?.find((user: any) => user.userId === userId)?.activityStatus ?? 'noStatus'
      const isOnline = ['ONLINE', 'AWAY', 'ON_CALL', 'WAITING_IN_ROOM'].includes(onlineStatus)
      if (filterOnline && !isOnline) return false

      // Favorites filtering
      const isUserInFavorites = dataFriends?.content?.some((item: any) => {
        return role === 'MODEL'
          ? item.relationship.isModelFavorite && item.client.user.id === userId
          : item.relationship.isClientFavorite && item.model.user.id === userId
      })
      if (filterFavorites && !isUserInFavorites) return false

      // Attract attention logic
      let booleanAttractAttention = true
      if (role === 'MODEL' && e.privateChat?.clientAttractAttentionCount > 0) {
        booleanAttractAttention = !e.privateChat?.approvedByModel && e.privateChat?.clientAttractAttentionCount > 0
      }
      if (!booleanAttractAttention) return false

      return true
    })
  }, [dataPrivateChats, role, searchName, subscribeUser, filterOnline, filterFavorites, dataFriends])
  const itemData = useMemo(
    () => ({
      filteredChats,
      role,
      userMe,
      searchName,
      filterOnline,
      filterFavorites,
      subscribeUser,
      dataFriends,
      selectedChatType,
      dataClient,
      dataModel,
      dataStaff,
    }),
    [
      filteredChats,
      role,
      userMe,
      searchName,
      filterOnline,
      filterFavorites,
      subscribeUser,
      dataFriends,
      selectedChatType,
      dataClient,
      dataModel,
      dataStaff,
    ],
  )

  const supportChat = dataPrivateChats?.content.find(e => e.privateChat.type === 'SUPPORT')
  const [sidebarHeight, setSidebarHeight] = useState<number>(0)

  useEffect(() => {
    const updateHeight = () => {
      const sidebar = document.querySelector('.sidebar__content')
      if (sidebar instanceof HTMLElement) {
        // Проверяем, что sidebar - HTMLElement
        setSidebarHeight(sidebar.offsetHeight)
      } else {
        console.warn('Элемент с классом .sidebar__content не является HTMLElement')
      }
    }

    // Получаем высоту при монтировании компонента
    updateHeight()

    // Обновляем высоту при изменении размера окна, если необходимо
    window.addEventListener('resize', updateHeight)

    // Очищаем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])
  return (
    <div className='messages-items'>
      {supportChat === undefined && searchName === '' && !filterFavorites ? <MessagesItemAdmin /> : null}
      {filteredChats.length > 0 ? (
        <List height={sidebarHeight - 95 - 48 - 32} itemCount={filteredChats.length} itemSize={73} width='100%' itemData={itemData}>
          {Row}
        </List>
      ) : (
        <>{searchName === '' && filterOnline === false && filterFavorites === false ? null : <NoResults />}</>
      )}
    </div>
  )
}

export default MessagesItems
