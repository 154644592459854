import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LocalUserChoices, PreJoin, useLiveKitRoom } from '@livekit/components-react'
import ActiveRoom from '../ActiveRoom/ActiveRoom'
import '@livekit/components-styles'
import '@livekit/components-styles/prefabs'
import {
  CreateRoom1ApiArg,
  RoomResponse,
  useCreateRoom1Mutation,
  useGetRoomQuery,
  useSearchQuery,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import './../../styles/custom.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import { resetVideoChatExceptRoomCreated, resetVideoChatState, setVideoChat } from 'store/reducer/videoChat/reducer'
import { selectVideoChatCreatedByModelBoolean, selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { deleteRoom, resetSetRooms, setRoom, setRooms } from 'store/reducer/rooms/reducer'
import { sendActivityUpdate } from '../../../../../socket/websocketActions'
import useWebSocket from '../../../../../socket/useWebSocket'
import { useServerUrl } from '../../lib/client-utils'
import NoRoom from '../NoRoom/NoRoom'
import { selectorRooms } from 'store/reducer/rooms/selector'
import { toastInfo } from '../../../../../elements/Notification/Notification'
import { selectTheme } from 'store/reducer/theme/selector'
import { useTranslation } from 'react-i18next'
interface IProps {
  secured?: string
}

const JoinRoomA: FC<IProps> = () => {
  const navigate = useNavigate()
  const { roomId } = useParams()
  const dispatch = useDispatch()
  const [preJoinChoices, setPreJoinChoices] = useState<LocalUserChoices | undefined>(undefined)
  const { data: userMe } = useUserGetFullMeQuery()
  const [preJoinDefaults, setPreJoinDefaults] = useState({
    username: '',
    videoEnabled: true,
    audioEnabled: true,
  })
  const { sendActivityUpdate } = useWebSocket()
  const role = useSelector(selectRole)
  const videoChatObg = useSelector(selectVideoChatObg)
  const [getJoinRoom] = useCreateRoom1Mutation()
  const [isRoomJoined, setIsRoomJoined] = useState(false)
  useEffect(() => {
    setPreJoinDefaults(prevDefaults => {
      let newUsername
      switch (role) {
        case 'CLIENT':
          newUsername = userMe?.client?.name
          break
        case 'MODEL':
          newUsername = userMe?.model?.name
          break
        case 'STAFF':
          newUsername = 'www'
          break
        default:
          newUsername = ''
          break
      }
      return {
        ...prevDefaults,
        username: newUsername,
      }
    })
  }, [userMe, role])
  // тут это нужно для того чтобы блокировать заход в комнату для модели которая создала команта и пересоздала ее и за лива
  const videoChatCreatedByModelBoolean = useSelector(selectVideoChatCreatedByModelBoolean)
  useEffect(() => {
    if (!isRoomJoined && videoChatObg === null && preJoinDefaults.username && roomId && !videoChatCreatedByModelBoolean) {
      const fetchToken = async () => {
        const payload: CreateRoom1ApiArg = {
          roomId: roomId,
          ...(role === 'STAFF' && { secured: true }),
        }
        try {
          const result = await getJoinRoom(payload).unwrap()
          dispatch(setVideoChat(result))
          setIsRoomJoined(true)
        } catch (error) {
          navigate('/')
          console.error('Error joining room:', error)
        }
      }
      fetchToken()
    }
  }, [isRoomJoined, videoChatObg, roomId, preJoinDefaults.username, getJoinRoom, dispatch])

  const handlePreJoinSubmit = useCallback((values: LocalUserChoices) => {
    setPreJoinChoices(values)
  }, [])
  useEffect(() => {
    setPreJoinChoices({
      videoEnabled: true,
      audioEnabled: true,
      videoDeviceId: '',
      audioDeviceId: '',
      username: 'www',
    })
  }, [])
  const onPreJoinError = useCallback((e: any) => {
    console.error(e)
  }, [])
  const liveKitUrl = useServerUrl(undefined)

  const roomOptions = {
    // Define your room options here
    // Add other necessary options
  }

  const { room } = useLiveKitRoom({
    serverUrl: liveKitUrl,
    token: videoChatObg?.token?.token,
  })
  console.log(room?.state)
  const onLeave = useCallback(async () => {
    if (room) {
      await room.disconnect()
    }
    if (roomId) {
      navigate('/')
      setTimeout(() => {
        dispatch(resetVideoChatExceptRoomCreated())
        dispatch(deleteRoom(roomId))
        sendActivityUpdate('ONLINE')
      }, 300) // добавляем setTimiuoy чтобы не выполнять действия при обновление страницы или когда у челвоек выключили свет и может быть даже интренет
    }
  }, [navigate, room])

  const { data: dataRoom, isLoading, error } = useGetRoomQuery({ roomId: videoChatObg?.room?.id! }, { skip: !videoChatObg?.room?.id })
  const { t } = useTranslation()

  useEffect(() => {
    if (dataRoom) {
      if (dataRoom?.isActive) {
        dispatch(setRoom(dataRoom))
      } else {
        dispatch(deleteRoom(dataRoom.id))
      }
    }
  }, [videoChatObg?.room?.id, dataRoom])
  const rooms = useSelector(selectorRooms) as RoomResponse[]
  useEffect(() => {
    // Храним идентификатор таймера, чтобы потом его очистить
    let checkTimer: ReturnType<typeof setTimeout> | undefined

    // Если есть roomId или уже есть какая-то room в videoChatObj
    if ((videoChatObg?.room?.id || roomId) && room) {
      // Ставим таймер на полсекунды
      checkTimer = setTimeout(() => {
        // По прошествии 500 мс проверяем актуальный список rooms
        const roomExists = rooms.some(r => r.id === videoChatObg?.room?.id)

        if (roomExists) {
          // Если комната существует — ничего не делаем
          return
        } else {
          // Если комнаты нет — делаем «отключение» и очищаем состояние
          if (room) {
            room.disconnect()
            toastInfo(t('components.global.videoChat.components.joinRoomA.room_not_exists'))
          }
          if (roomId) {
            navigate('/')
            setTimeout(() => {
              dispatch(resetVideoChatExceptRoomCreated())
              dispatch(deleteRoom(roomId))
              sendActivityUpdate('ONLINE')
            }, 300)
          }
        }
      }, 1200)
    }

    // При размонтировании или при изменении зависимостей
    // сбрасываем предыдущий таймер, чтобы не было «висячих» вызовов
    return () => {
      if (checkTimer) {
        clearTimeout(checkTimer)
      }
    }
  }, [rooms, videoChatObg?.room?.id, roomId, room])
  const currentTheme = useSelector(selectTheme)
  console.log(currentTheme)
  return (
    <>
      <div className={`videoChat ${currentTheme}`}>
        <main style={{ height: '100%' }} data-lk-theme='default'>
          {preJoinDefaults.username === '' ? null : (
            <>
              {preJoinChoices && videoChatObg?.token?.token ? (
                <ActiveRoom roomName={videoChatObg?.token?.token} userChoices={preJoinChoices} onLeave={onLeave} />
              ) : (
                <NoRoom />
              )}
            </>
          )}
        </main>
      </div>
    </>
  )
}

export default JoinRoomA
