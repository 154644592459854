import { useEffect, useState } from 'react'
import { ClientGetAllApiArg, ClientViewDto, useClientGetAllQuery, useClientGetFullByIdQuery } from 'store/apiMain/mainApi'
import Item from './Item/Item'
import Filter from './Filter/Filter'
import { ActiveFilters } from './Filter/types'
import { useDispatch, useSelector } from 'react-redux'
import { getSocketIsConnected, getSubscribeUser } from '../../../store/reducer/socket/selector'
import useWebSocket from '../../../socket/useWebSocket'
import { setClients } from 'store/reducer/clients/reducer'
import { selectClients } from 'store/reducer/clients/selectors'

import { selectorUpdateFriends } from '../../../store/reducer/friends/selectors'
import useSubscribeToNewUsers from '../../../hooks/socketTs/useSubscribeToNewUsers'
import { useTranslation } from 'react-i18next'
const People = () => {
  const [payload, setPayload] = useState<ClientGetAllApiArg>({
    isHaveRelationshipWithCurrentModel: undefined,
    relationshipStatuses: undefined,
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: undefined,
    ids: undefined,
    page: undefined,
    pageSize: 9999,
  })
  const subscribeUser = useSelector(getSubscribeUser)
  const dispatch = useDispatch()

  const socketIsConnected = useSelector(getSocketIsConnected)
  const { data: dataClients, refetch } = useClientGetAllQuery({ ...payload }, { skip: !socketIsConnected })
  const clients = useSelector(selectClients).clients

  useEffect(() => {
    if (dataClients) {
      dispatch(setClients(dataClients))
    }
  }, [dataClients])

  const [idGirls, setIdGirls] = useState<string[]>([]) // Specify the state as an array of strings
  const { t } = useTranslation()
  useEffect(() => {
    if (clients) {
      const userIds = clients.content
        .map(e => e.user?.id) // Use optional chaining to safely access user.id
        .filter((id): id is string => !!id) // Filter out undefined values
      setIdGirls(userIds)
    }
  }, [clients]) // Depend on data

  const { sendSubscribe } = useWebSocket()
  const subscribedUserIds = subscribeUser?.body?.map(user => user.userId) || []
  const userId = idGirls.filter(id => !subscribedUserIds.includes(id))

  useSubscribeToNewUsers({
    userId,
    sendSubscribe,
    subscribeUser,
  })

  useEffect(() => {
    if (socketIsConnected) {
      refetch().catch(console.error)
    }
  }, [socketIsConnected])

  const filterUpdateData = (filter: ActiveFilters) => {
    // Объект для определения состояния онлайна
    const onlineState = {
      true: true, // Если выбран только фильтр "онлайн"
      false: false, // Если выбран только фильтр "оффлайн"
      both: undefined, // Если выбраны оба или ни одного
    }

    // Определение состояния онлайна на основе активности фильтров
    // @ts-ignore
    const isOnline = onlineState[filter.online.every(filter => filter.active) ? 'both' : filter.online[0].active.toString()]

    // Определение, нужно ли иметь отношения с текущей моделью, и статусы отношений
    let isHaveRelationshipWithCurrentModel: boolean | undefined, relationshipStatuses: string[] | undefined

    if (filter.friends.every(filter => filter.active) || filter.friends.every(filter => !filter.active)) {
      isHaveRelationshipWithCurrentModel = undefined
      relationshipStatuses = undefined
    } else {
      isHaveRelationshipWithCurrentModel = filter.friends[0].active
      relationshipStatuses = ['FRIEND_REQUEST', 'FRIENDS']
    }

    // Обновление состояния с новыми значениями
    // @ts-ignore
    setPayload(prevPayload => ({
      ...prevPayload,
      isOnline,
      isHaveRelationshipWithCurrentModel,
      relationshipStatuses,
    }))
  }

  return (
    <div className='people'>
      <div className='people-filter'>
        <Filter filterUpdateData={filterUpdateData} />
      </div>
      <div className='people__block'>
        <div className='people__box'>
          {clients?.content?.length === 0 ? (
            <div>{t('components.model.people.people.no_search_results')}</div>
          ) : (
            clients?.content?.map((e: ClientViewDto) => {
              if (e?.relationshipWithCurrentUser?.initiatedBy === 'CLIENT' && e?.relationshipWithCurrentUser?.status === 'BLOCKED') return null
              const onlineStatus = subscribeUser?.body?.find(model => model.userId === e?.client?.id)?.activityStatus ?? 'noStatus'
              return <Item key={e?.client?.id} item={e} onlineStatus={onlineStatus} />
            })
          )}
        </div>
      </div>
    </div>
  )
}

export default People
