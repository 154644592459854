import { useCreateRoomMutation } from 'store/apiMain/mainApi'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setVideoChat, setLastRedirectedRoomId } from 'store/reducer/videoChat/reducer'
import { useNavigate } from 'react-router-dom'
import { selectVideoChatObg, selectLastRedirectedRoomId } from 'store/reducer/videoChat/selector'
import { selectRole } from 'store/reducer/token/selector'
import { popUpOpen } from 'store/reducer/popUp/reducer'

export function useCreateRoom() {
  const dispatch = useDispatch()
  const [createRoom, { isLoading, isError, isSuccess, error }] = useCreateRoomMutation()
  const navigate = useNavigate()

  const videoChatId = useSelector(selectVideoChatObg)?.room?.id
  const role = useSelector(selectRole)

  // Наш новый селектор
  const lastRedirectedRoomId = useSelector(selectLastRedirectedRoomId)

  // ref, чтобы хранить количество уже сделанных запросов
  const requestCountRef = useRef(0)

  // Функция для создания комнаты
  const handleCreateRoom = async (callerUserId?: string) => {
    try {
      if ((role === 'MODEL' || role === 'STAFF') && !isLoading) {
        // if ((role === 'MODEL' || role === 'STAFF') && !videoChatId && !isLoading) {
        requestCountRef.current += 1
        const result = await createRoom({ callerUserId }).unwrap()
        if (result) {
          dispatch(setVideoChat(result))
          dispatch(popUpOpen(''))
          console.log('Комната успешно создана:', result)
          return result
        }
      }
    } catch (err) {
      navigate(-1)
      console.error('Ошибка при создании комнаты:', err)
      throw err
    }
  }

  // Навигация при изменении videoChatId, но только если он НЕ совпадает с lastRedirectedRoomId
  useEffect(() => {
    if (videoChatId && role === 'MODEL') {
      // Сравниваем videoChatId с lastRedirectedRoomId
      if (lastRedirectedRoomId !== videoChatId) {
        // Запоминаем этот roomId в Redux, чтобы при следующей смене состояния не было повторного navigate
        dispatch(setLastRedirectedRoomId(videoChatId))
        navigate(`/room/${videoChatId}`)
      }
    }
  }, [videoChatId, role, navigate, dispatch, lastRedirectedRoomId])

  // Возвращаем функцию и состояния
  return { handleCreateRoom, isLoading, isError, isSuccess, error }
}
