import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket from '../useWebSocket'
import { setSocketConnectionsTimer } from 'store/reducer/socket/reducer'
import { popUpOpen } from 'store/reducer/popUp/reducer'
import { getSocketConnectionTimer, getSocketIsConnected } from 'store/reducer/socket/selector'

function Heartbeat() {
  const dispatch = useDispatch()
  const isConnected = useSelector(getSocketIsConnected)
  const connectionTimer = useSelector(getSocketConnectionTimer)

  const { sendHeartbeat } = useWebSocket()

  // Эффект для отправки Heartbeat и проверки ответа
  useEffect(() => {
    if (!isConnected) return

    // Интервал каждые 2 секунды
    const intervalId = setInterval(() => {
      // 1) Ставим флаг, что ждём ответ
      dispatch(setSocketConnectionsTimer(true))

      // Отправляем Heartbeat
      sendHeartbeat()

      // 2) Запускаем таймер на 1 секунду
      const timeoutId = setTimeout(() => {
        // Если флаг всё ещё true – ответа не дождались
        if (connectionTimer) {
          dispatch(popUpOpen('errorSocket'))
        }
      }, 1000)

      // При следующем повторе/размонтировании нужно очистить таймер
      return () => clearTimeout(timeoutId)
    }, 15000)

    return () => clearInterval(intervalId)
  }, [isConnected, connectionTimer, sendHeartbeat, dispatch])

  return null
}

export default Heartbeat
