import React, { useEffect } from 'react'
import { UserDeleteByIdApiArg, useUserDeleteByIdMutation } from 'store/apiMain/mainApi'
import { useNavigate } from 'react-router-dom'
import { toastSuccess } from 'elements/Notification/Notification'
import { useTranslation } from 'react-i18next' // Import useTranslation

interface DeleteProps {
  idDelete: string
  role: 'client' | 'admin' | 'model'
  refetch?: () => void
}

const Delete: React.FC<DeleteProps> = ({ idDelete, refetch, role }) => {
  const navigate = useNavigate()
  const [deleteUser, { isSuccess: isSuccessDelete }] = useUserDeleteByIdMutation()
  const payload: UserDeleteByIdApiArg = {
    userId: idDelete,
  }
  const { t } = useTranslation()
  useEffect(() => {
    if (isSuccessDelete) {
      if (role === 'admin') {
        navigate('/admin/models')
      }
      if (role === 'client') {
        navigate('/login')
      }
      if (role === 'model') {
        navigate('/model/registration')
      }
      toastSuccess(t('components.global.delete.successMessage')) // Use translation for success message
      if (refetch) {
        refetch()
      }
    }
  }, [isSuccessDelete])

  const handleDelete = () => {
    const isConfirmed = window.confirm(t('components.global.delete.buttonLabel')) // Use translation for confirmation message
    if (isConfirmed) {
      deleteUser(payload)
    }
  }

  return (
    <button className='delete' onClick={handleDelete} type='submit'>
      {t('components.global.delete.buttonLabel')}
    </button>
  )
}

export default Delete
