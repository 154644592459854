import React, { useEffect, useState } from 'react'
import './scss/index.scss'
import { SvgDiceMini, SvgRouletteDice } from '../../../../images/svg'
import { useRoomGetRandomWaitingQuery } from 'store/apiMain/mainApi'
import { useNavigate } from 'react-router-dom'
import { toastInfo } from '../../../../elements/Notification/Notification'
import { useTranslation } from 'react-i18next'

const ButtonRoulette = () => {
  const { t } = useTranslation()

  const [startQuery, setStartQuery] = useState(false)
  const navigate = useNavigate()
  const { data: randomRoom, refetch, isLoading, isError, error } = useRoomGetRandomWaitingQuery(undefined, { skip: !startQuery })
  console.log(error)
  useEffect(() => {
    if (isError) {
      // @ts-ignore
      if (error?.data?.type === 'ROOM_WAITING_NOT_FOUND') {
        toastInfo(t('components.user.roulette.buttonRoulette.no_rooms_available'))
      }
    }
  }, [isError])
  useEffect(() => {
    // @ts-ignore
    if (!isLoading && randomRoom?.roomId) {
      navigate(`/room/${randomRoom.roomId}`)
    }
  }, [isLoading])
  const handleClick = () => {
    if (!startQuery) {
      setStartQuery(true)
    } else {
      refetch()
    }
  }

  return (
    <div className='button-roulette'>
      <div className='button-roulette__block'>
        <div className='button-roulette__image'>
          <SvgRouletteDice />
        </div>
        <div className='button-roulette__text'>
          <h2 className='button-roulette__title'>{t('components.user.roulette.buttonRoulette.Chat_roulette')}</h2>
          <p className='button-roulette__description'>{t('components.user.roulette.buttonRoulette.chat_roulette_description')}</p>
          <button onClick={handleClick} className='button-roulette__wave-button'>
            <SvgDiceMini /> <p>{t('components.user.roulette.buttonRoulette.start_video_chat')}</p>
            <span className='button-roulette__wave'></span>
            <span className='button-roulette__wave'></span>
            <span className='button-roulette__wave'></span>
            <span className='button-roulette__wave'></span>
            <span className='button-roulette__wave'></span>
            <span className='button-roulette__wave'></span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ButtonRoulette
