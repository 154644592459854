import React, { FC, useEffect } from 'react'
import Popup from 'reactjs-popup'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { useSelector } from 'react-redux'
import { selectCameraState, selectMicrophoneState } from 'store/reducer/permissionMonitor/selectors'
import { SvgFinger, SvgMicrophoneOff, SvgVideoOff } from '../../../../images/svg'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import { useTranslation } from 'react-i18next'

type PermissionState = 'granted' | 'denied' | 'prompt'

const MediaPermissionMonitorPopUp: FC<IPopUp> = ({ open, closeModal }) => {
  const cameraState = useSelector(selectCameraState) as PermissionState
  const microphoneState = useSelector(selectMicrophoneState) as PermissionState
  const { t } = useTranslation()

  useEffect(() => {
    if (open) {
      if (cameraState === 'granted' && microphoneState === 'granted') {
        toastSuccess(t('ui.popUp.videoPopUp.mediaPermissionMonitorPopUp.call_permission_granted'))
        closeModal()
      }
    }
  }, [cameraState, microphoneState, open, closeModal])

  const missingPermissions: string[] = []
  if (cameraState !== 'granted') missingPermissions.push('camera')
  if (microphoneState !== 'granted') missingPermissions.push('microphone')

  const headerText =
    missingPermissions.length === 1
      ? t('ui.popUp.videoPopUp.mediaPermissionMonitorPopUp.missing_permission_single')
      : t('ui.popUp.videoPopUp.mediaPermissionMonitorPopUp.missing_permission_multiple')

  return (
    <Popup open={open} closeOnDocumentClick={false} closeOnEscape={false} modal>
      <div className='modal'>
        <div className='popup'>
          <div className='MediaPermissionMonitorPopUp'>
            <div className='MediaPermissionMonitorPopUp_help'>
              <SvgFinger />
            </div>
            <div className='MediaPermissionMonitorPopUp-header'>
              <div className='MediaPermissionMonitorPopUp-header_icons'>
                {microphoneState !== 'granted' && (
                  <div className='MediaPermissionMonitorPopUp-header_icons-microphone'>
                    <SvgMicrophoneOff />
                  </div>
                )}
                {cameraState !== 'granted' && (
                  <div className='MediaPermissionMonitorPopUp-header_icons-camera'>
                    <SvgVideoOff />
                  </div>
                )}
              </div>
              <h1>{headerText}</h1>
            </div>
            <div className='MediaPermissionMonitorPopUp-content'>
              <ol>
                <li>{t('ui.popUp.videoPopUp.mediaPermissionMonitorPopUp.camera_mic_access')}</li>
                <li>{t('ui.popUp.videoPopUp.mediaPermissionMonitorPopUp.troubleshoot_camera_mic')}</li>
              </ol>
            </div>
            <div className='MediaPermissionMonitorPopUp-footer'>
              <button className='try-again-button' onClick={closeModal}>
                {t('ui.popUp.videoPopUp.mediaPermissionMonitorPopUp.try_again')}{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default MediaPermissionMonitorPopUp
