import React, { useEffect, useState } from 'react'
import '../scss/index.scss'
import {
  ClientViewDto,
  RelationshipCreateApiArg,
  RelationshipDeleteByIdApiArg,
  RelationshipPartialUpdateByIdApiArg,
  useRelationshipCreateMutation,
  useRelationshipDeleteByIdMutation,
  useRelationshipPartialUpdateByIdMutation,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import { photoViewUser } from '../../../../UI/PhotoViewUser/PhotoViewUser'
import Status from '../../../../UI/Status/Status'
import { SvgAddButton, SvgBlock, SvgDelete, SvgMinus, SvgSendButton, SvgWhiteBlock } from 'images/svg'
import { sendMessages } from 'store/reducer/messages/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectUserMe } from 'store/reducer/token/selector'
import { setClientsToChangeRelationship } from 'store/reducer/clients/reducer'
import { useTranslation } from 'react-i18next'
interface IProps {
  item: ClientViewDto
  onlineStatus: any
}

const Item: React.FC<IProps> = ({ item, onlineStatus }) => {
  const [addFriendsServer, { isSuccess, data }] = useRelationshipCreateMutation()
  const [friendsUpdate, { isSuccess: isSuccessUpdate }] = useRelationshipPartialUpdateByIdMutation()
  const [friendsDelete, { isSuccess: isSuccessDelete }] = useRelationshipDeleteByIdMutation()

  const [relationshipWithCurrentUser, setRelationshipWithCurrentUser] = useState(item?.relationshipWithCurrentUser)
  useEffect(() => {
    setRelationshipWithCurrentUser(item?.relationshipWithCurrentUser)
  }, [item])
  const { t } = useTranslation()
  useEffect(() => {
    if (isSuccessDelete) {
      setRelationshipWithCurrentUser({})
    }
  }, [isSuccessDelete])

  const { data: user } = useUserGetFullMeQuery()
  const addFavorites = () => {
    const payload: RelationshipPartialUpdateByIdApiArg = {
      relationshipId: String(relationshipWithCurrentUser?.id),
      relationshipPartialUpdateDto: {
        isFavorite: !relationshipWithCurrentUser?.isClientFavorite,
      },
    }
    friendsUpdate(payload)
  }
  const acceptRequest = async () => {
    const payload: RelationshipPartialUpdateByIdApiArg = {
      relationshipId: String(relationshipWithCurrentUser?.id),
      relationshipPartialUpdateDto: {
        type: 'APPROVE_FRIEND_REQUEST',
      },
    }
    const result = await friendsUpdate(payload).unwrap()
    if (result) {
      const payload = {
        operation: 'RELATIONSHIP_UPDATED',
        relationship: result,
      }

      // @ts-ignore
      dispatch(setClientsToChangeRelationship(payload))
    }
  }
  const addFriends = () => {
    const payload: RelationshipCreateApiArg = {
      relationshipCreateDto: {
        clientId: String(item?.client?.id),
        modelId: user?.model?.id,
        type: 'FRIEND_REQUEST',
        initiatedBy: 'MODEL',
      },
    }
    addFriendsServer(payload)
  }
  const addDelete = () => {
    const payloadButton: RelationshipDeleteByIdApiArg = {
      // @ts-ignore
      relationshipId: relationshipWithCurrentUser?.id,
    }
    friendsDelete(payloadButton)
  }

  useEffect(() => {
    if (isSuccess) {
      toastSuccess(t('components.model.people.item.friendRequestSent'))
      // refetch()
    }
  }, [isSuccess, t])
  useEffect(() => {
    if (isSuccessUpdate) {
      toastSuccess(
        t(
          relationshipWithCurrentUser?.isClientFavorite
            ? t('components.model.people.item.removedFromFavorites')
            : t('components.model.people.item.addedToFavorites'),
        ),
      )
      // refetch()
    }
  }, [isSuccessUpdate])

  useEffect(() => {
    if (isSuccessDelete) {
      toastSuccess(t('components.model.people.item.deleted'))
    }
  }, [isSuccessDelete, t])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userMe = useSelector(selectUserMe)
  const buttonState = (status: 'FRIEND_REQUEST' | 'FRIENDS' | 'BLOCKED' | undefined, initiatedBy: 'MODEL' | 'CLIENT' | 'STAFF' | undefined) => {
    if (status === 'FRIENDS') {
      return (
        <>
          <button
            onClick={() => {
              if (item.relationshipWithCurrentUser?.clientId && item.relationshipWithCurrentUser?.modelId) {
                const obg = {
                  recipientId: item.relationshipWithCurrentUser?.clientId,
                  senderId: item.relationshipWithCurrentUser?.modelId,
                }
                dispatch(sendMessages(obg))
                navigate('/messages')
              }
            }}
          >
            <span>{t('components.model.people.item.sendMessage')}</span>
            <SvgSendButton />
          </button>
          <button onClick={addDelete}>
            <span>{t('components.model.people.item.Remove')}</span>
            <SvgDelete />
          </button>
        </>
      )
    }
    if (status === 'FRIEND_REQUEST' && relationshipWithCurrentUser?.initiatedBy === 'CLIENT') {
      return (
        <>
          <button onClick={acceptRequest}>
            <span>{t('components.model.people.item.Accept')}</span>
            <SvgAddButton />
          </button>
          <button onClick={addDelete}>
            <span>{t('components.model.people.item.Reject')}</span>
            <SvgWhiteBlock />
          </button>
        </>
      )
    }
    if (status === 'FRIEND_REQUEST' && relationshipWithCurrentUser?.initiatedBy === 'MODEL') {
      return (
        <>
          <button onClick={addDelete}>
            <span>{t('components.model.people.item.Cancel')}</span>
            <SvgMinus />
          </button>
        </>
      )
    }
    if (status === 'BLOCKED') {
      return (
        <>
          {relationshipWithCurrentUser?.initiatedBy === 'MODEL' ? (
            <button
              onClick={event => {
                event.stopPropagation()
                addDelete()
              }}
            >
              <span>{t('components.model.people.item.unblock')}</span>
              <SvgWhiteBlock />
            </button>
          ) : (
            <button style={{ cursor: 'not-allowed' }} type={'button'}>
              <span style={{ cursor: 'not-allowed' }}>{t('components.model.people.item.block')}</span>
              <SvgWhiteBlock />
            </button>
          )}
        </>
      )
    }
    if (status === undefined) {
      return (
        <button onClick={addFriends}>
          <span>{t('components.model.people.item.add_friend')}</span>
          <SvgAddButton />
        </button>
      )
    }
  }

  function convertStringRange(argument: string | undefined): string {
    switch (argument) {
      case 'EIGHTEEN__TWENTY_THREE':
        return t('components.model.people.item.From18_23')
      case 'TWENTY_FOUR__THIRTY_THREE':
        return t('components.model.people.item.From24_33')
      case 'THIRTY_FOUR__FORTY_FOUR':
        return t('components.model.people.item.From34_44')
      case 'FORTY_FIVE__MORE':
        return t('components.model.people.item.over45')
      default:
        return 'errors'
    }
  }

  return (
    <div key={item?.client?.id} className='client'>
      <div className='client__block'>
        <div className='client__photo'>
          {photoViewUser(Number(item?.client?.photoId))}
          <Status status={onlineStatus} />
        </div>
        <h2>{item?.client?.name}</h2>
        <p>{convertStringRange(item?.client?.ageRange)}</p>
        <div className='client__buttons'>{buttonState(relationshipWithCurrentUser?.status, relationshipWithCurrentUser?.initiatedBy)}</div>
      </div>
    </div>
  )
}

export default Item
