import React from 'react'
import { SvgRouletteHeart } from '../../../../images/svg'
import './scss/index.scss'

const heartsData = [
  { id: 1, top: 10, left: 15, size: 40, zIndex: 1, opacity: 0.3 },
  { id: 2, top: 20, left: 30, size: 60, zIndex: 2, opacity: 0.5 },
  { id: 3, top: 30, left: 80, size: 50, zIndex: 3, opacity: 0.7 },
  { id: 4, top: 40, left: 25, size: 90, zIndex: 4, opacity: 0.9 },
  { id: 5, top: 50, left: 50, size: 80, zIndex: 5, opacity: 0.4 },
  { id: 6, top: 60, left: 70, size: 45, zIndex: 6, opacity: 0.8 },
  { id: 7, top: 65, left: 20, size: 110, zIndex: 7, opacity: 0.6 },
  { id: 8, top: 75, left: 40, size: 70, zIndex: 8, opacity: 0.7 },
  { id: 9, top: 85, left: 60, size: 50, zIndex: 9, opacity: 0.5 },
  { id: 10, top: 90, left: 90, size: 100, zIndex: 10, opacity: 0.9 },
  { id: 11, top: 15, left: 10, size: 30, zIndex: 11, opacity: 0.6 },
  { id: 12, top: 25, left: 35, size: 65, zIndex: 12, opacity: 0.7 },
  { id: 13, top: 35, left: 75, size: 55, zIndex: 13, opacity: 0.8 },
  { id: 14, top: 45, left: 20, size: 95, zIndex: 14, opacity: 0.5 },
  { id: 15, top: 55, left: 55, size: 85, zIndex: 15, opacity: 0.9 },
  { id: 16, top: 65, left: 65, size: 40, zIndex: 16, opacity: 0.4 },
  { id: 17, top: 70, left: 25, size: 100, zIndex: 17, opacity: 0.8 },
  { id: 18, top: 80, left: 45, size: 75, zIndex: 18, opacity: 0.6 },
  { id: 19, top: 90, left: 85, size: 60, zIndex: 19, opacity: 0.7 },
  { id: 20, top: 95, left: 95, size: 120, zIndex: 20, opacity: 0.9 },
  { id: 21, top: 12, left: 18, size: 50, zIndex: 21, opacity: 0.5 },
  { id: 22, top: 22, left: 28, size: 70, zIndex: 22, opacity: 0.6 },
  { id: 23, top: 32, left: 38, size: 90, zIndex: 23, opacity: 0.7 },
  { id: 24, top: 42, left: 48, size: 100, zIndex: 24, opacity: 0.8 },
  { id: 25, top: 52, left: 58, size: 110, zIndex: 25, opacity: 0.9 },
  { id: 26, top: 62, left: 68, size: 45, zIndex: 26, opacity: 0.3 },
  { id: 27, top: 72, left: 78, size: 75, zIndex: 27, opacity: 0.6 },
  { id: 28, top: 82, left: 88, size: 95, zIndex: 28, opacity: 0.8 },
  { id: 29, top: 92, left: 98, size: 115, zIndex: 29, opacity: 0.9 },
  { id: 30, top: 100, left: 100, size: 130, zIndex: 30, opacity: 1.0 },
]

const Heart: React.FC = () => {
  return (
    <div className='heart'>
      {heartsData.map((heart, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: `${heart.top}%`,
            left: `${heart.left}%`,
            width: `${heart.size}px`,
            height: `${heart.size}px`,
            transform: 'translate(-50%, -50%)',
            zIndex: heart.zIndex,
            opacity: heart.opacity,
          }}
        >
          <SvgRouletteHeart width='100%' height='100%' />
        </div>
      ))}
    </div>
  )
}

export default Heart
