import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import SelectedAge from 'UI/SelectedAge/SelectedAge'
import { MyFormValues, Option } from './types'
import { ClientPartialUpdateByIdApiArg, useUserGetFullMeQuery, useClientPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import './scss/index.scss'
import { SvgDecorTwo, SvgGroup35 } from '../../../images/svg'
import { FormControl } from '../../../layouts-elements/FormControl/FormControl'
import { convertStringRange } from '../../../utils/ageRange'
import Email from './Email/Email'
import Password from './Password/Password'
import Tippy from '@tippyjs/react'
import EditPhotoTooltip from 'UI/Tooltip/EditPhotoTooltip/EditPhotoTooltip'
import { useNavigate } from 'react-router-dom'
import Delete from '../../Global/Delete/Delete'
import { useTranslation } from 'react-i18next'
import { photoViewUser } from '../../../UI/PhotoViewUser/PhotoViewUser'
import { getAccount } from '../../../store/reducer/accountController/selectors'
import EmailSuccess from './EmailSuccess/EmailSuccess'
import { toastSuccess } from '../../../elements/Notification/Notification'
import { popUpOpen } from 'store/reducer/popUp/reducer'
import useLogout from '../../../hooks/useLogout'

const Profile = () => {
  const { t } = useTranslation()

  const options: Option[] = [
    {
      value: 'from 18 to 23',
      label: t('components.user.profile.profile.From18_23'),
      name: 'EIGHTEEN__TWENTY_THREE',
    },
    {
      value: 'from 24 to 33',
      label: t('components.user.profile.profile.From24_33'),
      name: 'TWENTY_FOUR__THIRTY_THREE',
    },
    {
      value: 'from 34 to 44',
      label: t('components.user.profile.profile.From34_44'),
      name: 'THIRTY_FOUR__FORTY_FOUR',
    },
    { value: 'over 45', label: t('components.user.profile.profile.over45'), name: 'FORTY_FIVE__MORE' },
  ]

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [editFunc, { isSuccess, isLoading }] = useClientPartialUpdateByIdMutation()
  const { data, refetch } = useUserGetFullMeQuery()
  const ageRange = data?.client?.ageRange
  const name = data?.client?.name || ''
  const id = data?.client?.id
  const dataAmount = useSelector(getAccount)
  const logOutHook = useLogout()

  const [editCheck, setEditCheck] = useState(false)
  const optionFromAgeRange = options.find(option => option?.name === ageRange)
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(optionFromAgeRange ? [optionFromAgeRange] : [])

  useEffect(() => {
    if (!editCheck) {
      const optionFromAgeRangeUpdate = options.find(option => option?.name === ageRange)
      setSelectedOptions(optionFromAgeRangeUpdate ? [optionFromAgeRangeUpdate] : [])
    }
  }, [editCheck, ageRange])

  const handleSelect = (newSelectedOptions: Option[]) => {
    setSelectedOptions(newSelectedOptions)
  }

  const initialValues: MyFormValues = {
    name,
  }
  const validationSchema = yup.object().shape({
    name: yup.string().required('Error').min(1, 'Min 1').max(12, 'Max 12'),
  })

  useEffect(() => {
    if (isSuccess) {
      toastSuccess(t('components.user.profile.profile.password_changed'))
      refetch()
    }
  }, [isSuccess, refetch])

  const onSubmit = (values: MyFormValues) => {
    const payload: any = {
      clientId: String(id),
      clientPartialUpdateDto: {
        name: values.name,
        ageRange: selectedOptions[0].name as 'EIGHTEEN__TWENTY_THREE' | 'TWENTY_FOUR__THIRTY_THREE' | 'THIRTY_FOUR__FORTY_FOUR' | 'FORTY_FIVE__MORE',
      },
    }
    editFunc(payload)
  }

  useEffect(() => {
    if (isSuccess) {
      setEditCheck(false)
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const [visible, setVisible] = useState(false)

  function handleCloseTooltip() {
    setVisible(false)
  }

  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (!event.target.closest('.tooltip') && visible) {
        setVisible(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [visible])

  const onChangePhoto = (idPhoto: number) => {
    const payload: ClientPartialUpdateByIdApiArg = {
      clientId: String(id),
      clientPartialUpdateDto: {
        photoId: idPhoto,
      },
    }

    editFunc(payload)
  }

  return (
    <div className='profile'>
      <main className='profile__main'>
        <div className='profile__main-inner content'>
          <div className='content__img'>
            <div className='content__img___background'>{photoViewUser(data?.client?.photoId)}</div>
            <Tippy
              visible={visible}
              interactive
              content={<EditPhotoTooltip onClose={handleCloseTooltip} onChangePhoto={onChangePhoto} />}
              placement='bottom'
            >
              <div id='editPhoto' className='content__img__camera' onClick={() => setVisible(!visible)}>
                <SvgGroup35 />
              </div>
            </Tippy>
          </div>
          <div className='content__box'>
            <div className='content__box-item'>
              <h6>{t('components.user.profile.profile.personal_info')}</h6>
              {editCheck ? (
                <form onSubmit={formik.handleSubmit}>
                  <label className='name'>
                    <span> {t('components.user.profile.profile.name_label')}</span>
                    <FormControl name='name' type='text' formik={formik} />
                  </label>
                  <div className='age'>
                    <span> {t('components.user.profile.profile.age_label')}</span>
                    <SelectedAge selectedOptions={selectedOptions} handleSelect={handleSelect} />
                  </div>
                  <div className='item-buttons'>
                    <button
                      onClick={() => {
                        setEditCheck(!editCheck)
                        setSelectedOptions([])
                      }}
                      type='submit'
                    >
                      {t('components.user.profile.password.close_action')}
                    </button>
                    {!isLoading ? (
                      <button type='submit'>{t('components.user.profile.password.save')}</button>
                    ) : (
                      <button type='submit' disabled={true}>
                        {t('components.user.profile.password.loading_message')}
                      </button>
                    )}
                  </div>
                </form>
              ) : (
                <>
                  <label>
                    <span>{t('components.user.profile.profile.name_label')}</span>
                    <p>{name}</p>
                  </label>
                  <hr />
                  <label>
                    <span>{t('components.user.profile.profile.age_label')}</span>
                    <p>{convertStringRange(ageRange)}</p>
                  </label>
                  <div className='item-buttons'>
                    <button
                      onClick={() => {
                        setEditCheck(!editCheck)
                      }}
                    >
                      {t('components.user.profile.profile.edit_profile')}
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className='content__box-item'>
              <h6>{t('components.user.profile.profile.balances_label')}</h6>
              <label>
                <span>{t('components.user.profile.profile.coin_label')}</span>
                <p>{dataAmount?.coins}</p>
              </label>
              <hr />
              <label>
                <span> {t('components.user.profile.profile.messages_label')}</span>
                <p>{dataAmount?.messages}</p>
              </label>
              <div className='item-buttons'>
                <button
                  onClick={() => {
                    navigate('/transactional')
                  }}
                >
                  {t('components.user.profile.profile.transaction_history')}
                </button>
                <button
                  onClick={() => {
                    dispatch(popUpOpen('topUpBalance'))
                  }}
                >
                  {t('components.user.profile.profile.replenish_balance')}
                </button>
              </div>
            </div>
            <div className='content__box-item'>
              <h6>{t('components.user.profile.profile.camera_sound')}</h6>
              <div className='item-buttons'>
                <button
                  onClick={() => {
                    dispatch(popUpOpen('settingsVideo'))
                  }}
                >
                  {t('components.user.profile.profile.change_settings')}
                </button>
              </div>
            </div>
            {data?.userData?.user?.isEmailVerified ? <EmailSuccess /> : <Email />}
            <Password />
            <div className='content__box-item'>
              <h6>{t('components.user.profile.profile.account_label')}</h6>
              <div className='item-buttons'>
                <button onClick={logOutHook} type='submit'>
                  {t('components.user.profile.profile.logout_action')}
                </button>
                <Delete idDelete={data?.client?.id} role='client' />
              </div>
            </div>
          </div>
          <div className='profile__decor'>
            <SvgDecorTwo />
          </div>
        </div>
      </main>
    </div>
  )
}

export default Profile
