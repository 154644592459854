import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IParticipants, IVideoChatStore } from 'store/reducer/videoChat/types'
import { RoomResponse, RoomWithAccessResponse } from 'store/apiMain/mainApi'

const initialState: IVideoChatStore = {
  videoChatObg: null,
  videoChatParticipants: null,
  videoRoomCreatedByModel: null,
  infoPreviousRooms: null,
  // Новое поле для хранения последнего roomId, куда мы уже делали навигацию
  lastRedirectedRoomId: null,
}

const videoChatSlice = createSlice({
  name: 'videoChat',
  initialState,
  reducers: {
    setVideoChat: (state, action: PayloadAction<RoomWithAccessResponse>) => {
      state.videoChatObg = action.payload
    },
    setVideoMembers: (state, action: PayloadAction<IParticipants>) => {
      state.videoChatParticipants = action.payload
    },
    setMySelectedRoom: (
      state,
      action: PayloadAction<{
        localParticipant?: string
        remoteParticipant?: string
      }>,
    ) => {
      const { localParticipant, remoteParticipant } = action.payload
      if (!state.videoChatObg || !state.videoChatObg.room) return

      const currentRoom = state.videoChatObg.room

      if (currentRoom.ownerUserId === localParticipant || currentRoom.ownerUserId === remoteParticipant) {
        currentRoom.participantId = remoteParticipant

        let newParticipantRole: RoomResponse['participantRole']
        if (currentRoom.ownerUserRole === 'CLIENT') {
          newParticipantRole = 'MODEL'
        } else if (currentRoom.ownerUserRole === 'MODEL') {
          newParticipantRole = 'CLIENT'
        } else {
          newParticipantRole = 'CLIENT'
        }

        currentRoom.participantRole = newParticipantRole

        state.videoChatObg.room = currentRoom
      }
    },
    setVideoRoomCreatedByModel: (state, action: PayloadAction<boolean | null>) => {
      state.videoRoomCreatedByModel = action.payload
    },
    resetVideoChatState: state => {
      return initialState
    },
    resetVideoChatExceptRoomCreated: state => {
      state.infoPreviousRooms = state.videoChatObg
      state.videoChatObg = initialState.videoChatObg
      state.videoChatParticipants = initialState.videoChatParticipants
      // videoRoomCreatedByModel не сбрасываем
    },

    // Добавляем экшен для записи roomId, куда уже делали navigate
    setLastRedirectedRoomId: (state, action: PayloadAction<string | null>) => {
      console.log('setLastRedirectedRoomId')
      state.lastRedirectedRoomId = action.payload
    },
  },
})

export const {
  setVideoMembers,
  resetVideoChatState,
  setVideoChat,
  setMySelectedRoom,
  setVideoRoomCreatedByModel,
  resetVideoChatExceptRoomCreated,
  setLastRedirectedRoomId, // <-- экспортируем новый экшен
} = videoChatSlice.actions

export default videoChatSlice.reducer
