import { FC, useEffect } from 'react'
// @ts-ignore
import { Popup } from 'reactjs-popup'
import { useDispatch } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import errorSocketImg from '../../../images/img/error.png'
import useLockBodyScroll from 'hooks/useLockBodyScroll'
import { popUpOpen } from 'store/reducer/popUp/reducer'
import { useTranslation } from 'react-i18next'

const ErrorSocket: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  useLockBodyScroll(open)
  const { t } = useTranslation()

  useEffect(() => {
    if (!open) {
      dispatch(popUpOpen(''))
    }
  }, [open, dispatch])

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleClose = () => {
    window.close()
  }

  return (
    <Popup
      closeOnEscape={false}
      closeOnDocumentClick={false} // Отключаем закрытие при клике вне попапа
      open={open}
      onClose={() => closeModal()}
    >
      <div className='error-socket-modal'>
        <h2>{t('ui.popUp.errorSocket.connection_issue')} :(</h2>
        <img src={errorSocketImg} alt='Ошибка подключения' />
        <div className='error-socket-buttons'>
          <button onClick={handleClose} className='close-button'>
            {t('ui.popUp.errorSocket.close_page')}
          </button>
          <button onClick={handleRefresh} className='refresh-button'>
            {t('ui.popUp.errorSocket.refresh_page')}{' '}
          </button>
        </div>
      </div>
    </Popup>
  )
}

export default ErrorSocket
