// store/selectors/permissionMonitorSelectors.ts

import { createSelector } from 'reselect'
import { RootState } from 'store/types'

// Базовый селектор для доступа к permissionMonitor состоянию
const selectPermissionMonitorState = (state: RootState) => state.permissionMonitor

// Селектор для получения состояния разрешения камеры
export const selectCameraState = createSelector([selectPermissionMonitorState], permissionMonitor => permissionMonitor.cameraState)

// Селектор для получения состояния разрешения микрофона
export const selectMicrophoneState = createSelector([selectPermissionMonitorState], permissionMonitor => permissionMonitor.microphoneState)
