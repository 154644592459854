import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { IForgotPasswordInitialValues } from './types'
import './scss/index.scss'
import {
  UserSendForgotPasswordCodeApiArg,
  useUserSendForgotPasswordCodeMutation,
  UserResetPasswordApiArg,
  useUserResetPasswordMutation,
} from 'store/apiMain/mainApi'
import { useNavigate, Link } from 'react-router-dom'
import Logo from '../../Global/Logo/Logo'
import Language from '../../../UI/Language/Language'
import { useTranslation } from 'react-i18next'

import imgLoginDecor from '../../../images/img/login-decor.svg'
import imgLogin from '../../../images/img/1.jpg'
import { toastError, toastSuccess } from '../../../elements/Notification/Notification'
import notificationsHandler from '../../../utils/notificationsHandler/notificationsHandler'
import notificationContainerError from '../../../utils/notificationContainerError/notificationContainerError'

const ForgotPassword = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [sendForgotPasswordCode, { isSuccess: isCodeSentSuccess, isError: isCodeSentError, error: codeSentError }] =
    useUserSendForgotPasswordCodeMutation()
  useEffect(() => {
    if (isCodeSentError) {
      notificationContainerError(codeSentError)
    }
  }, [isCodeSentError])
  const [resetPassword, { isSuccess: isPasswordResetSuccess, isError: isPasswordResetError, error: passwordResetError }] =
    useUserResetPasswordMutation()

  const initialValues: IForgotPasswordInitialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    code: '',
  }
  useEffect(() => {
    if (isCodeSentError) {
      // @ts-ignore
      notificationContainerError(isCodeSentError)
    }
  }, [isCodeSentError])
  const validationSchema = yup.object({
    email: yup.string().required(t('components.user.forgotPassword.emailRequired')).email(t('components.user.forgotPassword.enterValidEmail')),

    password: yup.string().when('code', {
      is: (value: string | undefined) => value && value.length > 0,
      then: schema =>
        schema.required(t('components.user.forgotPassword.passwordRequired')).min(6, t('components.user.forgotPassword.passwordMinLength')),
      otherwise: schema => schema.notRequired(),
    }),

    confirmPassword: yup.string().when('password', {
      is: (value: string | undefined) => value && value.length > 0,
      then: schema =>
        schema
          .oneOf([yup.ref('password'), undefined], t('components.user.forgotPassword.passwordsMustMatch'))
          .required(t('components.user.forgotPassword.confirmPasswordRequired')),
      otherwise: schema => schema.notRequired(),
    }),

    code: yup.string().when('email', {
      is: (value: string | undefined) => isCodeSentSuccess,
      then: schema => schema.required(t('components.user.forgotPassword.codeRequired')),
      otherwise: schema => schema.notRequired(),
    }),
  })

  useEffect(() => {
    if (isCodeSentSuccess) {
      toastSuccess(t('components.user.forgotPassword.codeSentSuccess'))
    }
    if (isPasswordResetSuccess) {
      toastSuccess(t('components.user.forgotPassword.passwordResetSuccess'))
      navigate('/login')
    }
  }, [isCodeSentSuccess, isPasswordResetSuccess])

  const onSubmit = async (values: IForgotPasswordInitialValues) => {
    if (isCodeSentSuccess) {
      const { password, code } = values
      const body: UserResetPasswordApiArg = {
        resetPasswordRequest: {
          newPassword: password,
          confirmationCode: code,
        },
      }
      await resetPassword(body).unwrap()
    } else {
      const { email } = values
      const body: UserSendForgotPasswordCodeApiArg = {
        forgotPasswordRequest: { email },
      }
      await sendForgotPasswordCode(body).unwrap()
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div className='login'>
      <header className='login__header'>
        <Logo />
        <Language />
      </header>
      <div className='login__main'>
        <div className='login__main-left'>
          <div className='login__main-left_box'>
            <h1 className='login__title'>{t('components.user.forgotPassword.reset_password')}</h1>
            <form onSubmit={formik.handleSubmit} className='login__form'>
              <label className='email'>
                <span>{t('components.user.forgotPassword.email')}</span>
                <FormControl name='email' type='email' formik={formik} placeholder='' autoComplete='off' />
              </label>
              {isCodeSentSuccess && (
                <>
                  <label className='password'>
                    <span>{t('components.user.forgotPassword.password_label')}</span>
                    <FormControl name='password' type='password' formik={formik} placeholder='' autoComplete='off' />
                  </label>
                  <label className='confirm-password'>
                    <span>{t('components.user.forgotPassword.confirm_password')}</span>
                    <FormControl name='confirmPassword' type='password' formik={formik} placeholder='' autoComplete='off' />
                  </label>
                  <label className='code'>
                    <span>{t('components.user.forgotPassword.code_label')}</span>
                    <FormControl name='code' type='text' formik={formik} placeholder='' autoComplete='off' />
                  </label>
                </>
              )}
              <button type='submit' className='login__form-button'>
                {isCodeSentSuccess ? t('components.user.forgotPassword.set_new_password') : t('components.user.forgotPassword.reset_password')}
              </button>
              <div className='login__footer'>
                <span className='login__footer-link'>&nbsp;</span>
                <Link to='/login' className='login__footer-link'>
                  {t('components.user.forgotPassword.login_action')}
                </Link>
              </div>
            </form>
            {/*{isCodeSentError && <div className="error-message">{codeSentError || t('forgotPassword.error')}</div>}*/}
            {/*{isPasswordResetError && <div className="error-message">{passwordResetError || t('forgotPassword.error')}</div>}*/}
            <div className='login__decor'>
              <img src={imgLoginDecor} alt='Decor auth' />
            </div>
          </div>{' '}
        </div>
        <div className='login__main-img'>
          <img src={imgLogin} alt='logo' />
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
