import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMessagesSelectedId, setMessagesSelectedStaffFalse, setMessagesSelectedUser } from '../../../../../store/reducer/messages/reducer'
import ImgMessages from '../../ImgMessages/ImgMessages'
import Status from '../../../../../UI/Status/Status'
import Typing from '../../Typing/Typing'
import RenderGiftIconAndName from '../../../../../utils/RenderGiftIconAndName/RenderGiftIconAndName'
import dataFormatMessages from '../../../../../utils/dataFormatMessages'
import ReadStatusIcon from '../../../../../utils/ReadStatus'
import { findMatchingContent } from '../../func/findMatchingContent'
import './scss/index.scss'
import { SvgGiftInfo } from 'images/svg'
import getSystemMessageText from '../../func/getSystemMessageText'
import { useTranslation } from 'react-i18next'

interface ChatItemProps {
  chat: any
  role: any
  userMe: any
  searchName: string
  // Фильтрацию уже делаем в родительском компоненте, поэтому убрали:
  // filterOnline: boolean
  // filterFavorites: boolean
  subscribeUser: any
  dataFriends: any
  selectedChatType: any
  dataClient: any
  dataModel: any
  dataStaff: any
}

const MessagesItem: React.FC<ChatItemProps> = React.memo(
  ({ chat, role, userMe, subscribeUser, dataFriends, selectedChatType, dataClient, dataModel, dataStaff }) => {
    const dispatch = useDispatch()
    const messagesSelectedId = useSelector((state: any) => state.messages.messagesSelectedId)
    const { t } = useTranslation()
    // Ищем, с кем чат: клиент, модель или стафф
    const { matchingClientContent, matchingModelContent, matchingStaffContent } = findMatchingContent(
      chat,
      dataClient,
      dataModel,
      dataStaff,
      selectedChatType,
      role,
    )

    // Если это чат со стаффом, принудительно отображаем статус "онлайн" (требование для админ-чата)
    const staffOnlineEveryday = chat.privateChat?.matchingContent?.staff !== undefined

    // Для отображения статуса (онлайн, оффлайн и тд)
    const onlineStatus =
      subscribeUser?.body?.find((user: any) => user.userId === chat.privateChat?.matchingContent?.user?.id)?.activityStatus ?? 'noStatus'

    // При клике на чат выставляем нужного получателя и выделяем чат
    const handleClick = React.useCallback(() => {
      dispatch(setMessagesSelectedStaffFalse())
      dispatch(setMessagesSelectedId(`${chat.privateChat.id}`))

      if (matchingClientContent) {
        dispatch(setMessagesSelectedUser(matchingClientContent))
      } else if (matchingModelContent) {
        dispatch(setMessagesSelectedUser(matchingModelContent))
      } else if (matchingStaffContent) {
        dispatch(setMessagesSelectedUser(matchingStaffContent))
      }
    }, [dispatch, chat, matchingClientContent, matchingModelContent, matchingStaffContent])

    return (
      <div
        className={`messages__item 
          ${messagesSelectedId === chat.privateChat.id ? 'active' : ''} 
          ${chat?.privateChat?.clientAttractAttentionCount && messagesSelectedId !== chat.privateChat.id ? 'needs-to-add-friend' : ''}`}
        onClick={handleClick}
      >
        <div className='item__img'>
          <div className='item__profile-photo'>
            <ImgMessages
              recipientRole={chat.privateChat?.matchingContent?.user?.role}
              idClient={chat.privateChat?.matchingContent?.client?.photoId}
              imgUrl={chat.privateChat?.matchingContent?.profilePhoto?.file.url}
              data={chat}
            />
          </div>
          <div className='item__online-status'>
            <Status status={staffOnlineEveryday && role === 'STAFF' ? 'ONLINE' : onlineStatus} />
          </div>
        </div>

        <div className='item__content'>
          <div className='item__content-block'>
            <h6 className='item__content-name'>
              {chat.privateChat?.matchingContent?.client?.name ||
                chat.privateChat?.matchingContent?.model?.name ||
                chat.privateChat?.matchingContent?.staff?.name}
            </h6>
            <div className='item__content-creation-time'>
              <p>{dataFormatMessages(chat.lastMessage?.createdAt)}</p>
            </div>
          </div>

          <div className='item__content-block'>
            <Typing typing={chat?.privateChat?.chatTyping} privateChatId={chat?.privateChat?.id} targetUserId=''>
              {chat.lastMessage?.type === 'SYSTEM_GIFT_RECEIVED' && chat.lastMessage?.body ? (
                <>
                  <SvgGiftInfo />
                  <p className='item__content-text'>
                    {role === 'CLIENT'
                      ? t('components.user.messages.sidebarLeft.messageSitem.messageSitem.gift_sent')
                      : t('components.user.messages.sidebarLeft.messageSitem.messageSitem.gift_received')}
                  </p>
                </>
              ) : chat.lastMessage?.type === 'GENERAL' ? (
                <p className='item__content-text'>{chat.lastMessage?.body}</p>
              ) : (
                <p className='item__content-text'>{getSystemMessageText(chat.lastMessage?.type, role, t)}</p>
              )}
            </Typing>
            {chat.unreadMessageCount > 0 && chat.lastMessage?.createdBy !== userMe?.userData?.user?.id && (
              <div className='unread-count'>
                <p>{chat.unreadMessageCount}</p>
              </div>
            )}

            {chat.lastMessage?.createdBy === userMe?.userData?.user?.id && (
              <div className='item__content-read-status'>
                <ReadStatusIcon role={role} message={chat.lastMessage} userMe={userMe} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    // ШAllow или неAllow в зависимости от того, нужно ли нам действительно сравнивать все поля
    // Для оптимизации. Если нужно - оставляем, если нет - можно убрать
    for (let key in prevProps) {
      if (prevProps[key] !== nextProps[key]) {
        // console.log(`Prop "${key}" has changed`, {
        //   prev: prevProps[key],
        //   next: nextProps[key],
        // })
        return false
      }
    }
    return true
  },
)

export default React.memo(MessagesItem)
