import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRole, selectUserMe } from 'store/reducer/token/selector'
import { getSelectorSetMessagePrivetChats, selectorAdminSelectedChatType, selectorAllPrivateChatsSuccess } from 'store/reducer/messages/selectors'
import { PrivateChatGetAllApiArg, usePrivateChatGetAllQuery } from 'store/apiMain/mainApi'
import { changeFullPrivateChats, getAllPrivateChatsSuccess, setMessagePrivetChats } from 'store/reducer/messages/reducer'

function shouldApprove(approveType: string, role: any) {
  if (approveType === 'approvedByClient') {
    if (role === 'CLIENT') {
      return true // ApproveByClient и роль клиента
    } else if (role === 'MODEL') {
      return true // ApproveByClient и роль модели
    }
  } else if (approveType === 'approvedByModel') {
    if (role === 'CLIENT') {
      return true // ApproveByModel и роль клиента
    } else if (role === 'MODEL') {
      return true // ApproveByModel и роль модели
    }
  }
  // В случае, если значения не соответствуют условиям, возвращаем false по умолчанию
  return false
}

export const usePrivateChats = () => {
  const role = useSelector(selectRole)
  const userMe = useSelector(selectUserMe)

  const dispatch = useDispatch()
  const selectedChatType = useSelector(selectorAdminSelectedChatType)
  const [payload, setPayload] = useState<PrivateChatGetAllApiArg | null>(null)

  useEffect(() => {
    if (userMe && role) {
      // Инициализируем payload на основе доступных данных
      let newPayload: PrivateChatGetAllApiArg = {
        unreadMessageCountBy: role,
        approvedByClient: undefined,
        approvedByModel: undefined,
        approvedByStaff: undefined,
        clientId: undefined,
        modelId: undefined,
        staffId: undefined,
        token: undefined,
        disabled: selectedChatType?.disabled,
        pageSize: 8888,
      }

      if (role === 'CLIENT') {
        newPayload = {
          ...newPayload,
          approvedByModel: true,
          approvedByClient: true,
          // @ts-ignore
          clientId: userMe?.userData?.user?.id,
          disabled: false,
        }
      } else if (role === 'MODEL') {
        newPayload = {
          ...newPayload,
          approvedByClient: true,
          // @ts-ignore
          modelId: userMe?.userData?.user?.id,
          disabled: false,
        }
      } else if (role === 'STAFF') {
        newPayload = {
          ...newPayload,
          unreadMessageCountBy: undefined,
          ...selectedChatType,
          token: undefined,
          pageSize: 8888,
        }
      }

      setPayload(newPayload)
    }
  }, [userMe, role, selectedChatType])

  // Запрос выполняется только когда payload установлен
  const { data: privateChats, refetch, isLoading, isSuccess } = usePrivateChatGetAllQuery(payload!, { skip: !payload })
  const allPrivateChatsSuccess = useSelector(selectorAllPrivateChatsSuccess)
  useEffect(() => {
    if (isSuccess && privateChats !== undefined && !allPrivateChatsSuccess) {
      dispatch(setMessagePrivetChats(privateChats))
      dispatch(changeFullPrivateChats())
      if (role !== 'STAFF') {
        dispatch(getAllPrivateChatsSuccess())
      }
    }
  }, [privateChats, isSuccess])

  return { privateChats, refetch, isLoading, isSuccess }
}
