import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import './scss/index.scss'
import { toastInfo } from '../../elements/Notification/Notification'
import Loading from '../../elements/Loading/Loading'

interface MyVideo extends File {
  preview?: string
  id?: string
}

interface VideoProps {
  videos: MyVideo[]
  setVideos: React.Dispatch<React.SetStateAction<MyVideo[]>>
  addFile: any
  getFile: any
  setVideoIds: any
  videoIds: any[]
}

const Video: React.FC<VideoProps> = ({ videos, setVideos, addFile, getFile, setVideoIds, videoIds }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true)

      const acceptedVideos: MyVideo[] = acceptedFiles.filter(file => file.type.startsWith('video/')) as MyVideo[]
      if (acceptedVideos.length !== acceptedFiles.length) {
        toastInfo(t('ui.video.view_video'))
      }

      for (const video of acceptedVideos) {
        if (videoIds.includes(video.name)) {
          continue // Skip if the video is already uploaded
        }

        const formData = new FormData()
        formData.append('file', video)

        try {
          const result = await addFile({ body: formData }).unwrap()
          if (result && result.id) {
            const videoUrl = await getFile({ fileId: result.id }).unwrap()
            if (videoUrl) {
              setVideoIds((prevVideoIds: any) => [...prevVideoIds, result.id])
              setVideos(prev => [...prev, { ...video, preview: videoUrl, id: result.id }])
            }
          }
        } catch (error) {
          console.error('Error uploading video:', error)
        }
      }

      setLoading(false)
    },
    [addFile, getFile, setVideoIds, setVideos, videoIds],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'video/*' as unknown as DropzoneOptions['accept'],
    onDrop,
  })

  const removeVideo = useCallback(
    (index: number, video: MyVideo) => {
      setVideoIds((prevVideoIds: any) => prevVideoIds.filter((id: any) => id !== video.id))
      setVideos(prev => prev.filter((_, i) => i !== index))
    },
    [setVideoIds, setVideos],
  )

  useEffect(() => {
    const fetchVideosFromIds = async () => {
      setLoading(true)
      const fetchedVideos: MyVideo[] = []

      for (const videoObj of videoIds) {
        const id = videoObj
        if (videos.some(video => video.id === id)) {
          continue // Skip if the video is already in state
        }

        try {
          const videoUrl = await getFile({ fileId: id }).unwrap()
          if (videoUrl) {
            const response = await fetch(videoUrl)
            const data = await response.blob()
            const newVideo: MyVideo = new File([data], id, {
              type: 'video/mp4',
            })
            newVideo.preview = videoUrl
            newVideo.id = id
            fetchedVideos.push(newVideo)
          }
        } catch (error) {
          console.error('Error fetching video:', error)
        }
      }

      setVideos(prev => [...prev, ...fetchedVideos])
      setLoading(false)
    }

    if (videoIds.length > 0) {
      fetchVideosFromIds()
    }
  }, [videoIds, getFile, setVideos])

  const videoPreviews = videos.map((video, index) => (
    <div key={index}>
      <div>
        <video src={video.preview} controls></video>
      </div>
      <button type='button' onClick={() => removeVideo(index, video)}>
        {t('ui.video.delete')}
      </button>
    </div>
  ))

  return (
    <div className='videoModel'>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? <p>{t('ui.video.dragActive')}</p> : <p>{t('ui.video.dragInactive')}</p>}
      </div>
      <aside>
        <h4>{t('ui.video.files')}</h4>
        <ul>
          {videoPreviews}
          {loading && <Loading ouro={false} fontSize={'14px'} />}
        </ul>
      </aside>
    </div>
  )
}

export default Video
