import React, { useEffect } from 'react'
import './scss/index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'
import { selectRole } from '../../../store/reducer/token/selector'
import {
  MessageReadAllApiArg,
  PrivateChatDisableByIdApiArg,
  RelationshipDeleteByIdApiArg,
  RelationshipPartialUpdateByIdApiArg,
  useMessageReadAllMutation,
  usePrivateChatDisableByIdMutation,
  useRelationshipDeleteByIdMutation,
  useRelationshipPartialUpdateByIdMutation,
} from 'store/apiMain/mainApi'
import { getSelectorMessagesSelectedId, getSelectorSetMessagePrivetChats } from '../../../store/reducer/messages/selectors'
import { toastInfo, toastSuccess } from '../../../elements/Notification/Notification'

import useSoundPlayer from '../../../hooks/useSoundPlayer'
import { changeRelationshipClient, changeRelationshipModel } from 'store/reducer/messages/reducer'
import { useTranslation } from 'react-i18next'
const ActionUserTooltip = () => {
  const dispatch = useDispatch()
  const role = useSelector(selectRole)
  const [closeChat] = usePrivateChatDisableByIdMutation()
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const [readAllMessages] = useMessageReadAllMutation()
  const selectorSetMessagePrivetChats = useSelector(getSelectorSetMessagePrivetChats)

  const chatIndex = selectorSetMessagePrivetChats?.content.findIndex(chat => chat.privateChat.id === messagesSelectedId)
  // @ts-ignore
  const privateChat = selectorSetMessagePrivetChats?.content[chatIndex]
  console.log(privateChat)
  const [friendsUpdate, { isSuccess: isSuccessUpdate }] = useRelationshipPartialUpdateByIdMutation()
  const { playSound, stopAllSounds } = useSoundPlayer(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (isSuccessUpdate) {
      if (role === 'MODEL') {
        dispatch(changeRelationshipModel(privateChat?.privateChat?.id))
      } else {
        dispatch(changeRelationshipClient(privateChat?.privateChat?.id))
      }
      if (privateChat?.relationship?.isClientFavorite) {
        toastSuccess(t('ui.tooltip.actionUserTooltip.removed_from_favorites'))
      } else {
        toastSuccess(t('ui.tooltip.actionUserTooltip.added_to_favorites'))
        playSound('addFavorites')
      }
    }
  }, [isSuccessUpdate])

  const addFavorites = () => {
    if (privateChat?.relationship?.id !== undefined) {
      const isFavorite = role === 'MODEL' ? !privateChat?.relationship?.isModelFavorite : !privateChat?.relationship?.isClientFavorite
      const payload: RelationshipPartialUpdateByIdApiArg = {
        relationshipId: String(privateChat?.relationship?.id),
        relationshipPartialUpdateDto: {
          isFavorite: isFavorite,
        },
      }
      friendsUpdate(payload)
    } else {
      toastInfo(t('ui.tooltip.actionUserTooltip.friendship_required'))
    }
  }
  // Удаление друга
  const [friendsDelete, { isSuccess: isSuccessDelete }] = useRelationshipDeleteByIdMutation()
  const addDelete = () => {
    const payloadButton: RelationshipDeleteByIdApiArg = {
      relationshipId: privateChat?.relationship?.id,
    }
    friendsDelete(payloadButton)
  }
  const isFavorite = role === 'MODEL' ? privateChat?.relationship?.isModelFavorite : privateChat?.relationship?.isClientFavorite
  return (
    <div className='ActionUserTooltips'>
      <div className='tooltips'>
        <div className='tooltips-block'>
          <div className='tooltips-block_box'>
            <ul>
              <li
                onClick={() => {
                  dispatch(popUpOpen('complaint'))
                }}
              >
                {t('ui.tooltip.actionUserTooltip.send_complaint')}
              </li>
              <li
                onClick={() => {
                  dispatch(popUpOpen('complaint'))
                  dispatch(setPopUpData({ data: true }))
                }}
              >
                {t('ui.tooltip.actionUserTooltip.block_user')}
              </li>
              {role === 'STAFF' ? (
                <>
                  <li
                    onClick={() => {
                      if (messagesSelectedId) {
                        const payload: PrivateChatDisableByIdApiArg = {
                          privateChatId: messagesSelectedId,
                        }
                        closeChat(payload)
                      }
                    }}
                  >
                    {t('ui.tooltip.actionUserTooltip.close_chat')}
                  </li>
                </>
              ) : null}
              <li
                onClick={() => {
                  if (messagesSelectedId) {
                    const payload: MessageReadAllApiArg = {
                      privateChatId: messagesSelectedId,
                    }
                    readAllMessages(payload)
                  }
                }}
              >
                {t('ui.tooltip.actionUserTooltip.read_all_messages')}
              </li>
              <li
                onClick={() => {
                  addFavorites()
                }}
              >
                {isFavorite ? t('ui.tooltip.actionUserTooltip.remove_from_favorites') : t('ui.tooltip.actionUserTooltip.add_to_favorites')}
              </li>
              <li
                onClick={() => {
                  addDelete()
                }}
              >
                {t('ui.tooltip.actionUserTooltip.remove_friend')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionUserTooltip
