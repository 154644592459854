import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccount } from 'store/reducer/accountController/selectors'
import { SvgCoin } from '../../../../../../../../images/svg'
import { popUpOpen } from 'store/reducer/popUp/reducer'
import { useAccountGetByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { setAccountController } from 'store/reducer/accountController/reducer'
import { toastError, toastInfo } from '../../../../../../../../elements/Notification/Notification'
import { selectRole } from 'store/reducer/token/selector'
import { Id, toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
const Coin = () => {
  const { data, refetch } = useUserGetFullMeQuery()
  const role = useSelector(selectRole)
  const payloadAmount = {
    userId: data?.userData?.user?.id,
  }
  const dispatch = useDispatch()
  const { data: dataAmount, refetch: refetchAmount } = useAccountGetByIdQuery(payloadAmount, { skip: !data?.userData?.user?.id })
  useEffect(() => {
    if (dataAmount) {
      dispatch(setAccountController(dataAmount))
    }
  }, [dataAmount])
  const dataAmountSelect = useSelector(getAccount)
  const { t } = useTranslation()
  const toastId = useRef<Id | null>(null)

  useEffect(() => {
    // Если количество монет < 300, показываем toast
    if (dataAmountSelect && dataAmountSelect.coins < 300 && role === 'CLIENT') {
      // Проверяем, не открыт ли уже такой toast (чтобы не дублировать)
      if (!toastId.current) {
        toastId.current = toast.error(
          <>
            {t('components.liveKitCustom.component.button.coin.low_balance_warning')}

            <span onClick={() => handleTopUp()} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>
              {t('components.liveKitCustom.component.button.coin.top_up_balance')}
            </span>
          </>,
          { autoClose: 999999 },
        )
      }
    }
    // Если количество монет снова ≥ 300, закрываем toast
    else if (toastId.current && dataAmountSelect && dataAmountSelect.coins >= 300) {
      toast.dismiss(toastId.current)
      toastId.current = null
    }
  }, [dataAmountSelect, role])

  const handleTopUp = () => {
    dispatch(popUpOpen('topUpBalance'))
  }
  return (
    <div className='coin'>
      <button
        className='lk-button lk-chat-toggle'
        onClick={() => {
          dispatch(popUpOpen('topUpBalance'))
        }}
      >
        <SvgCoin />
        {dataAmountSelect?.coins}
      </button>
    </div>
  )
}

export default Coin
