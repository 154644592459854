import React, { FC } from 'react'
// @ts-ignore
import { Popup } from 'reactjs-popup'
import { useDispatch } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import { ageConfirmationCounter } from 'store/reducer/age/reducer'
import Language from 'UI/Language/Language'
import useLockBodyScroll from 'hooks/useLockBodyScroll'
import { useTranslation } from 'react-i18next'

const AgeBlockPopUp: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  useLockBodyScroll(open)
  const { t } = useTranslation()

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='age-block'>
        <div className='age-block__block'>
          <div className='age-block__header'>
            <p className='age-block__age'>
              <span>18+</span>
            </p>
            <div className='age-block__language'>
              <Language />
            </div>
          </div>
          <div className='age-block__box'>
            <p>{t('ui.popUp.ageBlock.website_warning')}</p>
            <p>
              <span>{t('ui.popUp.ageBlock.adult_content_warning')}</span>
            </p>
          </div>
          <div className='age-block__buttons'>
            <button type='button'>
              <a href='https://www.google.com'> {t('ui.popUp.ageBlock.leave_site_text')}</a>
            </button>
            <button
              onClick={() => {
                closeModal()
                dispatch(ageConfirmationCounter())
              }}
              type='submit'
            >
              {t('ui.popUp.ageBlock.confirm_age')}{' '}
            </button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default AgeBlockPopUp
