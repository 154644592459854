import React, { FC, useState, useRef, useEffect } from 'react'
import './scss/index.scss'

interface FireworkData {
  id: number
  left: number
  top: number
  scale: number
  color: string
}

const EXPLOSION_COUNT = 22

function getRandomColor(): string {
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}

function generateFirework(): FireworkData {
  return {
    id: Date.now() + Math.random(),
    left: Math.random() * 100,
    top: Math.random() * 100,
    scale: 0.5 + Math.random() * 2,
    color: getRandomColor(),
  }
}

const Boom: FC = () => {
  const [fireworks, setFireworks] = useState<FireworkData[]>([])
  const intervalRef = useRef<number | null>(null)

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setFireworks(prev => [...prev, generateFirework()])
    }, 200)

    const stopTimeoutId = window.setTimeout(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }, 4000000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      clearTimeout(stopTimeoutId)
    }
  }, [])
  const handleAnimationEnd = (id: number) => {
    setFireworks(prev => prev.filter(f => f.id !== id))
  }

  return (
    <>
      {fireworks.map(firework => (
        <div
          className='firework'
          key={firework.id}
          style={{
            left: `${firework.left}%`,
            top: `${firework.top}%`,
            transform: `scale(${firework.scale})`,
            // Можно выставить здесь разное время анимации,
            // чтобы "размазывать" эффект, etc.
          }}
          onAnimationEnd={() => handleAnimationEnd(firework.id)}
        >
          {[...Array(EXPLOSION_COUNT)].map((_, i) => (
            <div className='explosion' key={i}>
              <div className='explosion-inner' style={{ backgroundColor: firework.color }} />
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default Boom
