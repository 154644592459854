import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { useFormik } from 'formik'

import './scss/index.scss'

import { useNavigate } from 'react-router-dom'
import { useFileCreateMutation, useModelRequestCreateMutation, useModelRequestGetMeQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { toastSuccess } from 'elements/Notification/Notification'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import Photo from 'UI/Photo/Photo'
import { setSteps1 } from 'store/reducer/steps/reducer'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { getSocketStepApprovedModel } from '../../../../../store/reducer/socket/selector'
import { t } from 'i18next'

const EditDocument: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addRequest, { isSuccess }] = useModelRequestCreateMutation()

  const initialValues = {
    dataType: 'SECRET_DATA',
    realName: '',
  }

  const [checkedProverka, setCheckedProverka] = useState(false)
  const { data: modelData, isLoading, isError } = useUserGetFullMeQuery()

  const { data: modelRequest, isLoading: byIdLoading, isError: byIdError, refetch } = useModelRequestGetMeQuery()
  // const validationSchema = yup.object().shape({
  //     realName: yup.string().required('realName is required').min(2).max(50),
  // });

  // if(modelRequest===undefined && modelData?.model!==undefined) {
  //     navigate('/model/profile')
  // }
  const { t } = useTranslation()
  useEffect(() => {
    if (modelRequest?.secretData?.realName !== undefined) {
      setCheckedProverka(true)
    }
  }, [modelRequest])

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      realName: values?.realName ? values?.realName : undefined,
      documents: [...photoIds],
    }
    addRequest({ body })
  }

  useEffect(() => {
    if (isSuccess) {
      toastSuccess(t('components.model.profile.documents.edit.data_sent_success'))
      refetch()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isSuccess])
  const formik = useFormik({
    initialValues,
    onSubmit,
  })
  useEffect(() => {
    if (modelRequest?.secretData !== null && modelRequest?.secretData !== undefined) {
      const arrayPhoto = modelRequest?.secretData?.documents
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      formik.resetForm({
        values: {
          dataType: 'SECRET_DATA',
          // @ts-ignore
          realName: modelRequest?.secretData?.realName,
          // @ts-ignore
          documents: modelRequest?.secretData?.documents,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelRequest])

  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])

  const [videos, setVideos] = useState<any[]>([])
  const [videosIds, setVideosIds] = useState<string[]>([])

  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()
  return (
    <div className='steps2'>
      <div className='step2-information'>
        {checkedProverka ? (
          <div className='checkSteps'>
            <div className='checkSteps-block'>
              <p>{t('components.model.profile.documents.edit.send_true_data')}</p>
            </div>
          </div>
        ) : null}
        <div className='step2-information-block_title'>
          <h2>{t('components.model.profile.documents.edit.refactor_documents')} </h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <label className='name'>
            <span>{t('components.model.profile.documents.edit.passport_name')}</span>
            <FormControl name='realName' type='text' formik={formik} placeholder='' />
          </label>
          <div className='step2-information-block'>
            <div className='step2-information-block__item'>
              <div className='step2-information-block__item_box'>
                <Photo
                  photoIds={photoIds}
                  photos={photos}
                  setPhotos={setPhotos}
                  addFile={addFile}
                  getFile={getFile}
                  setPhotoIds={setPhotoIds}
                  document={true}
                />
              </div>
            </div>
            <div className='step2-information-block_box'>{/*<WebcamRecorder/>*/}</div>
            <div className='step2-information-block__button'>
              <button type='submit'>{t('components.model.profile.documents.edit.submit_action')}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditDocument
