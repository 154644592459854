import React, { useEffect } from 'react'
import { RelationshipPartialUpdateByIdApiArg, useRelationshipPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import { useDispatch } from 'react-redux'
import { clearMessagesPrivateChatClientAttractAttentionCount } from 'store/reducer/messages/reducer'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  relationshipWithCurrentUserId: string
  privateChatId: string
}

const NeedsToAddFriend: React.FC<IProps> = ({ relationshipWithCurrentUserId, privateChatId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [friendsUpdate, { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate }] = useRelationshipPartialUpdateByIdMutation()

  useEffect(() => {
    if (isSuccessUpdate) {
      dispatch(clearMessagesPrivateChatClientAttractAttentionCount(privateChatId))
    }
  }, [isSuccessUpdate, dispatch, privateChatId])

  return (
    <div className='NeedsToAddFriend'>
      <div className='NeedsToAddFriend-block'>
        <button
          onClick={event => {
            const payloadButton: RelationshipPartialUpdateByIdApiArg = {
              // @ts-ignore
              relationshipId: relationshipWithCurrentUserId,
              relationshipPartialUpdateDto: {
                type: 'APPROVE_FRIEND_REQUEST',
              },
            }
            friendsUpdate(payloadButton)
          }}
          disabled={isLoadingUpdate}
        >
          {isLoadingUpdate ? (
            <span className='loading-text'>
              {t('components.user.messages.rightBlock.needsToAddFriend.load')}

              <span className='loading-dots' />
            </span>
          ) : (
            t('components.user.messages.rightBlock.needsToAddFriend.acceptApplication')
          )}
        </button>
      </div>
    </div>
  )
}

export default NeedsToAddFriend
