import React, { useEffect, useLayoutEffect, useState } from 'react'
import './scss/index.scss'
import { HiddenItem } from '../../HiddenItem/HiddenItem'
import { useTranslation } from 'react-i18next'

const Recommendations = () => {
  const { t } = useTranslation()
  useEffect(() => {
    const toggleAccordion = (event: { currentTarget: any }) => {
      const item = event.currentTarget
      const content = item.nextElementSibling // Предполагая, что .accordion-content следует непосредственно за кнопкой
      const itemToggle = item.getAttribute('aria-expanded') === 'true'

      // Закрыть все элементы
      items.forEach(i => {
        i.setAttribute('aria-expanded', 'false')
        // @ts-ignore
        i.nextElementSibling.style.maxHeight = null // Сброс высоты контента
      })

      // Если элемент был закрыт, раскрыть его
      if (!itemToggle) {
        item.setAttribute('aria-expanded', 'true')
        // Устанавливаем max-height равным высоте содержимого плюс небольшой дополнительный отступ
        content.style.maxHeight = content.scrollHeight + 'px'
      }
    }

    const items = document.querySelectorAll('.accordion button')

    items.forEach(item => {
      item.addEventListener('click', toggleAccordion)
    })

    // Важно: используйте ту же ссылку на функцию для удаления обработчика
    return () => {
      items.forEach(item => {
        item.removeEventListener('click', toggleAccordion)
      })
    }
  }, [])

  return (
    <div className='recommendations'>
      <div className='Services'>
        <div className='Services-block'>
          <div className='container'>
            <h2>{t('components.model.profile.recommendations.recommendationsServices')}</h2>
            <p className='recommendations-text'>{t('components.model.profile.recommendations.recommendationsServicesText')}</p>
            <div className='accordion'>
              <HiddenItem title={t('components.model.profile.recommendations.JOI')} button_number={1}>
                <p>{t('components.model.profile.recommendations.JOI')} </p>
                <p>{t('components.model.profile.recommendations.joi_interaction')}</p>
                <p>{t('components.model.profile.recommendations.joi_variations')}</p>
              </HiddenItem>
              <HiddenItem
                title={t('components.model.profile.recommendations.sexStories')}
                button_number={2}
                text={t('components.model.profile.recommendations.sexStoriesText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.sexGames')}
                button_number={3}
                text={t('components.model.profile.recommendations.sexGamesText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.dirtyTalk')}
                button_number={4}
                text={t('components.model.profile.recommendations.dirtyTalkText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.lookAtBeauty')}
                button_number={5}
                text={t('components.model.profile.recommendations.lookAtBeautyText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.dominationGirl')}
                button_number={5}
                text={t('components.model.profile.recommendations.dominationGirlText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.subordination')}
                button_number={5}
                text={t('components.model.profile.recommendations.subordinationText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.ejaculationControl')}
                button_number={5}
                text={t('components.model.profile.recommendations.ejaculationControlText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.showYourself')}
                button_number={5}
                text={t('components.model.profile.recommendations.showYourselfText')}
              />
              <HiddenItem
                title={t('components.model.profile.recommendations.additionalServices')}
                button_number={5}
                text={t('components.model.profile.recommendations.additionalServicesText')}
              />{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recommendations
