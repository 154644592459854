import React, { useState, useEffect } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import './scss/index.scss'
import Tippy from '@tippyjs/react'
import TooltipGift from '../Tooltip/GiftTooltip/GiftTooltip'
import { SvgBlackGift1 } from '../../images/svg'
import PhotoVerificationTooltip from '../Tooltip/PhotoVerificationTooltip/PhotoVerificationTooltip'
import TextTooltip from '../Tooltip/TextTooltip/TextTooltip'
import { FileCreateApiArg } from 'store/apiMain/mainApi'
import { toastInfo } from '../../elements/Notification/Notification'
import Loading from '../../elements/Loading/Loading'
import Zoom from 'react-medium-image-zoom'

// ... other imports

interface MyFile extends File {
  preview?: string
  id?: string
}

interface PhotoProps {
  ownerId?: string | undefined
  photos: MyFile[]
  setPhotos: React.Dispatch<React.SetStateAction<MyFile[]>>
  addFile: any
  getFile: any
  setPhotoIds: any
  photoIds: any[]
  document?: boolean
}

export const DocumentBlock = () => {
  const { t } = useTranslation()

  return (
    <div className='photo-block'>
      <h1>{t('ui.photo.photo.upload_photos')}</h1>
      <p>
        1) {t('ui.photo.photo.close-up_photo')}
        <Tippy
          interactive
          trigger='mouseenter'
          content={<TextTooltip text={'Убедитесь, что вся информация с документа хорошо видна и легко читается'} />}
          placement='bottom'
        >
          <span className='info-circle'>?</span>
        </Tippy>
      </p>
      <Tippy interactive trigger='mouseenter' content={<PhotoVerificationTooltip example={1} />} placement='bottom'>
        <button type='button' style={{ cursor: 'pointer' }}>
          <p>{t('ui.photo.photo.example_description')}</p>
        </button>
      </Tippy>
      <p>
        2) {t('ui.photo.photo.photo_description')}
        <Tippy interactive trigger='mouseenter' content={<TextTooltip text={t('ui.photo.photo.upload_foto')} />} placement='bottom'>
          <span className='info-circle'>?</span>
        </Tippy>
      </p>
      <Tippy interactive trigger='mouseenter' content={<PhotoVerificationTooltip example={2} />} placement='bottom'>
        <button type='button' style={{ cursor: 'pointer' }}>
          <p>{t('ui.photo.photo.example_description')}</p>
        </button>
      </Tippy>
      <div className='arrow'>
        <div className='arrow-head'></div>
        <div className='arrow-stick'></div>
      </div>
    </div>
  )
}
export const DocumentBlockVideo = () => {
  const { t } = useTranslation()

  return (
    <div className='photo-block'>
      <h1>{t('ui.photo.photo.upload_video')}</h1>
      <p>
        2){t('ui.photo.photo.video_description')}
        <Tippy interactive trigger='mouseenter' content={<TextTooltip text={t('ui.photo.photo.upload_video2')} />} placement='bottom'>
          <span className='info-circle'>?</span>
        </Tippy>
      </p>
      <Tippy interactive trigger='mouseenter' content={<PhotoVerificationTooltip example={3} />} placement='bottom'>
        <button type='button' style={{ cursor: 'pointer' }}>
          <p>{t('ui.photo.photo.example_description')}</p>
        </button>
      </Tippy>
      <div className='arrow'>
        <div className='arrow-head'></div>
        <div className='arrow-stick'></div>
      </div>
    </div>
  )
}

const Photo: React.FC<PhotoProps> = ({ ownerId, photos, setPhotos, addFile, getFile, setPhotoIds, photoIds, document }) => {
  const { t } = useTranslation()
  const [uploading, setUploading] = useState<boolean>(false)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*' as unknown as DropzoneOptions['accept'],
    onDrop: async (acceptedFiles: File[]) => {
      const photoFiles = acceptedFiles.filter(file => file.type.startsWith('image/')) as MyFile[]

      if (photoFiles.length !== acceptedFiles.length) {
        toastInfo(t('ui.photo.photo.example_description'))
        return
      }

      setUploading(true) // Set uploading to true when upload starts

      const formData = new FormData()
      photoFiles.forEach(file => formData.append('file', file))

      const bodyParams = {
        ownerId: ownerId,
        body: formData,
      }
      try {
        const result = await addFile(bodyParams).unwrap()

        if (result && result.id) {
          const fileUrl = await getFile({ fileId: result.id }).unwrap()
          if (fileUrl) {
            setPhotoIds((prevPhotoIds: any) => [...(prevPhotoIds ?? []), result.id])
            setPhotos(prev => [...(prev ?? []), { ...photoFiles[0], preview: fileUrl, id: result.id }])
          }
        }
      } finally {
        setUploading(false) // Set uploading to false when upload finishes
      }
    },
  })

  const removeFile = (index: number, file: MyFile) => {
    setPhotoIds((prevPhotoIds: any) => prevPhotoIds.filter((id: any) => id !== file.id))
    setPhotos(prev => prev.filter((_, i) => i !== index))
  }

  useEffect(() => {
    const fetchPhotosFromIds = async () => {
      const fetchedPhotos: MyFile[] = []
      if (photoIds !== undefined) {
        for (const photoObj of photoIds) {
          const id = photoObj
          const fileUrl = await getFile({ fileId: id }).unwrap()
          if (fileUrl) {
            fetchedPhotos.push({
              id: photoObj,
              name: photoObj,
              type: 'image/jpeg',
              preview: fileUrl,
            } as MyFile)
          }
        }
        setPhotos(fetchedPhotos)
      }
    }
    if (photoIds?.length !== 0) {
      fetchPhotosFromIds()
    }
  }, [photoIds, getFile, setPhotos])

  const thumbs = photos.map((file, index) => (
    <div key={index}>
      <div>
        <Zoom>
          <img src={file.preview} alt='' />
        </Zoom>
      </div>
      <button type='button' onClick={() => removeFile(index, file)}>
        {t('ui.photo.photo.delete')}
      </button>
    </div>
  ))

  return (
    <div className='photo'>
      {document ? <DocumentBlock /> : null}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? <p>{t('ui.photo.photo.dragActive')}</p> : <p>{t('ui.photo.photo.dragInactive')}</p>}
      </div>
      <aside>
        <h4>{t('ui.photo.photo.filesPhoto')}</h4>
        <ul>
          {thumbs}
          <div>
            <div>{uploading && <Loading ouro={false} fontSize={'14px'} />}</div>
          </div>
        </ul>
      </aside>
    </div>
  )
}

export default Photo
