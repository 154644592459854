// config.ts

// ( 'local' или 'remote')
const ENV = process.env.REACT_APP_ENV?.toLowerCase() || 'remote'

const localConfig = {
  baseUrl: 'http://127.0.0.1:5000',
  baseWsUrl: 'ws://127.0.0.1:5000',
  baseLiveKitUrl: 'http://localhost:7880',
}
const remoteConfig = {
  baseUrl: process.env.REACT_APP_HOST ? `https://${process.env.REACT_APP_HOST}` : 'https://dev.test-vh.store', // или другой дефолт
  baseWsUrl: process.env.REACT_APP_HOST ? `wss://${process.env.REACT_APP_HOST}` : 'wss://dev.test-vh.store',
  baseLiveKitUrl: process.env.REACT_APP_LIVEKIT_HOST ? `https://${process.env.REACT_APP_LIVEKIT_HOST}` : 'https://livekit-stg.test-vh.store',
}

const config = ENV === 'local' ? localConfig : remoteConfig

export const baseUrl = config.baseUrl
export const baseWsUrl = config.baseWsUrl
export const baseLiveKitUrl = config.baseLiveKitUrl
