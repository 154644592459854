import React, { useEffect, useRef } from 'react'
import './StarRating.scss'
import { useTranslation } from 'react-i18next'

const StarRating = ({ onRatingSelect }) => {
  const formRef = useRef(null)
  const ratingRef = useRef(null)
  const { t } = useTranslation()

  const ratings = [
    { id: 1, name: 'Terrible' },
    { id: 2, name: 'Bad' },
    { id: 3, name: 'OK' },
    { id: 4, name: 'Good' },
    { id: 5, name: 'Excellent' },
  ]

  const updateRating = e => {
    // clear animation delays
    // @ts-ignore
    Array.from(formRef.current.querySelectorAll(`[for*="rating"]`)).forEach(el => {
      // @ts-ignore
      el.className = 'rating__label'
    })

    const ratingObject = ratings.find(r => r.id === +e.target.value)
    // @ts-ignore
    const prevRatingID = ratingRef.current?.id || 0

    onRatingSelect(+e.target.value)
    // @ts-ignore
    ratingRef.current = ratingObject
    let delay = 0

    ratings.forEach(r => {
      const { id } = r
      // @ts-ignore
      const ratingLabel = formRef.current.querySelector(`[for="rating-${id}"]`)
      // @ts-ignore
      if (id > prevRatingID + 1 && id <= ratingRef.current.id) {
        ++delay
        ratingLabel.classList.add(`rating__label--delay${delay}`)
      }
      /// @ts-ignore
      const ratingTextEl = formRef.current.querySelector(`[data-rating="${id}"]`)
      // @ts-ignore
      if (ratingRef.current.id !== id) ratingTextEl.setAttribute('hidden', true)
      else ratingTextEl.removeAttribute('hidden')
    })
  }

  useEffect(() => {
    const el = formRef.current
    if (el) {
      // @ts-ignore
      el.addEventListener('change', updateRating)
      try {
        // @ts-ignore
        el.reset()
      } catch (err) {
        console.error('Element isn’t a form.')
      }
    }
    return () => {
      if (el) {
        // @ts-ignore
        el.removeEventListener('change', updateRating)
      }
    }
  }, [])

  return (
    <div className='StarRating'>
      <form className='rating' ref={formRef}>
        <div className='rating__stars'>
          <input id='rating-1' className='rating__input rating__input-1' type='radio' name='rating' value='1' />
          <input id='rating-2' className='rating__input rating__input-2' type='radio' name='rating' value='2' />
          <input id='rating-3' className='rating__input rating__input-3' type='radio' name='rating' value='3' />
          <input id='rating-4' className='rating__input rating__input-4' type='radio' name='rating' value='4' />
          <input id='rating-5' className='rating__input rating__input-5' type='radio' name='rating' value='5' />

          <label className='rating__label' htmlFor='rating-1'>
            <svg className='rating__star' width='32' height='32' viewBox='0 0 32 32' aria-hidden='true'>
              <g transform='translate(16,16)'>
                <circle className='rating__star-ring' fill='none' stroke='#000' strokeWidth='16' r='8' transform='scale(0)' />
              </g>
              <g stroke='#000' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                <g transform='translate(16,16) rotate(180)'>
                  <polygon
                    className='rating__star-stroke'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='none'
                  />
                  <polygon
                    className='rating__star-fill'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='#000'
                  />
                </g>
                <g transform='translate(16,16)' strokeDasharray='12 12' strokeDashoffset='12'>
                  <polyline className='rating__star-line' transform='rotate(0)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(72)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(144)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(216)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(288)' points='0 4,0 16' />
                </g>
              </g>
            </svg>
            <span className='rating__sr'>{t('ui.popUp.videoPopUp.rateVideoCall.starRating.1')}</span>
          </label>

          <label className='rating__label' htmlFor='rating-2'>
            <svg className='rating__star' width='32' height='32' viewBox='0 0 32 32' aria-hidden='true'>
              <g transform='translate(16,16)'>
                <circle className='rating__star-ring' fill='none' stroke='#000' strokeWidth='16' r='8' transform='scale(0)' />
              </g>
              <g stroke='#000' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                <g transform='translate(16,16) rotate(180)'>
                  <polygon
                    className='rating__star-stroke'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='none'
                  />
                  <polygon
                    className='rating__star-fill'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='#000'
                  />
                </g>
                <g transform='translate(16,16)' strokeDasharray='12 12' strokeDashoffset='12'>
                  <polyline className='rating__star-line' transform='rotate(0)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(72)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(144)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(216)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(288)' points='0 4,0 16' />
                </g>
              </g>
            </svg>
            <span className='rating__sr'>{t('ui.popUp.videoPopUp.rateVideoCall.starRating.2')}</span>
          </label>

          <label className='rating__label' htmlFor='rating-3'>
            <svg className='rating__star' width='32' height='32' viewBox='0 0 32 32' aria-hidden='true'>
              <g transform='translate(16,16)'>
                <circle className='rating__star-ring' fill='none' stroke='#000' strokeWidth='16' r='8' transform='scale(0)' />
              </g>
              <g stroke='#000' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                <g transform='translate(16,16) rotate(180)'>
                  <polygon
                    className='rating__star-stroke'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='none'
                  />
                  <polygon
                    className='rating__star-fill'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='#000'
                  />
                </g>
                <g transform='translate(16,16)' strokeDasharray='12 12' strokeDashoffset='12'>
                  <polyline className='rating__star-line' transform='rotate(0)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(72)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(144)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(216)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(288)' points='0 4,0 16' />
                </g>
              </g>
            </svg>
            <span className='rating__sr'>{t('ui.popUp.videoPopUp.rateVideoCall.starRating.3')}</span>
          </label>

          <label className='rating__label' htmlFor='rating-4'>
            <svg className='rating__star' width='32' height='32' viewBox='0 0 32 32' aria-hidden='true'>
              <g transform='translate(16,16)'>
                <circle className='rating__star-ring' fill='none' stroke='#000' strokeWidth='16' r='8' transform='scale(0)' />
              </g>
              <g stroke='#000' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                <g transform='translate(16,16) rotate(180)'>
                  <polygon
                    className='rating__star-stroke'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='none'
                  />
                  <polygon
                    className='rating__star-fill'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='#000'
                  />
                </g>
                <g transform='translate(16,16)' strokeDasharray='12 12' strokeDashoffset='12'>
                  <polyline className='rating__star-line' transform='rotate(0)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(72)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(144)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(216)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(288)' points='0 4,0 16' />
                </g>
              </g>
            </svg>
            <span className='rating__sr'>{t('ui.popUp.videoPopUp.rateVideoCall.starRating.4')}</span>
          </label>

          <label className='rating__label' htmlFor='rating-5'>
            <svg className='rating__star' width='32' height='32' viewBox='0 0 32 32' aria-hidden='true'>
              <g transform='translate(16,16)'>
                <circle className='rating__star-ring' fill='none' stroke='#000' strokeWidth='16' r='8' transform='scale(0)' />
              </g>
              <g stroke='#000' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                <g transform='translate(16,16) rotate(180)'>
                  <polygon
                    className='rating__star-stroke'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='none'
                  />
                  <polygon
                    className='rating__star-fill'
                    points='0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07'
                    fill='#000'
                  />
                </g>
                <g transform='translate(16,16)' strokeDasharray='12 12' strokeDashoffset='12'>
                  <polyline className='rating__star-line' transform='rotate(0)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(72)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(144)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(216)' points='0 4,0 16' />
                  <polyline className='rating__star-line' transform='rotate(288)' points='0 4,0 16' />
                </g>
              </g>
            </svg>
            <span className='rating__sr'>{t('ui.popUp.videoPopUp.rateVideoCall.starRating.5')}</span>
          </label>

          <p className='rating__display' data-rating='1' hidden>
            {t('ui.popUp.videoPopUp.rateVideoCall.starRating.Terrible')}
          </p>
          <p className='rating__display' data-rating='2' hidden>
            {t('ui.popUp.videoPopUp.rateVideoCall.starRating.Bad')}
          </p>
          <p className='rating__display' data-rating='3' hidden>
            {t('ui.popUp.videoPopUp.rateVideoCall.starRating.OK')}
          </p>
          <p className='rating__display' data-rating='4' hidden>
            {t('ui.popUp.videoPopUp.rateVideoCall.starRating.Good')}
          </p>
          <p className='rating__display' data-rating='5' hidden>
            {t('ui.popUp.videoPopUp.rateVideoCall.starRating.Excellent')}
          </p>
        </div>
      </form>
    </div>
  )
}

export default StarRating
