import { MessageResponse } from 'store/apiMain/mainApi'
import dataFormatMessages from 'utils/dataFormatMessages'
import ReadStatusIcon from 'utils/ReadStatus'

type SystemMessageProps = {
  children?: JSX.Element
  text: string
  createdAt: string
  role: 'CLIENT' | 'MODEL' | 'STAFF' | undefined
  message: MessageResponse
  userMe: any
}

const SystemMessage = ({ children, text, createdAt, role, message, userMe }: SystemMessageProps) => {
  return (
    <div className='chat__item approved'>
      <div className='chat__item-content'>
        <div>{children}</div>
        <p className='text-message'>{text}</p>
        <div className='info-message'>
          <p className='time'>{dataFormatMessages(createdAt, true)}</p>
          <ReadStatusIcon role={role} message={message} userMe={userMe} />
        </div>
      </div>
    </div>
  )
}

export default SystemMessage
