import React from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { useDispatch, useSelector } from 'react-redux'
import { popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { selectRole } from 'store/reducer/token/selector'
import Coin from '../Coin/Coin'
import { SvGArrowWhiteTop, SvgBlackGift1 } from '../../../../../../../../images/svg'
import TooltipGift from '../../../../../../../../UI/Tooltip/GiftTooltip/GiftTooltip'
import { selectTheme } from 'store/reducer/theme/selector'
import { toggleTheme } from 'store/reducer/theme/reducer'
import { useTranslation } from 'react-i18next'
const Actions: React.FC = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = React.useState(false)

  const videoChat = useSelector(selectVideoChatObg)?.room
  const role = useSelector(selectRole)

  const disabled = videoChat?.participantRole === undefined || videoChat?.ownerUserRole === undefined

  const handleSendComplaint = () => {
    dispatch(popUpOpen('complaint'))
    setIsOpen(false) // Закрываем попап
  }
  const { t } = useTranslation()

  const handleViewProfile = () => {
    const modelId = (() => {
      if (videoChat && videoChat.ownerUserRole === 'MODEL') {
        return String(videoChat.ownerUserId)
      }
      if (videoChat && videoChat.participantRole === 'CLIENT') {
        return String(videoChat.ownerUserId)
      }
      return null
    })()
    dispatch(popUpOpen('GirlPopUp'))
    dispatch(
      setPopUpData({
        data: {
          modelId,
        },
      }),
    )
    setIsOpen(false) // Закрываем попап
  }
  const currentTheme = useSelector(selectTheme)
  console.log(currentTheme)
  return (
    <Tippy
      className='gift'
      interactive
      trigger='click'
      content={
        <div className='lk-device-menu-custom'>
          <button
            className='lk-button'
            onClick={() => {
              dispatch(toggleTheme())
            }}
          >
            {t('components.liveKitCustom.component.button.actions.change_theme')}
          </button>
          <div className='actions-buttons'>
            {role === 'MODEL' && (
              <button
                className='lk-button'
                onClick={handleSendComplaint}
                disabled={disabled}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
              >
                {t('components.liveKitCustom.component.button.actions.send_complaint')}
              </button>
            )}
            {role === 'CLIENT' && (
              <button className='lk-button' onClick={handleViewProfile} disabled={disabled} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
                {t('components.liveKitCustom.component.button.actions.view_profile')}
              </button>
            )}
            {role === 'MODEL' && <Coin />}
          </div>
        </div>
      } // Передаем определенный ID
      placement='bottom'
    >
      <button className={`lk-button lk-button-custom-arrow ${isOpen ? 'open' : ''}`} aria-pressed={isOpen} onClick={() => setIsOpen(prev => !prev)}>
        {t('components.liveKitCustom.component.button.actions.actions_label')}

        <SvGArrowWhiteTop className={`arrow-icon ${isOpen ? 'rotated' : ''}`} />
      </button>
    </Tippy>
  )
}

export default Actions
