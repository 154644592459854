import React from 'react'
import Logo from '../../Logo/Logo'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { photoViewUserMobile1 } from '../../../../UI/PhotoViewUserMobile2/PhotoViewUserMobile1'
import Notifications from '../../../../elements/Notifications/Notifications'
import Language from '../../../../UI/Language/Language'
import { photoViewUserMobile } from '../../../../UI/PhotoViewUserMobile/PhotoViewUserMobile'
import { useTranslation } from 'react-i18next'

import { getAccount } from '../../../../store/reducer/accountController/selectors'
import { useSelector } from 'react-redux'
import UnreadCountMessages from '../../../../utils/UnreadCount/UnreadCountMessages/UnreadCountMessages'
import UnreadCountFriends from '../../../../utils/UnreadCount/UnreadCountFriends/UnreadCountFriends'

const Client = ({ isMenuOpen, setIsMenuOpen, data, dataUser, handleMenuToggle }: any) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const dataAmount = useSelector(getAccount)

  return (
    <>
      <header className='header' role='banner'>
        <div className='header-menu_left'>
          <Logo />
        </div>

        {/* Десктопное меню */}
        <nav id='nav__desc'>
          <ul>
            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/messages' ? 'active' : ''}>
              <div className='header_link'>
                <Link to='/messages'>{t('components.global.header.block.client.messages')}</Link>
                {/*<UnreadCountMessages />*/}
              </div>
            </li>
            <li
              onClick={() => setIsMenuOpen(false)}
              className={location.pathname === '/girls' || location.pathname.startsWith('/girl/') ? 'active' : ''}
            >
              <Link to='/girls'>{t('components.global.header.block.client.girls')}</Link>
            </li>
            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/profile' ? 'active' : ''}>
              <Link to='/profile'>{t('components.global.header.block.client.profile')}</Link>
            </li>
            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/friends' ? 'active' : ''}>
              <Link to='/friends'>{t('components.global.header.block.client.friends')}</Link>
              <UnreadCountFriends />
            </li>
            {/* Новая вкладка 'Рулетка' */}
            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/roulette' ? 'active' : ''}>
              <Link to='/roulette'>{t('components.global.header.block.client.roulette_label')}</Link>
            </li>
          </ul>
        </nav>

        <div className='header-menu_right'>
          <div className='headers'>
            <nav>
              <ul>
                <li>
                  <Language />
                </li>
                <li>
                  <Notifications />
                </li>
                <li onClick={() => navigate('/profile')}>
                  <div className='box'>
                    <div className='box-content_box'>
                      <div className='box-content_img'>
                        <div className='img-box'>{photoViewUserMobile(data?.client?.photoId)}</div>
                      </div>
                      <div className='box-content_right'>
                        <p className='name'>{dataUser?.name}</p>
                        <p className='header-menu-right-text'>
                          {t('components.global.header.block.client.coin')}: <span>{dataAmount?.coins}</span>
                        </p>
                        <p className='header-menu-right-text'>
                          {t('components.global.header.block.client.messages')}: <span>{dataAmount?.messages}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* Мобильное меню */}
        <nav style={{ right: 0 }} id='nav' className={`nav${isMenuOpen ? ' nav--open' : ''}`} role='navigation'>
          <ul
            className='nav__menu'
            id='menu'
            // @ts-ignore
            tabIndex='-1'
            aria-label='main navigation'
            hidden={!isMenuOpen}
          >
            <div className='box nav__item nav__link boxMenuMobile'>
              <div className='img-box'>{photoViewUserMobile1(data?.client?.photoId)}</div>
              <div className='box-content'>
                <p className='name'>{dataUser?.name}</p>
                <div className='box-content_box'>
                  <p>{t('components.global.header.block.client.coin')}:</p>
                  <p>{dataAmount?.coins}</p>
                </div>
                <div className='box-content_box'>
                  <p>{t('components.global.header.block.client.messages')}:</p>
                  <p>{dataAmount?.messages}</p>
                </div>
              </div>
            </div>

            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/messages' ? 'nav__item active' : 'nav__item'}>
              <Link className='nav__link' to='/messages'>
                {t('components.global.header.block.client.messages')}
              </Link>
            </li>
            <li
              onClick={() => setIsMenuOpen(false)}
              className={location.pathname === '/girls' || location.pathname.startsWith('/girl/') ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/girls'>
                {t('components.global.header.block.client.girls')}
              </Link>
            </li>
            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/profile' ? 'nav__item active' : 'nav__item'}>
              <Link className='nav__link' to='/profile'>
                {t('components.global.header.block.client.profile')}
              </Link>
            </li>
            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/friends' ? 'nav__item active' : 'nav__item'}>
              <Link className='nav__link' to='/friends'>
                {t('components.global.header.block.client.friends')}
              </Link>
            </li>
            {/* Новая вкладка 'Рулетка' на мобильном */}
            <li onClick={() => setIsMenuOpen(false)} className={location.pathname === '/roulette' ? 'nav__item active' : 'nav__item'}>
              <Link className='nav__link' to='/roulette'>
                {t('components.global.header.block.client.roulette_label')}
              </Link>
            </li>
          </ul>

          <div className='menuMobile'>
            <a
              href='#nav'
              className={`nav__toggle${isMenuOpen ? ' nav__toggle--open' : ''}`}
              role='button'
              aria-expanded={isMenuOpen}
              aria-controls='menu'
              onClick={handleMenuToggle}
            >
              <svg
                className={`menuicon${isMenuOpen ? ' menuicon--open' : ''}`}
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='50'
                viewBox='0 0 50 50'
              >
                <title>{t('components.global.header.block.client.toggleMenu')}</title>
                <g>
                  <line className='menuicon__bar' x1='13' y1='16.5' x2='37' y2='16.5' />
                  <line className='menuicon__bar' x1='13' y1='24.5' x2='37' y2='24.5' />
                  <line className='menuicon__bar' x1='13' y1='32.5' x2='37' y2='32.5' />
                  <circle className='menuicon__circle' r='23' cx='25' cy='25' />
                </g>
              </svg>
            </a>
          </div>

          <div
            style={{
              opacity: isMenuOpen ? 1 : 0,
              pointerEvents: isMenuOpen ? 'auto' : 'none',
            }}
            className='splash'
          />
        </nav>
      </header>
    </>
  )
}

export default Client
