import './scss/index.scss'
import { useTranslation } from 'react-i18next'

const NoResults = () => {
  const { t } = useTranslation()

  return (
    <div className='no-results'>
      <img src={require('../../images/img/no-results_1.png')} alt='No results' />
      <h2>{t('ui.noResults.noResults')}</h2>
    </div>
  )
}

export default NoResults
