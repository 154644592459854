import React, { useState, useEffect } from 'react'
import './scss/index.scss'
import { useLocation } from 'react-router-dom'
import HeaderRightAuthorizedMobile from './HeaderRightAuthorized/HeaderRightAuthorizedMobile'
import { useSelector } from 'react-redux'
import { getSelectorMessagesSelectedId } from 'store/reducer/messages/selectors'

const Header = () => {
  const location = useLocation() // Получаем текущий URL
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 997) // Проверяем ширину экрана при загрузке
  const [shouldRenderMobileHeader, setShouldRenderMobileHeader] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      const isNowMobileView = window.innerWidth < 997
      const isMessageViewWithSelectedId = window.innerWidth < 768 && location.pathname.includes('messages') && messagesSelectedId

      setIsMobileView(isNowMobileView)
      setShouldRenderMobileHeader(!isMessageViewWithSelectedId)
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [location.pathname, messagesSelectedId])

  return <>{shouldRenderMobileHeader && <HeaderRightAuthorizedMobile />}</>
}

export default Header
