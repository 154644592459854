import React from 'react'
import './scss/index.scss'
import documentPassport from 'images/img/example-id.jpg'
import documentFaceData from 'images/img/example-face.jpg'
import documentPassportVideo from 'images/img/passport.mp4'
import { useTranslation } from 'react-i18next'
interface IProps {
  example: number
}

const PhotoVerificationTooltip = ({ example }: IProps) => {
  const { t } = useTranslation()
  return (
    <div className='PhotoVerificationTooltip'>
      {example === 1 ? <img src={documentPassport} alt='' /> : null}
      {example === 2 ? <img src={documentFaceData} alt='' /> : null}
      {example === 3 ? (
        <video controls width='600'>
          <source src={documentPassportVideo} type='video/mp4' />
          {t('ui.tooltip.photoVerificationTooltip.browser_unsupported')}
        </video>
      ) : null}
    </div>
  )
}

export default PhotoVerificationTooltip
