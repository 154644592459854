// notificationsHandler.ts
import { TFunction } from 'i18next'

const notificationsHandler = (type: string | undefined, t: TFunction): string => {
  if (!type) {
    return t('components.utils.notificationsHandler.unknown_type')
  }

  switch (type) {
    case 'PERMISSIONS_UPDATED':
      return t('components.utils.notificationsHandler.notificationsHandler')
    case 'FRIEND_REQUEST':
      return t('components.utils.notificationsHandler.friend_request')
    case 'USER_DISABLED':
      return t('components.utils.notificationsHandler.user_disabled')
    case 'FRIEND_REQUEST_APPROVED':
      return t('components.utils.notificationsHandler.friend_request_approved')
    case 'MODEL_REQUEST_MAIN_INFO_APPROVED':
      return t('components.utils.notificationsHandler.model_request_main_info_approved')
    case 'MODEL_REQUEST_SECRET_DATA_APPROVED':
      return t('components.utils.notificationsHandler.model_request_secret_data_approved')
    case 'NEW_MODEL_REQUEST_MAIN_INFO':
      return t('components.utils.notificationsHandler.new_model_request_main_info')
    case 'NEW_MODEL_REQUEST_SECRET_DATA':
      return t('components.utils.notificationsHandler.new_model_request_secret_data')
    case 'MODEL_DELETED':
      return t('components.utils.notificationsHandler.model_deleted')
    case 'STAFF_DELETED':
      return t('components.utils.notificationsHandler.staff_deleted')
    case 'MODEL_FULLY_APPROVED_AND_CREATED':
      return t('components.utils.notificationsHandler.model_fully_approved_and_created')
    case 'MODEL_REGISTERED':
      return t('components.utils.notificationsHandler.model_registered')
    case 'COMPLAINT_CREATED':
      return t('components.utils.notificationsHandler.complaint_created')
    case 'SERVICE_ADDED':
      return t('components.utils.notificationsHandler.service_added')
    case 'SERVICE_UPDATED':
      return t('components.utils.notificationsHandler.service_updated')
    case 'SERVICE_DELETED':
      return t('components.utils.notificationsHandler.service_deleted')
    case 'MODEL_FULLY_APPROVED':
      return t('components.utils.notificationsHandler.model_fully_approved')
    case 'ACCOUNT_COINS_BALANCE_CHANGED':
      return t('components.utils.notificationsHandler.account_coins_balance_changed')
    case 'ACCOUNT_MESSAGES_BALANCE_CHANGED':
      return t('components.utils.notificationsHandler.account_messages_balance_changed')
    case 'GIFT_RECEIVED':
      return t('components.utils.notificationsHandler.gift_received')
    default:
      return t('components.utils.notificationsHandler.unknown_type')
  }
}

export default notificationsHandler
