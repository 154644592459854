import './scss/index.scss'
import { useTranslation } from 'react-i18next' // Import useTranslation

const Tabs = ({ activeTab, setActiveTab }: any) => {
  const { t } = useTranslation()

  return (
    <div className='friends__tabs'>
      <div className={`friends__tabs-item ${activeTab === undefined ? 'active' : ''}`} onClick={() => setActiveTab(undefined)}>
        <p>{t('components.global.friends.content.tabs.all_label')}</p>
      </div>
      <div className={`friends__tabs-item ${activeTab === 'Sent' ? 'active' : ''}`} onClick={() => setActiveTab('Sent')}>
        <p>{t('components.global.friends.content.tabs.sent_label')}</p>
      </div>
      <div className={`friends__tabs-item ${activeTab === 'Received' ? 'active' : ''}`} onClick={() => setActiveTab('Received')}>
        <p>{t('components.global.friends.content.tabs.received_label')}</p>
      </div>
    </div>
  )
}

export default Tabs
