import React, { useState, useEffect } from 'react'
import './scss/index.scss'
import type { FlagIcon } from 'UI/languageData/icons'
import { useTranslation } from 'react-i18next'
type Filter = {
  id: number
  flag?: FlagIcon
  text: string
  active: boolean
}

type FilterTooltipProps = {
  filter: Filter[]
  onClose: () => void
  onFilterToggle: (valueId: number) => void
  children?: React.ReactNode
}

const FilterTooltip = ({ filter = [], onClose, onFilterToggle, children }: FilterTooltipProps) => {
  const [filters, setFilters] = useState(filter)
  const { t } = useTranslation()

  useEffect(() => {
    setFilters(filter)
  }, [filter])

  const handleChange = (valueId: number) => {
    const updatedFilters = filters.map(f => (f.id === valueId ? { ...f, active: !f.active } : f))

    // Проверяем, есть ли хоть один активный фильтр
    const isAnyActive = updatedFilters.some(f => f.active)

    // Если нет ни одного активного фильтра, то не обновляем состояние
    if (!isAnyActive) return

    onFilterToggle(valueId)
    setFilters(updatedFilters)
  }

  return (
    <>
      <div className='filter-tooltip'>
        {/* <h3>Virt</h3> */}
        {filters?.map(value => (
          <div className='filter-tooltip__box' key={value.id}>
            <input
              type='checkbox'
              id={value.id.toString()}
              className='filter-tooltip__checkbox'
              checked={value.active}
              onChange={() => handleChange(value.id)}
            />
            <label htmlFor={value.id.toString()} className='filter-tooltip__label'>
              {value.flag ? (
                <p className='flex'>
                  {value.flag} {value.text}
                </p>
              ) : (
                <p>{value.text}</p>
              )}
            </label>
          </div>
        ))}
      </div>
      <div className='filter-buttons'>
        {children}
        <button onClick={onClose}>{t('ui.tooltip.filterTooltip.close_button')}</button>
      </div>
    </>
  )
}

export default FilterTooltip
