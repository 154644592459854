// Vote.tsx
import React, { useState, FormEvent } from 'react'
import './scss/index.scss'
import { useSearchVoteRoomsQuery } from 'store/apiMain/mainApi'
import dataFormatString from '../../../utils/dataFormatString'
import CopyButton from '../../../UI/CopyButton/CopyButton'

const Vote: React.FC = () => {
  // Локальный стейт для всех фильтров
  const [roomId, setRoomId] = useState<string>('')
  const [ownerUserId, setOwnerUserId] = useState<string>('')
  const [fromDate, setFromDate] = useState<string>('')
  const [toDate, setToDate] = useState<string>('')
  const [lessRating, setLessRating] = useState<number | undefined>(undefined)
  const [greaterRating, setGreaterRating] = useState<number | undefined>(undefined)
  const [withComment, setWithComment] = useState<boolean | undefined>(undefined)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  // Подготовим объект аргументов для запроса
  const queryArgs = {
    roomId: roomId || undefined,
    ownerUserId: ownerUserId || undefined,
    fromDate: fromDate || undefined,
    toDate: toDate || undefined,
    lessRating: lessRating !== undefined ? lessRating : undefined,
    greaterRating: greaterRating !== undefined ? greaterRating : undefined,
    withComment,
    page,
    pageSize,
  }

  // Выполняем запрос
  const { data, isLoading, isError, refetch } = useSearchVoteRoomsQuery(queryArgs)

  // Обработчик сабмита формы (можно просто перестраивать стейт, и RTK Query будет автоматически подгружать данные)
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    // Можно вызвать refetch() или просто довериться RTK Query,
    // который перезапросит, если изменились параметры queryArgs.
    refetch()
  }

  // Удобный метод для переключения страницы
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  return (
    <div className='voteAdmin'>
      <div className='vote-container'>
        <h1>Vote Rooms</h1>

        {/* Форма для фильтрации */}
        <form onSubmit={handleSubmit} className='filters'>
          <label>
            Room ID
            <input type='text' value={roomId} onChange={e => setRoomId(e.target.value)} />
          </label>

          <label>
            Owner User ID
            <input type='text' value={ownerUserId} onChange={e => setOwnerUserId(e.target.value)} />
          </label>

          <label>
            From Date
            <input type='date' value={fromDate} onChange={e => setFromDate(e.target.value)} />
          </label>

          <label>
            To Date
            <input type='date' value={toDate} onChange={e => setToDate(e.target.value)} />
          </label>

          <label>
            Less Rating
            <input type='number' value={lessRating ?? ''} onChange={e => setLessRating(e.target.value ? Number(e.target.value) : undefined)} />
          </label>

          <label>
            Greater Rating
            <input type='number' value={greaterRating ?? ''} onChange={e => setGreaterRating(e.target.value ? Number(e.target.value) : undefined)} />
          </label>

          <label>
            With Comment?
            <select
              value={withComment === undefined ? '' : withComment ? 'true' : 'false'}
              onChange={e => {
                const val = e.target.value
                setWithComment(val === '' ? undefined : val === 'true')
              }}
            >
              <option value=''>Любой</option>
              <option value='true'>Да</option>
              <option value='false'>Нет</option>
            </select>
          </label>

          <label>
            Page
            <input type='number' value={page} onChange={e => setPage(Number(e.target.value))} />
          </label>

          <label>
            Page Size
            <input type='number' value={pageSize} onChange={e => setPageSize(Number(e.target.value))} />
          </label>

          <button type='submit'>Применить фильтры</button>
        </form>

        {/* Отображение статуса */}
        {isLoading && <p>Загрузка данных...</p>}
        {isError && <p className='error'>Произошла ошибка при загрузке данных.</p>}

        {/* Таблица результатов */}
        {data && (
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>Room ID</th>
                  <th>Owner User ID</th>
                  <th>Time Start</th>
                  <th>Time Stop</th>
                  <th>Active Time</th>
                  <th>Rating</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {data.content.map(vote => (
                  <tr key={`${vote.roomId}-${vote.ownerUserId}`}>
                    <td>
                      {vote.roomId} <CopyButton textToCopy={vote.roomId} />
                    </td>
                    <td>
                      {vote.ownerUserId} <CopyButton textToCopy={vote.ownerUserId} />
                    </td>
                    <td>{dataFormatString(vote.roomTimeStart)}</td>
                    <td>{dataFormatString(vote.roomTimeStop)}</td>
                    <td>{vote.activeTime}</td>
                    <td>{vote.rating}</td>
                    <td>{vote.comment || '—'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='pagination'>
              <button onClick={() => handleChangePage(page - 1)} disabled={page <= 0}>
                Назад
              </button>
              <span>
                Страница {data.currentPage + 1} из {data.totalPages}
              </span>
              <button onClick={() => handleChangePage(page + 1)} disabled={page >= data.totalPages - 1}>
                Вперёд
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Vote
