import React from 'react'
import { toastError, toastInfo } from '../../elements/Notification/Notification'
import errorText from './erorText'
import errorMessages from './erorText'
import actionsError from './actionsError'

type IErrorResponse = {
  error: {
    status: number
    data: {
      type: string
      title: string
      status: number
      instance: string
      code: string
      requiredPermission: string
      requiredSelfPermission: string
    }
  }
  meta: {
    request: Record<string, unknown>
    response: Record<string, unknown>
  }
}

const notificationContainerError = (errorData: any) => {
  if (errorData?.error?.data?.type === 'MODEL_REQUEST_NOT_FOUND') return null
  if (errorData?.error?.data?.type === 'USER_EMAIL_NOT_VERIFIED') return null
  if (!errorData.meta?.response?.ok) {
    if (errorMessages[errorData?.error?.data?.code] !== undefined) {
      toastError(errorMessages[errorData?.error?.data?.code])
    } else {
      actionsError(errorData)
    }
  }
  return null
}

export default notificationContainerError
