import React, { useEffect, useState } from 'react'
import {
  ModelGetFullByIdApiArg,
  RelationshipCreateApiArg,
  RelationshipDeleteByIdApiArg,
  RelationshipPartialUpdateByIdApiArg,
  useModelGetFullByIdQuery,
  useRelationshipCreateMutation,
  useRelationshipDeleteByIdMutation,
  useRelationshipPartialUpdateByIdMutation,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import { useParams } from 'react-router-dom'
import Item from './Item'
import './scss/index.scss'
import { toastInfo, toastSuccess } from '../../../elements/Notification/Notification'
import Loading from '../../../elements/Loading/Loading'
import { useSelector } from 'react-redux'
import { selectorUpdateFriends } from '../../../store/reducer/friends/selectors'
import useSoundPlayer from '../../../hooks/useSoundPlayer'
import { useTranslation } from 'react-i18next'

interface IProps {
  modelIdForVideoChat?: string
}

const Girl: React.FC<IProps> = ({ modelIdForVideoChat }) => {
  const { modelId } = useParams<{ modelId?: string }>()
  const { playSound, stopAllSounds } = useSoundPlayer(false)

  const [addFriendsServer, { isSuccess }] = useRelationshipCreateMutation()
  const [friendsUpdate, { isSuccess: isSuccessUpdate }] = useRelationshipPartialUpdateByIdMutation()
  const [friendsDelete, { isSuccess: isSuccessDelete }] = useRelationshipDeleteByIdMutation()

  const [payloadModel, setPayloadModel] = useState<ModelGetFullByIdApiArg>()

  useEffect(() => {
    if (modelIdForVideoChat) {
      setPayloadModel({
        modelId: modelIdForVideoChat,
        idType: 'ID',
      })
    } else if (modelId) {
      setPayloadModel({
        modelId: String(modelId),
        idType: 'IDENTIFIER',
      })
    } else {
      setPayloadModel(undefined)
    }
  }, [modelId, modelIdForVideoChat])

  const {
    data,
    isLoading,
    refetch: refetchModel,
    isUninitialized,
  } = useModelGetFullByIdQuery(payloadModel ?? { modelId: '', idType: 'IDENTIFIER' }, { skip: !payloadModel })

  const { data: user, refetch: refetchMe } = useUserGetFullMeQuery()
  const updateFriends = useSelector(selectorUpdateFriends)
  const { t } = useTranslation()

  useEffect(() => {
    if (!isUninitialized) {
      refetchModel()
    }
  }, [updateFriends, isUninitialized, refetchModel])

  const addFavorites = () => {
    if (data?.relationshipWithCurrentUser?.id !== undefined) {
      const payload: RelationshipPartialUpdateByIdApiArg = {
        relationshipId: String(data?.relationshipWithCurrentUser?.id),
        relationshipPartialUpdateDto: {
          isFavorite: !data?.relationshipWithCurrentUser?.isClientFavorite,
        },
      }
      friendsUpdate(payload)
    } else {
      toastInfo(t('components.user.girl.girl.must_be_friends'))
    }
  }
  const addFriends = () => {
    const payload: RelationshipCreateApiArg = {
      relationshipCreateDto: {
        clientId: user.client.id,
        modelId: String(data?.model?.id),
        type: 'FRIEND_REQUEST',
        initiatedBy: 'CLIENT',
      },
    }
    addFriendsServer(payload)
  }

  const addFriendsRequest = () => {
    const payloadButton: RelationshipPartialUpdateByIdApiArg = {
      relationshipId: String(data?.relationshipWithCurrentUser?.id),
      relationshipPartialUpdateDto: {
        type: 'APPROVE_FRIEND_REQUEST',
      },
    }
    friendsUpdate(payloadButton)
  }

  const addDelete = () => {
    const payloadButton: RelationshipDeleteByIdApiArg = {
      // @ts-ignore
      relationshipId: data.relationshipWithCurrentUser?.id,
    }
    friendsDelete(payloadButton)
  }

  useEffect(() => {
    if (isSuccess) {
      toastSuccess(t('components.user.girl.girl.friend_request_sent'))
      refetchModel()
    }
  }, [isSuccess])
  useEffect(() => {
    if (isSuccessUpdate) {
      if (data?.relationshipWithCurrentUser?.isClientFavorite) {
        toastSuccess(t('components.user.girl.girl.removed_from_favorites'))
      } else {
        toastSuccess(t('components.user.girl.girl.added_to_favorites'))
        playSound('addFavorites')
      }

      refetchModel()
    }
  }, [isSuccessUpdate])

  useEffect(() => {
    if (isSuccessDelete) {
      toastSuccess(t('components.user.girl.girl.deleted_success'))
      refetchModel()
    }
  }, [isSuccessDelete])

  return (
    <div className='girl'>
      {isLoading ? (
        <Loading />
      ) : data !== undefined ? (
        <Item
          modelIdForVideoChat={modelIdForVideoChat}
          data={data}
          addFriends={addFriends}
          addFriendsRequest={addFriendsRequest}
          addFavorites={addFavorites}
          addDelete={addDelete}
          role='client'
        />
      ) : null}
    </div>
  )
}

export default Girl
