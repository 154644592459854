import React from 'react'

import './Tabs/index.scss'
import { Navigate, Route, Routes } from 'react-router-dom'
import Tabs from './Tabs/Tabs'
import Tab from './Tabs/Tab'
import MainInfo from './MainInfo/MainInfo'
import Edit from './MainInfo/Edit/Edit'
import EditRequest from './MainInfo/EditRequest/EditRequest'
import Documents from './Documents/Documents'
import EditDocument from './Documents/Edit/EditDocument'
import Legal from './Legal/Legal'
import Recommendations from './Recommendations/Recommendations'
import Withdrawal from '../Withdrawal/Withdrawal'
import Gifts from '../Gifts/Gifts'
import EmailPassword from './EmailPassword/EmailPassword'
import Home from '../../Global/VideoChat/Components/Home/Home'
import { useTranslation } from 'react-i18next'

const Profile = () => {
  const { t } = useTranslation()

  return (
    <div className='profileModel'>
      <Tabs>
        <Tab to='recommendations' label={t('components.model.profile.profile.recommendations_tab')} />
        <Tab to='legal' label={t('components.model.profile.profile.legal_tab')} />
        <Tab to='withdrawal' label={t('components.model.profile.profile.withdrawal_tab')} />
        <Tab to='main-info' label={t('components.model.profile.profile.main_info_tab')} />
        <Tab to='gifts' label={t('components.model.profile.profile.gifts_tab')} />
        <Tab to='emailPassword' label={t('components.model.profile.profile.account_tab')} />
        <Tab to='documents' label={t('components.model.profile.profile.documents_tab')} />
        {/*<Tab to="rules" label="Rules" />*/}
      </Tabs>
      <Routes>
        <Route path='/' element={<Navigate to='main-info' replace />} />

        <Route path='main-info' element={<MainInfo />} />
        <Route path='/main-info/room' element={<Home />} />
        <Route path='/main-info/room/:name' element={<Home />} />

        <Route path='main-info/edit' element={<Edit />} />
        <Route path='main-info/recommendations' element={<Recommendations />} />
        <Route path='main-info/edit/request' element={<EditRequest />} />

        <Route path='gifts' element={<Gifts />} />

        <Route path='emailPassword' element={<EmailPassword />} />
        <Route path='documents' element={<Documents />} />

        <Route path='documents/edit' element={<EditDocument />} />
        <Route path='documents/edit/request' element={<EditDocument />} />

        {/*<Route*/}
        {/*  path="rules"*/}
        {/*  element={*/}
        {/*    <div>*/}
        {/*      <Rules />*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path='legal'
          element={
            <div>
              <Legal />
            </div>
          }
        />
        <Route
          path='recommendations'
          element={
            <div>
              <Recommendations />
            </div>
          }
        />
        <Route path='withdrawal' element={<Withdrawal />} />
      </Routes>
    </div>
  )
}

export default Profile
