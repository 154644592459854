// dispatchRoleText.ts
import { useTranslation } from 'react-i18next'

export const dispatchRoleText = (
  initiatedBy: string,
  item: any, // При наличии можно указать тип RelationshipFullDto
  role: string,
): string => {
  const { t } = useTranslation()

  if (item.relationship.status === 'FRIEND_REQUEST') {
    if (initiatedBy === role) {
      return t('components.global.friends.content.item.dispatchRoleText.you_sent_request')
    } else {
      return t('components.global.friends.content.item.dispatchRoleText.you_received_request')
    }
  }
  if (item.relationship.status === 'BLOCKED') {
    if (initiatedBy === role) {
      return t('components.global.friends.content.item.dispatchRoleText.you_blocked')
    } else {
      return t('components.global.friends.content.item.dispatchRoleText.you_were_blocked')
    }
  }
  if (item.relationship.status === 'FRIENDS') {
    return t('components.global.friends.content.item.dispatchRoleText.you_are_friends')
  }
  return ''
}
