import React, { useEffect, useState } from 'react'
import { useModelRequestCreateMutation, useFileCreateMutation, useUserGetFullMeQuery, useModelRequestGetMeQuery } from 'store/apiMain/mainApi'
import { useGetFileMutation } from '../../../../store/apiMain/emptyApi'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormControl } from '../../../../layouts-elements/FormControl/FormControl'
import Photo, { DocumentBlock, DocumentBlockVideo } from '../../../../UI/Photo/Photo'

import './scss/index.scss'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import { setSteps1 } from '../../../../store/reducer/steps/reducer'
import { useNavigate } from 'react-router-dom'
import { setRole } from '../../../../store/reducer/token/reducer'
import { useTranslation } from 'react-i18next'
import WebcamRecorder from '../../../../UI/VideoRecored/VideoRecored'
import ErrorInput from '../../../../UI/ErrorInput/ErrorInput'

const Step2 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addRequest, { isSuccess }] = useModelRequestCreateMutation()
  const initialValues = {
    dataType: 'SECRET_DATA',
    realName: '',
  }
  const { t } = useTranslation()
  useEffect(() => {
    dispatch(setSteps1(true))
  }, [])
  const [checkedProverka, setCheckedProverka] = useState(false)
  const { data: modelData, isLoading, isError } = useUserGetFullMeQuery()
  const { data: modelRequest, isLoading: byIdLoading, isError: byIdError, refetch } = useModelRequestGetMeQuery()
  const validationSchema = yup.object().shape({
    realName: yup
      .string()
      .required(t('components.model.steps.step2.name_required'))
      .min(2, t('components.model.steps.step2.name_min_length'))
      .max(50, t('components.model.steps.step2.name_max_length'))
      // @ts-ignore
      .matches(/^[\p{L}-]+$/u, t('components.model.steps.step2.name_invalid')),
  })

  if (modelRequest === undefined && modelData?.model !== undefined) {
    navigate('/model/profile')
  }
  useEffect(() => {
    if (modelRequest?.secretData?.realName !== undefined) {
      setCheckedProverka(true)
    }
  }, [modelRequest])

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      documents: [...photoIds],
    }
    if (photoIds.length >= 2) {
      addRequest({ body })
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      toastSuccess(t('components.model.steps.step2.data_sent_success'))
      refetch()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isSuccess])
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  useEffect(() => {
    if (modelRequest?.secretData !== null && modelRequest?.secretData !== undefined) {
      const arrayPhoto = modelRequest?.secretData?.documents
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      formik.resetForm({
        values: {
          dataType: 'SECRET_DATA',
          // @ts-ignore
          realName: modelRequest?.secretData?.realName,
          // @ts-ignore
          documents: modelRequest?.secretData?.documents,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelRequest])

  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])

  // const [videos, setVideos] = useState<any[]>([])
  // const [videosIds, setVideosIds] = useState<string[]>([])

  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()
  const [clickSubmit, setClickSubmit] = useState(false)
  useEffect(() => {
    if (formik.errors.realName && formik.submitCount > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [formik.errors.realName, formik.submitCount])
  return (
    <div className='step2-information'>
      {checkedProverka ? (
        <div className='checkSteps'>
          <div className='checkSteps-block'>
            <p>{t('components.model.steps.step2.info_pending')}</p>
          </div>
        </div>
      ) : null}
      <form onSubmit={formik.handleSubmit}>
        <label className='name'>
          <span>{t('components.model.steps.step2.passport_name')}</span>
          <FormControl name='realName' type='text' formik={formik} placeholder='' />
        </label>
        <div className='step2-information-block'>
          <div className='step2-information-block__item photos'>
            <label>{t('components.model.steps.step2.identity_document')}</label>
            <div className='step2-information-block__item_box step2-photo'>
              <Photo
                photoIds={photoIds}
                photos={photos}
                setPhotos={setPhotos}
                addFile={addFile}
                getFile={getFile}
                setPhotoIds={setPhotoIds}
                document={true}
              />
              <ErrorInput error={t('components.model.steps.step2.min_two_photos')} touched={clickSubmit && photos.length <= 1} />
            </div>
          </div>
          <div className='step2-information-block__item'>
            <label>{t('components.model.steps.step2.video_label')}</label>
            <div className='photo'>
              <DocumentBlockVideo />
            </div>
            <div className='step2-information-block__item_box step2-video'>
              <WebcamRecorder />
            </div>
          </div>
          <div className='step2-information-block__item_box'>{/*<WebcamRecorder/>*/}</div>
          <div className='step2-information-block__button'>
            <button
              onClick={() => {
                setClickSubmit(true)
              }}
              type='submit'
            >
              {t('components.model.steps.step2.send_action')}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Step2
