import Select from 'react-dropdown-select'
import { useTranslation } from 'react-i18next'
import './scss/index.scss'
import { Option } from './types'
import { SvgChevronDown } from '../../images/svg'

interface SelectedAgeProps {
  selectedOptions: Option[]
  handleSelect: (selected: Option[]) => void
}

const SelectedAge = ({ selectedOptions, handleSelect }: SelectedAgeProps) => {
  const { t } = useTranslation()

  // Options with translation
  const options: Option[] = [
    {
      value: 'from 18 to 23',
      label: t('ui.selectedAge.From18_23'),
      name: 'EIGHTEEN__TWENTY_THREE',
    },
    {
      value: 'from 24 to 33',
      label: t('ui.selectedAge.From24_33'),
      name: 'TWENTY_FOUR__THIRTY_THREE',
    },
    {
      value: 'from 34 to 44',
      label: t('ui.selectedAge.From34_44'),
      name: 'THIRTY_FOUR__FORTY_FOUR',
    },
    {
      value: 'over 45',
      label: t('ui.selectedAge.over45'),
      name: 'FORTY_FIVE__MORE',
    },
  ]

  return (
    <Select
      dropdownHandleRenderer={({ state }) => (
        <button type='button' className='dropdown-select-button'>
          <SvgChevronDown className={`dropdown-select-icon-${state.dropdown ? 'up' : 'down'}`} />
        </button>
      )}
      searchable={false}
      options={options}
      values={selectedOptions}
      onChange={handleSelect}
      closeOnSelect={true}
      closeOnScroll={true}
      dropdownGap={0}
      color='#4389ff'
    />
  )
}

export default SelectedAge
