import { useEffect, useState } from 'react'
import './scss/index.scss'
import { useModelRequestGetMeQuery } from 'store/apiMain/mainApi'
import { useSelector } from 'react-redux'
import { getSocketStepApprovedModel } from '../../../../store/reducer/socket/selector'
import { useTranslation } from 'react-i18next'
import Header from 'Components/Model/Header/Header'
import StepNavigation from './StepNavigation'

const StepHeader = ({ stepId }: { stepId: string | undefined }) => {
  const socketNoStepApprovedModel = useSelector(getSocketStepApprovedModel)
  const { t } = useTranslation()

  const [steps, setSteps] = useState([
    { id: 1, status: 'IN_PROGRESS', name: t('components.model.steps.stepHeader.title') },
    { id: 2, status: 'IN_PROGRESS', name: t('components.model.steps.stepHeader.Confidential') },
  ])

  const { data: modelRequest, isLoading: byIdLoading, isError: byIdError, refetch } = useModelRequestGetMeQuery()

  useEffect(() => {
    refetch()
  }, [socketNoStepApprovedModel])

  useEffect(() => {
    if (modelRequest !== null && modelRequest !== undefined) {
      setSteps(prevSteps =>
        prevSteps.map(step => {
          if (modelRequest?.mainInfo?.isApproved === false) {
            if (step.id === 1) {
              return { ...step, status: 'PENDING' }
            }
          }
          return step
        }),
      )
    }

    if (modelRequest?.request?.state === 'CREATE_NEW') {
      setSteps(prevSteps =>
        prevSteps.map(step => {
          if (modelRequest?.mainInfo?.isApproved) {
            if (step.id === 1) {
              return { ...step, status: 'COMPLETED' }
            }
          }
          if (step.id === 2) {
            return { ...step, status: 'IN_PROGRESS' }
          }
          return step
        }),
      )
    }

    if (modelRequest?.secretData?.realName !== undefined) {
      setSteps(prevSteps =>
        prevSteps.map(step => {
          if (step.id === 2) {
            return { ...step, status: 'PENDING' }
          }
          return step
        }),
      )
    }

    if (modelRequest?.secretData?.isApproved) {
      setSteps(prevSteps =>
        prevSteps.map(step => {
          if (step.id === 2) {
            return { ...step, status: 'COMPLETED' }
          }
          return step
        }),
      )
    }
  }, [modelRequest])

  return (
    <>
      <header className='step-header__main-header'>
        <Header />
      </header>
      <header className='step-header__step-navigation'>
        <h1 className='step-header__title'>{t('components.model.steps.stepHeader.title')}</h1>
        <div className='step-header__steps'>
          {steps.map((step, index) => (
            <StepNavigation key={step.id} status={step.status} name={step.name} index={index} stepId={stepId} />
          ))}
        </div>
      </header>
    </>
  )
}

export default StepHeader
