// socketSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISocketStore, ISubscribeResponse } from './types'

const initialState: ISocketStore = {
  socketToken: null,
  socket: null,
  isConnected: false,
  activityInfoSocket: null,
  notificationSocket: null,
  refreshToken: false,
  changeProfileModel: false,
  approvedModel: false,
  subscribeUser: null,
  socketEventGift: false,
  socketConnectionsTimer: false,
}

const socketSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setSocketToken: (state, action: PayloadAction<any>) => {
      state.socketToken = action.payload
    },
    setSocket: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload
    },
    setWebSocket: (state, action: PayloadAction<WebSocket | null>) => {
      state.socket = action.payload
    },
    setActivityInfoSocket: (state, action: PayloadAction<ISubscribeResponse>) => {
      state.activityInfoSocket = action.payload
    },
    setSubscribe: (state, action: PayloadAction<ISubscribeResponse>) => {
      if (state.subscribeUser) {
        const updatedBody = action.payload.body.map(newUser => {
          const existingUser = state.subscribeUser?.body.find(user => user.userId === newUser.userId)
          return existingUser ? { ...existingUser, ...newUser } : newUser
        })
        const newUsers = state.subscribeUser.body.filter(existingUser => !action.payload.body.some(newUser => newUser.userId === existingUser.userId))
        state.subscribeUser = {
          ...state.subscribeUser,
          body: [...updatedBody, ...newUsers],
          timestamp: action.payload.timestamp,
          type: action.payload.type,
        }
      } else {
        state.subscribeUser = action.payload
      }
    },
    setNotificationSocket: (state, action: PayloadAction<any>) => {
      state.notificationSocket = action.payload.type
      if (action.payload.body.type === 'MODEL_FULLY_APPROVED') {
        state.refreshToken = true
      }
      if (action.payload.body.type === 'MODEL_REQUEST_MAIN_INFO_APPROVED' || action.payload.body.type === 'MODEL_REQUEST_SECRET_DATA_APPROVED') {
        state.approvedModel = !state.approvedModel
      }
      if (action.payload.body.type === 'MODEL_FULLY_APPROVED') {
        state.refreshToken = true
        state.changeProfileModel = true
        window.location.href = '/model/profile/main-info'
      }
    },
    setChangeProfileModel: state => {
      state.changeProfileModel = null
    },
    setCloseSocket: state => {
      state.socketToken = null
      state.socket = null
      state.isConnected = false
    },
    changeSocketEventGift: state => {
      state.socketEventGift = !state.socketEventGift
    },
    setSocketConnectionsTimer: (state, action: PayloadAction<boolean>) => {
      state.socketConnectionsTimer = action.payload
    },
  },
})

export const {
  setSocketToken,
  setSocket,
  setWebSocket,
  setActivityInfoSocket,
  setNotificationSocket,
  setCloseSocket,
  setSubscribe,
  changeSocketEventGift,
  setChangeProfileModel,
  setSocketConnectionsTimer,
} = socketSlice.actions

export default socketSlice.reducer
