import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useWebSocket from '../../socket/useWebSocket'
import { getSocketIsConnected } from '../../store/reducer/socket/selector'
import { selectVideoChatParticipants } from 'store/reducer/videoChat/selector'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'

const ActivityDetector: React.FC = () => {
  const { sendActivityUpdate } = useWebSocket()
  const socketIsConnected = useSelector(getSocketIsConnected)
  const activityTimeout = useRef<NodeJS.Timeout | null>(null)
  const active = useRef<boolean>(false)

  const videoChatParticipants = useSelector(selectVideoChatParticipants)
  const { data: userMe } = useUserGetFullMeQuery()
  const userId = userMe?.userData?.user?.id

  /**
   * Храним последний отправленный ВИДЕО-статус (WAITING_IN_ROOM или ON_CALL).
   * Если статус тот же, повторно не отправляем.
   */
  const lastVideoChatStatus = useRef<'WAITING_IN_ROOM' | 'ON_CALL' | null>(null)

  /**
   * Минимальный интервал (в мс) между отправками ONLINE.
   * Например, 10 секунд.
   */
  const ONLINE_SEND_INTERVAL = 10_000

  /**
   * Храним время (timestamp) последней отправки ONLINE,
   * чтобы не отправлять статус чаще, чем раз в ONLINE_SEND_INTERVAL.
   */
  const lastOnlineSentAt = useRef<number>(0)

  /**
   * Функция для проверки статусов, связанных с видеочатом.
   * Возвращает true, если статус видеочата (WAITING_IN_ROOM или ON_CALL) был отправлен.
   * Возвращает false, если никакой из статусов не отправлен и нужно проверять ONLINE.
   */
  const handleVideoChatStatus = (): boolean => {
    if (!socketIsConnected) return true // нет смысла дальше проверять, если нет соединения

    if (videoChatParticipants) {
      // Ожидание в комнате
      if (videoChatParticipants.localParticipant === userId && videoChatParticipants.remoteParticipant === null) {
        // Проверяем, не отправляли ли мы уже WAITING_IN_ROOM
        if (lastVideoChatStatus.current !== 'WAITING_IN_ROOM') {
          sendActivityUpdate('WAITING_IN_ROOM')
          lastVideoChatStatus.current = 'WAITING_IN_ROOM'
        }
        return true
      }

      // Участник на звонке
      if (videoChatParticipants.localParticipant !== null && videoChatParticipants.remoteParticipant !== null) {
        // Проверяем, не отправляли ли мы уже ON_CALL
        if (lastVideoChatStatus.current !== 'ON_CALL') {
          sendActivityUpdate('ON_CALL')
          lastVideoChatStatus.current = 'ON_CALL'
        }
        return true
      }
    }

    // Если не WAITING_IN_ROOM и не ON_CALL — сбрасываем,
    // чтобы позволить отправить эти статусы снова при следующем входе
    lastVideoChatStatus.current = null

    // Возвращаем false, чтобы в sendStatusUpdate проверить ONLINE
    return false
  }

  /**
   * Функция для отправки статуса ONLINE, если пользователь активен.
   * Срабатывает с ограничением по времени (throttle).
   */
  const handleOnlineStatus = () => {
    if (!socketIsConnected) return

    if (active.current) {
      const now = Date.now()
      // Проверяем, прошло ли достаточно времени с последней отправки
      if (now - lastOnlineSentAt.current >= ONLINE_SEND_INTERVAL) {
        sendActivityUpdate('ONLINE')
        lastOnlineSentAt.current = now
      }
    }
  }

  /**
   * Общая функция: сначала пытаемся отправить статус видеочата (WAITING_IN_ROOM/ON_CALL).
   * Если видео-статус не отправлен (handleVideoChatStatus вернул false),
   * то отправляем ONLINE (если пользователь активен).
   */
  const sendStatusUpdate = () => {
    const videoChatStatusSent = handleVideoChatStatus()
    if (!videoChatStatusSent) {
      handleOnlineStatus()
    }
  }

  /**
   * При любом событии (mousemove, keydown, scroll) ставим активность
   * в true на 1 секунду, после чего снова сбрасываем.
   */
  const handleActivity = () => {
    active.current = true
    if (activityTimeout.current) {
      clearTimeout(activityTimeout.current)
    }
    activityTimeout.current = setTimeout(() => {
      active.current = false
    }, 1000)
  }

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll']
    events.forEach(event => window.addEventListener(event, handleActivity))

    if (socketIsConnected) {
      // При старте, если есть соединение, считаем пользователя активным
      handleActivity()
    }

    // Раз в секунду отправляем статус
    const interval = setInterval(() => {
      sendStatusUpdate()
    }, 100)

    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity))
      if (activityTimeout.current) {
        clearTimeout(activityTimeout.current)
      }
      clearInterval(interval)
    }
  }, [socketIsConnected, videoChatParticipants, userId])

  return null
}

export default ActivityDetector
