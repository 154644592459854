// Item.tsx
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { photoViewUser } from '../../../../../UI/PhotoViewUser/PhotoViewUser'
import Status from 'UI/Status/Status'
import { getSubscribeUser } from 'store/reducer/socket/selector'
import { dispatchRoleText } from './dispatchRoleText'
import ActionButtons from './ActionButtons'
import './scss/index.scss'

const Item = ({ role, item, filter, friendsDelete, friendsUpdate, userId }: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Новая логика: если элемент имеет статус BLOCKED и блокировка инициирована не мной,
  // то элемент не рендерится (то есть, меня кто-то заблокировал)
  if (item.relationship.status === 'BLOCKED' && item.relationship.initiatedBy !== role) {
    return null
  }

  // Получаем текст статуса с помощью helper-функции
  const roleText = dispatchRoleText(item.relationship?.initiatedBy, item, role)

  const subscribeUser = useSelector(getSubscribeUser)
  const onlineStatus =
    subscribeUser?.body?.find((user: any) => user.userId === (role !== 'MODEL' ? item.model.model.id : item.client.client.id))?.activityStatus ??
    'noStatus'

  return (
    <div
      onClick={event => {
        event.stopPropagation()
        if (role === 'CLIENT') {
          navigate(`/girl/${item.model?.model.identifier}`)
        }
      }}
      key={item.relationship?.id}
      className={role === 'CLIENT' ? 'friends__content-item model-link' : 'friends__content-item'}
    >
      <div className='flex-column'>
        <div className='item__img'>
          {role !== 'MODEL' ? <img src={item?.model?.profilePhoto?.file?.url} alt='' /> : photoViewUser(item?.client?.client?.photoId)}
          <Status status={onlineStatus} />
        </div>
        <div className='item__content flex-column'>
          <h6 className='item__content-title'>{role === 'CLIENT' ? item.model?.model?.name : item.client?.client?.name}</h6>
          <p className='item__content-text'>{roleText}</p>
        </div>
        <ActionButtons filter={filter} item={item} role={role} friendsDelete={friendsDelete} friendsUpdate={friendsUpdate} />
      </div>
    </div>
  )
}

export default Item
