import React, { FC, useEffect, useState } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import { ComplaintCreateApiArg, useComplaintCreateMutation } from 'store/apiMain/mainApi'
import { getSelectorMessagesSelectedUser } from '../../../store/reducer/messages/selectors'
import { toastSuccess } from '../../../elements/Notification/Notification'
import { getPopUpData } from '../../../store/reducer/popUp/selectors'
import { selectRole } from 'store/reducer/token/selector'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { selectorRooms } from 'store/reducer/rooms/selector'
import SelectItem from '../../../Components/Model/Steps/Step1/SelectItem'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

const Complaint: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const selectedDataPopUp = useSelector(getPopUpData)
  const { t } = useTranslation()

  const complaintReasons = [
    {
      value: 'Другая причина:',
      label: t('ui.popUp.complaint.Other reason:'),
    },
    {
      value: 'Спам',
      label: t('ui.popUp.complaint.Spam'),
    },
    {
      value: 'Вовлечение детей (эксплуатация)',
      label: t('ui.popUp.complaint.Child exploitation'),
    },
    {
      value: 'Домогательства или угрозы',
      label: t('ui.popUp.complaint.Harassment or threats'),
    },
    {
      value: 'Мошенничество',
      label: t('ui.popUp.complaint.Fraud'),
    },
    {
      value: 'Видеотрансляция',
      label: t('ui.popUp.complaint.Video broadcast'),
    },
    {
      value: 'Запись трансляции',
      label: t('ui.popUp.complaint.Broadcast recording'),
    },
    {
      value: 'Распространение личных данных',
      label: t('ui.popUp.complaint.Dissemination of personal data'),
    },
  ]

  const [blocked, setBlocked] = useState(false)
  const [addComplaint, { isSuccess }] = useComplaintCreateMutation()

  const [selectedReason, setSelectedReason] = useState('')

  const accusedId = useSelector(getSelectorMessagesSelectedUser)?.user?.id
  const role = useSelector(selectRole)
  const videoChat = useSelector(selectVideoChatObg)?.room

  useEffect(() => {
    setBlocked(selectedDataPopUp?.data ? selectedDataPopUp?.data : false)
  }, [selectedDataPopUp])

  const interlocutorId = (() => {
    if (videoChat && videoChat?.ownerUserRole === role) {
      return String(videoChat.participantId)
    }
    if (videoChat && videoChat?.participantRole === role) {
      return String(videoChat.ownerUserId)
    }
    return null
  })()

  useEffect(() => {
    if (isSuccess) {
      toastSuccess(t('ui.popUp.complaint.toast_success'))
      closeModal()
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: values => {
      let accused: string | undefined

      if (videoChat?.ownerUserRole !== undefined && videoChat?.participantRole !== undefined) {
        if (!interlocutorId) {
          console.error('interlocutorId не определён')
          return
        }
        accused = interlocutorId
      } else {
        if (!accusedId) {
          console.error('accusedId не определён')
          return
        }
        accused = accusedId
      }

      const payload: ComplaintCreateApiArg = {
        complaintCreateDto: {
          message: values.message,
          accused,
          initiatedWithBlock: blocked,
        },
      }

      addComplaint(payload)
    },
  })

  const handleReasonSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVal = e.target.value
    setSelectedReason(selectedVal)
    formik.setFieldValue('message', selectedVal)
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value

    // Если выбрана причина не 'Другая причина:' и новое значение не начинается с выбранной причины,
    // значит пользователь начал стирать или менять первоначальный текст причины.
    if (selectedReason && selectedReason !== 'Другая причина:' && !newValue.startsWith(selectedReason)) {
      setSelectedReason('Другая причина:')
      formik.setFieldValue('message', t('ui.popUp.complaint.Other reason:') + ':')
      return
    }

    // Если пользователь просто дополняет текст (начинается с причины) или выбрана 'Другая причина:',
    // тогда можно просто обновлять значение
    formik.setFieldValue('message', newValue)
  }

  const handleSendComplaint = () => {
    formik.handleSubmit()
  }

  return (
    <>
      <Popup closeOnDocumentClick={false} closeOnEscape={true} open={open}>
        <div className='modal'>
          <div className='popup'>
            <div className='Complaint'>
              <div className='Complaint-block'>
                <div className='Complaint-block_box'>
                  <h1>{t('ui.popUp.complaint.complaint_title')}</h1>
                  <select onChange={handleReasonSelect} value={selectedReason}>
                    <option value='' disabled>
                      {t('ui.popUp.complaint.select_option')}{' '}
                    </option>
                    {complaintReasons.map(reason => (
                      <option key={reason.value} value={reason.value}>
                        {reason.label}
                      </option>
                    ))}
                  </select>

                  <textarea
                    name='message'
                    value={formik.values.message}
                    onChange={handleTextChange}
                    placeholder='Describe the issue or select a predefined reason above'
                  />

                  <div className='Complaint-block_box_checked'>
                    <p>{t('ui.popUp.complaint.blocked_status')}</p>
                    <input type='checkbox' checked={blocked} onChange={() => setBlocked(!blocked)} />
                  </div>
                </div>

                <div className='Complaint-block_button'>
                  <button onClick={() => closeModal()} type='button'>
                    {t('ui.popUp.complaint.close_action')}
                  </button>
                  <button onClick={handleSendComplaint} type='submit'>
                    {t('ui.popUp.complaint.send_complaint')}{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default Complaint
