import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const NoRoom = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/') // Редирект на главную страницу
    }, 2000) // 2000 мс = 2 секунды

    return () => clearTimeout(timer) // Очистка таймера при размонтировании компонента
  }, [navigate])

  return <div>{t('components.global.videoChat.components.noRoom.no_rooms')}</div>
}

export default NoRoom
