import React, { useEffect, useState, useRef } from 'react'
import { useParticipants } from '@livekit/components-react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubscribeUser } from 'store/reducer/socket/selector'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { toastInfo } from '../../../../../../elements/Notification/Notification'
import { pauseTimer, startTimer } from 'store/reducer/videoChatWithdrawalMoney/reducer'
import useSubscribeToNewUsers from '../../../../../../hooks/socketTs/useSubscribeToNewUsers'
import useWebSocket from '../../../../../../socket/useWebSocket'
import { toast } from 'react-toastify'
import { sendActivityUpdate } from '../../../../../../socket/websocketActions'
import { popUpOpen } from 'store/reducer/popUp/reducer'
import { selectVideoChatCreatedByModelBoolean } from 'store/reducer/videoChat/selector'
import { useTranslation } from 'react-i18next'
interface IProps {
  onLeave: () => void
}

const HandlerLeaveRooms: React.FC<IProps> = ({ onLeave }) => {
  const dispatch = useDispatch()
  const { sendActivityUpdate } = useWebSocket()
  // 1. Получаем текущего юзера (для сравнения)
  const { data: userMe } = useUserGetFullMeQuery()
  const currentUserId = userMe?.userData?.user?.id

  // 2. Список участников из LiveKit, чтобы узнать Id собеседника
  const participants = useParticipants()
  const otherParticipantIds = participants.map(p => p.identity).filter(id => id !== currentUserId)

  // 3. Достаём данные о статусах из Redux
  const subscribeUser = useSelector(getSubscribeUser)
  const { sendSubscribe } = useWebSocket()

  // 4. ID “отслеживаемого” участника
  const [trackedUserId, setTrackedUserId] = useState<string | null>(null)
  const { t } = useTranslation()
  useEffect(() => {
    if (!trackedUserId && otherParticipantIds.length > 0) {
      setTrackedUserId(otherParticipantIds[0])
    }
  }, [trackedUserId, otherParticipantIds])

  // 5. Подписываемся на обновления статусов
  useSubscribeToNewUsers({
    userId: trackedUserId ? [trackedUserId] : [],
    sendSubscribe,
    subscribeUser,
  })

  // 6. Ищем «отслеживаемого» пользователя в subscribeUser
  const targetUserActivity = subscribeUser?.body?.find(item => item.userId === trackedUserId)

  // 7. Храним предыдущий статус
  const [prevActivityStatus, setPrevActivityStatus] = useState<string | null>(null)

  // 8. Храним момент, когда собеседник "пропал" (OFFLINE)
  const [lostConnectionAt, setLostConnectionAt] = useState<number | null>(null)

  // 9. Дебаунс для "онлайн-выхода"
  //    Если пользователь переходит ON_CALL -> ONLINE, ставим pendingOnlineAt = now,
  //    и ждём 3 секунды. Если за это время статус не сменился на OFFLINE/ON_CALL, то onLeave().
  const [pendingOnlineAt, setPendingOnlineAt] = useState<number | null>(null)
  const pendingOnlineTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const videoChatCreatedByModelBoolean = useSelector(selectVideoChatCreatedByModelBoolean)
  // --- useEffect для логики статусов ---
  useEffect(() => {
    if (!targetUserActivity) return

    const { activityStatus } = targetUserActivity

    // 1) DEBUG LOG
    console.log(`DEBUG: prev=${prevActivityStatus}, current=${activityStatus}, lost=${lostConnectionAt}, pendingOnline=${pendingOnlineAt}`)

    // 2) Если был (ON_CALL) -> (OFFLINE), ставим lostConnectionAt, ждём 10 секунд
    if (activityStatus === 'OFFLINE' && prevActivityStatus === 'ON_CALL' && !lostConnectionAt) {
      console.log('DEBUG: ON_CALL -> OFFLINE, запускаем 10-секундный таймер')
      setLostConnectionAt(Date.now())

      // Если случайно стоял «ожидаемый ONLINE», сбросим (так как теперь OFFLINE главнее)
      if (pendingOnlineTimeoutRef.current) {
        clearTimeout(pendingOnlineTimeoutRef.current)
        pendingOnlineTimeoutRef.current = null
      }
      setPendingOnlineAt(null)
    }
    // 3) OFFLINE -> ON_CALL: сбрасываем lostConnectionAt (вернулся)
    if (lostConnectionAt && activityStatus === 'ON_CALL') {
      if (offlineToastIdRef.current) {
        toast.dismiss(offlineToastIdRef.current)
        offlineToastIdRef.current = null
      }
      toastInfo(t('components.liveKitCustom.handler.handlerLeaveRooms.participant_returned'))
      console.log('DEBUG: OFFLINE -> ON_CALL, сбрасываем lostConnectionAt')
      dispatch(startTimer())
      setLostConnectionAt(null)
    }

    // 4) ON_CALL -> ONLINE (без OFFLINE) — не вызываем onLeave() сразу,
    //    а ставим "pendingOnlineAt" и ждём 3 секунды, вдруг придёт OFFLINE
    if (activityStatus === 'ONLINE' && prevActivityStatus === 'ON_CALL' && !lostConnectionAt) {
      console.log('DEBUG: ON_CALL -> ONLINE: запускаем debounce 3сек')
      setPendingOnlineAt(Date.now())

      // Если уже был какой-то старый таймер, почистим
      if (pendingOnlineTimeoutRef.current) {
        clearTimeout(pendingOnlineTimeoutRef.current)
      }

      // Ставим таймер на 1 секунды
      // Это нужно для того чтобы у нас пофиксить баг, с сервера перед офлайном прилеаткет статус онлайн, где это баг не не проверял написал просто эту проверку
      pendingOnlineTimeoutRef.current = setTimeout(() => {
        console.log('DEBUG: 1 сек истекло. ONLINE не сменился -> onLeave()')
        sendActivityUpdate('ONLINE')
        if (videoChatCreatedByModelBoolean) {
          dispatch(popUpOpen('loading'))
        }
        toastInfo(t('components.liveKitCustom.handler.handlerLeaveRooms.participant_left'))
        onLeave()
      }, 300)
    }

    // 5) Если пришёл OFFLINE, а у нас pendingOnlineAt != null, значит
    //    это "глюк" (сначала ONLINE, потом OFFLINE). Сбрасываем debounce
    if (pendingOnlineAt && activityStatus === 'OFFLINE') {
      console.log('DEBUG: ONLINE -> OFFLINE: сервер глюк, сбрасываем debounce')
      if (pendingOnlineTimeoutRef.current) {
        clearTimeout(pendingOnlineTimeoutRef.current)
        pendingOnlineTimeoutRef.current = null
      }
      setPendingOnlineAt(null)

      // Запускаем логику OFFLINE (если он действительно OFFLINE)
      if (prevActivityStatus === 'ON_CALL' && !lostConnectionAt) {
        setLostConnectionAt(Date.now())
      }
    }

    // 6) Если пришёл ON_CALL, а был pendingOnlineAt, значит человек
    //    "вернулся" (или вообще не выходил). Сбрасываем debounce
    if (pendingOnlineAt && activityStatus === 'ON_CALL') {
      console.log('DEBUG: ONLINE -> ON_CALL: сбрасываем pendingOnlineAt')
      if (pendingOnlineTimeoutRef.current) {
        clearTimeout(pendingOnlineTimeoutRef.current)
        pendingOnlineTimeoutRef.current = null
      }
      setPendingOnlineAt(null)
    }

    setPrevActivityStatus(activityStatus)
  }, [targetUserActivity, prevActivityStatus, lostConnectionAt, pendingOnlineAt, dispatch, onLeave])
  const offlineToastIdRef = useRef<string | number | null>(null)

  // --- useEffect для OFFLINE (10 секунд) ---
  useEffect(() => {
    if (!lostConnectionAt) return

    dispatch(pauseTimer())
    offlineToastIdRef.current = toastInfo(t('components.liveKitCustom.handler.handlerLeaveRooms.room_close_warning'), 10000)
    const timerId = setTimeout(() => {
      console.log('DEBUG: 10 секунд OFFLINE истекли -> onLeave()')
      onLeave()
    }, 10000)

    return () => clearTimeout(timerId)
  }, [lostConnectionAt, dispatch, onLeave])

  return null
}

export default HandlerLeaveRooms
