import React from 'react'
import Logo from '../../Logo/Logo'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { photoViewUserMobile1 } from '../../../../UI/PhotoViewUserMobile2/PhotoViewUserMobile1'
import Notifications from '../../../../elements/Notifications/Notifications'
import Language from '../../../../UI/Language/Language'
import { photoViewUserMobile } from '../../../../UI/PhotoViewUserMobile/PhotoViewUserMobile'
import { useTranslation } from 'react-i18next'
import { photoViewClient } from '../../../../UI/Photo/photoViewClient'
import { useSelector } from 'react-redux'
import { getAccount } from 'store/reducer/accountController/selectors'
import UnreadCountFriends from '../../../../utils/UnreadCount/UnreadCountFriends/UnreadCountFriends'

const Model = ({ isMenuOpen, setIsMenuOpen, data, dataUser, handleMenuToggle }: any) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const dataAmount = useSelector(getAccount)

  return (
    <>
      <header className='header' role='banner'>
        <div className='header-menu_left'>
          <Logo />
        </div>
        <nav id='nav__desc'>
          <ul>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/messages' ? 'active' : ''}
            >
              <Link to='/model/messages'>{t('components.global.header.block.model.messages')}</Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/people' ? 'active' : ''}
            >
              <Link to='/model/people'>{t('components.global.header.block.model.people')}</Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname.startsWith('/model/profile') ? 'active' : ''}
            >
              <Link to='/model/profile'>{t('components.global.header.block.model.profile')}</Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/friends' ? 'active' : ''}
            >
              <Link to='/model/friends'>{t('components.global.header.block.model.friends')}</Link>
              <UnreadCountFriends />
            </li>
          </ul>
        </nav>

        <div className='header-menu_right'>
          <div className='headers'>
            <nav>
              <ul>
                <li>
                  <Notifications />
                </li>
                <li>
                  <Language />
                </li>
                <li
                  onClick={() => {
                    navigate(`/model/profile`)
                  }}
                >
                  <div className='box'>
                    <div className='box-content_box'>
                      <div className='box-content_img'>
                        <div className='img-box'>
                          <img src={data?.profilePhoto?.file?.url} alt='' />
                        </div>
                      </div>
                      <div className='box-content_right'>
                        <p className='name'>{dataUser?.name}</p>
                        <div className='box-content_box'>
                          <p className='header-menu-right-text'>
                            {t('components.global.header.block.model.coin')}: <span>{dataAmount?.coins}</span>
                          </p>
                        </div>
                        <div className='box-content_box'>
                          <p className='header-menu-right-text'>
                            {t('components.global.header.block.model.messages')}: <span>&infin;</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <nav style={{ right: 0 }} id='nav' className={`${'nav'}${isMenuOpen ? ` ${['nav--open']}` : ''}`} role='navigation'>
          <ul
            className='nav__menu'
            id='menu'
            // @ts-ignore
            tabIndex='-1'
            aria-label='main navigation'
            hidden={!isMenuOpen}
          >
            <div className='box nav__item nav__link boxMenuMobile'>
              <div className='img-box'>
                <img src={data?.profilePhoto?.file?.url} alt='' />
              </div>
              <div className='box-content'>
                <p className='name'>{dataUser?.name}</p>
                <div className='box-content_box'>
                  <p>{t('components.global.header.block.model.coin')}:</p>
                  <p>{dataAmount?.coins}</p>
                </div>
                <div className='box-content_box'>
                  <p>{t('components.global.header.block.model.messages')}: &infin;</p>
                </div>
              </div>
            </div>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/messages' ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/model/messages'>
                {t('components.global.header.block.model.messages')}
              </Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/people' ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/model/people'>
                {t('components.global.header.block.model.people')}
              </Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname.startsWith('/model/profile') ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/model/profile'>
                {t('components.global.header.block.model.profile')}
              </Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/model/friends' ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/model/friends'>
                {t('components.global.header.block.model.friends')}
              </Link>
            </li>
          </ul>
          <div className='menuMobile'>
            <a
              href='#nav'
              className={`nav__toggle ${isMenuOpen ? ` ${['nav__toggle--open']}` : ''}`}
              role='button'
              aria-expanded={isMenuOpen}
              aria-controls='menu'
              onClick={handleMenuToggle}
            >
              <svg
                className={`menuicon ${isMenuOpen ? ` ${['menuicon--open']}` : ''}`}
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='50'
                viewBox='0 0 50 50'
              >
                <title>{t('components.global.header.block.model.toggleMenu')}</title>
                <g>
                  <line className={'menuicon__bar'} x1='13' y1='16.5' x2='37' y2='16.5' />
                  <line className={'menuicon__bar'} x1='13' y1='24.5' x2='37' y2='24.5' />
                  <line className={'menuicon__bar'} x1='13' y1='24.5' x2='37' y2='24.5' />
                  <line className={'menuicon__bar'} x1='13' y1='32.5' x2='37' y2='32.5' />
                  <circle className={'menuicon__circle'} r='23' cx='25' cy='25' />
                </g>
              </svg>
            </a>
          </div>

          <div
            style={{
              opacity: isMenuOpen ? 1 : 0,
              pointerEvents: isMenuOpen ? 'auto' : 'none',
            }}
            className={'splash'}
          ></div>
        </nav>
      </header>
    </>
  )
}

export default Model
