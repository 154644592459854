import React, { useEffect, useState } from 'react'
import { useParticipants } from '@livekit/components-react'
import { setMySelectedRoom, setVideoMembers } from 'store/reducer/videoChat/reducer'
import { useDispatch, useSelector } from 'react-redux'

import { selectVideoChatParticipants } from 'store/reducer/videoChat/selector'
import { getSelectorSetMessagePrivetChats } from 'store/reducer/messages/selectors'
import { sendMessages, setMessagesSelectedId, setMessagesSelectedStaffFalse, setMessagesSelectedUser } from 'store/reducer/messages/reducer'
import { findPrivateChatByParticipants } from '../../../../../../utils/chat/findPrivateChatByParticipants'
import { startTimer } from 'store/reducer/videoChatWithdrawalMoney/reducer'
import { setRoomParticipants } from 'store/reducer/rooms/reducer'
import { getSubscribeUser } from 'store/reducer/socket/selector'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { usePrivateChats } from '../../../../../../hooks/chat/usePrivateChats'
//
const HandlerParticipant = () => {
  const participants = useParticipants()
  const selectParticipants = useSelector(selectVideoChatParticipants)
  const dispatch = useDispatch()

  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const { isLoading: isLoadingPrivateChat, refetch } = usePrivateChats()
  useEffect(() => {
    if (participants.length > 0) {
      const localParticipant = participants[0]?.identity || null
      const remoteParticipant = participants[1]?.identity || null
      dispatch(setVideoMembers({ localParticipant, remoteParticipant }))
      if (localParticipant && remoteParticipant) {
        refetch()
        const obg = {
          recipientId: localParticipant,
          senderId: remoteParticipant,
        }
        dispatch(setRoomParticipants({ localParticipant, remoteParticipant }))
        dispatch(setMySelectedRoom({ localParticipant, remoteParticipant }))
        dispatch(sendMessages(obg))
      }
    }
  }, [participants, dispatch])

  useEffect(() => {
    if (dataPrivateChats && selectParticipants?.remoteParticipant && selectParticipants.localParticipant) {
      const result = findPrivateChatByParticipants(dataPrivateChats, selectParticipants.localParticipant, selectParticipants.remoteParticipant)
      dispatch(setMessagesSelectedStaffFalse())
      dispatch(setMessagesSelectedId(`${result?.privateChat.id}`))
    }
  }, [selectParticipants, dataPrivateChats])

  ////// Логика отвечающия за снятие денег
  useEffect(() => {
    if (selectParticipants?.remoteParticipant && selectParticipants.localParticipant) {
      dispatch(startTimer())
    }
  }, [selectParticipants])
  // логика отвечающия за выходы из комнат
  const { data: userMe } = useUserGetFullMeQuery()
  const localParticipant = participants[0]?.identity || null
  const remoteParticipant = participants[1]?.identity || null
  const subscribeUser = useSelector(getSubscribeUser)
  const currentUserId = userMe?.userData?.user?.id
  const otherParticipantIds = [localParticipant, remoteParticipant].filter(id => id !== currentUserId)
  const targetUserActivities = subscribeUser?.body.filter(item => otherParticipantIds.includes(item.userId))
  const targetUserActivity = targetUserActivities?.[0] || null

  console.log('Target User Activity:', targetUserActivity)
  return null
}
export default HandlerParticipant
