import { FC, useEffect, useState } from 'react'
import { Popup } from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../../types'
import './scss/index.scss'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import useWebSocket from '../../../../socket/useWebSocket'
import { useGlobalConfigGetQuery, useUserGetFullByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { selectRole } from 'store/reducer/token/selector'
import { photoViewClient } from '../../../Photo/photoViewClient'
import { ICallEvent } from '../../../../hooks/socketTs/videoChat'
import imgSupport from '../../../../images/img/imgSupport.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const EnterRoom: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const { handleCall } = useWebSocket()
  const selectedDataPopUp = useSelector(getPopUpData)?.data as ICallEvent | undefined
  const [selectedDataPop, setSelectedDataPop] = useState<ICallEvent | null>(null)
  const { data: dataConfig, refetch } = useGlobalConfigGetQuery()
  const { data: userMe } = useUserGetFullMeQuery()

  const { data: targetUserData } = useUserGetFullByIdQuery({
    // @ts-ignore
    userId:
      userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId
        ? selectedDataPopUp?.body.targetUserId
        : selectedDataPopUp?.body.callerUserId,
  })
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedDataPopUp) {
      setSelectedDataPop(selectedDataPopUp)
    }
  }, [selectedDataPopUp])
  const navigate = useNavigate()
  const handleAccept = () => {
    if (selectedDataPopUp?.body?.roomId) {
      closeModal()
      navigate(`/room/${selectedDataPopUp.body.roomId}`)
    }
  }

  const role = useSelector(selectRole)
  const getPhotoAndName = () => {
    if (!targetUserData)
      return {
        photo: null,
        name: t('ui.popUp.videoPopUp.enterRoom.unknown_status'),
      }
    const callerUserRole = selectedDataPopUp?.body?.callerUserRole

    if (callerUserRole === 'CLIENT' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || t('ui.popUp.videoPopUp.enterRoom.unknown_status'),
      }
    } else if (callerUserRole === 'CLIENT' && role === 'CLIENT') {
      return {
        photo: (
          <>
            <img src={targetUserData?.profilePhoto?.file?.url} alt='' />
          </>
        ),
        name: targetUserData?.model?.name || t('ui.popUp.videoPopUp.enterRoom.unknown_status'),
      }
    } else if (callerUserRole === 'MODEL' && role === 'CLIENT') {
      return {
        photo: (
          <>
            <img src={targetUserData?.profilePhoto?.file?.url} alt='' />
          </>
        ),
        name: targetUserData?.model?.name || t('ui.popUp.videoPopUp.enterRoom.unknown_status'),
      }
    } else if (callerUserRole === 'MODEL' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || t('ui.popUp.videoPopUp.enterRoom.unknown_status'),
      }
    } else if (callerUserRole === 'STAFF') {
      return {
        photo: (
          <>
            {' '}
            <img src={imgSupport} alt='' />
          </>
        ),
        name: t('ui.popUp.videoPopUp.enterRoom.admin_status'),
      }
    } else if (role === 'STAFF') {
      return {
        photo: (
          <>
            {' '}
            <img src={imgSupport} alt='' />
          </>
        ),
        name: t('ui.popUp.videoPopUp.enterRoom.admin_status'),
      }
    } else {
      return { photo: null, name: t('ui.popUp.videoPopUp.enterRoom.unknown_status') }
    }
  }
  const { photo, name } = getPhotoAndName()

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='enter-room-modal'>
        <div className='model-info'>
          <div className='model-photo'>{photo}</div>
          <h3 className='model-name'>{name}</h3>
        </div>
        <p className='enter-room-info'>
          {t('ui.popUp.videoPopUp.enterRoom.videochat_cost_notice')}{' '}
          {targetUserData?.model?.minutePrice *
            (dataConfig?.obligatoryMinutesForRoomWithClientAndModel || 1) *
            (dataConfig?.coefficientForObligatoryMinutesForRoomWithClientAndModel || 1)}{' '}
          {t('ui.popUp.videoPopUp.enterRoom.videochat_cost_notice1')}{' '}
        </p>
        {userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId ? null : (
          <div className='enter-room-buttons'>
            <button className='decline-call' onClick={closeModal}>
              {t('ui.popUp.videoPopUp.enterRoom.cancel_action')}
            </button>
            <button className='accept-call' onClick={handleAccept}>
              {t('ui.popUp.videoPopUp.enterRoom.enter_room')}{' '}
            </button>
          </div>
        )}
      </div>
    </Popup>
  )
}

export default EnterRoom
