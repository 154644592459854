import { popUpOpen } from 'store/reducer/popUp/reducer'
import { store } from 'store/store'
import { toastError, toastInfo } from '../../elements/Notification/Notification'
import { selectRole } from 'store/reducer/token/selector'
import errorMessages from './erorText'
import { useTranslation } from 'react-i18next'

const actionsError = (errorData: any) => {
  const state = store.getState()
  const role = selectRole(state)
  const { t } = useTranslation()

  if (errorData?.error?.data?.type === 'ACCOUNT_COINS_BALANCE_INSUFFICIENT') {
    if (role === 'CLIENT') {
      store.dispatch(popUpOpen('topUpBalance'))
      toastError(errorData?.error?.data?.title)
    } else {
      toastInfo(t('utils.notificationContainerError.actionsError.moneyOff'))
    }
  }
  if (errorData?.error?.data?.type === 'USER_EMAIL_NOT_FOUND') {
    toastError(errorMessages[errorData?.error?.data?.type])
  }
  return null
}

export default actionsError
