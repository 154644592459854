import React, { useEffect } from 'react'
import { popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { selectVideoChatInfoPreviousRooms, selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { selectRole } from 'store/reducer/token/selector'

const Rating = () => {
  const dispatch = useDispatch()
  const videoChatInfoPreviousRooms = useSelector(selectVideoChatInfoPreviousRooms)
  const role = useSelector(selectRole)
  const rooms = useSelector(selectVideoChatObg)
  useEffect(() => {
    if (rooms === null && videoChatInfoPreviousRooms !== null && role === 'CLIENT') {
      dispatch(popUpOpen('rateVideoCall'))
      dispatch(setPopUpData({ data: videoChatInfoPreviousRooms }))
    }
  }, [videoChatInfoPreviousRooms, rooms])
  return null
}

export default Rating
