import React from 'react'
import Logo from '../../Logo/Logo'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Language from '../../../../UI/Language/Language'

const User = ({ isMenuOpen, setIsMenuOpen, data, dataUser, handleMenuToggle }: any) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  return (
    <div className='user'>
      <header className='header' role='banner'>
        <div className='header-menu_left'>
          <Logo />
        </div>
        <nav id='nav__desc'>
          <ul>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/girls' ? 'active' : ''}
            >
              <Link to='/girls'>{t('header.girls')}</Link>
            </li>
          </ul>
        </nav>
        <div className='header-menu_right'>
          <ul>
            <li>
              <Language />
            </li>
            <div className='buttons'>
              <button style={{ margin: '5px' }} onClick={() => navigate('/login')}>
                {t('components.global.header.block.user.title1')}
              </button>
              <button style={{ margin: '5px' }} onClick={() => navigate('/registration')}>
                {t('components.global.header.block.user.title2')}
              </button>
            </div>
          </ul>
        </div>

        <nav style={{ right: 0 }} id='nav' className={`${'nav'}${isMenuOpen ? ` ${['nav--open']}` : ''}`} role='navigation'>
          <ul className='nav__menu' id='menu' tabIndex={-1} aria-label='main navigation' hidden={!isMenuOpen}>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/girls' ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/girls'>
                {t('components.global.header.block.user.girls')}
              </Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/login' ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/login'>
                {t('components.global.header.block.user.title1')}
              </Link>
            </li>
            <li
              onClick={() => {
                setIsMenuOpen(false)
              }}
              className={location.pathname === '/registration' ? 'nav__item active' : 'nav__item'}
            >
              <Link className='nav__link' to='/registration'>
                {t('components.global.header.block.user.title2')}
              </Link>
            </li>
          </ul>
          <div className='menuMobile'>
            <a
              href='#nav'
              className={`nav__toggle ${isMenuOpen ? ` ${['nav__toggle--open']}` : ''}`}
              role='button'
              aria-expanded={isMenuOpen}
              aria-controls='menu'
              onClick={handleMenuToggle}
            >
              <svg
                className={`menuicon ${isMenuOpen ? ` ${['menuicon--open']}` : ''}`}
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='50'
                viewBox='0 0 50 50'
              >
                <title>{t('components.global.header.block.user.toggle_menu')}</title>
                <g>
                  <line className={'menuicon__bar'} x1='13' y1='16.5' x2='37' y2='16.5' />
                  <line className={'menuicon__bar'} x1='13' y1='24.5' x2='37' y2='24.5' />
                  <line className={'menuicon__bar'} x1='13' y1='24.5' x2='37' y2='24.5' />
                  <line className={'menuicon__bar'} x1='13' y1='32.5' x2='37' y2='32.5' />
                  <circle className={'menuicon__circle'} r='23' cx='25' cy='25' />
                </g>
              </svg>
            </a>
          </div>

          <div
            style={{
              opacity: isMenuOpen ? 1 : 0,
              pointerEvents: isMenuOpen ? 'auto' : 'none',
            }}
            className={'splash'}
          ></div>
        </nav>
      </header>
    </div>
  )
}

export default User
