import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useClientGetIdsMutation } from 'store/apiMain/mainApi'
import { selectRole } from 'store/reducer/token/selector'
import {
  getSelectorSetMessagePrivetChats,
  selectorAdminSelectedChatType,
  selectorChangeModelsFetchAndBlock,
  selectorClientsFetchAndBlock,
} from 'store/reducer/messages/selectors'
import { setClients } from 'store/reducer/user/reducer'
import { changeClientsFetchAndBlock, changeModelsFetchAndBlock } from 'store/reducer/messages/reducer'
import { selectClients } from 'store/reducer/user/selectors'

const useFetchClientData = userMe => {
  const dispatch = useDispatch()
  const role = useSelector(selectRole)
  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const [clientGetIds] = useClientGetIdsMutation()
  const selectedChatType = useSelector(selectorAdminSelectedChatType)
  const clientsFetchAndBlock = useSelector(selectorClientsFetchAndBlock)
  const dataClients = useSelector(selectClients)
  const [loadingIds, setLoadingIds] = useState(new Set()) // ID, которые в процессе запроса
  const dataClientsIds = dataClients?.map(item => item.user.id) || []
  const dataPrivateChatClientsIds = dataPrivateChats?.content.map(item => item.privateChat.modelId) || []
  const modelsFetchAndBlock = useSelector(selectorChangeModelsFetchAndBlock)
  useEffect(() => {
    if (userMe?.model === undefined && role === 'MODEL') {
      return
    }
    if (role === 'STAFF') {
      if (modelsFetchAndBlock) {
        return
      }
      dispatch(changeModelsFetchAndBlock())
    }

    let dialogChatsId = []

    if (dataPrivateChats) {
      const content = dataPrivateChats.content
      if (selectedChatType.active === 'view_client' || (role !== 'STAFF' && selectedChatType.active !== 'view_model')) {
        // @ts-ignore
        dialogChatsId = content.map(e => {
          if (role === 'MODEL') return e.privateChat.clientId
          if (role === 'STAFF') return e.privateChat.clientId || e.privateChat.modelId
          return e.privateChat.modelId || e.privateChat.staffId
        })
      } else if (selectedChatType.active === 'view_model' || role === 'STAFF') {
        // @ts-ignore
        dialogChatsId = content.flatMap(e => {
          if (role === 'STAFF') return [e.privateChat.clientId, e.privateChat.modelId].filter(Boolean)
          if (role === 'MODEL') return e.privateChat.clientId
          return e.privateChat.modelId || e.privateChat.staffId
        })
      }
    }

    const uniqueIds = Array.from(new Set(dialogChatsId.filter(Boolean)))

    // Фильтруем только те ID, которых нет в dataClientsIds или в процессе загрузки
    const idsToFetch = uniqueIds.filter(id => !dataClientsIds.includes(id) && !loadingIds.has(id))
    if (idsToFetch.length > 0 && role !== 'CLIENT') {
      const fetchClientData = async () => {
        try {
          // @ts-ignore
          setLoadingIds(prev => new Set([...prev, ...idsToFetch]))
          const response = await clientGetIds({
            page: undefined,
            pageSize: 99999,
            clientFilterDto: {
              ids: idsToFetch,
            },
          }).unwrap()
          dispatch(setClients(response?.content))
        } catch (err) {
          console.error('Ошибка при получении данных клиентов:', err)
        } finally {
          setLoadingIds(prev => {
            const updated = new Set(prev)
            idsToFetch.forEach(id => updated.delete(id))
            return updated
          })
        }
      }

      fetchClientData()
    }
  }, [dataPrivateChats, userMe, role, loadingIds]) // Добавляем зависимости
}

export default useFetchClientData
