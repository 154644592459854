// TimeStartStop.tsx
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './scss/index.scss'
import { selectIsRunning, selectTime } from 'store/reducer/videoChatWithdrawalMoney/selector'
import { pauseTimer, resetTimer, startTimer, tick } from 'store/reducer/videoChatWithdrawalMoney/reducer'
import { formatTime } from '../../../../../../../utils/formatTime'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { toastInfo } from '../../../../../../../elements/Notification/Notification'
import { selectRole } from 'store/reducer/token/selector'
import { SvgBlock } from '../../../../../../../images/svg'
import { useTranslation } from 'react-i18next'

const TimeStartStop: React.FC = () => {
  const dispatch = useDispatch()
  const isRunning = useSelector(selectIsRunning)
  const time = useSelector(selectTime)
  const videoChat = useSelector(selectVideoChatObg)?.room
  const disabled = videoChat?.participantRole === undefined || videoChat?.ownerUserRole === undefined
  const role = useSelector(selectRole)
  const { t } = useTranslation()
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (isRunning) {
      interval = setInterval(() => {
        dispatch(tick())
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isRunning, dispatch])

  const toggleTimer = () => {
    if (disabled) {
      toastInfo(t('components.liveKitCustom.component.button.timeStartStop.no_client'))
      return
    }
    if (isRunning) {
      dispatch(pauseTimer())
    } else {
      dispatch(startTimer())
    }
  }
  console.log(time)

  return (
    <div className='timeStartStop'>
      <p>
        {formatTime(time)} {time < 60 ? <SvgBlock /> : null}
      </p>
      {role === 'CLIENT' ? null : (
        <button className='lk-button lk-chat-toggle' onClick={toggleTimer}>
          {isRunning
            ? t('components.liveKitCustom.component.button.timeStartStop.pause_action')
            : t('components.liveKitCustom.component.button.timeStartStop.start_action')}
        </button>
      )}
    </div>
  )
}

export default TimeStartStop
