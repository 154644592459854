import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  UserSendCurrentEmailConfirmationCodeApiArg,
  UserVerifyCurrentEmailApiArg,
  useUserGetFullMeQuery,
  useUserSendCurrentEmailConfirmationCodeMutation,
  useUserVerifyCurrentEmailMutation,
} from 'store/apiMain/mainApi'
import { FormControl } from '../../../../layouts-elements/FormControl/FormControl'
import { MyFormValues } from './types'
import { useTranslation } from 'react-i18next'
import { toastSuccess } from '../../../../elements/Notification/Notification'

const Email = () => {
  const [editFunc, { isSuccess: isEmailUpdateSuccess }] = useUserSendCurrentEmailConfirmationCodeMutation()
  const [verifyEmail, { isSuccess: isEmailVerifySuccess }] = useUserVerifyCurrentEmailMutation()
  const { data, refetch } = useUserGetFullMeQuery()
  const email = data?.userData?.user?.email
  const id = data?.client?.id
  const [editCheck, setEditCheck] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(data?.userData?.user?.isEmailVerified ?? false)
  const [showVerificationField, setShowVerificationField] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cooldown, setCooldown] = useState(0)

  const { t } = useTranslation()

  useEffect(() => {
    if (data?.userData?.user?.isEmailVerified !== undefined) {
      setIsEmailVerified(data.userData.user.isEmailVerified)
    }
  }, [data])

  useEffect(() => {
    if (isEmailUpdateSuccess) {
      toastSuccess(t('components.user.profile.email.code_sent'))
      setShowVerificationField(true)
      setLoading(false)
      setCooldown(30)
    }
    if (isEmailVerifySuccess) {
      toastSuccess(t('components.user.profile.email.email_verified'))
      setIsEmailVerified(true)
      setShowVerificationField(false)
      setEditCheck(false)
      refetch()
    }
  }, [isEmailUpdateSuccess, isEmailVerifySuccess, refetch])

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(prevCooldown => prevCooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldown])

  const initialValues = {
    email: email || '',
    confirmationCode: '',
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('components.user.profile.invalid_email')).required(t('components.user.profile.email_required')),
    confirmationCode: showVerificationField ? yup.string().required(t('components.user.profile.code_required')) : yup.string(),
  })

  const onSubmit = async (values: MyFormValues) => {
    setLoading(true)
    if (showVerificationField) {
      handleVerifyEmail(values.confirmationCode)
    } else {
      const payload: UserSendCurrentEmailConfirmationCodeApiArg = {
        emailUpdateConfirmationRequest: {
          email: values.email,
        },
      }
      await editFunc(payload)
      setLoading(false)
    }
  }

  const handleVerifyEmail = async (code: string) => {
    const payload: UserVerifyCurrentEmailApiArg = {
      emailVerificationRequest: {
        confirmationCode: code,
      },
    }
    await verifyEmail(payload)
  }

  const resendCode = async () => {
    setLoading(true)
    const payload: UserSendCurrentEmailConfirmationCodeApiArg = {
      emailUpdateConfirmationRequest: {
        email: email,
      },
    }
    await editFunc(payload)
    setLoading(false)
    setCooldown(30)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })

  return (
    <div className='content__box-item'>
      {editCheck ? (
        <form onSubmit={formik.handleSubmit}>
          <h6>{t('components.user.profile.email_label')}</h6>
          <label className='email'>
            <span>{t('components.user.profile.email_label')}</span>
            <FormControl name='email' type='text' formik={formik} placeholder={t('Enter your email')} />
          </label>
          {showVerificationField && (
            <label className='email'>
              <span>{t('components.user.profile.email.verification_code')} </span>
              <FormControl
                name='confirmationCode'
                type='text'
                formik={formik}
                placeholder={t('components.user.profile.email.enter_verification_code')}
              />
            </label>
          )}
          <div className='item-buttons'>
            {showVerificationField && (
              <button type='button' onClick={resendCode} disabled={cooldown > 0 || loading}>
                {cooldown > 0 ? `${t('components.user.profile.resend_code')} (${cooldown})` : t('components.user.profile.resend_code')}
              </button>
            )}
            <button onClick={() => setEditCheck(false)} type='button'>
              {t('components.user.profile.close')}
            </button>
            <button type='submit' disabled={loading}>
              {loading ? t('components.user.profile.email.loading_message') : t('components.user.profile.email.confirm_email')}
            </button>
          </div>
        </form>
      ) : (
        <>
          <h6>{t('components.user.profile.email_label')}</h6>
          <label>
            <span>{t('components.user.profile.email_label')}</span>
            <p>{email}</p>
            {isEmailVerified ? (
              <span className='success'>{t('components.user.profile.email_verified')}</span>
            ) : (
              <span className='error'>{t('components.user.profile.email.confirm_email')}</span>
            )}
          </label>
          <div className='item-buttons'>
            {!isEmailVerified && <button onClick={() => setEditCheck(true)}>{t('components.user.profile.email_verified')}</button>}
            {(isEmailVerified || data?.userData?.user?.role != 'CLIENT') && (
              <button onClick={() => setEditCheck(true)}>{t('components.user.profile.edit_action')} </button>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Email
